import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import {
  EditorDrawer,
  HeaderDialog,
  LeftMenu,
  RightMenu,
  RightPreview,
  RightSectionSettingsMenu,
  RightThemeMenu,
  FormSettingMenu,
  RowSettingMenu,
  SelectedBoxDivSettingMenu,
  StripeBuyButtonModal,
  PlanSetting,
  ListSettingMenu,
  IframeSettingMenu,
  PlanCardSettingMenu,
} from "./components";
import { Button, ButtonGroup, Divider, Dialog, IconButton, Stack, Modal, Typography } from "@mui/material";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";
import {
  _sections_list_against_type,
  _section_type,
} from "../../DAL/sections/section";
import { useSnackbar } from "notistack";
import { DragDropContext } from "react-beautiful-dnd";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { app_type } from "../../config/config";
import { _get_user, _is_dev } from "../../DAL/localStorage/localStorage";
import format from "date-fns/format/index";
import Loader from "../../components/Loader/Loader";
import ImageEditor from "./components/Image-Editor/ImageEditor";
import ElementSourceCode from "../../components/Element-Source-Code/ElementSourceCode";
import CustomClassMenu from "../../components/Custom-Class-Menu/CustomClassMenu";
import CustomJS from "../../components/Custom-JS/CustomJS";
import ReplaceableElementModal from "./components/Replaceable-Element-Modal/ReplaceableElementModal";
import IconEditor from "./components/Icon-Editor/IconEditor";
import ButtonEditor from "./components/Button-Editor/ButtonEditor";
import TextEditor from "./components/Text-Editor/TextEditor";
import IframeEditor from "./components/Iframe-Editor/IFrameEditor";
import AIAssistantModal from "./components/AI-Assistant-Modal/AiAssistantModal";
import AddSectionDialog from "./components/Add-Section/AddSectionModal";
import EventSettings from "./components/Event-Setting/EventSettings";

// const drawerWidth = 250;
// const drawerWidthRight = 400;
// const drawerWidthEditorMenu = 600;
// const drawerWidthRightSectionSetting = 400;
// const closedDrawerWidth = 50;
// const closedDrawerWidthHover = 195;
// const expandDrawerWidth = 350;
// const sectionDrawerWidth = 315;
// const elementsDrawerWidth = 275;

export default function ClippedDrawer(props) {
  const {
    setSelectedElement,
    getIsDevState,
    leftMenuOpen,
    setLeftMenuOpen,
    rightMenuOpen,
    setRightMenuOpen,
    editorMenuOpen,
    setEditorMenuOpen,
    OpenRowSetting,
    setOpenRowSetting,
    SelectedRow,
    setSelectedRow,
    // theme menu
    planSettingsMenuOpen,
    setPlanSettingsMenuOpen,
    rightThemeMenuOpen,
    setRightThemeMenuOpen,
    handleOnDragEnd,
    sectionSettingsMenuOpen,
    setSectionSettingsMenuOpen,
    setRootValuesFromProject,
    getEditModeState,
    rootValues,
    FormSettingsMenuOpen,
    setFormSettingsMenuOpen,
    isSaveLoading,
    setHistoyStack,
    setRedoStack,
    ReRenderHtml,
    CheckElementIsRow,

    openSelectedBoxDiv,
    setOpenSelectedBoxDiv,
    // editor
    leftDrawerType,
    setLeftDrawerType,
    selectedlistItem,
    setSelectedlistItem,
    drawerWidth,
    setDrawerWidth,
    showItemInLeftDrawer,
    openDeveloperOptionMenu,
    setOpenDeveloperOptionMenu,
    developerOptionMenuType,
    setDeveloperOptionMenuType,
    // replaceableelement
    openReplaceElementMenu,
    setOpenReplaceElementMenu,

    // list menu
    openListBlockDrawer,
    setOpenListBlockDrawer,
    selectedElement,
    checkElementIsForm,
    getSelectedSectionForm,
    showSectionItemInDrawer,

    // iframe
    openIframeSettingMenu,
    setOpenIframeSettingMenu,
    checkElementIsIFRAME,
    selectedIFRAME,
    openPlanCardSetting,
    setOpenPlanCardSetting,
    checkElementIsPaymentCard,

    // AI Writer Modal
    aIModalOpen,

    // drawer width

    drawerWidthRight,
    drawerWidthEditorMenu,
    drawerWidthRightSectionSetting,
    closedDrawerWidth,
    closedDrawerWidthHover,
    expandDrawerWidth,
    sectionDrawerWidth,
    elementsDrawerWidth,
    selectedMenu,
    setSelectedMenu,
    setIndexForAddSection,
    // Event States
    setEventSettingsMenuOpen,
    eventSettingsMenuOpen,
  } = useBuilder();
  // location

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  // sections state
  const [sectionType, setSectionType] = useState([]);
  const [sectionsList, setSectionsList] = useState([]);
  const [isSectionListLoading, setIsSectionListLoading] = useState(false);
  const [isMouseEnter, setIsMouseEnter] = useState(false);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    appBar: {
      // zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      height: "100%",
    },

    drawerPaper: {
      width: drawerWidth,
      marginTop: 40,
      transition: "0.3s ease-in-out",
      boxShadow: "3px 3px 6px rgba(145, 145, 145, 0.619)",
    },
    drawerRight: {
      width: drawerWidthRight,
      flexShrink: 0,
    },

    drawerEditorMenu: {
      width: drawerWidthEditorMenu,
      flexShrink: 0,
    },

    drawerPaperRight: {
      width: drawerWidthRight,
      // marginTop: 60,
    },

    drawerPaperEditorMenu: {
      width: drawerWidthEditorMenu,
    },

    drawerPaperRightSectionSetting: {
      overflowX: "hidden",
      width: drawerWidthRightSectionSetting,
      // marginTop: 60,
    },
    drawerContainer: {
      overflow: "auto",
      height: "100%",
      display: "flex",
    },
    drawerContainerRight: {},

    content: {
      flexGrow: 1,
      // padding: theme.spacing(3),
    },
    nested: {
      // paddingLeft: theme.spacing(4),
    },
  }));
  const classes = useStyles();

  const showSectionsInDrawer = (menu) => {
    setLeftDrawerType("expand");
    setDrawerWidth(sectionDrawerWidth);
    setSelectedMenu(menu);
  };

  const handleMouseEnter = () => {
    if (leftDrawerType === "closed") {
      setDrawerWidth(closedDrawerWidthHover);
      setIsMouseEnter(true);
    }
  };

  const handleMouseLeaves = () => {
    if (leftDrawerType === "closed") {
      setDrawerWidth(closedDrawerWidth);
      setIsMouseEnter(false);
      setIsMouseEnter(false);
    }
  };

  const hoverDrawer = () => {
    if (leftDrawerType !== "closed") {
      return;
    }
    setLeftDrawerType("expand");
    setDrawerWidth(expandDrawerWidth);
  };

  const leaveHoverDrawer = () => {
    setLeftDrawerType("closed");
    setDrawerWidth(closedDrawerWidth);
    setIsMouseEnter(false);
    setSelectedlistItem("");
    setSelectedMenu("");
    setIndexForAddSection(0);
  };
  const leaveSectionsListDrawer = () => {
    setDrawerWidth(closedDrawerWidthHover);
    setSelectedMenu("");
  };

  const handleTypeClick = () => {};

  const handleClick = () => {
    setLeftMenuOpen(true);
  };

  const handleSetThemeValuesFromProject = () => {
    if (!location.state) {
      if (params?.project_id && params?.page_id) {
        navigate("/builder-redirect-controller", {
          state: { project_id: params?.project_id, page_id: params?.page_id },
        });
      } else {
        navigate("/builder-redirect-controller");
      }

      return;
    }
    const {
      page_name,
      page_slug,
      page_group_id,
      mode,
      template_category,
      theme_heading_color,
      theme_primary_color,
      theme_secondary_color,
      text_primary_color,
      text_secondary_color,
      hover_text_color,
      hover_background_color,
      text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      //
      input_placeholder_color,
      input_text_color,
      input_background_color,
      input_border_color,
      menu_color,
      menu_hover_color,

      meta_title,
      meta_description,
      google_analytics_script,
      body_script,
      image,
      createdAt,
      updatedAt,
      project,
      version,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    } = location.state.page;

    const {
      _id,
      user,
      project_name,
      is_approved,
      is_completed,
      is_published,
      status,
      success_message,
      failure_message,
      domain,
      order,
      fav_icon,
      logo,
      payment_page_id,
    } = location.state.project;

    const { is_template, template } = location.state;

    const formattedValues = {
      _id,
      image,
      page_name,
      page_slug,
      page_group_id: page_group_id ? page_group_id : "",
      version: version ? version : null,
      project,
      project_name: project_name,
      project_category: template_category,
      template: template ? template : location.state.page.template,
      theme_heading_color,
      primary_color: theme_primary_color,
      secondary_color: theme_secondary_color,
      primary_text_color: text_primary_color,
      secondary_text_color: text_secondary_color,
      hover_text_color: hover_text_color,
      hover_background_color: hover_background_color,
      font_family: text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      //
      input_border_color,
      input_placeholder_color,
      input_text_color,
      input_background_color,

      menu_color,
      menu_hover_color,

      // meta values
      meta_title,
      meta_description,
      fav_icon,
      logo,
      google_analytics_script,
      body_script,

      success_message,
      failure_message,
      domain,
      // further status
      theme_mode: mode,
      is_approved,
      is_completed,
      is_published,
      status,
      order,
      createdAt,
      updatedAt,
      page_id: location.state.page._id,
      // check if list not available than add page data to list
      page_list: location.state.project.pages
        ? location.state.project.pages
        : [location.state.page],

      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
      payment_page_id: payment_page_id ? payment_page_id : null,
    };
    const options = {
      is_template,
      template,
    };

    console.log(payment_page_id, formattedValues, "formattedalues");

    setRootValuesFromProject(formattedValues, options);

    // empty the route state

    navigate("/builder", {
      state: null,
    });
  };

  /* ------------------------------------------------------
                SECTIONS TYPE AND SECTIONS LIST
  ------------------------------------------------------- */

  const fetchSectionsType = async () => {
    const resp = await _section_type();
    if (resp.code === 200) {
      setSectionType(resp.section_type);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const fetchSectionsAgainstType = async (section_type_id) => {
    if (!section_type_id) {
      return;
    }

    const user = _get_user();
    const postData = {
      type: "Type",
      id: section_type_id,
      app_type: `${user?.type}`,
    };
    setIsSectionListLoading(true);
    const resp = await _sections_list_against_type(postData);
    if (resp.code === 200) {
      setIsSectionListLoading(false);
      getIsDevState()
        ? setSectionsList(resp.section)
        : sortSection(resp.section);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const sortSection = (section) => {
    let array = [];
    section.map((sec) => {
      if (sec.is_published) {
        array.push(sec);
      }
    });
    setSectionsList([...array]);
  };

  const getSectionsType = () => {
    let array = sectionType;
    if (rootValues?.page_group_id === "" && _is_dev() != true) {
      const indexToRemove = array.findIndex(
        (obj) => obj.name === "Book A Call"
      );
      if (indexToRemove !== -1) {
        array.splice(indexToRemove, 1);
      }
    }
    return array;
  };

  const getSectionsList = () => {
    return sectionsList;
  };

  /*-------------------------------------------------------
    -------------------------------------------------------
    ------------------------------------------------------- */
  useEffect(() => {
    if (selectedMenu && selectedMenu._id) {
      fetchSectionsAgainstType(selectedMenu._id);
    }
  }, [selectedMenu]);

  useEffect(() => {
    setHistoyStack([]);
    setRedoStack([]);
    fetchSectionsType();
    handleSetThemeValuesFromProject();
    //check for Navigation Timing API support
  }, []);

  return (
    <DragDropContext
      onDragStart={({ draggableId, source }) => {
        console.log(JSON.parse(draggableId), source, "kfdskfjlsdkfdsjklfdjs");
      }}
      onDragEnd={handleOnDragEnd}
    >
      <div className={classes.root}>
        <CssBaseline />
        {/* Left Menu */}
        {getEditModeState() && (
          <Drawer
            id="LeftDrawer"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeaves}
            className={leftMenuOpen ? classes.drawer : ""}
            variant="persistent"
            open={leftMenuOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerContainer}>
              <LeftMenu
                leftDrawerType={leftDrawerType}
                showSectionsInDrawer={showSectionsInDrawer}
                showItemInDrawer={showItemInLeftDrawer}
                showSectionItemInDrawer={showSectionItemInDrawer}
                leaveHoverDrawer={leaveHoverDrawer}
                leaveSectionsListDrawer={leaveSectionsListDrawer}
                selectedMenu={selectedMenu}
                selectedlistItem={selectedlistItem}
                getSectionsType={getSectionsType}
                getSectionsList={getSectionsList}
                isSectionListLoading={isSectionListLoading}
                isMouseEnter={isMouseEnter}
              />
            </div>
          </Drawer>
        )}

        {/* Right Menu */}
        <Drawer
          className={rightMenuOpen ? classes.drawerRight : ""}
          variant="temporary"
          anchor="right"
          open={rightMenuOpen}
          classes={{
            paper: classes.drawerPaperRight,
          }}
          onClose={() => setRightMenuOpen(false)}
        >
          <div className={classes.drawerContainerRight}>
            <RightMenu />
          </div>
        </Drawer>

        {/* Right Row Setting menu */}
        <Drawer
          className={OpenRowSetting ? classes.drawerRight : ""}
          variant="temporary"
          anchor="right"
          open={OpenRowSetting}
          classes={{
            paper: classes.drawerPaperRight,
          }}
          onClose={() => {
            setOpenRowSetting(false);
            CheckElementIsRow(SelectedRow);
          }}
        >
          <div className={classes.drawerContainerRight}>
            <RowSettingMenu />
          </div>
        </Drawer>
        {/* Right Row Setting menu */}
        <Drawer
          className={openIframeSettingMenu ? classes.drawerRight : ""}
          variant="temporary"
          anchor="right"
          open={openIframeSettingMenu}
          classes={{
            paper: classes.drawerPaperRight,
          }}
          onClose={() => {
            setOpenIframeSettingMenu(false);
            checkElementIsIFRAME(selectedIFRAME);
          }}
        >
          <div className={classes.drawerContainerRight}>
            <IframeSettingMenu />
          </div>
        </Drawer>
        {/* Right Row Setting menu */}
        <Drawer
          className={openPlanCardSetting ? classes.drawerRight : ""}
          variant="temporary"
          anchor="right"
          open={openPlanCardSetting}
          classes={{
            paper: classes.drawerPaperRight,
          }}
          onClose={() => {
            setOpenPlanCardSetting(false);
            checkElementIsPaymentCard(selectedElement);
          }}
        >
          <div className={classes.drawerContainerRight}>
            <PlanCardSettingMenu />
          </div>
        </Drawer>

        {/* Right Selected Box DIv Setting menu */}
        {/* selectedBoxDiv,
    setSelectedBoxDiv,
    openSelectedBoxDiv,
    setOpenSelectedBoxDiv, */}
        <Drawer
          className={openSelectedBoxDiv ? classes.drawerRight : ""}
          variant="temporary"
          anchor="right"
          open={openSelectedBoxDiv}
          classes={{
            paper: classes.drawerPaperRight,
          }}
          onClose={() => {
            setOpenSelectedBoxDiv(false);
          }}
        >
          <div className={classes.drawerContainerRight}>
            <SelectedBoxDivSettingMenu />
          </div>
        </Drawer>

        {/* Editor Drawer */}
        <Drawer
          className={editorMenuOpen ? classes.drawerEditorMenu : ""}
          variant="temporary"
          disableEnforceFocus={true}
          anchor="right"
          open={editorMenuOpen}
          classes={{
            paper: classes.drawerPaperEditorMenu,
          }}
          onClose={() => setEditorMenuOpen(false)}
        >
          <div className={classes.drawerContainerRight}>
            <EditorDrawer />
          </div>
        </Drawer>

        {/* Theme Menu */}
        <Drawer
          className={rightMenuOpen ? classes.drawerRight : ""}
          variant="temporary"
          anchor="right"
          open={rightThemeMenuOpen}
          classes={{
            paper: classes.drawerPaperRight,
          }}
          onClose={() => setRightThemeMenuOpen(false)}
        >
          <div className={classes.drawerContainerRight}>
            <RightThemeMenu />
          </div>
          <div class="col-12 my-3 ps-3 ">
            <button
              onClick={() => setRightThemeMenuOpen(false)}
              type="button"
              class="btn btn-secondary me-1"
            >
              Close
            </button>
          </div>
        </Drawer>

        {/* Section Settings Menu */}
        <Drawer
          className={rightMenuOpen ? classes.drawerRight : ""}
          variant="temporary"
          anchor="right"
          open={sectionSettingsMenuOpen}
          classes={{
            paper: classes.drawerPaperRightSectionSetting,
          }}
          onClose={() => setSectionSettingsMenuOpen(false)}
        >
          <div className={classes.drawerContainerRight}>
            <RightSectionSettingsMenu
              setSectionSettingsMenuOpen={setSectionSettingsMenuOpen}
            />
          </div>
        </Drawer>

        {/* Event Setting */}

        <Drawer
          variant="temporary"
          anchor="right"
          open={eventSettingsMenuOpen}
          classes={{
            paper: classes.drawerPaperRightSectionSetting,
          }}
          onClose={() => setEventSettingsMenuOpen(false)}
        >
          <div className={classes.drawerContainerRight}>
            <EventSettings />
          </div>
        </Drawer>

        {/* Close Event Setting */}

        {/* Form Setting Menu */}
        <Drawer
          className={rightMenuOpen ? classes.drawerRight : ""}
          variant="temporary"
          anchor="right"
          open={FormSettingsMenuOpen}
          classes={{
            paper: classes.drawerPaperRightSectionSetting,
          }}
          onClose={() => {
            const { form, status } = getSelectedSectionForm();
            checkElementIsForm(form[0]);
            setFormSettingsMenuOpen(false);
          }}
        >
          <div className={classes.drawerContainerRight}>
            <FormSettingMenu
              setFormSettingsMenuOpen={setFormSettingsMenuOpen}
            />
          </div>
        </Drawer>
        {/*  Plan Setting Menu */}
        <Drawer
          variant="temporary"
          anchor="right"
          open={planSettingsMenuOpen}
          classes={{
            paper: classes.drawerPaperRightSectionSetting,
          }}
          onClose={() => setPlanSettingsMenuOpen(false)}
        >
          <div className={classes.drawerContainerRight}>
            <PlanSetting />
          </div>
        </Drawer>
        {/*  Plan Setting Menu */}
        <Drawer
          variant="temporary"
          anchor="right"
          open={openListBlockDrawer}
          classes={{
            paper: classes.drawerPaperRightSectionSetting,
          }}
          onClose={() => setOpenListBlockDrawer(false)}
        >
          <div className={classes.drawerContainerRight}>
            <ListSettingMenu />
          </div>
        </Drawer>

        {/* stripe button setting modal */}
        <StripeBuyButtonModal />

        {/* replaceable elementModal */}
        <ReplaceableElementModal />

        {/* imageEditor */}
        <ImageEditor />
        {/* iconEditor */}
        <IconEditor />
        {/* Text Editor */}
        <TextEditor />
        {/* ButtonEditor */}
        <ButtonEditor />
        {/* IframeEditor */}
        <IframeEditor />
        {/* Ai Assistant Modal  */}
        {aIModalOpen && <AIAssistantModal />}

        {/* Add Section dialog  */}
        <AddSectionDialog />
        <Drawer
          anchor="bottom"
          ModalProps={{
            keepMounted: false,
          }}
          open={openDeveloperOptionMenu}
          onClose={() => {
            setOpenDeveloperOptionMenu(false);
            setSelectedElement(null);
          }}
          variant="temporary"
        >
          <Stack direction={"row"} sx={{ padding: 2 }}>
            <ButtonGroup
              onClick={(e) => {
                setDeveloperOptionMenuType(e.target.value);
              }}
              // variant="text"
            >
              <Button
                variant={
                  developerOptionMenuType == "source-code" ? "contained" : ""
                }
                value={"source-code"}
              >
                Source Code
              </Button>
              <Button
                variant={
                  developerOptionMenuType == "custom-class" ? "contained" : ""
                }
                value={"custom-class"}
              >
                Custom Class
              </Button>
              <Button
                variant={
                  developerOptionMenuType == "custom-js" ? "contained" : ""
                }
                value={"custom-js"}
              >
                Custom JS
              </Button>
            </ButtonGroup>
          </Stack>
          <Divider />
          <Stack sx={{ height: "45vh", overflowY: "scroll" }}>
            {developerOptionMenuType == "source-code" && <ElementSourceCode />}
            {developerOptionMenuType == "custom-class" && <CustomClassMenu />}
            {developerOptionMenuType == "custom-js" && <CustomJS />}
          </Stack>
        </Drawer>

        {!leftMenuOpen && (
          <>
            <span className="left-menu-toggle">
              <IconButton onClick={handleClick}>
                <ArrowForwardIosIcon />
              </IconButton>
            </span>
          </>
        )}
        <main className={classes.content}>
          {/* Right preview */}

          <RightPreview />
        </main>
        <HeaderDialog />
      </div>
    </DragDropContext>
  );
}
