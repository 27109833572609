import { Icon } from "@iconify/react";
import { Tooltip } from "@mui/material";
import React from "react";

export default function ButtonWidth({
  buttonwidth,
  handleChangeInClass,
  classesList,
}) {
  return (
    <>
      <div class="mb-2">
        {/* <label for="edit-able-text-input" class="form-label">
              Text Transform
            </label> */}

        {/* icons toolbar */}
        <div className="font-size-xsm mt-2">
          <Tooltip title={"Reset"} placement="top" arrow>
            <span
              className={buttonwidth ? "wb-icons-box" : "wb-icons-box-active"}
              onClick={() =>
                handleChangeInClass("buttonwidth", null, classesList)
              }
            >
              <Icon icon="ant-design:stop-outlined" />
            </span>
          </Tooltip>

          {classesList.map((cls) => (
            <Tooltip title={cls.label} placement="top" arrow>
              <span
                className={
                  buttonwidth === cls.value
                    ? "wb-icons-box-active " + cls.value
                    : "wb-icons-box " + cls.value
                }
                onClick={() =>
                  handleChangeInClass("buttonwidth", cls.value, classesList)
                }
              >
                {cls.label}
              </span>
            </Tooltip>
          ))}
        </div>
      </div>
    </>
  );
}
