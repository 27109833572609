import React, { useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SettingsIcon from "@mui/icons-material/Settings";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useEffect } from "react";
import { Backdrop, Tooltip } from "@mui/material";
import Loader from "../../../../components/Loader/Loader";
import { Icon } from "@iconify/react";
import CustomConfirmation from "../../../../components/CustomConfirmation";
import DevicesPreview from "../Devices-Preview/DevicesPreview";
import { CloseAllEditor } from "../../../../utils/inlineeTinyEditor";
function RightPreview() {
  const {
    getSelectedSections,
    handleRemoveSelection,
    handleUpSelection,
    handleDownSelection,
    getRootValues,
    handleOpenSectionSettings,
    handleOpenFormSetting,
    handleOpenPlanSetting,
    handleDuplicateSection,
    getEditModeState,
    fetchFileFromURL,
    isPreviewLoading,
    setOpenRowSetting,
    setSelectedRow,
    setSelectedRowClasses,
    getSelectedSectionFormByID,
    getSelectedSectionPlanByID,
    checkIsEditorElement,
    setRightMenuAnchorEl,
    CheckElementIsRow,
    showSectionItemInDrawer,
    setIndexForAddSection,
    setAddSectionDialog,
    handleOpenEventSetting,
    getSelectedSectionType,
    isSaveLoading,
  } = useBuilder();

  const [customJS, setCustomJS] = useState("");
  const [Delmsg, setDelmsg] = useState(false);
  const [TargetSection, setTargetSection] = useState("");
  const add_custom_js_files = async () => {
    const js = await fetchFileFromURL(
      `https://builder-templates-bucket.s3.amazonaws.com/asserts-db/js/${SelectedPage}.js`
    );

    console.log(js, "JS");
    setCustomJS(js);
  };

  const handleScript = () => {
    const script = document.createElement("script");
    script.src = `https://accelerator-builder-bucket.s3.amazonaws.com/61fd682bbf36a54280edebeb/js/${SelectedPage}.js`;
    script.async = true;
    document.body.appendChild(script);
  };
  const getFormData = (id) => {
    let { form, status } = getSelectedSectionFormByID(id);
    if (form && form.length > 0) {
      let form_seting_value = form[0].getAttribute("enable_form_setting");

      if (form_seting_value == false || form_seting_value == "false") {
        status = false;
      }
    }
    return { form, status };
  };
  const getPlanCard = (id) => {
    let { payment_plan_card, status } = getSelectedSectionPlanByID(id);

    return { payment_plan_card, status };
  };

  const getSectionType = (id, type) => {
    let { SectionElement, status } = getSelectedSectionType(id, type);
    return { SectionElement, status };
  };

  useEffect(() => {}, [getSelectedSections()]);

  if (isPreviewLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1200 }}
        open={true}
        // onClick={handleClose}
      >
        <Loader />
        <span className="mt-5 pt-5">Please wait! Loading your project...</span>
      </Backdrop>
    );
  }
  const handledeletesection = () => {
    handleRemoveSelection(TargetSection);
    setDelmsg(false);
  };
  const Get_Tag_and_Class = (element, name, search) => {
    let tagname = element.tagName;
    if (tagname == name) {
      let classname = element.className ? element.className : null;
      if (classname != null) {
        let result = classname.search(search);
        if (result >= 0) {
          return true;
        }
      }
    }
    return false;
  };

  const handleShowFormOfCalendarSection = (section) => {
    const element = document.getElementById(section.section_id);
    console.log(element, section, "cmdckdmcdkmckdkmckd");
    const calenderContainer = element.querySelectorAll(
      ".wb-calendar-container"
    );
    calenderContainer[0].style.display = "none";
    const formContainer = element.querySelectorAll(".form-container");
    formContainer[0].style.display = "block";
  };

  return (
    <>
      <CustomConfirmation
        open={Delmsg}
        setOpen={setDelmsg}
        handleAgree={handledeletesection}
        title="Are you sure you want to remove this Section?"
      />

      {getSelectedSections().map((section) => (
        <style
          key={section.section_id}
          dangerouslySetInnerHTML={{ __html: section.section_css }}
        ></style>
      ))}
      <style
        dangerouslySetInnerHTML={{
          __html: getRootValues(),
        }}
      ></style>

      <Droppable droppableId="preview-sections">
        {(provided) => (
          <div
            id="preview-div"
            className="wb-preview-div"
            {...provided.droppableProps}
            ref={provided.innerRef}
            onClick={(e) => {
              setRightMenuAnchorEl(null);
              let element = e.target;
              let is_editor = false;
              while (element && element.parentNode) {
                if (
                  element.classList &&
                  element.classList.contains("wb-editor-block")
                ) {
                  is_editor = true;
                  break;
                }
                element = element.parentNode;
              }
              if (is_editor) return;

              CloseAllEditor();
            }}
          >
            {getSelectedSections().map((section, index) => {
              return (
                <Draggable
                  isDragDisabled
                  key={section.section_id}
                  draggableId={section.section_id}
                  index={index}
                >
                  {(provided) => (
                    <div
                      className="edit-able-section"
                      key={section.section_id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div
                        className="break-able-section"
                        id={section.section_id}
                        name={section.section_name}
                      >
                        <div
                          style={{ pointerEvents: isSaveLoading && "none" }}
                          // className="edit-able-section-data"
                          onMouseOver={(e) => {
                            e.preventDefault();
                            let element = e.target;

                            CheckElementIsRow(element);
                          }}
                          onMouseLeave={() => {
                            let list =
                              document.getElementsByClassName("wp-row-block");
                            for (let i = 0; i < list.length; i++) {
                              list[i].remove();
                            }
                          }}
                          dangerouslySetInnerHTML={{
                            __html: section.section_html,
                          }}
                        ></div>

                        {/* to stop from removing FUll div and crashing while saving action to stack Div */}
                        <div></div>
                      </div>
                      <div className="edit-able-section-actions">
                        {getFormData(section.section_id).status &&
                          !getSectionType(
                            section.section_id,
                            "wb-calendar-container"
                          ).status && (
                            <Tooltip title="Form Setting">
                              <Icon
                                icon="healthicons:health-worker-form"
                                width="22"
                                height="22"
                                className="edit-able-section-actions-delete"
                                onClick={() => {
                                  handleOpenFormSetting(section.section_id);
                                  let obj = getSectionType(
                                    section.section_id,
                                    "wb-calendar-container"
                                  );
                                  if (obj.status) {
                                    handleShowFormOfCalendarSection(section);
                                  }
                                }}
                              />
                            </Tooltip>
                          )}
                        {getSectionType(
                          section.section_id,
                          "wb-calendar-container"
                        ).status && (
                          <Tooltip title="Event Setting">
                            <Icon
                              icon="material-symbols:call-log-outline"
                              width="22"
                              height="22"
                              className="edit-able-section-actions-delete"
                              onClick={() =>
                                handleOpenEventSetting(section.section_id)
                              }
                            />
                          </Tooltip>
                        )}
                        {getPlanCard(section.section_id).status && (
                          <Tooltip title="Payment Plans Setting">
                            <Icon
                              icon="mdi:cart"
                              width="22"
                              height="22"
                              className="edit-able-section-actions-delete"
                              onClick={() =>
                                handleOpenPlanSetting(section.section_id)
                              }
                            />
                          </Tooltip>
                        )}
                        <Tooltip title="Section Setting">
                          <SettingsIcon
                            className="edit-able-section-actions-delete"
                            onClick={() =>
                              handleOpenSectionSettings(section.section_id)
                            }
                          />
                        </Tooltip>

                        <Tooltip title="Duplicate Section">
                          <Icon
                            icon="heroicons-outline:document-duplicate"
                            width="22"
                            height="22"
                            className="edit-able-section-actions-delete"
                            onClick={() => handleDuplicateSection(section)}
                          />
                        </Tooltip>
                        <Tooltip title="Move Section Up">
                          <ArrowUpwardIcon
                            className="edit-able-section-actions-delete"
                            onClick={() =>
                              handleUpSelection(section.section_id)
                            }
                          />
                        </Tooltip>
                        <Tooltip title="Move Section Down">
                          <ArrowDownwardIcon
                            className="edit-able-section-actions-delete"
                            onClick={() =>
                              handleDownSelection(section.section_id)
                            }
                          />
                        </Tooltip>

                        {getEditModeState() && (
                          <Tooltip title="Remove Section">
                            <HighlightOffIcon
                              className="edit-able-section-actions-delete"
                              onClick={() => {
                                setTargetSection(section.section_id);
                                setDelmsg(true);
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>
                      <div
                        className="edit-able-section-actions-add-section"
                        onClick={() => {
                          setAddSectionDialog(true);
                          setIndexForAddSection(index + 1);
                        }}
                      >
                        <Icon icon="ic:baseline-add" width="22" height="22" />
                        <span>Add Section</span>
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <DevicesPreview />
      {getSelectedSections().map((section) => (
        <script
          key={section.section_id}
          dangerouslySetInnerHTML={{ __html: section.section_js }}
        ></script>
      ))}

      {/* <button onClick={handleScript}>add script</button> */}
    </>
  );
}

export default RightPreview;
