import { Stack, TextField, Typography } from "@mui/material";
import React from "react";

const StripeBuyButtonKeys = ({ inputs, handleChange }) => {
  return (
    <>
      <Stack sx={{ paddingX: 1,paddingTop:3 }} spacing={3}>
        <TextField
          label="button_id"
          name="button_id"
          placeholder="buy_btn_***********************"
          value={inputs.button_id}
          onChange={handleChange}
        />

        <TextField
          label="publishable_key"
          name="publishable_key"
          placeholder="pk_*****************************"
          value={inputs.publishable_key}
          onChange={handleChange}
        />
      </Stack>
    </>
  );
};

export default StripeBuyButtonKeys;
