import React from "react";
import { RowDivManipulation } from "../Row-Div-Manipulation/RowDivManipulation";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AnimationSelect from "../../../Right-Theme-Menu/components/Animations/AnimationSelect";
import { RowDivManipulationWithIcon } from "../Row-Div-Manipulation/RowDivManipulationWithIcon";

export const RowBlockClasses = ({
  // justify content
  justify_content,
  justify_value,
  // align item
  align_items,
  align_items_value,
  // row and row reverse classes
  row,
  row_value,
  // handler
  handleChangeRowClass,
  // Animation
  handleChangeInAnimation,
  animate_animation_list,
  animation,
}) => {
  return (
    <div className="row ">
      {/* classes of row and row reverse */}
      <RowDivManipulation
        label="Row"
        classlist={row}
        value={row_value ? row_value : "row"}
        handleChangeRowClass={handleChangeRowClass}
      />
      {/* classes of justify content */}
      <RowDivManipulationWithIcon
        label="Horizontal Alignment"
        classlist={justify_content}
        value={justify_value ? justify_value : "justify-content-start"}
        handleChangeRowClass={handleChangeRowClass}
      />

      {/* classes of align items */}
      <RowDivManipulationWithIcon
        label="Vertical Alignment"
        classlist={align_items}
        value={align_items_value ? align_items_value : "align-items-start"}
        handleChangeRowClass={handleChangeRowClass}
      />

      {/* <div className="col-12 mb-2">
        <div className="col-12">
          <AnimationSelect
            label="All Animation"
            handleChange={handleChangeInAnimation}
            data={animate_animation_list}
            value={animation}
            showFrequent={true}
          />
        </div>
      </div> */}
    </div>
  );
};
