import React, { useEffect, useRef, useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";

import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  Modal,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MicIcon from "@mui/icons-material/Mic";
import RemoveIcon from "@mui/icons-material/Remove";
import ClearIcon from "@mui/icons-material/Clear";
import { chat_bot, robot_hi } from "../../../../assets";
import { Icon } from "@iconify/react";
import { _send_query_to_ai } from "../../../../DAL/AIAssistant/ai";
import { useSnackbar } from "notistack";
import CopyToClipboard from "react-copy-to-clipboard";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { _get_user } from "../../../../DAL/localStorage/localStorage";
import { s3baseUrl } from "../../../../config/config";
import { useSpeechSynthesis } from "react-speech-kit";
import CustomConfirmation from "../../../../components/CustomConfirmation";

const AIAssistantModal = () => {
  const latestMessage = useRef(null);
  const inputRef = useRef(null);
  const silenceTimeout = useRef(null);
  const { speak, speaking, cancel } = useSpeechSynthesis();
  const { aIModalOpen, setAIModalOpen, aIModalMinimize, setAIModalMinimize } =
    useBuilder();
  const [message, setMessage] = useState("");
  const [addWords, setAddWords] = useState("of 150 words");
  const [waiting, setWaiting] = useState(false);
  const [responseMessage, setResponseMessage] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const profile = _get_user();

  const handleClose = () => {
    setAIModalOpen(false);
    handleStopListening();
    cancel();
  };
  const handleMinimize = () => {
    setAIModalMinimize(true);
    handleStopListening();
    cancel();
  };

  const handleEscapeKeyDown = (event) => {
    if (event.key === "Escape" && handleMinimize) {
      handleMinimize();
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  //Speech Recognization

  const {
    transcript,
    listening,
    resetTranscript,
    interimTranscript,
    finalTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const [listeningStopped, setListeningStopped] = useState(true);

  const handleStartListening = () => {
    setListeningStopped(false);
    SpeechRecognition.startListening({ continuous: true });
    let micElement = document.getElementById("microphoneOfAiModal");
    micElement.classList.add("active");
  };
  const handleStopListening = () => {
    SpeechRecognition.stopListening();
    setListeningStopped(true);
    let micElement = document.getElementById("microphoneOfAiModal");
    micElement.classList.remove("active");
  };

  useEffect(() => {
    if (!listeningStopped && !listening) {
      SpeechRecognition.startListening({ continuous: true });
    }
    if (transcript && listening) {
      setMessage(transcript);
    }
  }, [transcript, listening]);

  useEffect(() => {
    clearTimeout(silenceTimeout.current);
    silenceTimeout.current = setTimeout(() => {
      if (listening && !listeningStopped) {
        // Stop listening after silence
        handleStopListening();
        if (message !== "") {
          handleSubmit();
        }
      }
    }, 1500);
  }, [interimTranscript, finalTranscript]);

  // end Speech Recognization

  const wordList = [
    // {
    //   label: "No Limit",
    //   value: "",
    // },
    {
      label: "50 Words",
      value: "of 50 words",
    },
    {
      label: "100 Words",
      value: "of 100 words",
    },
    {
      label: "150 Words",
      value: "of 150 words",
    },
    {
      label: "200 Words",
      value: "of 200 words",
    },
    {
      label: "300 Words",
      value: "of 300 words",
    },
    {
      label: "500 Words",
      value: "of 500 words",
    },
  ];

  const handleCopied = () => {
    enqueueSnackbar("Copied to clipboard!", {
      variant: "success",
    });
  };
  const handleSubmit = async (e = null) => {
    e?.preventDefault();
    handleStopListening();
    if (!waiting) {
      resetTranscript();
      setWaiting(true);

      const postData = {
        query: message + " " + addWords,
      };

      const resp = await _send_query_to_ai(postData);
      if (resp.code === 200) {
        let list = [];
        resp.content.map((msj) => {
          let obj = {
            query: message,
            ans: msj.message.content,
          };
          list.push(obj);
          // setResponseMessage((prevArray) => [...prevArray, obj]);
        });

        setResponseMessage(list);
        console.log(resp, "mehoresponse");
      } else {
        enqueueSnackbar(resp.message, {
          variant: "error",
        });
      }
      // setMessage("");
      setWaiting(false);
    }
  };

  useEffect(() => {
    if (latestMessage.current) {
      latestMessage.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [responseMessage]);

  useEffect(() => {
    if (
      message !== "" &&
      inputRef.current &&
      inputRef.current.value?.length > 0
    ) {
      inputRef.current.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length
      );
    }
  }, [message]);

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}`,
    };
  }
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < `${string.length}`; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const handleClickPlay = (textToPlay) => {
    if (speaking) {
      cancel();
    } else {
      speak({ text: textToPlay });
    }
  };

  return (
    <>
      <CustomConfirmation
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        handleAgree={handleClose}
        title="Are you sure you want to close the AI Writer?"
        info={"Closing it will result in the loss of your current content."}
      />

      {!aIModalMinimize && (
        <Dialog
          open={aIModalOpen}
          onClose={handleMinimize}
          onEscapeKeyDown={handleEscapeKeyDown}
          fullWidth
          maxWidth={"lg"}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                overflow: "hidden",
                padding: "0px",
                width: "100%",
                maxHeight: "98vh",
              },
              "& .MuiDialog-content": {
                overflow: "hidden",
              },
            },
          }}
          PaperProps={{
            style: {
              borderTop: "5px solid",
              borderColor: "#023373",
            },
          }}
        >
          <DialogTitle className="border-bottom" sx={{ padding: "12px 18px" }}>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <Avatar
                  src={chat_bot}
                  alt="photoURL"
                  style={{ width: "36px", height: "36px" }}
                />
                <span className="ms-2">AI Writer</span>
              </div>

              {/* <span>AI Writer</span> */}
              <div className="d-flex">
                <div className="icon me-2" onClick={() => handleMinimize()}>
                  <RemoveIcon fontSize="12px" />
                </div>
                <div
                  className="icon"
                  onClick={() =>
                    responseMessage.length === 0
                      ? handleClose()
                      : setOpenConfirmation(true)
                  }
                >
                  <ClearIcon fontSize="12px" />
                </div>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                className="d-flex flex-column align-items-center justify-content-center"
              >
                <div
                  style={{
                    boxShadow: "0 2px 5px 1px rgba(64,60,67,.16)",
                    borderRadius: "4px",
                    padding: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    background: "#fff",
                    marginTop: "12px",
                  }}
                  className="col-8"
                >
                  <IconButton
                    sx={{
                      padding: "6px",
                      margin: "4px",
                      color: "#023373",
                    }}
                    type="button"
                    onClick={() => {
                      listening
                        ? handleStopListening()
                        : handleStartListening();
                    }}
                    className="microphone"
                    id="microphoneOfAiModal"
                  >
                    <MicIcon />
                  </IconButton>

                  <InputBase
                    sx={{ ml: 1 }}
                    fullWidth
                    required
                    readOnly={waiting}
                    placeholder={
                      listening ? "Listening..." : "Enter text here..."
                    }
                    value={message}
                    onChange={(e) => {
                      !waiting && setMessage(e.target.value);
                    }}
                    onClick={() => handleStopListening()}
                    autoFocus
                    ref={inputRef}
                  />
                  <div className="d-flex align-items-center">
                    {message !== "" && (
                      <>
                        <IconButton
                          sx={{ padding: "10px", color: "#023373" }}
                          type="button"
                          onClick={() => {
                            setMessage("");
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                        <hr
                          style={{
                            height: "30px",
                            border: "1px inset",
                            margin: "0",
                          }}
                        />
                      </>
                    )}
                    {!waiting ? (
                      <IconButton
                        sx={{ padding: "10px", color: "#023373" }}
                        type="submit"
                      >
                        <SendIcon />
                      </IconButton>
                    ) : (
                      <IconButton sx={{ padding: "10px", color: "#023373" }}>
                        <Icon icon="svg-spinners:3-dots-bounce" />
                      </IconButton>
                    )}
                  </div>
                </div>
                <div>
                  {wordList.map((list, index) => (
                    <Chip
                      key={index}
                      label={list.label}
                      size="small"
                      className="me-2 mt-3"
                      onClick={() => {
                        setAddWords(list.value);
                      }}
                      style={{
                        backgroundColor:
                          addWords === list.value ? "#023373" : "white",
                        color: addWords === list.value ? "white" : "black",
                        cursor: "pointer",
                        padding: "0.5rem 0.8rem",
                        border: addWords !== list.value && "1px inset #023373",
                      }}
                    />
                  ))}
                </div>
              </form>
              {responseMessage.length === 0 && (
                <div
                  className="d-flex align-items-center mt-3"
                  style={{
                    height: "70vh",
                    background: "#E5F2FF",
                    marginInline: "-24px",
                  }}
                >
                  <div style={{ width: "40%" }}>
                    <div class="d-flex flex-column align-items-center justify-content-center">
                      <h5
                        class="items-center mb-4"
                        style={{ color: "#343541" }}
                      >
                        Examples
                      </h5>

                      <p class="example-box">
                        "Write a short description for my photography portfolio
                        to make it more engaging."
                      </p>
                      <p class="example-box">
                        "Generate a creative slogan for my new product."
                      </p>
                      <p class="example-box">
                        "Help me write a concise and persuasive product
                        description for my e-commerce site."
                      </p>

                      {/* <p class="example-box">
                        "Explain quantum computing in simple terms"
                      </p>
                      <p class="example-box">
                        "Give me a pragraph on life coaching"
                      </p>
                      <p class="example-box">
                        "Got any creative ideas for a 10 year old’s birthday?"
                      </p> */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "60%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={robot_hi}
                      alt={robot_hi}
                      style={{ height: "80%", width: "90%" }}
                    />
                  </div>
                </div>
              )}
            </DialogContentText>

            {responseMessage.length > 0 && (
              <div
                className="d-flex flex-column align-items-center mt-3 border-top"
                style={{
                  minHeight: "70vh",
                  maxHeight: "70vh",
                  overflow: "auto",
                  marginInline: "-24px",
                  background: "#E5F2FF",
                  // paddingRight: "24px",
                }}
              >
                {responseMessage.map((resp, index) => (
                  <div
                    key={index}
                    ref={
                      index === responseMessage.length - 1
                        ? latestMessage
                        : null
                    }
                    className="d-flex flex-column w-100"
                  >
                    {/* <div className="d-flex justify-content-center">
                      {profile.profile_image ? (
                        <Avatar
                          src={
                            profile.profile_image
                              ? s3baseUrl + "/" + profile.profile_image
                              : profile.first_name[0]
                          }
                          alt="photoURL"
                          sx={{ marginY: "20px" }}
                        />
                      ) : (
                        <div className="my-3">
                          <Avatar {...stringAvatar(profile.first_name)} />
                        </div>
                      )}
                      <p
                        className="mb-0"
                        style={{
                          // background: "#023373",
                          color: "#000",
                          padding: "20px",
                          width: "75%",
                        }}
                      >
                        {resp.query}
                      </p>
                    </div> */}
                    <div
                      className="d-flex flex-column  ml-5"
                      style={{
                        // background: index % 2 === 0 ? "#E5F2FF" : "#F2F3F3",
                        paddingInline: "24px",
                        paddingBlock: "20px",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="d-flex align-items-center">
                          <Avatar
                            src={chat_bot}
                            alt="photoURL"
                            style={{ width: "36px", height: "36px" }}
                          />
                          <span
                            className="ms-2"
                            style={{ fontSize: "16px", fontWeight: "500" }}
                          >
                            AI Writer
                          </span>
                        </div>
                        <div className="d-flex">
                          <Icon
                            icon={
                              speaking
                                ? "ph:speaker-simple-x-light"
                                : "fluent:speaker-2-32-regular"
                            }
                            width={"18px"}
                            className="mb-0"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClickPlay(resp.ans)}
                          />
                          <CopyToClipboard
                            onCopy={handleCopied}
                            text={resp.ans}
                          >
                            <Tooltip title="Copy Text" placement="top" arrow>
                              <ContentCopyIcon
                                className="ms-3 p-0 pointer"
                                fontSize="xs"
                              />
                            </Tooltip>
                          </CopyToClipboard>
                        </div>
                      </div>
                      <div>
                        <p
                          className="mb-0"
                          style={{
                            // background: "#fff",
                            color: "black",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                            fontFamily: "Poppins,sans-serif",
                          }}
                        >
                          {resp.ans}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default AIAssistantModal;
