import React, { useEffect, useState } from "react";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import { _s3upload } from "../../../../../../DAL/s3upload/s3upload";
import { TwitterPicker } from "react-color";
import Select from "react-select";
import {
  options,
  colors,
  basicColors,
  mode_options,
  google_font_family_classes,
  DESIGNER_MADE_PALETTE,
} from "../../../../../../ui_libs/Constants/constant";
import MuiSelect from "../MuiSelect/MuiSelect";
import {
  Stack,
  styled,
  Switch,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { DesignServicesTwoTone } from "@mui/icons-material";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 25,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="15" width="15" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#000",
        width: 22,
        height: 22,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#fff",
    width: 22,
    height: 22,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#000"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

function QuickSelection() {
  const { rootValues, handleChangeRootValue } = useBuilder();

  return (
    <>
      <div className="row px-2" role="presentation">
        {/* <div className="col-12" style={{}}>
          <h6 style={{ lineHeight: "42px" }}>Website Styles</h6>
        </div> */}
        {/* Font Family */}
        {/* <div className="col-12 form-label custom-label">Font-Family:</div> */}
        <div className="col-12 mt-4">
          <MuiSelect
            label="Font Family"
            type="cssRule"
            handleChange={handleChangeRootValue}
            data={google_font_family_classes}
            currentFamily={rootValues.font_family.replace(" ", "")}
          />
        </div>
        {/* Them Mode light or dark */}
        {/* <div className="col-12 mt-4 custom-label">Theme Mode:</div>
        <div className="col-12 mt-2 custom-label">
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography className="custom-label">Light</Typography>
            <MaterialUISwitch
              defaultChecked={rootValues.theme_mode === "dark"}
              inputProps={{ "aria-label": "MUI design" }}
              onChange={() => {
                rootValues.theme_mode === "light"
                  ? handleChangeRootValue("mode", "dark")
                  : handleChangeRootValue("mode", "light");
              }}
            />
            <Typography className="custom-label">Dark</Typography>
          </Stack>
        </div> */}
        {/* Secondary Color */}
        <div className="col-12 mt-4 custom-label">Current Theme:</div>
        <div className="col-12 mt-2">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              border: "1.5px solid #a6a6a6",
              borderRadius: "4px",
              padding: "8px 20px",
              cursor: "pointer",
              backgroundColor: rootValues.primary_color,
            }}
            // onClick={() => handleChangeRootValue("theme", palette)}
          >
            <div
              style={{
                alignSelf: "center",
                color: rootValues.theme_heading_color,
              }}
              className="custom-label"
            >
              Palette
            </div>

            <div
              style={{
                display: "flex",
                cursor: "pointer",
              }}
            >
              <Tooltip
                title={
                  <span style={{ whiteSpace: "pre-line" }}>
                    {"Secondary Color\n" + rootValues.secondary_color}
                  </span>
                }
                TransitionComponent={Zoom}
                arrow
                placement="top-start"
              >
                <div
                  style={{
                    backgroundColor: rootValues.secondary_color,
                    width: "20px",
                    height: "20px",
                    borderRadius: "4px",
                    margin: "0px 2px",
                    border:
                      rootValues.primary_color === rootValues.secondary_color &&
                      "solid 0.003px #e6e6e6",
                  }}
                >
                  <span></span>
                </div>
              </Tooltip>
              <Tooltip
                title={
                  <span style={{ whiteSpace: "pre-line" }}>
                    {"Heading Color\n" + rootValues.theme_heading_color}
                  </span>
                }
                TransitionComponent={Zoom}
                arrow
                placement="top-start"
              >
                <div
                  style={{
                    backgroundColor: rootValues.theme_heading_color,
                    width: "20px",
                    height: "20px",
                    borderRadius: "4px",
                    margin: "0px 2px",
                    border:
                      rootValues.primary_color ===
                        rootValues.theme_heading_color &&
                      "solid 0.003px #e6e6e6",
                  }}
                >
                  <span></span>
                </div>
              </Tooltip>
              <Tooltip
                title={
                  <span style={{ whiteSpace: "pre-line" }}>
                    {"Text Color\n" + rootValues.primary_text_color}
                  </span>
                }
                TransitionComponent={Zoom}
                arrow
                placement="top-start"
              >
                <div
                  style={{
                    backgroundColor: rootValues.primary_text_color,
                    width: "20px",
                    height: "20px",
                    borderRadius: "4px",
                    margin: "0px 2px",
                    border:
                      rootValues.primary_color ===
                        rootValues.primary_text_color &&
                      "solid 0.003px #e6e6e6",
                  }}
                >
                  <span></span>
                </div>
              </Tooltip>
              <Tooltip
                title={
                  <span style={{ whiteSpace: "pre-line" }}>
                    {"Secondary Text Color" + "\n" + rootValues.primary_color}
                  </span>
                }
                TransitionComponent={Zoom}
                arrow
                placement="top-start"
              >
                <div
                  style={{
                    backgroundColor: rootValues.secondary_text_color,
                    width: "20px",
                    height: "20px",
                    borderRadius: "4px",
                    margin: "0px 2px",
                    border:
                      rootValues.primary_color ===
                        rootValues.secondary_text_color &&
                      "solid 0.003px #e6e6e6",
                  }}
                >
                  <span></span>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="col-12 mt-4 custom-label">Featured Themes:</div>
        {DESIGNER_MADE_PALETTE.map((palette) => (
          <div className="col-12 mt-2">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // flexDirection: "column",
                backgroundColor: palette.primary_color,
                border: "1.5px solid #a6a6a6",
                borderRadius: "4px",
                padding: "8px 20px",
                cursor: "pointer",
              }}
              onClick={() => handleChangeRootValue("theme", palette)}
            >
              <div
                style={{
                  alignSelf: "center",
                  color: palette.theme_heading_color,
                }}
                className="custom-label"
              >
                {palette.label}
              </div>

              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                  justifyContent: "center",
                  // marginTop: "5px",
                }}
              >
                <Tooltip
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {"Secondary Color\n" + palette.secondary_color}
                    </span>
                  }
                  TransitionComponent={Zoom}
                  arrow
                  placement="top-start"
                >
                  <div
                    style={{
                      backgroundColor: palette.secondary_color,
                      width: "20px",
                      height: "20px",
                      borderRadius: "4px",
                      margin: "0px 2px",
                    }}
                  >
                    <span></span>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {"Heading Color\n" + palette.theme_heading_color}
                    </span>
                  }
                  TransitionComponent={Zoom}
                  arrow
                  placement="top-start"
                >
                  <div
                    style={{
                      backgroundColor: palette.theme_heading_color,
                      width: "20px",
                      height: "20px",
                      borderRadius: "4px",
                      margin: "0px 2px",
                    }}
                  >
                    <span></span>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {"Text Color\n" + palette.primary_text_color}
                    </span>
                  }
                  TransitionComponent={Zoom}
                  arrow
                  placement="top-start"
                >
                  <div
                    style={{
                      backgroundColor: palette.primary_text_color,
                      width: "20px",
                      height: "20px",
                      borderRadius: "4px",
                      margin: "0px 2px",
                    }}
                  >
                    <span></span>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {"Secondary Text Color" + "\n" + palette.primary_color}
                    </span>
                  }
                  TransitionComponent={Zoom}
                  arrow
                  placement="top-start"
                >
                  <div
                    style={{
                      backgroundColor: palette.secondary_text_color,
                      width: "20px",
                      height: "20px",
                      borderRadius: "4px",
                      margin: "0px 2px",
                    }}
                  >
                    <span></span>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
        {/* <TwitterPicker
        colors={colors}
        width="100%"
        onChange={(e) => handleChangeRootValue("theme", e.hex)}
        color={rootValues.secondary_color}
      /> */}
        <div className="col-12 text-right mt-5"></div>
      </div>
    </>
  );
}

export default QuickSelection;
