/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  FormControl,
  InputAdornment,
  InputLabel,
  Tooltip,
  TextField,
  Input,
  Box,
  OutlinedInput,
  IconButton,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";

import AccountCircle from "@mui/icons-material/AccountCircle";
import { margin } from "@mui/system";

const Frequently_Used_Icons = [
  {
    name: "facebook",
    class: "fab fa-facebook",
    identifier: "fa-facebook",
    type: "brands",
    variant: "fab",
  },
  {
    name: "facebook messenger",
    class: "fab fa-facebook-messenger",
    identifier: "fa-facebook-messenger",
    type: "brands",
    variant: "fab",
  },
  {
    name: "twitter",
    class: "fab fa-twitter",
    identifier: "fa-twitter",
    type: "brands",
    variant: "fab",
  },
  {
    name: "instagram",
    class: "fab fa-instagram",
    identifier: "fa-instagram",
    type: "brands",
    variant: "fab",
  },
  {
    name: "whatsapp",
    class: "fab fa-whatsapp",
    identifier: "fa-whatsapp",
    type: "brands",
    variant: "fab",
  },
  {
    name: "linkedin",
    class: "fab fa-linkedin",
    identifier: "fa-linkedin",
    type: "brands",
    variant: "fab",
  },
  {
    name: "snapchat",
    class: "fab fa-snapchat",
    identifier: "fa-snapchat",
    type: "brands",
    variant: "fab",
  },
  {
    name: "youtube",
    class: "fab fa-youtube",
    identifier: "fa-youtube",
    type: "brands",
    variant: "fab",
  },
  {
    name: "address book",
    class: "fas fa-address-book",
    identifier: "fa-address-book",
    type: "solid",
    variant: "fas",
  },
];

const fontAwesomeSizes = [
  "fa-xs",
  "fa-sm",
  "fa-lg",
  "fa-2x",
  "fa-3x",
  "fa-4x",
  "fa-5x",
  "fa-6x",
  "fa-7x",
  "fa-8x",
  "fa-9x",
  "fa-10x",
];
const useStyles = makeStyles({
  selected: {
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: "#0d85e734",
      // backgroundColor: "#ff0b4424",
      color: "black",
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: "#0d85e7",
      // backgroundColor: "#fa2356",
      color: "white",
    },
    fontSize: ".875rem",
    "& > span": {
      marginRight: 50,
      fontSize: "0.875rem",
    },
  },
});
export default function IconSelect({
  data,
  handleChange,
  iconClass,
  previewRef,
  label,
}) {
  const { getSelectedElement } = useBuilder();
  const [prevIcon, setPrevIcon] = useState("");
  const [AutocompleteValue, setAutocompleteValue] = React.useState(data[0]);
  const [inputValue, setInputValue] = React.useState("");
  const [selectedElement, setSelectedElement] = useState(getSelectedElement());
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const curFamily = useRef();
  const oldIdentifier = useRef();
  const oldVariant = useRef();
  const [FilterValue, setFilterValue] = useState("");
  const [FilterData, setFilterData] = useState(data);
  const [UpdatedIcon, setUpdatedIcon] = useState("");
  const [iconClasses, setIconClasses] = useState("");
  const [HoverIcon, setHoverIcon] = useState("");
  const [IconIndex, setIconIndex] = useState(-1);
  const [showIcon, setShowIcon] = useState(false);
  const handleInputChange = (event, newValue) => {
    setInputValue(newValue);
  };
  const updateIcon = (ref, newIdentifier, newVariant) => {
    let targetClass = ref.current.getAttribute("class");

    if (!UpdatedIcon && iconClasses === "") {
      setIconClasses(targetClass);
    }
    let newClass = targetClass;
    newClass = newClass
      .replace(oldVariant.current, "")
      .replace("fas ", "")
      .replace("fa ", "")
      .replace("fab ", "")
      .replace("far ", "")
      .replace("fa-solid ", "")
      .replace("fa-brands ", "");
    newClass = newClass.replace(oldIdentifier.current, newIdentifier);
    oldIdentifier.current = newIdentifier;
    oldVariant.current = newVariant;
    if (!newClass.includes(newIdentifier)) {
      let startingIndex = newClass.search("fa-");
      let endingIndex = newClass.search(" ", startingIndex);
      let slice = newClass.slice(startingIndex, endingIndex);
      newClass = newClass.replace(slice, "");
      newClass = newIdentifier + newClass;
    }
    newClass = newVariant + " " + newClass;

    previewRef.current.setAttribute("class", newClass);
    selectedElement.setAttribute("class", newClass);
  };
  const handleOnChange = (event, newValue) => {
    if (newValue === null || undefined) return;
    curFamily.current = newValue;
    updateIcon(previewRef, newValue.identifier, newValue.variant);
    setAutocompleteValue(newValue);
    setPrevIcon(newValue.identifier);
  };
  const handleHighlightChange = (e, newValue) => {
    if (!newValue || newValue == "" || newValue == undefined) return;
    // if (newValue.identifier !== prevIcon) {
    updateIcon(previewRef, newValue.identifier, newValue.variant);
    //   console.log(newValue);
    //   console.log(previewRef.current, "ref");
    // }
  };
  const resetIcon = () => {
    let targetClass = previewRef.current.getAttribute("class");
    let oldClass = targetClass.replace(
      oldIdentifier.current,
      curFamily.current.identifier
    );
    oldClass = oldClass.replace(oldVariant.current, curFamily.current.variant);
    previewRef.current.setAttribute("class", oldClass);
    oldIdentifier.current = curFamily.current.identifier;
    oldVariant.current = curFamily.current.variant;
    selectedElement.setAttribute("class", oldClass);
  };
  const handleClose = () => {
    if ((previewRef.current, oldVariant.current, curFamily.current)) {
      resetIcon();
    }
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleBlur = (e) => {
    if ((previewRef.current, oldVariant.current, curFamily.current)) {
      resetIcon();
    }
    setOpen(false);
  };
  const findIcon = () => {
    for (let cls of selectedElement.classList) {
      data.forEach((icon, i) => {
        if (cls === icon.identifier) {
          for (let variant of selectedElement.classList) {
            // if (variant == icon.variant) {
            setSelectedIcon(icon, i);
            setUpdatedIcon({ ...icon });
            // }
          }
        }
      });
    }
  };
  const checkIconClass = () => {
    let isFaultyClass = false;
    for (let cls of selectedElement.classList) {
      if (cls === "fa") {
        for (let identifier of selectedElement.classList) {
          data.forEach((icon, i) => {
            if (identifier === icon.identifier) {
              setSelectedIcon(icon, i);
              setUpdatedIcon({ ...icon });
            }
          });
        }
        isFaultyClass = true;
      }
    }

    return isFaultyClass;
  };
  const setSelectedIcon = (icon, i) => {
    curFamily.current = data[i];
    setAutocompleteValue(data[i]);
    setPrevIcon(icon.identifier);
    oldIdentifier.current = icon.identifier;
    oldVariant.current = icon.variant;
  };
  const getSelectedIcon = () => {
    let isCLassFaulty = checkIconClass();
    if (isCLassFaulty) {
    } else {
      findIcon();
    }
  };
  const handleFilter = (value) => {
    setFilterValue(value);
    let result = data;
    if (value.length > 0) {
      result = data.filter((icon) => {
        if (icon.name.includes(value)) {
          return icon;
        }
      });
    }
    setFilterData([...result]);
  };
  useEffect(() => {
    getSelectedIcon();

    setTimeout(() => {
      setShowIcon(true);
    }, 500);
  }, []);
  useEffect(() => {}, [UpdatedIcon]);

  const CheckIcon = (icon) => {
    if (UpdatedIcon && UpdatedIcon.class == icon.class) {
      return "#d21d48";
    } else {
      return "";
    }
  };
  return (
    <div
      onMouseLeave={() => {
        if (HoverIcon && HoverIcon.class != "") {
          if (HoverIcon.class != UpdatedIcon.class) {
            handleHighlightChange(FilterValue, UpdatedIcon);
          }
        }
      }}
      className="row"
    >
      {/* <Autocomplete
        blurOnSelect
        open={open}
        onOpen={handleOpen}
        onBlur={handleBlur}
        onClose={handleClose}
        inputValue={inputValue}
        value={AutocompleteValue}
        onChange={(event, newValue) => {
          handleOnChange(event, newValue)
        }}
        onHighlightChange={(e, newValue) => handleHighlightChange(e, newValue)}
        onInputChange={(event, newValue) => handleInputChange(event, newValue)}
        id="family-select"
        color="secondary"
        size="small"
        options={data}
        classes={{
          option: classes.selected,
        }}
        className="familySelect"
        autoHighlight
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, state) => {
          return (
            <li
              {...props}
              value={option.value}
              className={`${option.value} ${props.className} auto-complete-option`}
            >
              <i className={`${option.class} me-1 float-end`}></i>
              {option.name}
            </li>
          )
        }}
        renderInput={(params) => (
          <TextField
            autoComplete="off"
            // className="familySelect"
            size="small"
            color="secondary"
            // classes={{ root: classes.selected }}
            {...params}
            label={label ? label : 'untitled'}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      /> */}

      {/* {UpdatedIcon && UpdatedIcon.class && ( */}

      {/* <div className="col-12 mb-3 mt-0">
        <label className="form-label custom-label">Frequently Used:</label>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {Frequently_Used_Icons.map((icon, index) => (
            <div
              key={index}
              value={index}
              className="mb-2 d-grid col-4 align-items-center justify-content-center "
            >
              <Tooltip arrow placement="top" title={icon.name}>
                <div
                  style={{
                    backgroundColor: CheckIcon(icon),

                    width: 100,
                    height: "40",
                    overflow: "hidden",
                    cursor: "pointer",

                    textOverflow: "ellipsis",
                    whiteSpace: "noWrap",
                  }}
                  className={`p-2 rounded form-checkbox-color icon-hover-class d-flex align-items-center`}
                  onClick={() => {
                    setUpdatedIcon(icon);
                    setHoverIcon(icon);
                    handleHighlightChange(FilterValue, icon);
                  }}
                  onMouseOver={(e) => {
                    setHoverIcon(icon);
                    handleHighlightChange(FilterValue, icon);
                  }}
                  onMouseLeave={() => {
                    if (HoverIcon && HoverIcon.class != "") {
                      if (HoverIcon.class != UpdatedIcon.class) {
                        handleHighlightChange(FilterValue, UpdatedIcon);
                      }
                    }
                  }}
                >
                  <i
                    style={{ cursor: "pointer", fontSize: 15 }}
                    className={`${icon.class} me-1 float-start`}
                  ></i>

                  <label
                    style={{
                      fontSize: 10,
                      height: 20,
                      overflow: "hidden",
                      flexWrap: "wrap",
                      cursor: "pointer",
                    }}
                  >
                    {icon.name}
                  </label>
                </div>
              </Tooltip>
            </div>
          ))}
        </div>
      </div> */}

      <label className="form-label custom-label">Choose Icon:</label>

      {!showIcon && (
        <div
          className="row"
          style={{
            position: "relative",
            height: 230,
          }}
        >
          {" "}
          <Box
            sx={{
              height: 230,
              width: "100%",
              display: "grid",
              gridTemplateColumns: "auto auto auto",
              margin: "0px 20px",
            }}
          >
            <Skeleton variant="rounded" width={100} height={45} />
            <Skeleton variant="rounded" width={100} height={45} />
            <Skeleton variant="rounded" width={100} height={45} />
            <Skeleton variant="rounded" width={100} height={45} />
            <Skeleton variant="rounded" width={100} height={45} />
            <Skeleton variant="rounded" width={100} height={45} />
            <Skeleton variant="rounded" width={100} height={45} />
            <Skeleton variant="rounded" width={100} height={45} />
            <Skeleton variant="rounded" width={100} height={45} />
            <Skeleton variant="rounded" width={100} height={45} />
            <Skeleton variant="rounded" width={100} height={45} />
            <Skeleton variant="rounded" width={100} height={45} />
          </Box>
        </div>
      )}
      {showIcon && (
        <div
          className="row"
          style={{
            // position: "relative",
            width: "80%",
            height: 230,
            marginLeft: 15,
          }}
        >
          <div
            style={{
              backgroundColor: "silver",
              height: 230,
              width: 360,
              overflowX: "hidden",
              position: "absolute",
              overflowY: "scroll",
            }}
            className="row rounded d-flex align-items-start"
          >
            <div
              className="d-grid position-sticky mb-2 px-1"
              style={{
                width: "330px",
                top: "0%",
                paddingTop: 10,
                backgroundColor: "silver",
              }}
            >
              <input
                className="form-control rounded"
                placeholder="Search"
                value={FilterValue}
                onChange={(e) => {
                  handleFilter(e.target.value);
                }}
              />
            </div>

            {FilterData.map((icon, index) => (
              <div
                key={index}
                value={index}
                className="mb-2 d-grid col-4 align-items-center justify-content-center "
              >
                <Tooltip arrow placement="top" title={icon.name}>
                  <div
                    style={{
                      backgroundColor: CheckIcon(icon),

                      width: 100,
                      height: "40",
                      overflow: "hidden",
                      cursor: "pointer",

                      textOverflow: "ellipsis",
                      whiteSpace: "noWrap",
                    }}
                    className={`p-2 rounded form-checkbox-color icon-hover-class d-flex align-items-center`}
                    onClick={() => {
                      setUpdatedIcon(icon);
                      setHoverIcon(icon);
                      handleHighlightChange(FilterValue, FilterData[index]);
                    }}
                    onMouseOver={(e) => {
                      setHoverIcon(icon);
                      handleHighlightChange(FilterValue, FilterData[index]);
                    }}
                    onMouseLeave={() => {
                      if (HoverIcon && HoverIcon.class != "") {
                        if (
                          UpdatedIcon &&
                          HoverIcon.class != UpdatedIcon.class
                        ) {
                          handleHighlightChange(FilterValue, UpdatedIcon);
                          return;
                        }
                      }
                      previewRef.current.setAttribute("class", iconClass);
                      selectedElement.setAttribute("class", iconClass);
                    }}
                  >
                    <i
                      style={{ cursor: "pointer", fontSize: 15 }}
                      className={`${icon.class} me-1 float-start`}
                    ></i>

                    <label
                      style={{
                        fontSize: 10,
                        height: 20,
                        overflow: "hidden",
                        flexWrap: "wrap",
                        cursor: "pointer",
                      }}
                    >
                      {icon.name}
                    </label>
                  </div>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      )}
      {/* )} */}
    </div>
  );
}
