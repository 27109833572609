import React, { useEffect, useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { Button, Tooltip } from "@mui/material";
import { list_all_plan_by_project_id } from "../../../../DAL/user-created-plans/userCreatedPlans";
import { useSnackbar } from "notistack";
import ManagePaymentCard from "./component/ManagePaymentCard";

// plan listing from strip
// wb-plan-card
const activePaymentElementListName = [
  { name: "plan_name", value: "wb-plan-name" },
  {
    name: "image",
    value: "wb-plan-image",
  },
  { name: "amount", value: "wb-plan-price" },
  { name: "interval_time", value: "wb-plan-duration" },
  { name: "currency_type", value: "wb-plan-curency" },
  { name: "plan_subtitle", value: "wb-plan-description" },
  {
    name: "button",
    value: "wb-payment-plan-button",
  },
  {
    name: "option_active",
    value: "wb-plan-option-active",
  },
  {
    name: "option_inactive",
    value: "wb-plan-option-inactive",
  },
  {
    name: "option_icon_active",
    value: "wb-plan-option-icon-active",
  },
  {
    name: "option_icon_inactive",
    value: "wb-plan-option-icon-inactive",
  },
  {
    name: "option_label",
    value: "wb-plan-option-label",
  },

  {
    name: "discount",
    value: "wb-plan-discount",
  },
  {
    name: "ribon",
    value: "wb-plan-ribon",
  },
];
const activePaymentPlansListTest = [
  {
    _id: "6465c599ee098b47d003df50",
    project: "641431fb5609a03968902730",
    user: "641405b9dcb48a1a24c16444",
    plan_name: "basic",
    plan_subtitle: "this is a plan",
    amount: 100,
    currency_type: "$",
    payment_type: "recurring",
    interval_time: "month",
    order: 2,
    status: true,
    createdAt: "2023-05-18T06:28:41.193Z",
    updatedAt: "2023-05-18T06:28:41.193Z",
  },
  {
    _id: "6465c86aee098b47d003dfe0",
    project: "641431fb5609a03968902730",
    user: "641405b9dcb48a1a24c16444",
    plan_name: "pro",
    plan_subtitle: "this is a plan",
    amount: 200,
    currency_type: "$",
    payment_type: "one_time",
    interval_time: "month",
    order: 3,
    status: true,
    createdAt: "2023-05-18T06:40:42.549Z",
    updatedAt: "2023-05-18T06:40:42.549Z",
  },
  {
    _id: "6465c9c08f39fa36349967d6",
    project: "641431fb5609a03968902730",
    user: "641405b9dcb48a1a24c16444",
    plan_name: "Ultra",
    plan_subtitle: "this is a plan",
    amount: 300,
    currency_type: "$",
    payment_type: "recurring",
    interval_time: "Month",
    order: 4,
    status: true,
    createdAt: "2023-05-18T06:46:24.014Z",
    updatedAt: "2023-05-18T06:46:24.014Z",
  },
];

let optionTypes = {
  inactive: null,
  active: null,
};

const PlanSetting = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [groupPage_list, setGroupPage_list] = useState([]);

  // context
  const {
    setPlanSettingsMenuOpen,
    getSelectedSectionPlanByID,
    SaveActionToStack,
    rootValues,
    activePaymentPlansList,
    setActivePaymentPlansList,
    setRootValues,
    funnelDetails,
  } = useBuilder();

  // states
  const [paymentCardsElements, setPaymentCardsElements] = useState([]);
  const [changeInCards, setChangeInCards] = useState(false);
  const [availablePaymentCardElementList, setAvailablePaymentCardElementList] =
    useState({});
  const [selectedPaymentPage, setSelectedPaymentPage] = useState("index");
  const [stepMessageShow, setStepMessageShow] = useState(false);

  // functions

  const getPlanIndexFromID = (value) => {
    let result = -1;
    activePaymentPlansList.map((plan, index) => {
      if (plan._id == value) {
        result = index;
      }
    });

    return result;
  };
  const handleSelectPlan = (element, e) => {
    const { value } = e.target;
    if (value == -1) return;
    let planIndex = getPlanIndexFromID(value);
    findElementsContainPlanAttributes(element, planIndex, value);
    setChangeInCards(!changeInCards);
  };
  const findElementsContainPlanAttributes = (element, planIndex, planID) => {
    SaveActionToStack();
    let availableSelectedCardElementsList = [];
    activePaymentElementListName.map((elementName) => {
      let value = getElementContainProperty(element, "name", elementName.value);
      if (value) {
        availableSelectedCardElementsList[elementName.name] = value[0];
      }
    });
    Object.keys(availableSelectedCardElementsList).map((value) => {
      if (activePaymentPlansList[planIndex][value] != null) {
        availableSelectedCardElementsList[
          value
        ].innerHTML = `${activePaymentPlansList[planIndex][value]}`;
        availableSelectedCardElementsList[value].classList.add(
          "text-uppercase"
        );
      }
    });

    // handlePlanOptions(availableSelectedCardElementsList, planIndex);
    if (availableSelectedCardElementsList.button) {
      availableSelectedCardElementsList.button.href = `${selectedPaymentPage}.html?plan_id=${activePaymentPlansList[planIndex]._id}`;
    }
    element.setAttribute("active_plan_id", planID);
    setChangeInCards(!changeInCards);
  };
  const handlePlanOptions = (availableSelectedCardElementsList, planIndex) => {
    let optionParentWraper =
      availableSelectedCardElementsList.option_active.parentNode;
    let newOptionParentWraper = document.createElement("div");
    activePaymentPlansList[planIndex].option.map((option) => {
      let newActiveOption;
      if (option.status) {
        newActiveOption =
          availablePaymentCardElementList.option_active.cloneNode(true);
      } else {
        newActiveOption =
          availablePaymentCardElementList.option_inactive.cloneNode(true);
      }
      let optionLabel = getElementContainProperty(
        newActiveOption,
        "name",
        "wb-plan-option-label"
      );

      if (optionLabel) {
        optionLabel.innerHTML = option.label;
        newOptionParentWraper.append(newActiveOption);
      }
    });
    optionParentWraper.innerHTML = newOptionParentWraper.innerHTML;
  };

  const getElementContainProperty = (element, property, value) => {
    if (!element) return null;
    let result = [];
    result = element.querySelectorAll(`[${property}=${value}]`);

    return result.length > 0 ? result : null;
  };
  const getElementSelectedPlanValue = (element) => {
    let value = -1;
    if (element) {
      value = element.getAttribute("active_plan_id");
    }
    return value ? value : -1;
  };

  const getselectedPaymentPage = (availablePaymentCardElementList) => {
    let result = "";

    rootValues.page_list.map((page) => {
      if (rootValues.payment_page_id == page._id) {
        result = page.page_slug;
      }
    });
    if (paymentCardsElements?.length > 0) {
      paymentCardsElements.map((card) => {
        let button = getElementContainProperty(
          card,
          "name",
          "wb-payment-plan-button"
        );
        if (
          rootValues.page_group_id !== "" &&
          funnelDetails?.page_group_type !== "generic_website"
        ) {
          if (button && button.length > 0) {
            if (
              button[0].classList.contains("move-to-next") &&
              funnelDetails?.page_group_type !== "generic_website"
            ) {
              result = "move-to-next";
              if (rootValues.payment_page_id === null) {
                groupPage_list?.map((page) => {
                  if (page.order === rootValues.order + 1) {
                    rootValues.payment_page_id = page._id;
                    setRootValues({ ...rootValues });
                  }
                });
              }
            }
          }
        }
      });
    }

    setSelectedPaymentPage(result);

    return selectedPaymentPage;
  };

  const handleChangePaymentPage = (e) => {
    let value = e.target.value;
    if (e.target.value === "move-to-next") {
      groupPage_list.map((page, i) => {
        if (page._id === rootValues.page_id) {
          if (i < groupPage_list.length - 1) {
            value = groupPage_list[i + 1].page_slug;
          } else {
            setStepMessageShow(true);
            setSelectedPaymentPage("move-to-next");
            return (value = false);
          }
        }
      });
    }
    if (value === false) {
      return;
    }

    console.log("chaaaaaaa");
    setStepMessageShow(false);
    setSelectedPaymentPage(value);
    rootValues.page_list.map((page) => {
      if (page.page_slug == value) {
        rootValues.payment_page_id = page._id;
        setRootValues({ ...rootValues });
      }
    });
    if (paymentCardsElements?.length > 0) {
      paymentCardsElements.map((card) => {
        let button = getElementContainProperty(
          card,
          "name",
          "wb-payment-plan-button"
        );
        if (button && button.length > 0 && button[0].href) {
          if (e.target.value === "move-to-next") {
            if (button[0].getAttribute("class")) {
              button[0].classList.add(e.target.value);
            } else {
              button[0].setAttribute("class", e.target.value);
            }
          } else {
            if (button[0].classList.contains("move-to-next")) {
              button[0].classList.remove("move-to-next");
            }
          }

          let href = button[0].href;
          let startIndex = href.indexOf(".html");
          let result = href.substring(startIndex, href.length);
          button[0].href = `${value}${result}`;
        }
      });
    }

    setChangeInCards(!changeInCards);
  };
  const getSignAgainstCurrency = (plan, plansList) => {
    switch (plan.currency_type) {
      case "EUR":
        plan.currency_type = "€";
        break;
      case "GBP":
        plan.currency_type = "£";
        break;
      case "CNY":
        plan.currency_type = "¥";
        break;
      case "RUB":
        plan.currency_type = "₽";
        break;

      default:
        plan.currency_type = "$";
        break;
    }
    plansList.push(plan);
  };

  const GotoClientPortal = () => {
    const baseURL = sessionStorage.getItem("client_base_url");
    let user = sessionStorage.getItem("userType");

    if (baseURL && user === "3") {
      window.open(
        `${baseURL}website-pages/${rootValues.page_group_id}/payment-plans`
      );
    } else if (baseURL) {
      window.open(
        `${baseURL}/manage-project/${rootValues._id}?moveTo=payment_plans`
      );
    } else {
      window.open(baseURL);
    }
  };

  const getPlanList = async () => {
    let resp = await list_all_plan_by_project_id(rootValues.project);
    if (resp.code == 200) {
      let plansList = [];
      resp.data.map((plan) => {
        getSignAgainstCurrency(plan, plansList);
      });
      let list = [];
      plansList.map((plan) => {
        if (
          plan.page_group_id &&
          plan.page_group_id === rootValues.page_group_id
        ) {
          list.push(plan);
        }
      });
      if (list.length > 0) {
        setActivePaymentPlansList(list);
      } else {
        setActivePaymentPlansList(plansList);
      }
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    // setActivePaymentPlansList([...activePaymentPlansListTest]);
  };

  // useeffect

  useEffect(() => {
    getPlanList();
  }, []);

  useEffect(() => {
    const { payment_plan_card } = getSelectedSectionPlanByID();

    setPaymentCardsElements([...payment_plan_card]);
  }, [changeInCards]);

  useEffect(() => {
    if (paymentCardsElements && paymentCardsElements.length > 0) {
      for (let index = 0; index < paymentCardsElements.length; index++) {
        activePaymentElementListName.map((elementName) => {
          let value = getElementContainProperty(
            paymentCardsElements[index],
            "name",
            elementName.value
          );
          if (value) {
            availablePaymentCardElementList[elementName.name] = value[0];
          }
        });
      }
      getselectedPaymentPage();
      setAvailablePaymentCardElementList({
        ...availablePaymentCardElementList,
      });
    }
  }, [paymentCardsElements]);

  useEffect(() => {
    if (rootValues) {
      let values = rootValues;
      let list = [];
      if (
        funnelDetails?.page_group_type !== "generic_website" &&
        values.page_group_id !== ""
      ) {
        values?.page_list?.map((page) => {
          if (page.page_group_id?._id === values.page_group_id) {
            list.push(page);
          }
          if (page.page_group_id === values.page_group_id) {
            list.push(page);
          }
        });
      } else {
        list = values.page_list;
      }
      console.log(values, list, "njenjcnececneiniceicienicei");
      setGroupPage_list(list);
    }
  }, [rootValues]);

  return (
    <>
      <div className="p-3 pt-0">
        <div
          className="col-12  py-2 mb-2 border-bottom"
          style={{
            position: "sticky",
            zIndex: "100",
            background: "white",
            top: "0%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h6 className="mb-0">Plans Settings</h6>
          <div style={{ display: "flex" }}>
            <Tooltip title="Close" placement="bottom" arrow>
              <div
                className="icon me-2"
                onClick={() => setPlanSettingsMenuOpen(false)}
              >
                <i class="fa fa-xmark"></i>
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="">
          <div className="pb-3 mb-2 border-bottom">
            <div className="col-12 d-flex  mb-2 align-items-center">
              <span> {`Select payment page:`}</span>
            </div>

            <div className="col-12">
              <>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-link"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                    <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                  </svg>
                </span>
                <select
                  value={selectedPaymentPage}
                  onChange={handleChangePaymentPage}
                  className="form-select form-select-sm mt-2"
                >
                  <option index hidden value={-1}>
                    select one page
                  </option>
                  {rootValues.page_group_id !== "" &&
                    funnelDetails?.page_group_type !== "generic_website" && (
                      <option value="move-to-next">
                        Redirect To Next Step
                      </option>
                    )}
                  {groupPage_list?.length > 0 &&
                    groupPage_list.map((page, index) => {
                      return (
                        <option key={`$-${index}`} value={page.page_slug}>
                          {page.page_name}
                        </option>
                      );
                    })}
                </select>

                {stepMessageShow && (
                  <div className="mt-2">
                    <span style={{ fontSize: "12px" }}>
                      There are no additional steps after this page.*
                    </span>
                  </div>
                )}
              </>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-between mb-2 align-items-center">
            <span> {`Select plan for cards:`}</span>
            <Button size="small" variant="outlined" onClick={GotoClientPortal}>
              Manage Plans
            </Button>
          </div>
          <ManagePaymentCard />
          {/* {paymentCardsElements.map((paymentCardElement, index) => (
            <div key={index} className="row pb-3">
              <div className="col-5 d-flex align-items-center">
                {`Card  ${index + 1}:`}
              </div>

              <div className="col-7">
                <select
                  value={getElementSelectedPlanValue(paymentCardElement)}
                  onChange={(e) => handleSelectPlan(paymentCardElement, e)}
                  className="form-select form-select-sm"
                >
                  <option index hidden value="">
                    select one plan
                  </option>
                  {activePaymentPlansList.map((plan, plan_index) => (
                    <option key={`${index}-${plan_index}`} value={plan._id}>
                      {plan.plan_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </>
  );
};

export default PlanSetting;
