import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  font_family_classes,
  google_font_family_classes,
} from "../../ui_libs/Constants/constant";
import { tinyMCEKey } from "../../config/config";
export const get_formatted_font_size = () => {
  let formatted_string =
    "10px=0.625rem 12px=0.75rem 14px=0.875rem 15px=0.94rem 16px=1rem 18px=1.125rem 20px=1.25rem 22px=1.375rem 24px=1.5rem 28px=1.75rem 32px=2rem 36px=2.25rem 40px=2.5rem 44px=2.75rem 48px=3rem 56px=3.5rem 64px=4rem 72px=4.5rem 80px=5rem 88px=5.5rem 96px=6rem 100px=6.25rem 110px=6.88rem 120px=7.50rem 130px=8.13rem 140px=8.75rem 150px=9.38rem";
  return formatted_string;
};
function CustomEditor({ mode, content, setContent, editorRef }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(content);

  const get_formatted_fonts = () => {
    let formatted_string = "";
    sessionStorage.getItem("is_dev") === "true" &&
      [1, 2, 3, 4].map((item) => {
        let single_font = `Default H${item}=var(--wb-theme-h${item}-font-family);`;
        formatted_string += single_font;
      });

    google_font_family_classes.forEach((font) => {
      let single_font = `${font.label}=${font.css_rule};`;
      formatted_string += single_font;
    });
    return formatted_string;
  };

  const get_content_style = () => {
    let formatted_string = "";
    sessionStorage.getItem("is_dev") === "true" &&
      [1, 2, 3, 4].map((item) => {
        let single_font =
          "@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');";
        formatted_string += single_font;
      });

    google_font_family_classes.forEach((font) => {
      let single_font = `${font.import_link}`;
      formatted_string += single_font;
    });

    formatted_string += "body { background-color: #02285912; }";

    return formatted_string;
  };

  // const get_formatted_font_size = () => {
  //   let formatted_string =
  //     "10px=0.625rem 12px=0.75rem 14px=0.875rem 16px=1rem 18px=1.125rem 20px=1.25rem 22px=1.375rem 24px=1.5rem 28px=1.75rem 32px=2rem 36px=2.25rem 40px=2.5rem 44px=2.75rem 48px=3rem 56px=3.5rem 64px=4rem 72px=4.5rem 80px=5rem 88px=5.5rem 96px=6rem";
  //   return formatted_string;
  // };

  const remove_editor_branding = () => {
    const branding = document.getElementsByClassName("tox-statusbar__branding");
    if (branding.length > 0) {
      branding[0].remove();
    }
  };

  useEffect(() => {
    remove_editor_branding();
  }, []);

  // if (isLoading) {
  //   return <></>;
  // }

  return (
    <div>
      <Editor
        apiKey={tinyMCEKey}
        onInit={(evt, editor) => {
          editorRef.current = editor;
          console.log(editor, "chchchhchchchchchh");

          // remove_editor_branding();
        }}
        initialValue={content}
        init={{
          //   images_upload_handler: example_image_upload_handler,
          height: 500,
          menubar: false,
          // casechange
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "charmap",
            "print",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "paste",
            "code",
            "help",
            "wordcount",
          ],
          menu: {
            file: {
              title: "File",
              items: "newdocument restoredraft | preview | print ",
            },
            edit: {
              title: "Edit",
              items: "undo redo | cut copy paste | selectall | searchreplace",
            },
            view: {
              title: "View",
              items:
                "code | visualaid visualchars visualblocks | spellchecker | preview fullscreen",
            },
            insert: {
              title: "Insert",
              items:
                "image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime",
            },
            format: {
              title: "Format",
              items:
                "bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat",
            },
            tools: {
              title: "Tools",
              items: "spellchecker spellcheckerlanguage | code wordcount",
            },

            help: { title: "Help", items: "help" },
          },
          // casechange
          toolbar:
            "undo redo | fontfamily blocks fontsize  removeformat | " +
            "forecolor backcolor  bold link italic underline strikethrough subscript superscript lineheight alignnone alignleft aligncenter alignright alignjustify outdent indent numlist bullist" +
            // "numlist bullist " +
            "",
          toolbar_mode: "wrap",
          font_size_formats: get_formatted_font_size(),
          font_family_formats: get_formatted_fonts(),
          content_style: get_content_style(),
        }}
      />
    </div>
  );
}

export default CustomEditor;
