import React, { useState, useEffect, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// react image crop
import {
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import HandymanIcon from "@mui/icons-material/Handyman";
import { Close, Done } from "@mui/icons-material";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Alert from "@mui/material/Alert";
import CollectionsIcon from "@mui/icons-material/Collections";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "../../../Right-Menu/components/Crop-Image/useDebounceEffect";
import { CanvasPreview } from "../../../Right-Menu/components/Crop-Image/CanvasPreview";
import CircularProgress from "@mui/material/CircularProgress";
import GalleryDialog from "../../../Gallery/GalleryDialog";

function BackgroundImage({
  backgroundImage,
  handleImageSelection,
  handleRemoveBackgroundImage,
  isLoadingBackgroundImage,
  ImgData,
  handleImageSelectionFromGallery,
  overLayShow = false,
  overLay,
  setOverLay,
}) {
  const parse_background_image = (image) => {
    const image_url = image.trim();

    if (image_url.search("url") === -1) {
      return image_url;
    }

    const image_ = image_url.slice(5, image_url.length - 2);
    return image_;
  };

  // react crop image
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  const [imgSrc, setImgSrc] = useState("");
  const [galleryDialog, setGalleryDialog] = useState(false);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [targetImage, setTargetImage] = useState(false);
  const [openDeleteImageDiloge, setOpenDeleteImageDiloge] = useState(false);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState();
  const [ImageDimention, setImageDimention] = useState();
  const [Customcrop, setCustomcrop] = useState(false);
  const [Error, setError] = useState(false);
  const [sizeLimit, setSizeLimit] = useState(250);
  const [PreviewImage, setPreviewImage] = useState({
    src: "",
    show: true,
  });
  const [uploadedimgheightwidth, setuploadedimgheightwidth] = useState({
    height: "",
    width: "",
  });

  const [Croperheightwidth, setCroperheightwidth] = useState({
    height: "",
    width: "",
    status: false,
  });
  const [Change, setChange] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );

      setError(false);
      PreviewImage.show = false;
      reader.readAsDataURL(e.target.files[0]);
    }
  }
  useEffect(() => {}, [Change]);

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    setImageDimention({ x: 0, y: 0, height, width, unit: "px" });
    const { naturalWidth, naturalHeight } = e.currentTarget;
    uploadedimgheightwidth.height = naturalHeight;
    uploadedimgheightwidth.width = naturalWidth;
    handlechangecropaspect("fix");
    // setCompletedCrop({
    //   x: 10,
    //   y: 10,
    //   height: newheight,
    //   width: newwidth,
    //   unit: "px",
    // });

    // setCrop({ x: 10, y: 10, height: newheight, width: newwidth, unit: "px" });
    Croperheightwidth.status = true;
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        CanvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
        ChangeCropHeightWidth();
      }
    },
    100,
    [completedCrop]
  );

  const ChangeCropHeightWidth = () => {
    Croperheightwidth.height = parseInt(previewCanvasRef.current.height);
    Croperheightwidth.width = parseInt(previewCanvasRef.current.width);

    setChange(!Change);
  };

  const handlecrop = () => {
    const canvas = previewCanvasRef.current;

    let height = Croperheightwidth.height;
    let width = Croperheightwidth.width;
    // if (Checked == "fix") {
    //   if (height < ImgData.height || width < ImgData.width) {
    //     setError(true);
    //     return;
    //   } else {
    //     setError(false);
    //     return;
    //   }
    // }
    const final = canvas.toDataURL("image/png", 1);
    const dataURI = final;

    // convversion back to image
    var byteString = atob(dataURI.split(",")[1]);

    // old code with no extention

    // // convversion back to image
    // var byteString = atob(dataURI.split(",")[1]);

    // // write the bytes of the string to an ArrayBuffer
    // var ab = new ArrayBuffer(byteString.length);
    // var ia = new Uint8Array(ab);
    // for (var i = 0; i < byteString.length; i++) {
    //   ia[i] = byteString.charCodeAt(i);
    // }
    // const myblob = new File([ab], { type: "image/jpeg" });

    // new code with extention added
    var byteCharacters = atob(dataURI.split(",")[1]);
    var byteArrays = [];
    const contentType = "png";
    const sliceSize = 512;
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var myblob = new Blob(byteArrays, { type: contentType });
    handleImageSelection(myblob, showupdatedimg);
    // setTimeout(() => {

    //   showupdatedimg()
    // }, 100)
  };

  const handleImageSelectFromGallery = (e, name) => {
    handleImageSelectionFromGallery(e, showupdatedimg, name);
  };
  const showupdatedimg = () => {
    setTimeout(() => {
      if (isLoadingBackgroundImage) {
        showupdatedimg();
      } else {
        PreviewImage.show = true;
        setImgSrc("");
      }
    }, 1500);
  };

  const handleFullSelect = () => {
    setChecked("full");
    setCrop({
      unit: "%", // Can be 'px' or '%'
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    });
    setAspect(undefined);
    setCompletedCrop(ImageDimention);
  };
  const handleremoveIMG = () => {
    PreviewImage.show = true;
    setError(false);
    setImgSrc("");
  };

  const [Checked, setChecked] = useState("custom");
  const handlechangecropaspect = (click) => {
    if (click == "custom") {
      setChecked("custom");
      setAspect(undefined);
    }
    if (click == "fix") {
      setAspect(ImgData.width / ImgData.height);
      setChecked("fix");

      // image height width from img tag
      let recomended_height = parseInt(ImgData.height);
      let recomended_width = parseInt(ImgData.width);

      // new uploaded image height width
      let image_height = uploadedimgheightwidth.height;
      let image_width = uploadedimgheightwidth.width;
      // check if new image is greater or smaller than the recomended size
      if (
        image_height <= recomended_height ||
        image_width <= recomended_width
      ) {
        if (
          image_width / image_height ==
          recomended_width / recomended_height
        ) {
          // aspect ratio same
          const { height, width } = imgRef.current;
          if (width < image_width) {
            image_width = image_width / (uploadedimgheightwidth.width / width);
          }
          if (height < image_height) {
            image_height =
              image_height / (uploadedimgheightwidth.height / height);
          }
          recomended_height = image_height;
          recomended_width = image_width;
        } else {
          let asp = recomended_width / recomended_height;
          if (image_width / image_height > 1) {
            recomended_width =
              recomended_width * image_height * (1 / recomended_height);
            recomended_height = image_height;
          } else {
            recomended_height =
              image_width * recomended_height * (1 / recomended_width);
            recomended_width = image_width;
          }
          recomended_width =
            recomended_width /
            (uploadedimgheightwidth.width / imgRef.current.width);
          recomended_height =
            recomended_height /
            (uploadedimgheightwidth.height / imgRef.current.height);
        }
      } else {
        const { height, width } = imgRef.current;
        recomended_height = recomended_height * (width / image_width);
        recomended_width = recomended_width * (height / image_height);
      }
      setCrop({
        x: 0,
        y: 0,
        height: recomended_height,
        width: recomended_width,
        unit: "px",
      });
      setCompletedCrop({
        x: 0,
        y: 0,
        height: recomended_height,
        width: recomended_width,
        unit: "px",
      });
    }

    setCustomcrop(!Customcrop);
  };
  const handleChooseFromGallery = () => {
    setGalleryDialog(true);
  };
  const DeleteImageConfirmation = (image) => {
    setTargetImage(image);
    setOpenDeleteImageDiloge(true);
  };

  return (
    <>
      <GalleryDialog
        galleryDialog={galleryDialog}
        setGalleryDialog={setGalleryDialog}
        uploadDialog={uploadDialog}
        setUploadDialog={setUploadDialog}
        DeleteImageConfirmation={DeleteImageConfirmation}
        changeBackgroundImage={true}
        handleImageSelectFromGallery={handleImageSelectFromGallery}
      />
      <div className="row">
        {PreviewImage.show && (
          <div className="col-12">
            <img src={parse_background_image(backgroundImage)} alt="" />
            {/* {isLoadingBackgroundImage && <span>Updating...</span>} */}
          </div>
        )}
        <div className="mt-2 d-flex align-items-center justify-content-between my-2">
          {/* <Tooltip title="Choose From Gallery" arrow placement="top">
            <IconButton onClick={handleChooseFromGallery}>
              <CollectionsIcon />
            </IconButton>
          </Tooltip> */}

          {backgroundImage !== "" && overLayShow && (
            <div>
              <FormControlLabel
                size="small"
                control={
                  <Switch
                    size="small"
                    color="primary"
                    checked={overLay}
                    onChange={() => setOverLay(!overLay)}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    style={{ fontSize: "0.8rem", marginInlineEnd: "8px" }}
                  >
                    Overlay:
                  </Typography>
                }
                labelPlacement="start"
                sx={{ margin: "0" }}
              />
            </div>
          )}
          <div className="d-flex align-items-center justify-content-end w-100">
            <Button
              variant="outlined"
              // component="label"
              size="small"
              sx={{
                borderRadius: "30px",
                padding: "0px 12px",
                boxShadow: "none",
                height: "24px",
              }}
              onClick={handleChooseFromGallery}
            >
              {/* <input
              hidden
              type="file"
              id="edit-able-src-input-file"
              accept="image/*"
              // onChange={handleImageSelection}
              onChange={onSelectFile}
              variant="outlined"
            /> */}
              <i class="fa-solid fa-plus" style={{ fontSize: "12px" }}></i>
              <span
                style={{
                  marginLeft: "0.5rem",
                  fontSize: "12px",
                  fontWeight: "lighter",
                }}
              >
                Upload Image
              </span>
            </Button>
          </div>
        </div>
        {/* {Boolean(imgSrc) && ( */}
        <div className="col-12 mb-1">
          <span className="custom-label">
            Recomended Height : {ImgData?.height} Width : {ImgData?.width}
          </span>
        </div>
        {/* )} */}
        <div>
          {Boolean(imgSrc) && (
            <div className="col-12">
              <div>
                <div className="row mb-2">
                  <div className="col-12">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="recomendedsize"
                        checked={Checked == "fix" && "true"}
                        onClick={() => handlechangecropaspect("fix")}
                      />
                      <label
                        className="form-check-label pointer custom-label"
                        htmlFor="recomendedsize"
                      >
                        Recomended Size
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="customsize"
                        checked={Checked == "custom" && "true"}
                        onClick={() => handlechangecropaspect("custom")}
                      />
                      <label
                        className="form-check-label pointer custom-label"
                        htmlFor="customsize"
                      >
                        Custom Size
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="full-size"
                        checked={Checked == "full" && "true"}
                        onClick={handleFullSelect}
                      />
                      <label
                        className="form-check-label pointer custom-label"
                        htmlFor="full-size"
                      >
                        Full Image
                      </label>
                    </div>
                  </div>
                </div>
                {Croperheightwidth.status && (
                  <div className="row mb-2">
                    <div className="col-12">
                      <span className="custom-label">
                        Image Height:
                        {Croperheightwidth.height +
                          " Width:" +
                          Croperheightwidth.width}
                      </span>
                    </div>
                  </div>
                )}

                <ReactCrop
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </div>
            </div>
          )}
          {Boolean(imgSrc) && (
            <div>
              {Boolean(completedCrop) && (
                <div className="col-12">
                  <div>
                    <label className="form-label custom-label">Preview</label>
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          border: "1px solid black",
                          objectFit: "contain",
                          width: completedCrop.width,
                          height: completedCrop.height,
                        }}
                      />
                    </div>
                    {Checked == "fix" &&
                      (ImgData.height > Croperheightwidth.height ||
                        ImgData.width > Croperheightwidth.width) && (
                        <Alert severity="warning">
                          Recomended Height Width is (
                          {ImgData.height + "," + ImgData.width})
                        </Alert>
                      )}
                    {Boolean(completedCrop) && (
                      <div className="pt-2 pb-2 col-6 align-items-center">
                        <div
                          class="btn-group mr-2"
                          role="group"
                          aria-label="First group"
                        >
                          {/* <div className="pr-1 icon-container fit-content">
                        <span className="mx-1 ">
                          <label className="form-label custom-label">Actions : </label>
                        </span>
                      </div> */}

                          <div className="p-0 icon-container fit-content">
                            <span className="mx-1 ">
                              <Tooltip
                                title="update Image"
                                placement="top"
                                arrow
                              >
                                {isLoadingBackgroundImage ? (
                                  <CircularProgress size="1.2rem" />
                                ) : (
                                  <Done
                                    htmlColor="green"
                                    onClick={handlecrop}
                                  />
                                )}
                              </Tooltip>
                            </span>
                          </div>
                          <div className="pr-1 icon-container fit-content">
                            <span className="mx-1 ">
                              <Tooltip
                                title="Cancel Image"
                                placement="top"
                                arrow
                              >
                                <Close
                                  htmlColor="red"
                                  onClick={handleremoveIMG}
                                />
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="col-12 text-end mt-2">
          <span
            className="custom-link"
            onClick={() => handleRemoveBackgroundImage("")}
          >
            Reset Background Image
          </span>
        </div>
        <div className="col-12 text-end mt-2">
          <span
            className="custom-link"
            onClick={() => handleRemoveBackgroundImage("none")}
          >
            Remove Background Image
          </span>
        </div>
      </div>
    </>
  );
}

export default BackgroundImage;
