import { Icon } from "@iconify/react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  Tooltip,
} from "@mui/material";
import React from "react";
function LimitationModal({ open, setOpen, limitationModalTitle }) {
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const GotoClientPortal = () => {
    const baseURL = sessionStorage.getItem("client_base_url");
    const paramsPath = sessionStorage.getItem("source_link");
    let user = sessionStorage.getItem("userType");

    if (paramsPath !== "" && paramsPath !== null) {
      window.location.href = paramsPath;
    } else if (baseURL && user === "3") {
      window.location.href = baseURL + "website-pages";
    } else if (baseURL) {
      window.location.href = baseURL + "/payment-plan";
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleCloseDialog()}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              padding: "0.5rem 1rem",
              borderTop: "0.3rem solid",
              color: "#023373",
            },
          },
        }}
      >
        <DialogTitle sx={{}}>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon
              icon="circum:face-smile"
              style={{ color: "ffcc00", fontSize: "100px" }}
              className="mb-3"
            />
            <h3 className="h3-font-size">It's time to upgrade.</h3>

            <p style={{ fontSize: "14px" }} className="text-center">
              Your have reached the limit of your current plan.
              <br /> To add another {limitationModalTitle}, you need to choose a
              higher plan.
            </p>
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Tooltip placement="top" title="Upgrade your plan." arrow>
            <Button
              className=""
              variant="contained"
              onClick={() => {
                GotoClientPortal();
                handleCloseDialog();
              }}
            >
              <Icon
                icon="ic:sharp-star"
                className="me-2"
                style={{ color: "ffcc00" }}
              />
              Upgrade
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default LimitationModal;
