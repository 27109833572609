import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import HeaderOnlyLayout from "./layouts/HeaderOnlyLayout";
import MainEditorLayout from "./layouts/MainEditorLayout/Main.component";
//
import {
  BuilderRedirectController,
  AdminAuthController,
  Builder,
  ChooseTemplate,
  AddNewProject,
  ProjectList,
  ProjectPagesList,
  Login,
  Register,
  WebsiteSettings,
  PageSettings,
  ForgetPassword,
  Subscribers,
  HelpingCategories,
  HelpingArticles,
  SupportTickets,
  SupportTicketDetails,
  ProjectVersionList,
  ClientPortalController,
} from "./pages/index";
import VersionsList from "./pages/Version-List/versionsList";
import Page404 from "./pages/Page404";
// import { ProjectVersionList } from "./pages/Project-List/component/ProjectVersionList";

// ----------------------------------------------------------------------
const Authentication = () => {
  let token = sessionStorage.getItem("token");
  const { hostname, pathname } = window.location;
  if (hostname == "localhost") {
    if (token) {
      return <Navigate to="/projects"> </Navigate>;
    } else {
      return <Navigate to="/login"> </Navigate>;
    }
  }
  return <Navigate to="/page404"> </Navigate>;
};

export default function Router() {
  return (
    <>
      <Routes>
        <Route
          path="/admin-auth-controller/:token"
          element={<AdminAuthController />}
        />
        <Route
          path="/client-auth-controller/:token/:project_id/:page_id"
          element={<ClientPortalController />}
        />
         <Route
          path="/user-auth-controller"
          element={<ClientPortalController />}
        />
        <Route element={<LogoOnlyLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
        </Route>

        <Route element={<HeaderOnlyLayout />}>
          {/* helping articles */}
          <Route
            path="/helping-articles-categories"
            element={<HelpingCategories />}
          />

          <Route
            path="/helping-articles/:category_id"
            element={<HelpingArticles />}
          />

          <Route path="/choose-template" element={<ChooseTemplate />} />
          <Route path="/add-project" element={<AddNewProject />} />
          <Route path="/projects" element={<ProjectList />} />
          <Route path="/projects/:project_id" element={<ProjectPagesList />} />
          <Route
            path="/projects/:project_id/version"
            element={<ProjectVersionList />}
          />
          <Route
            path="/projects/:project_id/:page_id/version"
            element={<VersionsList />}
          />
          <Route
            path="/website-settings/:project_id"
            element={<WebsiteSettings />}
          />
          <Route
            path="/page-settings/:project_id/:page_id"
            element={<PageSettings />}
          />
          <Route path="/subscribers/:project_id" element={<Subscribers />} />
          <Route path="/support-tickets" element={<SupportTickets />} />
          <Route
            path="/support-tickets/:ticket_id"
            element={<SupportTicketDetails />}
          />
          <Route
            path="/builder-redirect-controller"
            element={<BuilderRedirectController />}
          />
        </Route>

        <Route element={<MainEditorLayout />}>
          <Route path="/builder/:project_id/:page_id" element={<Builder />} />

          <Route path="/builder" element={<Builder />} />
        </Route>

        <Route element={<DashboardLayout />}>
          <Route path="/" element={<Authentication />} />
        </Route>

        <Route path="*" element={<Navigate to="/page404" />} />
        <Route path="/page404" element={<Page404 />} />
      </Routes>
    </>
  );
}
