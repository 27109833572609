import { useState } from "react";
import { Outlet, Navigate, useLocation, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import { client_side_url } from "../../config/config";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 60;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const path = location.pathname;

  if (
    window.location.hostname !== "localhost" &&
    path !== "/choose-template" &&
    path !== "/builder-redirect-controller"
  ) {
    return <Navigate to="/page404"> </Navigate>;
  }
  if (!sessionStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />

      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
