import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { forwardRef } from "react";
// material
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

const PageMetaTitles = forwardRef(
  ({ children, title = "", favIcon = "", ...other }, ref) => (
    <Box ref={ref} {...other}>
      <Helmet>
        <meta charset="utf-8" />
        <title>{title}</title>
        <link rel="apple-touch-icon" sizes="180x180" href={favIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={favIcon} />
        <link rel="icon" type="image/png" sizes="16x16" href={favIcon} />
      </Helmet>
      {children}
    </Box>
  )
);

PageMetaTitles.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  favIcon: PropTypes.string,
};

export default PageMetaTitles;
