import React, { useEffect, useState } from "react";

import {
  Box,
  ListItemButton,
  List,
  ListItemText,
  Divider,
} from "@mui/material";

import { Icon } from "@iconify/react";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";

const NavlistButtonPopup = () => {
  const {
    setNavlistButtonPopupAnchorEl,
    navlistButtonPopupAnchorEl,
    handleElementClick,
    editButtonPopupText,
    handleAddNavListListner,
    setSelectedElement,
    setSelectedNavBarElement,
  } = useBuilder();

  const [rightMenuList, setRightMenuList] = useState();
  const [element, setElement] = useState(null);
  const [showButton, setShowButton] = useState(false);

  const handleClose = () => {
    setNavlistButtonPopupAnchorEl(null);
  };
  const handleOpen = (e) => {
    e.preventDefault();
    const Project_Menu_list = [];
    Project_Menu_list.push({
      label: ``,
      icon: "mingcute:settings-6-line",
      divider: false,
      handleClick: () => handleElementClick(e, element),
    });

    setRightMenuList(Project_Menu_list);
  };

  const handleClick = () => {
    let targetElement = element;
    while (targetElement && targetElement.parentNode) {
      if (targetElement.tagName == "SECTION") {
        return;
      } else if (targetElement.tagName == "NAV") {
        break;
      }
      targetElement = targetElement.parentNode;
    }

    handleAddNavListListner(targetElement, element);
  };

  useEffect(() => {
    if (navlistButtonPopupAnchorEl) {
      let element = navlistButtonPopupAnchorEl;
      const editButton = document.querySelector(".wb-navlist-button");
      editButton.addEventListener("mouseenter", (e) => {
        setShowButton(true);
        element.style.outline = "1px solid blue";
      });
      editButton.addEventListener("mouseleave", (e) => {
        setShowButton(false);
        element.style.outline = "none";
      });
      setElement(element);
    }
  }, [navlistButtonPopupAnchorEl]);

  return (
    <>
      <div
        className="wb-navlist-button"
        style={{
          visibility:
            navlistButtonPopupAnchorEl || showButton ? "visible" : "hidden",
        }}
        onClick={() => handleClick()}
      >
        <i
          class="wp-list-click fa-solid fa-gear"
          style={{ width: "max-content" }}
        >
          Nav List
        </i>
        {/* <Box>
          <List
            component="nav"
            aria-label="secondary mailbox folder"
            onClick={handleClose}
            sx={{ padding: "0 0" }}
          >
            {rightMenuList?.map((list, index) => (
              <>
                <div key={index}>
                  {list?.divider == true && <Divider className="my-2" />}

                  <ListItemButton
                    disabled={list.disable}
                    onClick={list.handleClick}
                    sx={{
                      borderRadius: "8px",
                      cursor: "pointer",
                      marginInline: "8px",
                      padding: "2px",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor:
                          list.backgroundColor && list.backgroundColor,
                      },
                    }}
                  >
                    <Icon
                      icon="mingcute:settings-6-line"
                      className=""
                      style={{
                        color: list.color ? list.color : "#023373",
                      }}
                    />
                    {editButtonPopupText && (
                      <span className="ms-2">Edit Image</span>
                    )}
                  </ListItemButton>
                </div>
              </>
            ))}
          </List>
        </Box> */}
      </div>
    </>
  );
};

export default NavlistButtonPopup;
