import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";

import AccordionDetails from "@mui/material/AccordionDetails";
import { Button, TextField } from "@mui/material";
const GenerateGradient = ({
  handleAddGradient,
  getColumnproperty,
  removeColumnproperty,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [colors, setColors] = useState(getColumnproperty("background"));
  const [gradientType, setGradientType] = useState("linear");
  const [stringError, setStringError] = useState(false);

  const [gradientDirection, setGradientDirection] = useState("");
  const handleChange = (e, index) => {
    let value = e.target.value;
    value = value.replace(";", "");
    console.log(value, "jncscjsjcsjjncsj");
    if (!isValidGradient(value)) {
      setStringError(true);
      setColors(value);
      return;
    }
    setStringError(false);
    setColors(value);
  };

  function isValidGradient(gradientString) {
    // Regular expression to match a valid gradient pattern
    const gradientPattern =
      /^(\s*((linear|radial|conic|repeating-linear|repeating-radial|repeating-conic)-gradient\(.+?\)|((rgba?|hsla?|#?[A-Fa-f0-9]{6}|#?[A-Fa-f0-9]{3})\(.+?\))\s*\d*%?\s*,?\s*)+)+$/;

    return gradientPattern.test(gradientString);
  }

  const AddGradient = () => {
    if (colors?.length <= 0 || stringError) {
      enqueueSnackbar("Please paste gradient string", { variant: "error" });
      return;
    }
    // handleAddGradient(
    //   "background",
    //   `${gradientType}-gradient( ${
    //     gradientDirection ? gradientDirection + "," : ""
    //   }${GetColorList()})`
    // );
    handleAddGradient("background", colors);
  };
  const RemoveGradient = () => {
    setColors("");

    removeColumnproperty("background");
  };
  const GetColorList = () => {
    let value_list = "";
    colors.map((color, index) => {
      value_list += color.value;
      if (index < colors.length - 1) {
        value_list += ",";
      }
    });
    return value_list;
  };
  return (
    <div>
      {/* <Accordion className="card mb-2">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        > */}
      <div className="col-12">
        <label className="form-label custom-label m-0">Gradient:</label>
      </div>

      {/* </AccordionSummary>
        <AccordionDetails> */}
      <div className="row mb-2 mt-2 p-0 mx-0">
        <div className=" col-12 p-0">
          {/* <div className=" col-3 d-flex align-items-center">
            <div>
              <label className="custom-label d-flex align-items-start">
                Gradient
              </label>
            </div>
          </div> */}

          <div className="col-12 p-0">
            {/* <input
              placeholder="Paste gradient string"
              className="form-control form-control-sm"
              value={colors}
              onChange={handleChange}
            /> */}

            <TextField
              className="text-muted"
              InputLabelProps={{ style: { fontSize: 14 } }}
              id="gradiant-color"
              error={stringError}
              helperText={stringError && "Please paste a valid gradient string"}
              placeholder="Paste gradient string"
              variant="outlined"
              fullWidth
              size="small"
              name="GradientString"
              value={colors}
              onChange={handleChange}
            />
          </div>
          {!stringError && colors?.length > 0 && (
            <div
              className="col-12 p-0 mt-2 border rounded"
              style={{
                height: "100px",
                width: "100%",
                background: colors,
              }}
            >
              {" "}
            </div>
          )}
        </div>
        <div className="col-12 mt-2 d-flex p-0 justify-content-end">
          <Button
            size="small"
            variant="outlined"
            className="me-2"
            onClick={RemoveGradient}
          >
            Remove
          </Button>
          <Button
            className=""
            size="small"
            variant="contained"
            onClick={AddGradient}
          >
            Add
          </Button>
        </div>
      </div>
      {/* </AccordionDetails>
      </Accordion> */}
    </div>
  );
};

export default GenerateGradient;
