import { Tooltip, Typography, capitalize } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import DrawerMenuHeader from "../../../../components/Drawer-Menu-Header/DrawerMenuHeader";
import { list_all_plan_by_project_id } from "../../../../DAL/user-created-plans/userCreatedPlans";
import { useSnackbar } from "notistack";

// plan listing from strip
// wb-plan-card
const activePaymentElementListName = [
  { name: "plan_name", value: "wb-plan-name" },
  {
    name: "image",
    value: "wb-plan-image",
  },
  { name: "amount", value: "wb-plan-price" },
  { name: "interval_time", value: "wb-plan-duration" },
  { name: "currency_type", value: "wb-plan-curency" },
  { name: "plan_subtitle", value: "wb-plan-description" },
  {
    name: "button",
    value: "wb-payment-plan-button",
  },
  {
    name: "option_active",
    value: "wb-plan-option-active",
  },
  {
    name: "option_inactive",
    value: "wb-plan-option-inactive",
  },
  {
    name: "option_icon_active",
    value: "wb-plan-option-icon-active",
  },
  {
    name: "option_icon_inactive",
    value: "wb-plan-option-icon-inactive",
  },
  {
    name: "option_label",
    value: "wb-plan-option-label",
  },

  {
    name: "discount",
    value: "wb-plan-discount",
  },
  {
    name: "ribon",
    value: "wb-plan-ribon",
  },
];

const PlanCardSettingMenu = () => {
  const {
    setOpenPlanCardSetting,
    selectedElement,
    getParentNode,
    rootValues,
    activePaymentPlansList,
    setActivePaymentPlansList,
    ReRenderHtml,
    SaveActionToStack,
    funnelDetails,
  } = useBuilder();
  const [selectedPaymentPage, setSelectedPaymentPage] = useState("index");
  const [paymentCardElement, setPaymentCardElement] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleRemovePlanCard = () => {
    const element = getParentNode(selectedElement);
    element.remove();
    setOpenPlanCardSetting(false);
  };

  const getPlanList = async () => {
    let resp = await list_all_plan_by_project_id(rootValues.project);
    if (resp.code == 200) {
      let plansList = [];
      resp.data.map((plan) => {
        getSignAgainstCurrency(plan, plansList);
      });
      let list = [];
      plansList.map((plan) => {
        if (
          plan.page_group_id &&
          plan.page_group_id === rootValues.page_group_id
        ) {
          list.push(plan);
        }
      });
      if (list.length > 0) {
        setActivePaymentPlansList(list);
      } else {
        setActivePaymentPlansList(plansList);
      }
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    // setActivePaymentPlansList([...activePaymentPlansListTest]);
  };

  const getSignAgainstCurrency = (plan, plansList) => {
    switch (plan.currency_type) {
      case "EUR":
        plan.currency_type = "€";
        break;
      case "GBP":
        plan.currency_type = "£";
        break;
      case "CNY":
        plan.currency_type = "¥";
        break;
      case "RUB":
        plan.currency_type = "₽";
        break;

      default:
        plan.currency_type = "$";
        break;
    }
    plansList.push(plan);
  };
  const getselectedPaymentPage = (element) => {
    let result = "";

    rootValues.page_list.map((page) => {
      if (rootValues.payment_page_id == page._id) {
        result = page.page_slug;
      }
    });

    let button = getElementContainProperty(
      element,
      "name",
      "wb-payment-plan-button"
    );

    if (
      rootValues.page_group_id !== "" &&
      funnelDetails?.page_group_type !== "generic_website"
    ) {
      if (button && button.length > 0) {
        if (button[0].classList.contains("move-to-next")) {
          result = "Redirect To Next Step";
        }
      }
    }

    console.log(element, "Card element");
    setSelectedPaymentPage(result);

    return selectedPaymentPage;
  };

  const getElementSelectedPlanValue = (element) => {
    let value = -1;
    if (element) {
      value = element.getAttribute("active_plan_id");
    }
    return value ? value : -1;
  };

  const handleSelectPlan = (element, e) => {
    const { value } = e.target;
    if (value == -1) return;
    let planIndex = getPlanIndexFromID(value);
    findElementsContainPlanAttributes(element, planIndex, value);
    // setChangeInCards(!changeInCards);
  };

  const getPlanIndexFromID = (value) => {
    let result = -1;
    activePaymentPlansList.map((plan, index) => {
      if (plan._id == value) {
        result = index;
      }
    });

    return result;
  };
  const findElementsContainPlanAttributes = (element, planIndex, planID) => {
    SaveActionToStack();
    let availableSelectedCardElementsList = [];
    activePaymentElementListName.map((elementName) => {
      let value = getElementContainProperty(element, "name", elementName.value);
      if (value) {
        availableSelectedCardElementsList[elementName.name] = value[0];
      }
    });
    Object.keys(availableSelectedCardElementsList).map((value) => {
      if (activePaymentPlansList[planIndex][value] != null) {
        availableSelectedCardElementsList[
          value
        ].innerHTML = `${activePaymentPlansList[planIndex][value]}`;
        availableSelectedCardElementsList[value].classList.add(
          "text-uppercase"
        );
      }
    });

    // handlePlanOptions(availableSelectedCardElementsList, planIndex);
    if (availableSelectedCardElementsList.button) {
      availableSelectedCardElementsList.button.href = `${selectedPaymentPage}.html?plan_id=${activePaymentPlansList[planIndex]._id}`;
    }
    element.setAttribute("active_plan_id", planID);
    // setChangeInCards(!changeInCards);
  };

  const getElementContainProperty = (element, property, value) => {
    if (!element) return null;
    let result = [];
    result = element.querySelectorAll(`[${property}=${value}]`);

    return result.length > 0 ? result : null;
  };

  useEffect(() => {
    const element = getParentNode(selectedElement);
    setPaymentCardElement(element);
    getselectedPaymentPage(element);
    getPlanList();
  }, []);

  return (
    <>
      <DrawerMenuHeader
        label="Plan Card Setting"
        setState={setOpenPlanCardSetting}
        handleRemove={handleRemovePlanCard}
      />
      <div className="px-3">
        <div className="pb-2 pe-3 border-bottom">
          <div className="col-12 d-flex align-items-center justify-content-between">
            <span> {`Payment page:`}</span>
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              {selectedPaymentPage
                ? `/${selectedPaymentPage}`
                : "Not selected yet"}
            </span>
          </div>
          <div className="col-12"></div>
        </div>

        <div className="row border m-1 mt-2 pb-3 p-1 align-items-center column-block-list">
          <div className="mb-2 d-flex justify-content-between">
            <Typography variant="subtitle2">{`Card Setting:`}</Typography>
          </div>

          <div className="col-12">
            <span style={{ fontSize: "14px" }}>{`Select plan:`}</span>

            <div className="col-12 mt-2">
              <select
                value={getElementSelectedPlanValue(selectedElement)}
                onChange={(e) => handleSelectPlan(selectedElement, e)}
                className="form-select form-select-sm"
              >
                <option index hidden value="">
                  select one plan
                </option>
                {activePaymentPlansList.map((plan, plan_index) => (
                  <option key={`${plan_index}`} value={plan._id}>
                    {plan.plan_name}
                  </option>
                ))}
              </select>
              {activePaymentPlansList.map((planList, plan_index) => (
                <div>
                  {planList._id ===
                    getElementSelectedPlanValue(selectedElement) && (
                    <div
                      className="d-flex justify-content-between mt-2"
                      style={{ fontSize: "12px" }}
                    >
                      Plan Detail:
                      <div>
                        <span>
                          {planList.currency_type}
                          {planList.amount}/{" "}
                          {planList.interval_time
                            ? capitalize(planList.interval_time)
                            : "One time"}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanCardSettingMenu;
