import React, { useState, useEffect } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  IconButton,
  Stack,
  TextField,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import StripeBuyButtonKeys from "./components/StripeBuyButtonKeys";
import StripeBuyButtonEmbedCode from "./components/StripeBuyButtonEmbedCode";

const StripeBuyButtonModal = () => {
  const {
    selectedElement,
    openStripeButtonModal,
    setOpenStripeButtonModal,
    ReRenderHtml,
  } = useBuilder();
  // tab shifting
  const [selectedTab, setSelectedTab] = useState(0);

  // keys state
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    button_id: "",
    publishable_key: "",
  });

  // embed code states
  const [stripeEmbedCode, setStripeEmbedCode] = useState("");
  const [scriptExistInEmbedCode, setScriptExistInEmbedCode] = useState(false);
  const [embedCodeError, setEmbedCodeError] = useState("");

  const handleClose = () => {
    setOpenStripeButtonModal(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  const handleChangeStripeButtonKeys = () => {
    setIsLoading(true);
    selectedElement?.children[0].setAttribute(
      "buy-button-id",
      inputs.button_id
    );
    selectedElement?.children[0].setAttribute(
      "publishable-key",
      inputs.publishable_key
    );
    handleClose();
  };
  const findString = (str = "") => {
    let result = false;
    if (
      stripeEmbedCode &&
      stripeEmbedCode.length > str.length &&
      stripeEmbedCode.toLocaleLowerCase().search(str) >= 0
    ) {
      result = true;
    }
    return result;
  };
  const handleAddStripeEmbedCode = () => {
    if (findString("<script>") || findString("</script>")) {
      setScriptExistInEmbedCode(true);
      setEmbedCodeError("Please Remove the Script from the embeded code");
      return;
    }
    if (
      !(
        findString("stripe-buy-button") &&
        findString("buy-button-id") &&
        findString("publishable-key")
      )
    ) {
      setScriptExistInEmbedCode(true);
      setEmbedCodeError("Invalid stripe button embed code");
      return;
    }
    console.log(selectedElement, "objectobjectobjectobject");
    selectedElement.innerHTML = stripeEmbedCode.trim();

    ReRenderHtml();
    handleClose();
  };
  const handleSubmit = () => {
    selectedTab == 0 && handleChangeStripeButtonKeys();
    selectedTab == 1 && handleAddStripeEmbedCode();
  };
  useEffect(() => {

    let publishable_key = "";
    let button_id = "";
    let embed_code = "";
    if (openStripeButtonModal) {
      setSelectedTab(0)
      setIsLoading(false);
      publishable_key =
        selectedElement?.children[0]?.getAttribute("publishable-key");
      button_id = selectedElement?.children[0]?.getAttribute("buy-button-id");
      embed_code = selectedElement.innerHTML;
    }
    setInputs({
      ...inputs,
      button_id: button_id,
      publishable_key: publishable_key,
    });
    setStripeEmbedCode(embed_code);
    
  }, [openStripeButtonModal]);

  return (
    <Dialog
      open={openStripeButtonModal}
      onClose={isLoading ? "" : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Stack sx={{ padding: 1, width: 600 }}>
        {/* head */}
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            paddingX: 1,
          }}
        >
          <Typography variant="h6">Stripe Buy Button Setting</Typography>
          <IconButton
            className="pointer"
            onClick={isLoading ? "" : handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <div className="d-flex">
          <div
            className={
              selectedTab === 0
                ? "image-editor-button image-editor-button-active"
                : "image-editor-button"
            }
            onClick={() => setSelectedTab(0)}
          >
            <span>keys</span>
          </div>
          <div
            className={
              selectedTab === 1
                ? "image-editor-button image-editor-button-active"
                : "image-editor-button"
            }
            onClick={() => setSelectedTab(1)}
          >
            <span>Embeded Code</span>
          </div>
        </div>
        <Divider className="mt-0 mx-2 mb-3" />

        {/* body */}

        {selectedTab == 0 && (
          <StripeBuyButtonKeys inputs={inputs} handleChange={handleChange} />
        )}
        {selectedTab == 1 && (
          <StripeBuyButtonEmbedCode
            stripeEmbedCode={stripeEmbedCode}
            setStripeEmbedCode={setStripeEmbedCode}
            scriptExistInEmbedCode={scriptExistInEmbedCode}
            setScriptExistInEmbedCode={setScriptExistInEmbedCode}
            embedCodeError={embedCodeError}
            setEmbedCodeError={setEmbedCodeError}
          />
        )}
      </Stack>

      <DialogActions sx={{ paddingX: 2 }}>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleSubmit}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default StripeBuyButtonModal;
