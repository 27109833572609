import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { s3baseUrl } from "../config/config";
import palette from "../theme/palette";
import { infoImage } from "../assets";
function CustomConfirmation({
  open,
  setOpen,
  handleAgree,
  title,
  CancelAction = null,
  IMG = null,
  info = null,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
    if (CancelAction) {
      CancelAction(false);
    }
  };
  useEffect(() => {
    setIsLoading(false);
  }, [open]);

  let _multiple_hit = true;
  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            borderTop: "5px solid",
            borderColor: palette.primary.main,
          },
        }}
      >
        <DialogTitle sx={{ paddingBottom: 0 }}>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {title ? title : "Are you sure you want to take this action?"}
            {IMG && (
              <img
                style={{
                  marginTop: 5,
                  borderRadius: 10,
                  width: 130,
                  height: 130,
                  objectFit: "cover",
                }}
                src={s3baseUrl + "/" + IMG.file_path}
              />
            )}

            {info && (
              <div className="alert alert-info d-flex w-100 mt-3" role="alert">
                <div
                  style={{ width: "36px", height: "36px" }}
                  className="me-3 mt-1"
                >
                  <Avatar
                    src={infoImage}
                    alt="photoURL"
                    style={{ width: "36px", height: "36px" }}
                  />
                </div>
                <div>
                  <strong>Alert:</strong>

                  <p className="mb-0" style={{fontSize:"14px", fontWeight:"500"}}>{info}</p>
                </div>
              </div>
            )}
          </Stack>
        </DialogTitle>
        <DialogActions className="mx-3 mb-2">
          <Button variant="outlined" size="small" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            autoFocus
            disableRipple
            variant="contained"
            size="small"
            onClick={() => {
              // setTimeout(() => {
              setIsLoading(true);
              handleAgree(_multiple_hit);
              if (_multiple_hit) {
                _multiple_hit = null;
              }
              // }, 1)
            }}
          >
            Yes, Continue
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomConfirmation;
