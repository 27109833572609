import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, IconButton, Input, Stack, TextField } from "@mui/material";

import { _save_user_design_html } from "../../../../DAL/projects/projects";
import { asserts_base_url, s3baseUrl } from "../../../../config/config";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { _get_user_design_details } from "../../../../DAL/projects/projects";
import { _update_page_meta_settings } from "../../../../DAL/projects-pages/projectspages";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import Loader from "../../../../components/Loader/Loader";
import {
  _get_user,
  _set_user,
} from "../../../../DAL/localStorage/localStorage";
import { _upload_file_on_s3 } from "../../../../DAL/s3upload/s3upload";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import es from "date-fns/esm/locale/es/index.js";
function PageSetting(props) {
  const { customerSubscriptionDetail } = useBuilder();
  var myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");

  var myInit = {
    method: "GET",
    headers: myHeaders,
  };
  const { page } = props;
  console.log(props);
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    project_name: "",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    google_analytics_script: "",
    body_script: "",
    expanded: false,
    isLoading: false,
  });
  //
  const [isLoading, setisLoading] = useState(false);
  const handleChangeActiveExpand = (value) => {
    setInputs({ ...inputs, expanded: value });
  };

  const handleChangeCompanionLoader = (value) => {
    setInputs({ ...inputs, isLoading: value });
  };

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const fetchFileFromURL = (url) => {
    return new Promise(async (resolve, reject) => {
      const resp = await fetch(url, myInit);
      if (resp.status === 200) {
        const data = await resp.text();
        resolve(data);
      } else {
        console.error(resp);
        resolve("");
      }
    });
  };
  const getRootValues = (updatedPage) => {
    const {
      theme_heading_color,
      theme_primary_color,
      theme_secondary_color,
      text_primary_color,
      text_secondary_color,
      hover_text_color,
      hover_background_color,
      text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      //
      input_placeholder_color,
      input_text_color,
      input_background_color,
      input_border_color,
      menu_color,
      menu_hover_color,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    } = updatedPage;

    const rootValues = {
      primary_color: theme_primary_color,
      secondary_color: theme_secondary_color,
      theme_heading_color,
      primary_text_color: text_primary_color,
      secondary_text_color: text_secondary_color,
      hover_text_color: hover_text_color,
      hover_background_color: hover_background_color,
      font_family: text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      input_placeholder_color,
      input_text_color,
      input_background_color,
      input_border_color,
      menu_color,
      menu_hover_color,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    };
    const root = `
    :root {
      --wb-website-theme-primary-color: ${rootValues.primary_color};
      --wb-website-theme-secondary-color: ${rootValues.secondary_color};
      --wb-website-text-heading_color: ${rootValues.theme_heading_color};
      --wb-website-text-primary-color: ${rootValues.primary_text_color};
      --wb-website-text-secondary-color: ${rootValues.secondary_text_color};
      --wb-website-hover-text-color: ${rootValues.hover_text_color};
      --wb-website-hover-background-color: ${rootValues.hover_background_color};
      --wb-website-text-font-family: ${rootValues.font_family};
      --wb-theme-box-shadow: ${rootValues.theme_box_shadow};
      --wb-theme-h1-font-size:${rootValues.theme_h1_font_size};
      --wb-theme-h2-font-size:${rootValues.theme_h2_font_size};
      --wb-theme-h3-font-size:${rootValues.theme_h3_font_size};
      --wb-theme-h4-font-size:${rootValues.theme_h4_font_size};
      --wb-theme-p-font-size:${rootValues.theme_p_font_size};
      --wb-theme-h1-font-weight:${rootValues.theme_h1_font_weight};
      --wb-theme-h2-font-weight:${rootValues.theme_h2_font_weight};
      --wb-theme-h3-font-weight:${rootValues.theme_h3_font_weight};
      --wb-theme-h4-font-weight:${rootValues.theme_h4_font_weight};
      --wb-theme-p-font-weight:${rootValues.theme_p_font_weight};
      --wb-theme-line-height:${rootValues.theme_line_height};
      --wb-theme-h1-font-family:${rootValues.theme_h1_font_family};
      --wb-theme-h2-font-family:${rootValues.theme_h2_font_family};
      --wb-theme-h3-font-family:${rootValues.theme_h3_font_family};
      --wb-theme-h4-font-family:${rootValues.theme_h4_font_family};

      --wb-input-placeholder-color:${rootValues.input_placeholder_color};
      --wb-input-text-color :${rootValues.input_text_color};
      --wb-input-background-color:${rootValues.input_background_color};
      --wb-input-border-color:${rootValues.input_border_color};
      --wb-menu-color:${rootValues.menu_color};
      --wb-menu-hover-color:${rootValues.menu_hover_color};
    }
    `;

    return root;
  };
  const GetFavIcon = () => {
    let icon = sessionStorage.getItem("fav_icon");
    return icon ? icon : "";
  };
  const getFinalHTML = (
    updatedSectionsList,
    active_compain_div,
    updatedPage
  ) => {
    let collect_html = "";
    for (let index = 0; index < updatedSectionsList.length; index++) {
      collect_html += updatedSectionsList[index].outerHTML + "\n\n";
    }

    collect_html = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>${
          updatedPage.meta_title ? updatedPage.meta_title : inputs.project_name
        }</title>
        <link rel="icon" type="image/x-icon" href="${GetFavIcon()}">
        <meta name="description" content="${
          updatedPage.meta_description ? updatedPage.meta_description : ""
        }" />
        <meta name="keywords" content="${
          updatedPage.meta_keywords ? updatedPage.meta_keywords : ""
        }" />
        <!-- Header Script  Start-->
        ${
          updatedPage.google_analytics_script
            ? updatedPage.google_analytics_script
            : ""
        }
        <!-- Header Script End -->

       
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
          crossorigin="anonymous"
        />
        <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />

      <!-- Using Carousel -->
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css"
        integrity="sha512-sMXtMNL1zRzolHYKEujM2AqCLUR9F2C4/05cdbxjjLSRvMQIciEPCQZo++nk7go3BtSuK9kfa/s+a4f4i5pLkw=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css"
        integrity="sha512-tS3S5qG0BlhnQROyJXvNjeEM4UpMXHrQfTGmbQ1gKmelCxlSEBUaxhRBj/EFTzpbP4RVSrpEikbmdJobCvhE3g=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
      />
      
      <link
        rel="stylesheet"
        href="https://builder-templates-bucket.s3.amazonaws.com/asserts-db/css/fonts.css"
      />
      <link
        rel="stylesheet"
        href="https://builder-templates-bucket.s3.amazonaws.com/asserts-db/css/main.css"
      />

      
      
      <link href="css/${
        updatedPage.page_slug == "index" ? "style" : updatedPage.page_slug
      }.css" rel="stylesheet">
      <script src="https://js.stripe.com/v3/"></script>
      <style>
      ${getRootValues(updatedPage)}
      </style>
      </head>
      <body>
      ${active_compain_div}
        ${collect_html}


        <!-- Stripe Payment Button Script Start-->
        <script async="" src="https://js.stripe.com/v3/buy-button.js"></script>
        <!-- Stripe Payment Button Script Ends-->

        <!-- Body Script Start-->
        ${inputs.body_script ? inputs.body_script : ""}
        <!-- Body Script End -->

        <script src="https://code.jquery.com/jquery-3.6.0.js"
      integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
        <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossorigin="anonymous"
      ></script>
      
      <!-- font-awesome 6 -->
      <script
        src="https://kit.fontawesome.com/e30259c958.js"
        crossorigin="anonymous"
      ></script>
      
      <!-- Carousel -->
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"
        integrity="sha512-bPs7Ae6pVvhOSiIcyUClR7/q2OAsRiovw4vAkX+zJbw3ShAeeqezq50RIIcIURq7Oa20rW2n2q+fyXBNcU9lrw=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
      ></script>

       <script src="${asserts_base_url}/asserts-db/js/index.js" crossorigin="anonymous"></script>
       <script src="${asserts_base_url}/asserts-db/js/payment.js" crossorigin="anonymous"></script>
      <script src="js/${page.page_slug}.js"></script>
      
    </body>
    </html>
    `;

    return collect_html;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const postData = {
      page_id: params.page_id,
      meta_title: inputs.meta_title,
      meta_description: inputs.meta_description,
      meta_keywords: inputs.meta_keywords,
      google_analytics_script: inputs.google_analytics_script,
      body_script: inputs.body_script,
    };

    handleChangeCompanionLoader(true);
    const resp = await _update_page_meta_settings(postData);
    if (resp.code === 200) {
      // get page_html
      const section_html = await fetchFileFromURL(
        `${s3baseUrl}/${params.project_id}/${page.page_slug}.html`
      );

      // put page html in div
      const el = document.createElement("div");
      el.innerHTML = section_html;
      // get page 1st div contain success and faliure message
      let active_compain_div = el.getElementsByTagName("div")[0];
      if (active_compain_div) {
        active_compain_div.setAttribute(
          "data-stripe_public_key",
          `"${
            customerSubscriptionDetail
              ? customerSubscriptionDetail.public_key
                ? customerSubscriptionDetail.public_key
                : ""
              : ""
          }"`
        );
      }
      const elements = el.getElementsByTagName("SECTION");

      const finalHTML = getFinalHTML(
        elements,
        active_compain_div.outerHTML,
        resp.page
      );
      const postData = {
        html: finalHTML,
        project_id: params.project_id,
        slug_name: resp.page.page_slug,
      };
      const page_resp = await _save_user_design_html(postData);
      if (page_resp.code == 200) {
        enqueueSnackbar("Page Edited successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(page_resp.message, { variant: "error" });
      }
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    handleChangeCompanionLoader(false);
  };

  const setPageData = async () => {
    const project_resp = await _get_user_design_details(params.project_id);
    if (project_resp.code == 200) {
      setInputs({
        project_name: project_resp.Project.project_name,
        meta_title: page.meta_title,
        meta_description: page.meta_description,
        meta_keywords: page.meta_keywords,
        google_analytics_script: page.google_analytics_script,
        body_script: page.body_script,
      });
    } else {
      enqueueSnackbar(project_resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    setPageData();
  }, []);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <>
        <div className="row mt-2">
          <div className="col-12 ">
            <Accordion expanded={inputs.expanded} className="card">
              <AccordionSummary
                onClick={() => handleChangeActiveExpand(!inputs.expanded)}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Page Meta Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <form onSubmit={handleSubmit}>
                  <div className="row  justify-content-end">
                    <div className="col-12 col-lg-6 mt-3">
                      <TextField
                        fullWidth
                        label="Meta Title"
                        name="meta_title"
                        value={inputs.meta_title}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-12 col-lg-6 mt-3">
                      <TextField
                        fullWidth
                        label="Meta Description"
                        name="meta_description"
                        value={inputs.meta_description}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-12 col-lg-6 mt-3">
                      <TextField
                        fullWidth
                        label="Meta Keywords"
                        name="meta_keywords"
                        placeholder="Happy, Good, Enjoy, Play"
                        value={inputs.meta_keywords}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <TextField
                        fullWidth
                        multiline
                        rows="3"
                        label="Header Script"
                        name="google_analytics_script"
                        value={inputs.google_analytics_script}
                        onChange={handleChange}
                        placeholder="Please add your all custom header scripts here e.g(Google Analytics)"
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <TextField
                        fullWidth
                        multiline
                        rows="3"
                        label="Body Script"
                        name="body_script"
                        value={inputs.body_script}
                        onChange={handleChange}
                        placeholder="Please add your all custom body scripts here"
                      />
                    </div>

                    <div className="col-12 col-lg-3 text-end mt-3">
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={inputs.isLoading}
                      >
                        Update
                      </LoadingButton>
                    </div>
                  </div>
                </form>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </>
    </>
  );
}

export default PageSetting;
