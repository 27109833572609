import { Card, CardMedia, IconButton, Popover, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { s3baseUrl, s3builderSource } from "../../../../config/config";
import {
  delete_picture,
  get_user_gallery,
} from "../../../../DAL/User-Gallery/user_gallery";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import GalleryDialog from "./GalleryDialog";
import MediaUploadDialog from "./MediaUploadDialog";
import CustomConfirmation from "../../../../components/CustomConfirmation";
import NotFound from "../../../../components/NotFound";
import { get_short_string } from "../../../../utils/utils";
function Gallery(props) {
  const {
    userImagesList,
    setUserImagesList,
    searchImageList,
    setSearchImageList,
    RemoveUserGalleryClass,
    GetImagesList,
    searchTemplateImageList,
    templateImagesList,
    stockImagesList,
    searchStockImageList,
  } = useBuilder();

  const { leaveHoverDrawer, selectedlistItem } = props;
  const [previewImage, setPreviewImage] = useState("");
  const [galleryDialog, setGalleryDialog] = useState(false);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState("");
  const [openDeleteImageDiloge, setOpenDeleteImageDiloge] = useState(false);
  const [targetImage, setTargetImage] = useState(false);
  const [showUserImageListing, setShowUserImageListing] = useState(false);
  const open = Boolean(anchorEl);
  const [searchFocus, setSearchFocus] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [imagesToShow, setImagesToShow] = useState([...userImagesList]);
  const [imagesToSearch, setImagesToSearch] = useState([...searchImageList]);

  const [selectedTab, setSelectedTab] = useState("Gallery");
  const handleDialogOpen = () => {
    // setGalleryDialog(true);
    setUploadDialog(true);
    searchInputCloser();
  };
  const handlePreview = (e, image) => {
    setAnchorEl(e.currentTarget);
    setPreviewImage(image);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImages((p) => p.concat(imagesArray));
  };
  const DeleteImageConfirmation = (image) => {
    setTargetImage(image);
    setOpenDeleteImageDiloge(true);
  };
  const handleDeleteImage = async () => {
    const resp = await delete_picture({ image_object: targetImage });
    if (resp.code == 200) {
      let list = userImagesList.filter((img) => {
        if (img != targetImage) return img;
      });
      setOpenDeleteImageDiloge(false);
      setUserImagesList([...list]);
      setSearchImageList([...list]);
    }
  };

  const handleClose = () => {
    leaveHoverDrawer();
  };

  const searchBlurControler = () => {
    if (searchInput.trim().length === 0) setSearchFocus(false);
  };

  const searchInputControler = () => {
    setSearchInput("");
    setSearchFocus(true);
  };
  const searchInputCloser = () => {
    setSearchInput("");
    setSearchFocus(false);
    handleChangeSearch();
  };

  const handleChangeSearch = (e) => {
    if (e) {
      setSearchInput(e.target.value);
      let value = e.target.value;
      let list = imagesToShow;
      if (value.trim().length > 0) {
        list = [];
        imagesToShow.map((image) => {
          if (image.file_name.toLowerCase().includes(value.toLowerCase())) {
            list.push(image);
          }
        });
      }
      setImagesToSearch([...list]);
    } else {
      let list = imagesToShow;
      setImagesToSearch([...list]);
    }
  };

  useEffect(() => {
    GetImagesList();
    setTimeout(() => {
      setShowUserImageListing(true);
    }, 500);

    return () => {
      setShowUserImageListing(false);
    };
  }, []);

  useEffect(() => {
    if (selectedTab === "Gallery") {
      setImagesToShow([...userImagesList]);
      setImagesToSearch([...searchImageList]);
    } else if (selectedTab === "Stock Images") {
      setImagesToShow([...stockImagesList]);
      setImagesToSearch([...searchStockImageList]);
    } else if (selectedTab === "Theme Images") {
      setImagesToShow([...templateImagesList]);
      setImagesToSearch([...searchTemplateImageList]);
    }
    setSearchFocus(false);
    setSearchInput("");
  }, [selectedTab, userImagesList, searchImageList]);

  return (
    <>
      <CustomConfirmation
        open={openDeleteImageDiloge}
        setOpen={setOpenDeleteImageDiloge}
        handleAgree={handleDeleteImage}
        IMG={targetImage}
        title="Are you sure you want to delete this image?"
      />
      {selectedlistItem === "Gallery" && (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              overflow: "hidden",
            }}
            className="transition pb-5"
          >
            <div
              className="border-bottom"
              style={{
                lineHeight: "41px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 8px 0 0",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "41px",
                  width: "80%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className={
                    selectedTab === "Gallery"
                      ? "wb-style-selection-tab wb-style-selection-tab-active text-center"
                      : "wb-style-selection-tab wb-style-selection-tab text-center"
                  }
                  style={{
                    height: "42px",
                    lineHeight: "42px",
                    padding: "0px 0px",
                    width: "100%",
                  }}
                  onClick={() => setSelectedTab("Gallery")}
                >
                  <span className="pointer">Gallery</span>
                </div>
                <div
                  className={
                    selectedTab === "Stock Images"
                      ? "wb-style-selection-tab wb-style-selection-tab-active text-center"
                      : "wb-style-selection-tab wb-style-selection-tab text-center"
                  }
                  onClick={() => setSelectedTab("Stock Images")}
                  style={{
                    height: "42px",
                    lineHeight: "42px",
                    padding: "0px 0px",
                    width: "100%",
                  }}
                >
                  <span className="pointer">Stock Images</span>
                </div>
                <div
                  className={
                    selectedTab === "Theme Images"
                      ? "wb-style-selection-tab wb-style-selection-tab-active text-center"
                      : "wb-style-selection-tab wb-style-selection-tab text-center"
                  }
                  onClick={() => setSelectedTab("Theme Images")}
                  style={{
                    height: "42px",
                    lineHeight: "42px",
                    padding: "0px 0px",
                    width: "100%",
                  }}
                >
                  <span className="pointer">Theme Images</span>
                </div>
              </div>
              <div className="icon" onClick={handleClose}>
                <i class="fa fa-xmark"></i>
              </div>
            </div>
            <div
              className="border-bottom"
              style={{
                lineHeight: "41px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 8px",
                fontSize: "14px",
                fontWeight: "500",
                position: "relative",
              }}
            >
              <div>{selectedTab}</div>
              <div className="d-flex">
                <div className="search-box-gallery" style={{ zIndex: "10" }}>
                  {searchFocus && (
                    <input
                      className="input search-box-gallery-width searchInput"
                      type="text"
                      autoFocus
                      onBlur={searchBlurControler}
                      placeholder="Search..."
                      value={searchInput}
                      onChange={handleChangeSearch}
                      style={{ position: "relative" }}
                    />
                  )}
                  {!searchFocus && (
                    <div className="icon" onClick={searchInputControler}>
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                  )}
                  {searchFocus && (
                    <div className="icon" onClick={searchInputCloser}>
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                  )}
                </div>
                {selectedTab === "Gallery" && (
                  <Tooltip title="Upload Media" placement="bottom" arrow>
                    <IconButton
                      color="primary"
                      className="icon"
                      aria-label="upload picture"
                      component="label"
                      style={{ marginInlineEnd: "38px" }}
                      onClick={handleDialogOpen}
                    >
                      <i
                        class="fa-solid fa-upload"
                        style={{ color: "#116dff" }}
                      ></i>
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>

            {/* Gallery Images funtionality goes here */}
            {imagesToSearch.length === 0 && (
              <div align="center" colSpan={12} style={{ marginTop: "50px" }}>
                <NotFound
                  searchQuery={imagesToSearch}
                  title={"You have not any uploaded images"}
                  icon="clarity:image-gallery-solid"
                />
              </div>
            )}
            <div className="wb-image-block h-100">
              {showUserImageListing &&
                imagesToShow &&
                imagesToSearch.map((image, index) => {
                  return (
                    <div
                      key={image}
                      className="wb-image col-6 border p-1"
                      style={{
                        width: "140px",
                        height: "160px",
                        borderRadius: "4px",
                      }}
                    >
                      <img
                        onDragStart={(e) => {
                          RemoveUserGalleryClass();
                          e.target.classList.add("user-gallery-img");
                        }}
                        src={
                          selectedTab === "Gallery"
                            ? s3baseUrl + "/" + image.file_path
                            : s3builderSource + "/" + image.file_path
                        }
                        style={{
                          width: "100%",
                          height: "135px",
                          objectFit: "cover",
                          background: "#f0f7ff",
                          // cursor: "pointer",
                        }}
                        alt="upload"
                      />
                      <div className="image-name-size border-top m-0 d-flex align-items-center justify-content-center">
                        <span>{get_short_string(image.file_name, 17)}</span>
                      </div>
                      {selectedTab === "Gallery" && (
                        <Tooltip title="Remove Image" placement="top" arrow>
                          <div
                            className="image-icon icon-delete"
                            style={{ top: "2%", right: "30px" }}
                            onClick={() => DeleteImageConfirmation(image)}
                          >
                            <i class="fa-solid fa-trash-can"></i>
                          </div>
                        </Tooltip>
                      )}
                      {/* <Tooltip title="Preview Image" placement="top" arrow> */}
                      <div
                        className="image-icon"
                        style={{ top: "2%" }}
                        onMouseEnter={(e) => handlePreview(e, image)}
                        onMouseLeave={() => handlePopoverClose()}
                        // onClick={handleRemoveRow}
                      >
                        <i class="fa-solid fa-eye"></i>
                      </div>
                      {/* </Tooltip> */}
                    </div>
                  );
                })}
            </div>

            <div>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                  marginLeft: "5px",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <div
                  style={{
                    width: "225px",
                    height: "100%",
                    background: "#f0f7ff",
                  }}
                >
                  <img
                    src={
                      selectedTab === "Gallery"
                        ? s3baseUrl + "/" + previewImage.file_path
                        : s3builderSource + "/" + previewImage.file_path
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      border: "8px solid white",
                    }}
                    alt={previewImage.file_name}
                  />
                  {/* <div dangerouslySetInnerHTML={{ __html: anchorEl.outterHTML }}></div> */}
                  {/* {anchorEl} */}
                </div>
              </Popover>
            </div>
            <GalleryDialog
              galleryDialog={galleryDialog}
              setGalleryDialog={setGalleryDialog}
              uploadDialog={uploadDialog}
              setUploadDialog={setUploadDialog}
              DeleteImageConfirmation={DeleteImageConfirmation}
            />
            <MediaUploadDialog
              uploadDialog={uploadDialog}
              setUploadDialog={setUploadDialog}
              setUserImagesList={setUserImagesList}
              userImagesList={userImagesList}
            />
          </div>
        </>
      )}
    </>
  );
}

export default Gallery;
