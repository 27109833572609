import React from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import {
  getElementList,
  stripeButton,
} from "../../../../ui_libs/Constants/constant";
import {
  Dialog,
  DialogActions,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import StripeBuyButtonEmbedCode from "../Stripe-Buy-Button/components/StripeBuyButtonEmbedCode";

const ReplaceableElementModal = () => {
  const {
    addElementOnTop,
    openReplaceElementMenu,
    setOpenReplaceElementMenu,
    isReplaceableElement,
    setIsReplaceableElement,
    selectedElement,
    SaveActionToStack,
    ReRenderHtml,
  } = useBuilder();
  const [openstripeEmbedCodeModal, setOpenStripeEmbedCodeModal] =
    React.useState(false);
  const [scriptExistInEmbedCode, setScriptExistInEmbedCode] = useState(false);
  const [stripeEmbedCode, setStripeEmbedCode] = useState("");
  const [embedCodeError, setEmbedCodeError] = useState("");
  const handleClose = () => {
    setOpenReplaceElementMenu(false);
  };
  const handleCloseEmbedCodeModal = () => {
    setOpenStripeEmbedCodeModal(false);
    setStripeEmbedCode("");
    setScriptExistInEmbedCode(false);
  };
  const handleCheckStripeButtonAdded = (element) => {
    let stripeButton = [
      ...element.getElementsByClassName("wb-stripe-buy-button"),
    ];

    return stripeButton.length > 0 ? true : false;
  };
  const handleReplace = (el) => {
    let element = getNewElement(el);

    if (handleCheckStripeButtonAdded(element)) {
      setOpenStripeEmbedCodeModal(true);
      return;
    }
    handleConfirmReplace(element);
  };
  const handleConfirmReplace = (element) => {
    if (!selectedElement) return;
    selectedElement.parentNode.innerHTML = element.outerHTML;
    handleClose();
    handleCloseEmbedCodeModal();
    ReRenderHtml();
  };
  const handleAddElement = (el) => {
    let element = getNewElement(el);

    if (handleCheckStripeButtonAdded(element)) {
      setOpenStripeEmbedCodeModal(true);
      return;
    }
    handleConfirmAddElement(element);
  };
  const handleConfirmAddElement = (element) => {
    let parent = selectedElement;

    if (parent.classList.contains("wp-add-element-button")) {
      parent.parentNode.parentNode.innerHTML = element.innerHTML;
      ReRenderHtml();
      handleClose();
      handleCloseEmbedCodeModal();
      return;
    }

    if (
      parent.children &&
      parent.children.length > 0 &&
      parent.children[0].tagName == "SECTION"
    ) {
      parent = parent.children[0];
    } else if (parent.classList) {
      if (parent.classList.contains("break-able-section")) {
        parent = parent.children[0].children[0];
      } else if (parent.classList.contains("edit-able-section")) {
        parent = parent.children[0].children[0].children[0];
      }
    }
    while (parent) {
      if (parent.tagName == "DIV" || parent.tagName == "SECTION") {
        // if (parent.classList && parent.classList.contains("row")) {
        let div = document.createElement("div");
        div.classList.add("row");
        div.innerHTML = element.innerHTML;
        if (addElementOnTop) {
          parent.prepend(div);
        } else {
          parent.append(div);
        }
        // } else {
        //   parent.append(element);
        // }
        ReRenderHtml();
        handleClose();
        handleCloseEmbedCodeModal();

        break;
      }
      parent = parent.parentNode;
    }
  };
  const getNewElement = (el) => {
    SaveActionToStack();
    let element = document.createElement("div");
    if (el.alternate_code && el.alternate_code == "iframe") {
      element.classList.add("row");
      element.innerHTML = ` <div class="wb-iframe"><iframe width="560" height="315" src="https://www.youtube.com/embed/inpok4MKVLM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe></div>`;
    } else if (el.alternate_code && el.alternate_code == "map") {
      element.classList.add("row");
      element.innerHTML = ` <div class="wb-iframe"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3431.959416035186!2d73.09375081460895!3d30.663271296029095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3922b7d4a0280655%3A0x8569edf3694d40d2!2sDynamic%20logix!5e0!3m2!1sen!2s!4v1667905363850!5m2!1sen!2s" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>`;
    } else {
      element.innerHTML = el.code;

      element = element.children[0];
      element.children[0].removeAttribute("width");
      element.removeAttribute("id");
      element.className = "row";
    }

    return element;
  };
  const getHeading = () => {
    return isReplaceableElement
      ? "Click on any element to replace selected element"
      : "Click on element to add";
  };
  const findString = (str = "") => {
    let result = false;
    if (
      stripeEmbedCode &&
      stripeEmbedCode.length > str.length &&
      stripeEmbedCode.toLocaleLowerCase().search(str) >= 0
    ) {
      result = true;
    }
    return result;
  };
  const handleAddStripeEmbedCode = () => {
    if (findString("<script>") || findString("</script>")) {
      setScriptExistInEmbedCode(true);
      setEmbedCodeError("Please Remove the Script from the embeded code");
      return;
    }
    if (
      !(
        findString("stripe-buy-button") &&
        findString("buy-button-id") &&
        findString("publishable-key")
      )
    ) {
      setScriptExistInEmbedCode(true);
      setEmbedCodeError("Invalid stripe button embed code");
      return;
    }
    let stripeWraper = document.createElement("div");
    stripeWraper.classList.add("wb-stripe-buy-button");
    stripeWraper.innerHTML = stripeEmbedCode.trim();
    let stripeWraperParent = document.createElement("div");
    stripeWraperParent.classList.add("row");
    stripeWraperParent.prepend(stripeWraper);

    isReplaceableElement
      ? handleConfirmReplace(stripeWraperParent)
      : handleConfirmAddElement(stripeWraperParent);
  };

  return (
    <>
      {/* stripe embedcode modal */}
      <Dialog
        keepMounted={true}
        open={openstripeEmbedCodeModal}
        onClose={handleCloseEmbedCodeModal}
        sx={{ zIndex: 100 }}
      >
        <Stack spacing={2} sx={{ width: 600, padding: 2 }}>
          <Stack
            direction={"row"}
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Typography variant="h6">Stripe Button Embeded Code</Typography>
            <IconButton className="pointer" onClick={handleCloseEmbedCodeModal}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <StripeBuyButtonEmbedCode
            stripeEmbedCode={stripeEmbedCode}
            setStripeEmbedCode={setStripeEmbedCode}
            scriptExistInEmbedCode={scriptExistInEmbedCode}
            setScriptExistInEmbedCode={setScriptExistInEmbedCode}
            embedCodeError={embedCodeError}
            setEmbedCodeError={setEmbedCodeError}
          />
          <Stack direction={"row-reverse"}>
            <LoadingButton
              variant="contained"
              onClick={handleAddStripeEmbedCode}
            >
              Update
            </LoadingButton>
          </Stack>
        </Stack>
      </Dialog>

      {/* replaceable element modal */}
      <Dialog
        keepMounted={true}
        open={openReplaceElementMenu}
        onClose={handleClose}
        sx={{ zIndex: 1 }}
      >
        <Stack spacing={2} sx={{ padding: 2 }}>
          <Stack
            direction={"row"}
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Typography variant="h6">{getHeading()}</Typography>
            <IconButton className="pointer" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div className="row p-4">
            {getElementList(true).map((el, i) => (
              <div
                key={i}
                onClick={() =>
                  isReplaceableElement
                    ? handleReplace(el)
                    : handleAddElement(el)
                }
                className="col-3 p-1"
              >
                <div className="element-card">
                  <i class={el.icon}></i>
                  <div className="text-center custom-label pt-2">
                    <span className="">{el.title}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Stack>
      </Dialog>
    </>
  );
};

export default ReplaceableElementModal;
