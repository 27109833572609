import tinymce from "tinymce";
import { get_formatted_font_size } from "../components/Custom-Editor/CustomEditor";
let editorsList = [];
let elementEditor = null;
let topStyle = null;
let topValue = null;
let newTopValue = null;
let toolbar_Id = null;
let button = [];

export const InitializeTinyEditor = (setEditorMenuOpen, editorMenuOpen) => {
  try {
    RemoveContentEditable();
    tinymce.init({
      selector: ".wb-editor-block", // change this value according to the HTML
      inline: true,
      menubar: false,
      branding: false,
      max_width: 400,
      plugins: ["link", "lists"],

      toolbar:
        "closeButton undo redo bold italic fontsize | fontfamily forecolor alignleft aligncenter alignright alignjustify outdent indent  underline strikethrough subscript link | superscript lineheight settingButton",
      // toolbar_mode: "wrap",

      setup: function (editor) {
        editor.ui.registry.addButton("closeButton", {
          icon: "close",
          tooltip: "Close",
          disabled: true,
          onAction: function () {
            let targetEditor = CheckEditorAlreadyExist(editor.id);
            if (targetEditor) {
              targetEditor.hide();
            } else {
              editorsList.push(editor);
              editor.hide();
            }
          },
        });
        editor.ui.registry.addButton("settingButton", {
          icon: "settings",
          tooltip: "Advance setting",
          disabled: true,
          onAction: function () {
            let targetEditor = CheckEditorAlreadyExist(editor.id);
            if (targetEditor) {
              targetEditor.hide();
            } else {
              editorsList.push(editor);
              editor.hide();
            }
            setTimeout(() => {
              if (!editorMenuOpen) {
                setEditorMenuOpen(true);
              }
            }, 100);
          },
        });
        // initialize function
        editor.on("init", function (e) {
          editorsList.push(editor);
        });
      },
      font_size_formats: get_formatted_font_size(),
    });
  } catch (error) {
    console.error(error, "Error in tiny editor");
  }
};
export const RemoveContentEditable = () => {
  editorsList = [];
  // tinymce.remove();
  [...document.getElementsByClassName("wb-editor-block")].map((block) => {
    block.classList.remove("mce-content-body");
    block.classList.remove("mce-edit-focus");
    block.style.removeProperty("position");
    block.removeAttribute("id");
    block.removeAttribute("contenteditable");
    block.removeAttribute("spellcheck");
  });
};

export const ShowEditor = async (element) => {
  CloseAllEditor(element);
  let targetEditor = CheckEditorAlreadyExist(element.getAttribute("id"));

  elementEditor = await targetEditor.container;
  setTimeout(() => {
    button = [...elementEditor?.querySelectorAll(".tox-tbtn")];
    button[button.length - 1]?.addEventListener("click", addListener);
  }, 20);
  if (targetEditor.hidden) {
    addListener();
    targetEditor.show();
    setTimeout(() => {
      targetEditor.focus();
    }, 1);
  }
};

export const CloseAllEditor = (element) => {
  if (editorsList && editorsList.length > 0) {
    for (let i = 0; i < editorsList.length; i++) {
      if (element && element.getAttribute("id") === editorsList[i].id) {
        continue;
      }
      editorsList[i].hide();
    }
  }
  button[button.length - 1]?.removeEventListener("click", addListener);
};

function addListener() {
  setTimeout(() => {
    toolbar_Id = button[button.length - 1]?.getAttribute("aria-controls");
    let toolbarElement = document.getElementById(toolbar_Id);
    if (toolbarElement !== null) {
      topStyle = toolbarElement?.firstElementChild.style.top;
      topValue = parseInt(topStyle, 10); // Parse the top value as an integer (removing "px" from the end)
      newTopValue = topValue - 160; // Subtract 125px from the top value
      toolbarElement.firstElementChild.style.top = newTopValue + "px";
    }
  }, 20);
}
const CheckEditorAlreadyExist = (targetID) => {
  if (targetID && editorsList && editorsList.length > 0) {
    for (let i = 0; i < editorsList.length; i++) {
      if (editorsList[i].id === targetID) {
        return editorsList[i];
      }
    }
    return false;
  }
  return false;
};
