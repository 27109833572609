import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { _s3upload } from "../../../../DAL/s3upload/s3upload";

import { TwitterPicker } from "react-color";
import CodeIcon from "@mui/icons-material/Code";

import Select from "react-select";
import {
  options,
  colors,
  color_classes,
  container_classes,
  bg_color_classes,
  text_color_classes,
  background_size_classes,
  background_repeat_classes,
  background_position_classes,
  animate_animation_list,
} from "../../../../ui_libs/Constants/constant";
import { s3baseUrl } from "../../../../config/config";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  BackgroundColor,
  BackgroundImage,
  Layout,
  Margins,
  MenuList,
  Paddings,
  TextColor,
  VideoIframe,
  SectionTitle,
  FormMenu,
  MenuStyles,
  BackgroundSize,
  BackgroundRepeat,
  BackgorundPosition,
} from "./components";
import { FormControlLabel, Switch, Tooltip } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import ElementSourceCodeEditorBar from "../../../../components/Element-Source-Code-Editor-Bar/ElementSourceCodeEditorBar";
import AnimationSelect from "../Right-Theme-Menu/components/Animations/AnimationSelect";
import { _dispatch_find_element_by_id_and_child } from "../../../../hooks/builder-context/utils";
import GenerateGradient from "../../../../components/Generate-Gradient/GenerateGradient";

const useStyles = makeStyles({
  list: {
    width: 280,
  },
  fullList: {
    width: "auto",
  },
});

const container_layout_action_child = 1;
const background_image_action_child = 0;
const background_color_action_child = 0;
const text_color_action_child = 0;
const paddings_action_child = 0;
const margins_action_child = 0;

function RightSectionSettingsMenu(props) {
  const classes = useStyles();
  const {
    handleRemoveElement,
    rootValues,
    handleUpdateSectionSettings,
    getSelectedSectionStyle,
    getSelectedSectionheightwidth,
    updateSelectedSectionStyle,
    getSelectedSectionClass,
    updateSelectedSectionClass,
    getSelectedSectionLists,
    handleReplaceListElements,
    getSelectedSectionFrames,
    getSelectedSections,
    getSelectedSectionSettingId,
    getSelectedSectionDataset,
    updateSelectedSectionTitle,
    getSelectedSectionForm,
    updateSelectedElementClass,
    isSaveLoading,
    handleUpateCss,
    getIsDevState,
    handleOpenElementSourceCode,
    updateAttributeOfSelectedSection,
    selectedSectionSettingId,
  } = useBuilder();

  const [selectedSectionDataset, setSelectedSectionDataset] = useState();

  const { setSectionSettingsMenuOpen } = props;

  const [isLoadingBackgroundImage, setIsLoadingBackgroundImage] =
    useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [overLay, setOverLay] = useState(false);

  const [styles, setStyles] = useState({
    layout: "",
    backgroundColor: "",
    background: "",
    backgroundImage: "",
  });

  const [activeClasses, setActiveClasses] = useState({
    backgroundSize: "",
    BackgroudPosition: "",
    animation: "",
  });

  const [controls, setControls] = useState({
    is_shown_background_color: false,
    is_shown_text_color: false,
  });

  const handleCancel = () => {
    setSectionSettingsMenuOpen(false);
  };

  const handleSave = () => {
    handleUpdateSectionSettings();
  };
  const handleRemove = () => {
    handleRemoveElement();
  };
  const [ImgData, setImgData] = useState({
    height: "",
    width: "",
  });
  const handleChangeInClass = (key, value, class_list) => {
    setActiveClasses({ ...activeClasses, [key]: value });
    const payload = {
      class_list,
      value,
      child: 0,
    };
    updateSelectedSectionClass(payload);
  };
  const handleChangeInBackgroundSizeClass = (key, value, class_list) => {
    const payload = {
      class_list,
      value,
      child: 0,
    };
    updateSelectedSectionClass(payload);
    // setTimeout(() => {
    //   const val = "";
    //   const payload2 = {
    //     class_list: background_position_classes,
    //     value: val,
    //     child: 0,
    //   };
    //   updateSelectedSectionClass(payload2);
    // }, 100);
    setActiveClasses({
      ...activeClasses,
      [key]: value,
      // ["BackgroudPosition"]: "",
    });
  };
  const handleChangeInAnimation = (key, value, identifier, class_list) => {
    setActiveClasses({ ...activeClasses, [key]: value });
    const payload = {
      class_list,
      value: value,
      child: 0,
      identifier: identifier,
    };

    updateSelectedSectionClass(payload);
    setTimeout(() => {
      const payload2 = {
        class_list,
        value: "",
        child: 0,
        identifier: identifier,
      };
      updateSelectedSectionClass(payload2);
    }, 1000);
    updateAttributeOfSelectedSection("data-animation", value);
  };
  const handleChangeInBackgroundPositionClass = (key, value, class_list) => {
    const payload = {
      class_list,
      value,
      child: 0,
    };
    updateSelectedSectionClass(payload);
    // setTimeout(() => {
    //   const val = "background-size-auto";
    //   const payload2 = {
    //     class_list: background_size_classes,
    //     value: val,
    //     child: 0,
    //   };
    //   updateSelectedSectionClass(payload2);
    // }, 100);
    setActiveClasses({
      ...activeClasses,
      [key]: value,
      // ["backgroundSize"]: "background-size-auto",
    });
  };

  const handleRemoveBackgroundImage = (value) => {
    const child = background_image_action_child;
    handleUpdateStyleProperty("backgroundImage", value, child);
    let section = _dispatch_find_element_by_id_and_child(
      selectedSectionSettingId,
      0
    );

    if (section.getAttribute("data-image_name")) {
      section.removeAttribute("data-image_name");
    }

    setStyles({ ...styles, backgroundImage: "" });
  };

  const handleImageSelection = async (e, showupdatedimg) => {
    setIsLoadingBackgroundImage(true);
    const resp = await _s3upload(`${rootValues._id}`, e);
    if (resp.code === 200) {
      const image_url = resp.file_name.trim();
      const img = s3baseUrl + "/" + image_url.replace(" ", "%20");
      const child = background_image_action_child;
      handleUpdateStyleProperty("backgroundImage", `url("${img}")`, child);
      // handleUpdateStyleProperty("backgroundRepeat", `no-repeat`, child);
      // handleUpdateStyleProperty("backgroundSize", `cover`, child);
      // setStyles({ ...styles, backgroundImage: img });
      showupdatedimg();
      setIsLoadingBackgroundImage(false);
    }
  };

  const handleImageSelectionFromGallery = async (e, showupdatedimg, name) => {
    setIsLoadingBackgroundImage(true);
    const img = e;
    const child = background_image_action_child;
    if (overLay) {
      handleUpdateStyleProperty(
        "backgroundImage",
        `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url("${img}")`,
        child,
        `${img}`
      );
    } else {
      handleUpdateStyleProperty(
        "backgroundImage",
        `url("${img}")`,
        child,
        `${img}`
      );
    }
    let section = _dispatch_find_element_by_id_and_child(
      selectedSectionSettingId,
      0
    );
    section.setAttribute("data-image_name", name);
    showupdatedimg();
    setIsLoadingBackgroundImage(false);
  };
  // General handlers for setting and getting styles

  const handleUpdateStyleProperty = (property, value, child, _link) => {
    if (_link) {
      setStyles({ ...styles, [property]: _link });
    } else {
      setStyles({ ...styles, [property]: value });
    }
    updateSelectedSectionStyle(property, value, child);
  };

  const handleGetStyleProperty = (property) => {
    const value = getSelectedSectionStyle(property, paddings_action_child);
    return value;
  };

  // Padding handlers for setting and getting styles

  const handleGetSpacingCustom = (property) => {
    const value =
      getSelectedSectionStyle(property, paddings_action_child).split("rem")[0] *
      16;
    return value ? value : 0;
  };
  const handleGetSpacingClass = (myproperty) => {
    const payload = {
      child: paddings_action_child,
      class_list: myproperty.class_list,
    };

    return getSelectedSectionClass(payload);
  };
  const handlegetbackgroundpositionclass = () => {
    const payload = {
      child: paddings_action_child,
      class_list: background_position_classes,
    };

    activeClasses.BackgroudPosition = getSelectedSectionClass(payload);
    return getSelectedSectionClass(payload);
  };

  const handleupdateSpacingClass = (property) => {
    const payload = {
      child: paddings_action_child,
      class_list: property.class_list,
      value: property.value,
    };
    updateSelectedSectionClass(payload);
  };

  const handleUpdateSpacingCustom = (property, val) => {
    const value = val ? val / 16 + "rem" : "0rem";
    updateSelectedSectionStyle(property, value, paddings_action_child);
  };

  const handleChangeLayout = (e) => {
    setStyles({ ...styles, layout: e.target.id });

    updateSelectedSectionClass({
      value: e.target.id,
      child: container_layout_action_child,
      class_list: container_classes,
    });
  };

  const handleChangeSectionLayout = (value) => {
    setStyles({ ...styles, layout: value });

    updateSelectedSectionClass({
      value: value,
      child: container_layout_action_child,
      class_list: container_classes,
    });
  };

  const handleChangeBackground = (e) => {
    setStyles({ ...styles, backgroundColor: e.hex });

    const color_class = bg_color_classes.find(
      (clr) => clr.color.toUpperCase() === e.hex.toUpperCase()
    );

    if (color_class) {
      const payload = {
        value: color_class.value,
        child: background_color_action_child,
        class_list: bg_color_classes,
      };
      updateSelectedSectionClass(payload);
    } else {
      // remove all important classes
      const payload = {
        value: "",
        child: background_color_action_child,
        class_list: bg_color_classes,
      };
      updateSelectedSectionClass(payload);
      // apply style inline
      handleUpdateStyleProperty(
        "backgroundColor",
        e.hex,
        background_color_action_child
      );
    }
  };
  const handleChangeBackgroundGradinet = (property, string) => {
    setStyles({ ...styles, backgroundColor: string });
    // remove all important classes
    const payload = {
      value: "",
      child: background_color_action_child,
      class_list: bg_color_classes,
    };
    updateSelectedSectionClass(payload);
    // apply style inline
    handleUpdateStyleProperty(property, string, background_color_action_child);
  };

  const handleResetBackgroundGradient = (e) => {
    // remove all important classes
    const payload = {
      value: "",
      child: background_color_action_child,
      class_list: bg_color_classes,
    };
    updateSelectedSectionClass(payload);
    // apply style inline
    handleUpdateStyleProperty("background", "", background_color_action_child);
  };
  const handleResetBackground = (e) => {
    // remove all important classes
    const payload = {
      value: "",
      child: background_color_action_child,
      class_list: bg_color_classes,
    };
    updateSelectedSectionClass(payload);
    // apply style inline
    handleUpdateStyleProperty(
      "backgroundColor",
      "",
      background_color_action_child
    );
  };

  const handleChangeTextColor = (e) => {
    setStyles({ ...styles, textColor: e.hex });

    const color_class = text_color_classes.find(
      (clr) => clr.color.toUpperCase() === e.hex.toUpperCase()
    );

    if (color_class) {
      const payload = {
        value: color_class.value,
        child: text_color_action_child,
        class_list: text_color_classes,
      };
      updateSelectedSectionClass(payload);
    } else {
      // remove all important classes
      const payload = {
        value: "",
        child: text_color_action_child,
        class_list: text_color_classes,
      };
      updateSelectedSectionClass(payload);
      // apply style inline
      handleUpdateStyleProperty("color", e.hex, text_color_action_child);
    }
  };

  const handleResetTextColor = (e) => {
    // remove all important classes
    const payload = {
      value: "",
      child: text_color_action_child,
      class_list: text_color_classes,
    };
    updateSelectedSectionClass(payload);
    // apply style inline
    handleUpdateStyleProperty("textColor", "", text_color_action_child);
  };

  const handleSetLayout = () => {
    const layout = getSelectedSectionClass({
      child: container_layout_action_child,
      class_list: container_classes,
    });
    // const backgroundColor = handleGetStyleProperty(
    //   'backgroundColor',
    //   background_color_action_child,
    // )
    let payload = {
      // value: color_class.value,
      child: background_color_action_child,
      class_list: bg_color_classes,
    };
    let backgroundColor = getSelectedSectionClass(payload);

    if (backgroundColor) {
      bg_color_classes.forEach((color) => {
        if (backgroundColor == color.value) {
          backgroundColor = color.color;
        }
      });
    }
    payload = {
      // value: color_class.value,
      child: background_color_action_child,
      class_list: text_color_classes,
    };
    let textColor = getSelectedSectionClass(payload);

    if (textColor) {
      text_color_classes.forEach((color) => {
        if (textColor == color.value) {
          textColor = color.color;
        }
      });
    }

    const backgroundImage = handleGetStyleProperty(
      "backgroundImage",
      background_color_action_child
    );

    let getStyles = {};

    getStyles.layout = layout;

    if (backgroundColor) {
      getStyles.backgroundColor = backgroundColor;
    }
    if (textColor) {
      getStyles.textColor = textColor;
    }

    if (backgroundImage) {
      let startingIndex = backgroundImage.indexOf("url") + 5;
      let endingIndex = backgroundImage.lastIndexOf(")") - 1;
      getStyles.backgroundImage = backgroundImage.slice(
        startingIndex,
        endingIndex
      );

      if (
        backgroundImage.includes("gradient") &&
        backgroundImage.includes("url")
      ) {
        setOverLay(true);
      } else {
        setOverLay(false);
      }
    }

    setStyles((c) => {
      return {
        ...c,
        ...getStyles,
      };
    });
  };
  const handleUpdateDatasetTitle = (newTitle) => {
    updateSelectedSectionTitle(newTitle);
  };
  const handleSelectedSectionDataset = () => {
    let dataset = getSelectedSectionDataset();
    setSelectedSectionDataset(dataset);
    if (dataset.animation) {
      setActiveClasses({ ...activeClasses, animation: dataset.animation });
    }
  };

  const getFormData = () => {
    const { form, status } = getSelectedSectionForm();
    return { form, status };
  };

  const handleGetClassByType = () => {
    const classes_data = {
      backgroundSize: getSelectedSectionClass({
        class_list: background_size_classes,
        child: 0,
      }),

      backgroundRepeat: getSelectedSectionClass({
        class_list: background_repeat_classes,
        child: 0,
      }),
      BackgroudPosition: handlegetbackgroundpositionclass(),
      animation: getSelectedSectionClass({
        class_list: animate_animation_list,
        child: 0,
      }),
    };

    setActiveClasses(classes_data);
  };

  useEffect(() => {
    const value = getSelectedSectionheightwidth(paddings_action_child);
    ImgData.height = value.height;
    ImgData.width = value.width;
    handleSetLayout();
    handleGetClassByType();
    handleSelectedSectionDataset();
  }, []);

  useEffect(() => {
    if (styles.backgroundImage !== "") {
      if (overLay) {
        handleUpdateStyleProperty(
          "backgroundImage",
          `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url("${styles.backgroundImage}")`,
          background_image_action_child,
          `${styles.backgroundImage}`
        );
      } else {
        handleUpdateStyleProperty(
          "backgroundImage",
          `url("${styles.backgroundImage}")`,
          background_image_action_child,
          `${styles.backgroundImage}`
        );
      }
    }
  }, [overLay]);
  return (
    <div className="p-3 pt-0" role="presentation">
      <div
        className="col-12 pt-2 mb-2 pb-2 border-bottom"
        style={{
          position: "sticky",
          zIndex: "100",
          background: "white",
          top: "0%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h6 className="mb-0">Section Settings</h6>
        <div className="d-flex">
          <ElementSourceCodeEditorBar showJSIcon={true} />
          <Tooltip title="Close" position="bottom" arrow>
            <div className="icon me-2" onClick={handleCancel}>
              <i class="fa fa-xmark"></i>
            </div>
          </Tooltip>
        </div>
      </div>

      <div
        style={{
          position: "sticky",
          top: "40px",
          background: "#fff",
          zIndex: 100,
          borderBottom: "1px Solid #022859",
          marginInline: "-14px",
        }}
        className="mb-2"
      >
        <div className="d-flex">
          <div
            className={
              selectedTab === 0
                ? "image-editor-button image-editor-button-active"
                : "image-editor-button"
            }
            onClick={() => setSelectedTab(0)}
          >
            <span>Style</span>
          </div>
          {/* <div
            className={
              selectedTab === 1
                ? "image-editor-button image-editor-button-active"
                : "image-editor-button"
            }
            onClick={() => setSelectedTab(1)}
          >
            <span>Layout</span>
          </div> */}

          <div
            className={
              selectedTab === 1
                ? "image-editor-button image-editor-button-active"
                : "image-editor-button"
            }
            onClick={() => setSelectedTab(1)}
          >
            <span>Spacing</span>
          </div>
          <div
            className={
              selectedTab === 2
                ? "image-editor-button image-editor-button-active"
                : "image-editor-button"
            }
            onClick={() => setSelectedTab(2)}
          >
            <span>Animation</span>
          </div>
          {getSelectedSectionFrames().show_menu && (
            <div
              className={
                selectedTab === 3
                  ? "image-editor-button image-editor-button-active"
                  : "image-editor-button"
              }
              onClick={() => setSelectedTab(3)}
            >
              <span>iFrame</span>
            </div>
          )}
          {getSelectedSectionLists().show_menu && (
            <div
              className={
                selectedTab === 4
                  ? "image-editor-button image-editor-button-active"
                  : "image-editor-button"
              }
              onClick={() => setSelectedTab(4)}
            >
              <span>Menu</span>
            </div>
          )}
          {getSelectedSectionLists().show_menu && (
            <div
              className={
                selectedTab === 5
                  ? "image-editor-button image-editor-button-active"
                  : "image-editor-button"
              }
              onClick={() => setSelectedTab(5)}
            >
              <span>Menu Style</span>
            </div>
          )}
        </div>
        {/* <Divider className="mt-0 mx-3 mb-2" /> */}
      </div>

      {selectedTab === 0 && (
        <>
          <div className="col-12 mb-2">
            {selectedSectionDataset && (
              <SectionTitle
                UpdateDatasetTitle={handleUpdateDatasetTitle}
                dataset={selectedSectionDataset}
              />
            )}
          </div>
          {/* {getSelectedSectionLists().show_menu && (
            <div className="col-12 mb-2">
              <MenuList
                handleReplaceListElements={handleReplaceListElements}
                getSelectedSectionLists={getSelectedSectionLists}
                handleClose={handleCancel}
              />
            </div>
          )}

          {getSelectedSectionLists().show_menu && (
            <div className="col-12 mb-2">
              <MenuStyles />
            </div>
          )}
          {getSelectedSectionFrames().show_menu && (
            <div className="col-12 mb-2">
              <VideoIframe
                getSelectedSectionFrames={getSelectedSectionFrames}
                handleChangeLayout={handleChangeLayout}
              />
            </div>
          )} */}
          <div className="col-12 mb-2">
            <Layout styles={styles} handleChangeLayout={handleChangeLayout} />
          </div>

          <div className="col-12 mb-2">
            {/* <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Section Background</Typography>
              </AccordionSummary>
              <AccordionDetails> */}
            <div className="col-12 mb-1">
              <span className="custom-label">Section Background:</span>
            </div>
            <BackgroundImage
              ImgData={ImgData}
              backgroundImage={styles.backgroundImage}
              isLoadingBackgroundImage={isLoadingBackgroundImage}
              handleImageSelection={handleImageSelection}
              handleRemoveBackgroundImage={handleRemoveBackgroundImage}
              handleImageSelectionFromGallery={handleImageSelectionFromGallery}
              overLayShow={true}
              overLay={overLay}
              setOverLay={setOverLay}
            />
            <BackgroundSize
              value={
                activeClasses.backgroundSize
                  ? activeClasses.backgroundSize
                  : "background-size-auto"
              }
              handleChangeInClass={handleChangeInBackgroundSizeClass}
              // handleChangeInClass={handleChangeInClass}
              classesList={background_size_classes}
            />
            <BackgroundRepeat
              value={
                activeClasses.backgroundRepeat
                  ? activeClasses.backgroundRepeat
                  : "background-repeat-no-repeat"
              }
              handleChangeInClass={handleChangeInClass}
              classesList={background_repeat_classes}
            />
            <BackgorundPosition
              backgroundPosition={activeClasses.BackgroudPosition}
              handleChangeInClass={handleChangeInBackgroundPositionClass}
              classesList={background_position_classes}
            />
            <BackgroundColor
              styles={styles}
              handleChangeBackground={handleChangeBackground}
              handleResetBackground={handleResetBackground}
            />

            <div className="col-12 mt-2">
              <GenerateGradient
                handleAddGradient={handleChangeBackgroundGradinet}
                getColumnproperty={() => {
                  let backgroundImage = handleGetStyleProperty(
                    "backgroundImage",
                    background_color_action_child
                  );
                  if (backgroundImage) {
                    const gradientPattern =
                      /^(\s*((linear|radial|conic|repeating-linear|repeating-radial|repeating-conic)-gradient\(.+?\)|((rgba?|hsla?|#?[A-Fa-f0-9]{6}|#?[A-Fa-f0-9]{3})\(.+?\))\s*\d*%?\s*,?\s*)+)+$/;

                    if (!gradientPattern.test(backgroundImage)) {
                      backgroundImage = "";
                    }
                  }
                  return backgroundImage;
                }}
                removeColumnproperty={handleResetBackgroundGradient}
              />
            </div>
            {/* </AccordionDetails>
            </Accordion> */}
          </div>

          <div className="col-12 mb-2">
            <TextColor
              styles={styles}
              handleChangeTextColor={handleChangeTextColor}
              handleResetTextColor={handleResetTextColor}
            />
          </div>

          {getIsDevState() && (
            <div className="col-12">
              <div className="col-12 mb-2">
                <Accordion className="card">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Advance Setting</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Tooltip title="Update CSS" arrow placement="top">
                      <span
                        onClick={handleUpateCss}
                        className="wb-header-preview border-start"
                      >
                        <SyncIcon className="me-1" />
                        Update Css
                      </span>
                    </Tooltip>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          )}
        </>
      )}

      {selectedTab === 1 && (
        <>
          <div className="col-12 mb-2">
            <Paddings
              handleGetPaddingClass={handleGetSpacingClass}
              handleupdatePaddingClass={handleupdateSpacingClass}
              handleGetPaddingCustom={handleGetSpacingCustom}
              handleUpdatePaddingCustom={handleUpdateSpacingCustom}
            />
          </div>
          <div className="col-12 mb-2">
            <Margins
              handleGetMarginClass={handleGetSpacingClass}
              handleupdateMarginClass={handleupdateSpacingClass}
              handleGetMarginCustom={handleGetSpacingCustom}
              handleUpdateMarginCustom={handleUpdateSpacingCustom}
            />
          </div>
        </>
      )}

      {selectedTab === 2 && (
        <div className="col-12 mb-2">
          {/* <Accordion className="card">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Section Animation</Typography>
            </AccordionSummary>
            <AccordionDetails> */}
          <AnimationSelect
            label="All Animations"
            handleChange={handleChangeInAnimation}
            data={animate_animation_list}
            value={activeClasses.animation}
            showFrequent={true}
          />
          {/* </AccordionDetails>
          </Accordion> */}
        </div>
      )}

      {getSelectedSectionFrames().show_menu && selectedTab === 3 && (
        <div className="col-12 mb-2">
          <VideoIframe
            getSelectedSectionFrames={getSelectedSectionFrames}
            handleChangeLayout={handleChangeLayout}
          />
        </div>
      )}

      {getSelectedSectionLists().show_menu && selectedTab === 4 && (
        <div className="col-12 mb-2">
          <MenuList
            handleReplaceListElements={handleReplaceListElements}
            getSelectedSectionLists={getSelectedSectionLists}
            handleClose={handleCancel}
          />
        </div>
      )}

      {getSelectedSectionLists().show_menu && selectedTab === 5 && (
        <div className="col-12 mb-2">
          <MenuStyles />
        </div>
      )}

      {/* {getIsDevState() && (
        <div className="col-12">
          <div className="col-12 mb-2">
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Advance Setting</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Tooltip title="Update CSS" arrow placement="top">
                  <span
                    onClick={handleUpateCss}
                    className="wb-header-preview border-start"
                  >
                    <SyncIcon className="me-1" />
                    Update Css
                  </span>
                </Tooltip>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )} */}

      {/* {getFormData().status && (
        <div className="col-12 mb-2">
          <FormMenu
            getFormData={getFormData}
            handleChangeLayout={handleChangeLayout}
          />
        </div>
      )} */}

      {/* <div className="col-12 m-4 ">
        <button
          type="button"
          class="btn btn-secondary me-1"
          onClick={handleCancel}
        >
          Close
        </button>
      </div> */}
      <div className="col-12 text-right mt-5"></div>
    </div>
  );
}

export default RightSectionSettingsMenu;
