import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import {
  ButtonGroup,
  Chip,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import VSEditor from "../../pages/Builder/components/VSEditor/VSEditor";
const CustomJS = () => {
  const {
    openDeveloperOptionMenu,
    setOpenDeveloperOptionMenu,
    developerOptionMenuType,
    setDeveloperOptionMenuType,
    setSelectedElement,
    selectedElement,
    getSelectedSections,

    setSelectedSections,
    ReRenderHtml,
  } = useBuilder();

  const [isLoading, setIsLoading] = useState(false);
  const [targetElement, setTargetElement] = useState("");
  const [customJS, setCustomJS] = useState("");
  const [targetSectionIndex, setTargetSectionIndex] = useState("");
  const [allSectionList, setAllSectionList] = useState([]);
  const handleClose = () => {
    setOpenDeveloperOptionMenu(false);
    setSelectedElement(null);
  };

  const handleChange = (value) => {
    setCustomJS(value);
  };
  const getSectionJS = () => {
    if (selectedElement) {
      let sectionList = getSelectedSections();
      console.log(targetSectionIndex, allSectionList, "sectionsectionsection");

      let element = selectedElement;
      let section_id = null;
      let found_section = false;
      while (element && element.parentNode) {
        if (element.tagName == "SECTION") {
          found_section = true;
          break;
        }
        element = element.parentNode;
      }
      if (!found_section) return;
      section_id = element.parentNode.parentNode.getAttribute("id");

      sectionList.map((section, index) => {
        if (
          section.section_html.includes(selectedElement.outerHTML) ||
          (section_id && section.section_id == section_id)
        ) {
          setTargetSectionIndex(index);
          setCustomJS(section.section_js);
        } else {
          console.log("No Section", "sectionsectionsection");
        }
      });
      setAllSectionList(sectionList);
    }
  };

  const handleAddCustomJS = () => {
    setIsLoading(true);
    allSectionList[targetSectionIndex].section_js = customJS;
    setSelectedSections([...allSectionList]);
    handleClose();
  };

  useEffect(() => {
    getSectionJS();

    setTargetElement(selectedElement?.cloneNode(true));

    setIsLoading(false);
  }, [selectedElement, openDeveloperOptionMenu, developerOptionMenuType]);

  return (
    <Stack sx={{ padding: 2 }}>
      <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
        <Typography variant="h6">Add Java Script</Typography>

        <Stack direction={"row"} spacing={2}>
          <LoadingButton
            variant="contained"
            size="small"
            // disabled={!isChange}
            loading={isLoading}
            onClick={handleAddCustomJS}
            autoFocus
          >
            Update
          </LoadingButton>
          <Button size="small" disabled={isLoading} onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </Stack>

      <VSEditor
        defaultValue={customJS}
        onChange={handleChange}
        defaultLanguage="html"
      />
    </Stack>
  );
};

export default CustomJS;
