import { source_base_uri } from "../../config/config";
import { invokeApi } from "../../bl_libs/invokeApi";

export const _templates_list = (postData) => {
  let requestObj = {
    path: "/api/template/list_template",
    method: "POST",
    base_url: source_base_uri,
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    postData,
  };

  return invokeApi(requestObj);
};

export const _page_category_list = () => {
  let requestObj = {
    path: "/api/template_category/list_template_category",
    method: "GET",
    base_url: source_base_uri,
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _sections_list = () => {
  let requestObj = {
    path: "/api/section/list_section",
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _templates_category_list = () => {
  let requestObj = {
    path: "/api/category/list_category",
    method: "GET",
    base_url: source_base_uri,
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
