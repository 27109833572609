import React from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { Tooltip } from "@mui/material";
import { VideoIframe } from "../Right-Section-Settings-Menu/components";
import DrawerMenuHeader from "../../../../components/Drawer-Menu-Header/DrawerMenuHeader";

const IframeSettingMenu = () => {
  const {
    SaveActionToStack,
    openIframeSettingMenu,
    setOpenIframeSettingMenu,
    selectedIFRAME,
    getParentNode,
  } = useBuilder();

  const getSelectedIframe = () => {
    let data = {
      frame: [selectedIFRAME.children[1]],
      list: [
        {
          element: selectedIFRAME.children[1],
          value: selectedIFRAME.children[1].outerHTML,
          height: selectedIFRAME.children[1].clientHeight,
        },
      ],
    };
    return data;
  };
  const handleRemoveIFRAME = () => {
    SaveActionToStack();
    setOpenIframeSettingMenu(false);
    const element = getParentNode(selectedIFRAME);
    element.remove();
  };
  return (
    <div className="">
      <DrawerMenuHeader
        label="IFRAME Setting"
        setState={setOpenIframeSettingMenu}
        handleRemove={handleRemoveIFRAME}
      />

      <div className="col-12 pt-2 px-2 mb-2 pb-2">
        <VideoIframe
          getSelectedSectionFrames={getSelectedIframe}
          handleRemoveIFRAME={handleRemoveIFRAME}
        />
      </div>
    </div>
  );
};

export default IframeSettingMenu;
