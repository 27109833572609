import { Divider, IconButton, Menu, Stack, Tooltip } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box } from "@mui/system";
import React from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import CloseIcon from "@mui/icons-material/Close";

import { VideoIframe } from "../Right-Section-Settings-Menu/components";
import { _find_element_has_class } from "../../../../hooks/builder-context/utils";

const IframeEditor = () => {
  const {
    SaveActionToStack,
    iconEditorPosition,
    openIframeSettingEditor,
    setOpenIframeSettingEditor,
    selectedIFRAME,
    setSelectedIFRAME,
    getParentNode,
    add_HTML_on_remove_block,
    setSelectedSections,
    selectedSections,
  } = useBuilder();

  const handleClose = () => {
    setOpenIframeSettingEditor(null);
  };

  const handleRemove = () => {
    SaveActionToStack();
    setOpenIframeSettingEditor(null);
    const element = getParentNode(selectedIFRAME);
    element.classList.remove("wb-iframe");
    add_HTML_on_remove_block(element);
    setSelectedSections([...selectedSections]);
  };

  const getSelectedIframe = () => {
    let list = [];
    let frame = _find_element_has_class(selectedIFRAME, "wb-iframe");
    setSelectedIFRAME(frame);
    const element = frame;
    const value = frame.innerHTML;
    const height = frame.clientHeight;
    list.push({ element, value: value ? value.trim() : "", height: height });
    return { list, frame };
  };

  return (
    <>
      <Menu
        // anchorEl={iconAnchorEl}
        id="account-menu"
        open={Boolean(openIframeSettingEditor)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "hidden",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0,
            ml: iconEditorPosition === "left" ? 4 : -4,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
            },
            "& .MuiList-root": {
              padding: "0",
            },
          },
        }}
        transformOrigin={{ horizontal: iconEditorPosition, vertical: "center" }}
        anchorOrigin={{
          horizontal: openIframeSettingEditor?.pageX,
          vertical: openIframeSettingEditor?.pageY,
        }}
      >
        <Box sx={{ width: 392, height: 450 }}>
          <Stack direction={"column"}>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                position: "sticky",
                top: "0",
                background: "#fff",
                zIndex: 100,
                padding: "8px 0",
              }}
            >
              <Stack direction={"row"} sx={{ paddingX: 2 }}>
                <h5 className="mb-0">Iframe Editor</h5>
              </Stack>
              <div className="d-flex">
                <Tooltip title="Remove Button" arrow placement="top">
                  <IconButton
                    onClick={handleRemove}
                    size="small"
                    className="m-0 p-0"
                  >
                    <DeleteOutlineIcon sx={{ color: "red" }} className="p-1" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Close" arrow placement="top">
                  <IconButton
                    onClick={handleClose}
                    size="small"
                    className="mx-2 m-0 p-0"
                  >
                    <CloseIcon className="p-1" />
                  </IconButton>
                </Tooltip>
              </div>
            </Stack>
            <div
              style={{
                position: "sticky",
                top: "40px",
                background: "#fff",
                zIndex: 100,
                borderBottom: "1px Solid #022859",
              }}
              className="mb-2"
            >
              <div className="d-flex mx-2">
                <div
                  className={"image-editor-button image-editor-button-active"}
                >
                  <span>iframe Setting</span>
                </div>
              </div>
              {/* <Divider className="mt-0 mx-3 mb-2" /> */}
            </div>
            <div className="col-12 pt-2 px-2 mb-2 pb-2">
              <VideoIframe
                getSelectedSectionFrames={getSelectedIframe}
                handleRemoveIFRAME={handleRemove}
              />
            </div>
          </Stack>
        </Box>
      </Menu>
    </>
  );
};

export default IframeEditor;
