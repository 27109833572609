import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ViewDayIcon from "@mui/icons-material/ViewDay";

import { Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
function Layout({ styles, handleChangeLayout }) {
  return (
    <>
      {/* <Accordion className="card">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Layout</Typography>
      </AccordionSummary>
      <AccordionDetails> */}
      <div className="col-12 mb-1">
        <span className="custom-label">Layout:</span>
      </div>
      <div className="row">
        <div className="col-12 mt-2 ">
          <div class="d-flex">
            <Tooltip title="Containerize" arrow placement="top">
              <div
                style={{
                  border:
                    styles.layout === "container"
                      ? "1px solid #023373"
                      : "1px solid #e6e6e6",
                  padding: "0px 16px",
                  cursor: "pointer",
                  borderRadius: "2px",
                  marginInlineEnd: "24px",
                }}
                id="container"
                onClick={() => handleChangeLayout("container")}
              >
                <Icon
                  icon="dashicons:align-center"
                  style={{ fontSize: "22px" }}
                />
              </div>
            </Tooltip>
            <Tooltip title="Full Width" arrow placement="top">
              <div
                style={{
                  border:
                    styles.layout === "container-fluid"
                      ? "1px solid #023373"
                      : "1px solid #e6e6e6",
                  padding: "0px 16px",
                  cursor: "pointer",
                  borderRadius: "2px",
                }}
                id="container-fluid"
                onClick={() => handleChangeLayout("container-fluid")}
              >
                <ViewDayIcon style={{ fontSize: 22 }} />
              </div>
            </Tooltip>
          </div>

          {/* <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="container"
              checked={styles.layout === "container"}
              onChange={handleChangeLayout}
            />
            <label
              className="form-check-label pointer custom-label"
              htmlFor="container"
            >
              Containerize
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="container-fluid"
              checked={styles.layout === "container-fluid"}
              onChange={handleChangeLayout}
            />
            <label
              className="form-check-label pointer custom-label"
              htmlFor="container-fluid"
            >
              Full Width
            </label>
          </div> */}
        </div>
      </div>
      {/* </AccordionDetails>
    </Accordion> */}
    </>
  );
}

export default Layout;
