import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { _s3upload } from "../../../../../../DAL/s3upload/s3upload";
import { s3baseUrl } from "../../../../../../config/config";
import BackgroundImage from "../../../Right-Section-Settings-Menu/components/Background-Image/BackgroundImage";
import {
  background_size_classes,
  background_repeat_classes,
  colors,
  background_position_classes,
  button_radius_classes,
} from "../../../../../../ui_libs/Constants/constant";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  BackgorundPosition,
  BackgroundRepeat,
  Margins,
  Paddings,
} from "../../../Right-Section-Settings-Menu/components";
import { BackgroundSize } from "../../../Right-Section-Settings-Menu/components";
import ButtonProperty from "../../../Right-Menu/components/Buton-Property/ButtonProperty";
import { makeStyles } from "@mui/styles";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import GenerateGradient from "../../../../../../components/Generate-Gradient/GenerateGradient";
import { Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";

const useStyles = makeStyles({
  btn: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});
export const RowColumnStyleAccordion = ({
  child,
  OpenColumnStyle,
  ChangeColumnBlockStyle,
  ChangeColumnBackgroundColor,
  getColumnproperty,
  ResetColumnproperty,
  ResetColumnBackgroundColor,
  ChangeColumnBlockClass,
  getColumnBlockClass,
  ImgData,
}) => {
  const { rootValues } = useBuilder();
  const styleClasses = useStyles();
  const [isLoadingBackgroundImage, setisLoadingBackgroundImage] =
    useState(false);

  const [selectedTab, setSelectedTab] = useState(0);
  const [borderRadius, setBorderRadius] = useState("0");

  const handleGetColumnProperty = (property) => {
    return getColumnproperty(property, OpenColumnStyle);
  };
  const handleResetColumnproperty = (property) => {
    ResetColumnproperty(property, OpenColumnStyle);
  };
  const handleChangeColumnBlockStyle = (property, value, name) => {
    ChangeColumnBlockStyle(property, value, OpenColumnStyle, name);
  };
  const handleChangeColumnBackgroundColor = (property, value) => {
    ChangeColumnBackgroundColor(property, value, OpenColumnStyle);
  };
  const handleResetColumnBackgroundColor = (property) => {
    ResetColumnBackgroundColor(property, OpenColumnStyle);
  };

  const handleChangeColumnBlockClass = (property, value, classlist) => {
    ChangeColumnBlockClass(value, classlist, OpenColumnStyle);
  };
  const handleGetColumnBlockBackgroundSize = () => {
    let value = getColumnBlockClass(background_size_classes, OpenColumnStyle);
    return value;
  };
  const handleGetColumnBlockBackgroundPosition = () => {
    let value = getColumnBlockClass(
      background_position_classes,
      OpenColumnStyle
    );
    return value;
  };
  const handleGetColumnBlockBackgroundRepeat = () => {
    let value = getColumnBlockClass(background_repeat_classes, OpenColumnStyle);
    return value;
  };

  const handleImageSelectionFromGallery = async (e, showupdatedimg, name) => {
    setisLoadingBackgroundImage(true);
    const img = e;
    handleChangeColumnBlockStyle("background-image", `url("${img}")`, name);
    showupdatedimg();
    setisLoadingBackgroundImage(false);
  };

  const handleImageSelection = async (e, showupdatedimg) => {
    setisLoadingBackgroundImage(true);
    const resp = await _s3upload(`${rootValues._id}`, e);
    if (resp.code === 200) {
      const image_url = resp.file_name.trim();
      const img = s3baseUrl + "/" + image_url.replace(" ", "%20");
      handleChangeColumnBlockStyle("background-image", `url("${img}")`);

      setisLoadingBackgroundImage(false);
      showupdatedimg();
    } else {
      setisLoadingBackgroundImage(false);
    }
  };
  const CheckBoxClassAvailable = (child) => {
    let result = false;
    if (child) {
      let classlist = child.classList;
      if (classlist) {
        classlist.forEach((cl) => {
          console.log(cl, "classlistclasslistclasslistclasslist");

          if (cl == "wb-box") {
            result = true;
          }
        });
      }
    }
    return result;
  };

  const handleGetSpacingClass = (payload) => {
    let value = getColumnBlockClass(payload.class_list, OpenColumnStyle);
    return value;
  };
  const handleupdateSpacingClass = (payload) => {
    ChangeColumnBlockClass(payload.value, payload.class_list, OpenColumnStyle);
  };

  const getRowSpacingProperty = (name) => {
    let property = getColumnproperty(name, OpenColumnStyle);
    property = property.replace("rem", "");
    property = property * 16;
    return property ? property : "";
  };

  const handleChnageRowStyleSpacing = (spacingName, val) => {
    const value = val ? val / 16 + "rem" : "0rem";
    handleChangeColumnBlockStyle(spacingName, value);
  };

  const handleChangeBorderRadius = (_value) => {
    let value = _value ? _value + "px" : 0;
    handleChangeColumnBlockStyle("border-radius", value);
    setBorderRadius(_value);
  };

  const getValue = () => {
    let border_radius = getColumnproperty("border-radius", OpenColumnStyle);

    if (border_radius) {
      border_radius = border_radius.replace("px", "");
    } else {
      border_radius = 0;
    }

    setBorderRadius(border_radius);
  };

  useEffect(() => {
    getValue();
  }, []);

  return (
    <Accordion className="card mb-2">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {child && child.getAttribute("block-name")
          ? child.getAttribute("block-name")
          : "Box Style"}
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            background: "#fff",
            zIndex: 100,
            borderBottom: "1px Solid #022859",
          }}
          className="mb-2"
        >
          <div className="d-flex">
            <div
              className={
                selectedTab === 0
                  ? "image-editor-button image-editor-button-active"
                  : "image-editor-button"
              }
              onClick={() => setSelectedTab(0)}
            >
              <span>Style</span>
            </div>

            <div
              className={
                selectedTab === 1
                  ? "image-editor-button image-editor-button-active"
                  : "image-editor-button"
              }
              onClick={() => setSelectedTab(1)}
            >
              <span>Spacing</span>
            </div>
          </div>
        </div>

        {selectedTab == 0 && (
          <div>
            <div className="col-12 mt-2">
              <span className="custom-label ">Background Image</span>
            </div>
            <BackgroundImage
              ImgData={ImgData}
              backgroundImage={getColumnproperty(
                "background-image",
                OpenColumnStyle
              )}
              handleRemoveBackgroundImage={() =>
                handleChangeColumnBlockStyle("background-image", "", "")
              }
              handleImageSelection={handleImageSelection}
              isLoadingBackgroundImage={isLoadingBackgroundImage}
              handleImageSelectionFromGallery={handleImageSelectionFromGallery}
            />

            <BackgroundSize
              value={handleGetColumnBlockBackgroundSize()}
              handleChangeInClass={(property, value, classlist) => {
                // handleChangeColumnBlockClass(
                //   property,
                //   "",
                //   background_position_classes
                // );
                handleChangeColumnBlockClass(property, value, classlist);
              }}
              classesList={background_size_classes}
              OpenColumnStyle={OpenColumnStyle}
            />
            <BackgroundRepeat
              value={handleGetColumnBlockBackgroundRepeat()}
              handleChangeInClass={handleChangeColumnBlockClass}
              classesList={background_repeat_classes}
              OpenColumnStyle={OpenColumnStyle}
            />
            <BackgorundPosition
              backgroundPosition={handleGetColumnBlockBackgroundPosition()}
              handleChangeInClass={(property, value, classlist) => {
                // handleChangeColumnBlockClass(
                //   property,
                //   "background-size-auto",
                //   background_size_classes
                // );
                // handleChangeColumnBlockClass(
                //   property,
                //   "background-repeat-no-repeat",
                //   background_repeat_classes
                // );
                handleChangeColumnBlockClass(property, value, classlist);
              }}
              classesList={background_position_classes}
            />

            <div className="col-12">
              <div>
                <label className="form-label custom-label">Border Radius</label>
              </div>

              <>
                <div class="mb-2">
                  <div className="font-size-xsm mt-2 d-flex">
                    <Tooltip title={"Reset"} placement="top" arrow>
                      <span
                        className={
                          getColumnBlockClass(
                            button_radius_classes,
                            OpenColumnStyle
                          )
                            ? "wb-icons-box"
                            : "wb-icons-box-active"
                        }
                        onClick={() => {
                          handleChangeColumnBlockStyle("border-radius", "", "");
                          ChangeColumnBlockClass(
                            "",
                            button_radius_classes,
                            OpenColumnStyle
                          );
                        }}
                      >
                        <Icon icon="ant-design:stop-outlined" />
                      </span>
                    </Tooltip>

                    {button_radius_classes.map((cls, i) => (
                      <Tooltip key={i} title={cls.label} placement="top" arrow>
                        <span
                          className={
                            getColumnBlockClass(
                              button_radius_classes,
                              OpenColumnStyle
                            ) === cls.value
                              ? "wb-icons-box-active"
                              : "wb-icons-box"
                          }
                          onClick={() => {
                            if (
                              button_radius_classes[i].value ==
                              "img-custom-round-size"
                            ) {
                            } else {
                              handleChangeColumnBlockStyle(
                                "border-radius",
                                "",
                                ""
                              );
                            }
                            ChangeColumnBlockClass(
                              cls.value,
                              button_radius_classes,
                              OpenColumnStyle
                            );
                          }}
                        >
                          <Icon icon={cls.icon_name} />
                        </span>
                      </Tooltip>
                    ))}
                    {getColumnBlockClass(
                      button_radius_classes,
                      OpenColumnStyle
                    ) === "img-custom-round-size" && (
                      <>
                        <input
                          className="form-control form-control-sm"
                          id="edit-able-width-input"
                          size="small"
                          label="Width"
                          type="number"
                          value={borderRadius}
                          onChange={(e) => {
                            if (e.target.value >= 0) {
                              handleChangeBorderRadius(e.target.value);
                            }
                          }}
                          placeholder="custom"
                          autoFocus
                        />
                      </>
                    )}
                  </div>
                </div>
              </>
            </div>

            <ButtonProperty
              GetProperty={handleGetColumnProperty}
              label={"Background Color"}
              ResetProperty={handleResetColumnBackgroundColor}
              property={"background-color"}
              handleChangeProperty={handleChangeColumnBackgroundColor}
              colors={colors}
              child={child}
            />

            <div className="col-12 mt-2">
              <GenerateGradient
                handleAddGradient={handleChangeColumnBackgroundColor}
                getColumnproperty={() => {
                  let backgroundImage = getColumnproperty(
                    "backgroundImage",
                    OpenColumnStyle
                  );
                  if (backgroundImage) {
                    const gradientPattern =
                      /^(\s*((linear|radial|conic|repeating-linear|repeating-radial|repeating-conic)-gradient\(.+?\)|((rgba?|hsla?|#?[A-Fa-f0-9]{6}|#?[A-Fa-f0-9]{3})\(.+?\))\s*\d*%?\s*,?\s*)+)+$/;

                    if (!gradientPattern.test(backgroundImage)) {
                      backgroundImage = "";
                    }
                  }
                  return backgroundImage;
                }}
                removeColumnproperty={() => {
                  ResetColumnBackgroundColor("background", OpenColumnStyle);
                }}
              />
            </div>
          </div>
        )}

        {selectedTab == 1 && (
          <>
            <div className="col-12 mb-2">
              <Paddings
                handleGetPaddingClass={handleGetSpacingClass}
                handleupdatePaddingClass={handleupdateSpacingClass}
                handleGetPaddingCustom={getRowSpacingProperty}
                handleUpdatePaddingCustom={handleChnageRowStyleSpacing}
              />
            </div>
            {/* <div className="col-12 mb-2">
            <Margins
              handleGetMarginClass={handleGetSpacingClass}
              handleupdateMarginClass={handleupdateSpacingClass}
              handleGetMarginCustom={getRowSpacingProperty}
              handleUpdateMarginCustom={handleChnageRowStyleSpacing}
            />
          </div> */}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
