import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { _s3upload } from "../../../../DAL/s3upload/s3upload";
import DrawerMenuHeader from "../../../../components/Drawer-Menu-Header/DrawerMenuHeader";

import { TwitterPicker } from "react-color";
import Select from "react-select";
import {
  options,
  colors,
  color_classes,
  container_classes,
  bg_color_classes,
  text_color_classes,
  background_size_classes,
  background_repeat_classes,
  background_position_classes,
} from "../../../../ui_libs/Constants/constant";
import CodeIcon from "@mui/icons-material/Code";

import { s3baseUrl } from "../../../../config/config";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BasicFormSetting from "./components/BasicFormSetting/BasicFormSetting";
import FormCustomization from "./components/FormCustomization/FormCustomization";
import FormStyling from "./components/FormStyling/FormStyling";
import { Divider, Tooltip } from "@mui/material";
import ActiveCampaign from "../../../Website-Settings/components/Active-Campaign/Active_Compaign_Page";
import { _get_user_design_details_page } from "../../../../DAL/projects-pages/projectspages";
import ElementSourceCodeEditorBar from "../../../../components/Element-Source-Code-Editor-Bar/ElementSourceCodeEditorBar";
import ThirdPary from "./components/3rdParty/ThirdParty";
import Loader from "../../../../components/Loader/Loader";
const useStyles = makeStyles({
  list: {
    width: 280,
  },
  fullList: {
    width: "auto",
  },
});

const container_layout_action_child = 1;
const background_image_action_child = 0;
const background_color_action_child = 0;
const text_color_action_child = 0;
const paddings_action_child = 0;
const margins_action_child = 0;

function FormSettingMenu(props) {
  const classes = useStyles();
  const {
    handleRemoveElement,
    rootValues,
    handleUpdateSectionSettings,
    getSelectedSectionStyle,
    getSelectedSectionheightwidth,
    updateSelectedSectionStyle,
    getSelectedSectionClass,
    updateSelectedSectionClass,
    getSelectedSectionLists,
    handleReplaceListElements,
    getSelectedSectionFrames,
    getSelectedSections,
    getSelectedSectionSettingId,
    getSelectedSectionDataset,
    updateSelectedSectionTitle,
    getSelectedSectionForm,
    updateSelectedElementClass,
    setSelectedElement,
    FormSettingsMenuOpen,
    setFormSettingsMenuOpen,
    getParentNode,
    handleOpenElementSourceCode,
  } = useBuilder();
  const [settingTab, setSettingTab] = useState("0");
  const [Page, setPage] = useState();
  const [activeCompaignData, setActiveCompaignData] = useState({
    active_campaign_list_id: "",
    active_campaign_tag_id: "",
    success_message: "",
    faliure_message: "",
  });

  const [Form, setForm] = useState(null);
  const [LabelRequire, setLabelRequire] = useState(false);
  const [HelperTextRequire, setHelperTextRequire] = useState(false);
  const [selectOptionList, setSelectOptionList] = useState([]);

  const [FormRequire, setFormRequire] = useState(false);
  const getFormData = () => {
    const { form, status } = getSelectedSectionForm();
    if (form && form.length > 0) {
      let row_block_div = form[0].querySelector(".wp-row-block");
      if (row_block_div) {
        row_block_div.remove();
      }

      if (!form[0].getAttribute("form_label")) {
        form[0].setAttribute("form_label", "false");
      }
      return form[0];
      if (form[0].children.length > 0) {
        if (form[0].children[1].classList) {
          if (!form[0].children[1].classList.contains("row")) {
            let _div = document.createElement("div");
            _div.classList.add("row");
            _div.innerHTML = form[0].innerHTML;

            form[0].innerHTML = _div.outerHTML;
            let submit_button = form[0].getElementsByTagName("a");
            if (submit_button && submit_button.length == 0) {
              submit_button = form[0].getElementsByTagName("button");
            }
            if (submit_button && submit_button.length > 0) {
              let button = submit_button[0].cloneNode(true);
              submit_button[0].remove();
              let _div = document.createElement("div");
              _div.classList.add("row");
              _div.innerHTML = button.outerHTML;

              form[0].append(_div);
            }
          }
        }
      }
    }

    return form[0];
  };
  const getForm = () => {
    let row_block_div = Form.querySelector(".wp-row-block");
    if (row_block_div) {
      row_block_div.remove();
      setForm(Form);
    }
    return Form;
  };

  const CheckInputAvailable = (tag) => {
    let _input_list = [...Form.getElementsByTagName(tag)];

    let result = false;
    if (_input_list.length > 0) {
      result = true;
    }
    return result;
  };
  const handleUpdateForm = (
    active_campaign_list_id,
    active_campaign_tag_id,
    success_message,
    faliure_message
  ) => {
    let form = getForm();
    form.setAttribute("data-active_campaign_list_id", active_campaign_list_id);
    form.setAttribute("data-active_campaign_tag_id", active_campaign_tag_id);
    form.setAttribute("data-success_message", success_message);
    form.setAttribute("data-faliure_message", faliure_message);
    setActiveCompaignData({
      ...activeCompaignData,
      active_campaign_list_id,
      active_campaign_tag_id,
      success_message,
      faliure_message,
    });
  };
  const handleCancel = () => {
    setFormSettingsMenuOpen(false);
  };
  const GetInputList = () => {
    let input_list = getForm().getElementsByTagName("input");
    let textarea = getForm().getElementsByTagName("textarea");
    let select = getForm().getElementsByTagName("select");
    // let button = Form.getElementsByTagName("button");
    input_list = [...input_list, ...textarea, ...select];
    return input_list;
  };

  const GetSelectList = (formElement) => {
    let select = [...formElement.getElementsByTagName("select")];

    let selectArray = [];
    select.map((_select) => {
      let _option_list = [..._select.getElementsByTagName("option")];
      let option_object = [];
      _option_list.splice(0, 1);
      _option_list.map((option) => {
        option_object.push({
          label: option.innerHTML,
          defaultSelected: option.getAttribute("selected"),
        });
      });
      selectArray.push({
        selectLabel: _select.dataset.name,
        selectOptions: option_object,
        selectElement: _select,
      });
    });
    setSelectOptionList([...selectArray]);
  };
  const fetchPageInfo = async () => {
    const page_resp = await _get_user_design_details_page(rootValues.page_id);
    if (page_resp.code === 200) {
      const page = page_resp.Page;
      setPage(page);
    } else {
      enqueueSnackbar(page_resp.message, { variant: "error" });
    }
  };
  const GetActiveCompaignValues = (form = null) => {
    if (form == null) {
      form = getForm();
    }
    if (!form) return {};

    const list_id = form.getAttribute("data-active_campaign_list_id");
    const tag_id = form.getAttribute("data-active_campaign_tag_id");
    const success_message = form.getAttribute("data-success_message");
    const faliure_message = form.getAttribute("data-faliure_message");
    let list = {
      ...activeCompaignData,
      active_campaign_list_id: list_id ? list_id : "",
      active_campaign_tag_id: tag_id ? tag_id : "",
      success_message: success_message ? success_message : "",
      faliure_message: faliure_message ? faliure_message : "",
    };
    setActiveCompaignData({ ...list });

    return list;
  };
  const handleRemoveForm = () => {
    setFormSettingsMenuOpen(false);
    let element = getParentNode(Form);
    element.remove();
  };

  useEffect(() => {
    if (FormSettingsMenuOpen) {
      const formElement = getFormData();
      setHelperTextRequire(
        formElement.getAttribute("helper_text_require") == "true" ? true : false
      );
      setFormRequire(
        formElement.getAttribute("form_require") == "true" ? true : false
      );
      setForm(formElement);
      fetchPageInfo();
      GetActiveCompaignValues(formElement);
      setSelectedElement(formElement);
      GetSelectList(formElement);
    }
  }, [FormSettingsMenuOpen]);

  if (!Form) {
    return <Loader />;
  }
  return (
    <>
      <div className="row">
        <div
          className="col-12 pt-2 px-4 mb-2 pb-2 border-bottom"
          style={{
            position: "sticky",
            zIndex: "100",
            background: "white",
            top: "0%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h6 className="mb-0">Form Settings</h6>
          <div style={{ display: "flex" }}>
            <ElementSourceCodeEditorBar />
            <Tooltip title="Close" position="bottom" arrow>
            <div className="icon me-2" onClick={handleCancel}>
              <i class="fa fa-xmark"></i>
            </div>
          </Tooltip>
          </div>
        </div>
        <div className="d-flex">
          <div
            className={
              settingTab === "0"
                ? "col-3 wb-style-selection-tab wb-style-selection-tab-active text-center"
                : "col-3 wb-style-selection-tab wb-style-selection-tab text-center"
            }
            onClick={() => setSettingTab("0")}
          >
            <span className="pointer">Basic</span>
          </div>
          <div
            className={
              settingTab === "1"
                ? "col-3 wb-style-selection-tab wb-style-selection-tab-active text-center"
                : "col-3 wb-style-selection-tab wb-style-selection-tab text-center"
            }
            onClick={() => setSettingTab("1")}
          >
            <span className="pointer">Styles</span>
          </div>
          <div
            className={
              settingTab === "3"
                ? "col-3   wb-style-selection-tab wb-style-selection-tab-active text-center"
                : "col-3 wb-style-selection-tab wb-style-selection-tab text-center"
            }
            onClick={() => setSettingTab("3")}
          >
            <span className="pointer">Customization</span>
          </div>
          <div
            className={
              settingTab === "4"
                ? "col-3   wb-style-selection-tab wb-style-selection-tab-active text-center"
                : "col-3 wb-style-selection-tab wb-style-selection-tab text-center"
            }
            onClick={() => setSettingTab("4")}
          >
            <span className="pointer">3rd Party</span>
          </div>
        </div>
        <Divider />
      </div>
      {settingTab === "0" && (
        <BasicFormSetting
          CheckInputAvailable={CheckInputAvailable}
          Form={Form}
          setForm={setForm}
          getForm={getForm}
          GetInputList={GetInputList}
          LabelRequire={LabelRequire}
          HelperTextRequire={HelperTextRequire}
          setHelperTextRequire={setHelperTextRequire}
          setLabelRequire={setLabelRequire}
          FormRequire={FormRequire}
          setFormRequire={setFormRequire}
          // active compain
          Page={Page}
          setPage={setPage}
          activeCompaignData={activeCompaignData}
          handleUpdateForm={handleUpdateForm}
          GetActiveCompaignValues={GetActiveCompaignValues}
          cardToOpen={settingTab}
          selectOptionList={selectOptionList}
          setSelectOptionList={setSelectOptionList}
          GetSelectList={GetSelectList}
        />
      )}

      {settingTab === "1" && (
        <FormStyling
          CheckInputAvailable={CheckInputAvailable}
          Form={Form}
          setForm={setForm}
          getForm={getForm}
          GetInputList={GetInputList}
        />
      )}
      {/* {settingTab === "2" && (
        <ActiveCampaign
          page={Page}
          setPage={setPage}
          activeCompaignData={activeCompaignData}
          handleUpdateForm={handleUpdateForm}
          cardToOpen={settingTab}
        />
      )} */}

      {settingTab === "3" && (
        <FormCustomization
          CheckInputAvailable={CheckInputAvailable}
          Form={Form}
          setForm={setForm}
          getForm={getForm}
          GetInputList={GetInputList}
          LabelRequire={LabelRequire}
          setLabelRequire={setLabelRequire}
          FormRequire={FormRequire}
          setFormRequire={setFormRequire}
          selectOptionList={selectOptionList}
          setSelectOptionList={setSelectOptionList}
          GetSelectList={GetSelectList}
        />
      )}

      {settingTab === "4" && (
        <ThirdPary
          Page={Page}
          setPage={setPage}
          activeCompaignData={activeCompaignData}
          handleUpdateForm={handleUpdateForm}
          GetActiveCompaignValues={GetActiveCompaignValues}
          cardToOpen={settingTab}
        />
      )}

      {/* <div className="col-12 mx-3 my-3 ">
        <button
          type="button"
          className="btn btn-secondary me-1"
          onClick={() => setFormSettingsMenuOpen(false)}
        >
          Close
        </button>
      </div> */}
    </>
  );
}

export default FormSettingMenu;
