import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { _set_user } from "../../DAL/localStorage/localStorage";
import { _get_user_profile } from "../../DAL/login/login";
import {
  _get_user_created_designs,
  _get_user_design_details,
} from "../../DAL/projects/projects";
import { _get_user_design_details_page } from "../../DAL/projects-pages/projectspages";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";
import { CropDinSharp } from "@mui/icons-material";
import PageMetaTitles from "../../components/Page";
function BuilderRedirectController(props) {
  const location = useLocation();
  const { state } = location;

  const { SelectedPage, setSelectedPage } = useBuilder();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();

  const pushToBuilder = async () => {
    let page_values = JSON.parse(sessionStorage.getItem("page"));
    let project_values = JSON.parse(sessionStorage.getItem("project"));
    if (page_values && project_values) {
      navigate(`/builder/${project_values._id}/${page_values._id}`, {
        state: null,
      });
      return;
    }
    const resp = await _get_user_created_designs();

    if (resp.code === 200) {
      if (resp.Project.length === 0) {
        navigate("/choose-template");
        return;
      }

      const project = resp.Project[0];

      let pages = resp.Project[0].pages;
      pages.map((page) => {
        if (page.page_slug == SelectedPage) {
          navigate(`/builder`, {
            state: { project: project, page: page },
          });
          return;
        }
      });
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const fetch_project_by_id_and_push_to_builder = async (
    project_id,
    page_id
  ) => {
    const resp = await _get_user_design_details(project_id);
    if (resp.code === 200) {
      const project = resp.Project;
      const page_resp = await _get_user_design_details_page(page_id);
      setSelectedPage(page_resp.Page.page_slug);
      if (page_resp.code == 200) {
        project.pages = resp.Pages;
        navigate(`/builder`, {
          state: {
            project: project,
            page: page_resp.Page,
          },
        });
      } else {
        enqueueSnackbar(page_resp.message, { variant: "error" });
      }
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const check_builder_url = () => {
    const project_id = location.state?.project_id;
    const page_id = location.state?.page_id;
    if (project_id && page_id) {
      fetch_project_by_id_and_push_to_builder(project_id, page_id);
      return;
    }

    pushToBuilder();
  };

  const fetchProfile = async () => {
    const resp = await _get_user_profile();

    const user = { ...resp.Dashboard.user, ...resp.Dashboard.user_detail };

    _set_user(user);
    if (resp.code === 200) {
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    check_builder_url();
    fetchProfile();
  }, []);
  return (
    <div>
      {sessionStorage.getItem("userType") === "3" ? (
        <PageMetaTitles
          title="Vissioon | Builder"
          favIcon={
            sessionStorage.getItem("brand_favicon") !== "null"
              ? sessionStorage.getItem("brand_favicon")
              : "../favicon/vissiion-favicon.png"
          }
        />
      ) : (
        <PageMetaTitles
          title="Wezily | Builder"
          favIcon={"../favicon/wezily-favIcon.png"}
        />
      )}
      <Loader />
    </div>
  );
}

export default BuilderRedirectController;
