import { invokeApi } from "../../bl_libs/invokeApi";
import { source_base_uri } from "../../config/config";

export const _upload_picture_to_user_gallery = (files) => {
  let requestObj = {
    path: "/api/customer/upload_picture_to_user_gallery",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  requestObj["postData"] = files;
  return invokeApi(requestObj);
};

export const get_user_gallery = () => {
  let requestObj = {
    path: "/api/customer/get_user_gallery",
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_template_images = (data) => {
  let requestObj = {
    path: "/api/template/template_files",
    method: "POST",
    base_url: source_base_uri,
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_stock_images = () => {
  let requestObj = {
    path: "/api/images/get_stock_images_list",
    method: "GET",
    base_url: source_base_uri,
  };
  return invokeApi(requestObj);
};
export const delete_picture = (data) => {
  let requestObj = {
    path: "/api/customer/delete_picture",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};
