/* <<========== FONT AWESOME ICONS V5==========>> */

const solidFontAwesomeIcons = [
  {
    name: "address book",
    class: "fas fa-address-book",
    identifier: "fa-address-book",
    type: "solid",
    variant: "fas",
  },

  {
    name: "address card",
    class: "fas fa-address-card",
    identifier: "fa-address-card",
    type: "solid",
    variant: "fas",
  },
  {
    name: "adjust",
    class: "fas fa-adjust",
    identifier: "fa-adjust",
    type: "solid",
    variant: "fas",
  },
  {
    name: "air freshener",
    class: "fas fa-air-freshener",
    identifier: "fa-air-freshener",
    type: "solid",
    variant: "fas",
  },
  {
    name: "align center",
    class: "fas fa-align-center",
    identifier: "fa-align-center",
    type: "solid",
    variant: "fas",
  },
  {
    name: "align justify",
    class: "fas fa-align-justify",
    identifier: "fa-align-justify",
    type: "solid",
    variant: "fas",
  },
  {
    name: "align left",
    class: "fas fa-align-left",
    identifier: "fa-align-left",
    type: "solid",
    variant: "fas",
  },
  {
    name: "align right",
    class: "fas fa-align-right",
    identifier: "fa-align-right",
    type: "solid",
    variant: "fas",
  },
  {
    name: "allergies",
    class: "fas fa-allergies",
    identifier: "fa-allergies",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ambulance",
    class: "fas fa-ambulance",
    identifier: "fa-ambulance",
    type: "solid",
    variant: "fas",
  },
  {
    name: "angle down",
    class: "fas fa-angle-down",
    identifier: "fa-angle-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "anchor",
    class: "fas fa-anchor",
    identifier: "fa-anchor",
    type: "solid",
    variant: "fas",
  },
  {
    name: "angle double-down",
    class: "fas fa-angle-double-down",
    identifier: "fa-angle-double-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "angle double-left",
    class: "fas fa-angle-double-left",
    identifier: "fa-angle-double-left",
    type: "solid",
    variant: "fas",
  },
  {
    name: "angle double-right",
    class: "fas fa-angle-double-right",
    identifier: "fa-angle-double-right",
    type: "solid",
    variant: "fas",
  },
  {
    name: "angle double-up",
    class: "fas fa-angle-double-up",
    identifier: "fa-angle-double-up",
    type: "solid",
    variant: "fas",
  },

  {
    name: "american sign-language-interpreting",
    class: "fas fa-american-sign-language-interpreting",
    identifier: "fa-american-sign-language-interpreting",
    type: "solid",
    variant: "fas",
  },
  {
    name: "angle left",
    class: "fas fa-angle-left",
    identifier: "fa-angle-left",
    type: "solid",
    variant: "fas",
  },
  {
    name: "angle right",
    class: "fas fa-angle-right",
    identifier: "fa-angle-right",
    type: "solid",
    variant: "fas",
  },
  {
    name: "angle up",
    class: "fas fa-angle-up",
    identifier: "fa-angle-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "angry",
    class: "fas fa-angry",
    identifier: "fa-angry",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ankh",
    class: "fas fa-ankh",
    identifier: "fa-ankh",
    type: "solid",
    variant: "fas",
  },
  {
    name: "apple alt",
    class: "fas fa-apple-alt",
    identifier: "fa-apple-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "archive",
    class: "fas fa-archive",
    identifier: "fa-archive",
    type: "solid",
    variant: "fas",
  },
  {
    name: "archway",
    class: "fas fa-archway",
    identifier: "fa-archway",
    type: "solid",
    variant: "fas",
  },
  {
    name: "arrow alt-circle-down",
    class: "fas fa-arrow-alt-circle-down",
    identifier: "fa-arrow-alt-circle-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "arrow alt-circle-left",
    class: "fas fa-arrow-alt-circle-left",
    identifier: "fa-arrow-alt-circle-left",
    type: "solid",
    variant: "fas",
  },
  {
    name: "arrow alt-circle-right",
    class: "fas fa-arrow-alt-circle-right",
    identifier: "fa-arrow-alt-circle-right",
    type: "solid",
    variant: "fas",
  },
  {
    name: "arrow alt-circle-up",
    class: "fas fa-arrow-alt-circle-up",
    identifier: "fa-arrow-alt-circle-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "arrow circle-down",
    class: "fas fa-arrow-circle-down",
    identifier: "fa-arrow-circle-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "arrow circle-left",
    class: "fas fa-arrow-circle-left",
    identifier: "fa-arrow-circle-left",
    type: "solid",
    variant: "fas",
  },
  {
    name: "arrow circle-right",
    class: "fas fa-arrow-circle-right",
    identifier: "fa-arrow-circle-right",
    type: "solid",
    variant: "fas",
  },
  {
    name: "arrow circle-up",
    class: "fas fa-arrow-circle-up",
    identifier: "fa-arrow-circle-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "arrow down",
    class: "fas fa-arrow-down",
    identifier: "fa-arrow-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "arrow left",
    class: "fas fa-arrow-left",
    identifier: "fa-arrow-left",
    type: "solid",
    variant: "fas",
  },
  {
    name: "arrow right",
    class: "fas fa-arrow-right",
    identifier: "fa-arrow-right",
    type: "solid",
    variant: "fas",
  },
  {
    name: "arrow up",
    class: "fas fa-arrow-up",
    identifier: "fa-arrow-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "arrows alt",
    class: "fas fa-arrows-alt",
    identifier: "fa-arrows-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "arrows alt-h",
    class: "fas fa-arrows-alt-h",
    identifier: "fa-arrows-alt-h",
    type: "solid",
    variant: "fas",
  },
  {
    name: "arrows alt-v",
    class: "fas fa-arrows-alt-v",
    identifier: "fa-arrows-alt-v",
    type: "solid",
    variant: "fas",
  },
  {
    name: "assistive listening-systems",
    class: "fas fa-assistive-listening-systems",
    identifier: "fa-assistive-listening-systems",
    type: "solid",
    variant: "fas",
  },
  {
    name: "asterisk",
    class: "fas fa-asterisk",
    identifier: "fa-asterisk",
    type: "solid",
    variant: "fas",
  },
  {
    name: "at",
    class: "fas fa-at",
    identifier: "fa-at",
    type: "solid",
    variant: "fas",
  },
  {
    name: "atlas",
    class: "fas fa-atlas",
    identifier: "fa-atlas",
    type: "solid",
    variant: "fas",
  },
  {
    name: "atom",
    class: "fas fa-atom",
    identifier: "fa-atom",
    type: "solid",
    variant: "fas",
  },
  {
    name: "audio description",
    class: "fas fa-audio-description",
    identifier: "fa-audio-description",
    type: "solid",
    variant: "fas",
  },
  {
    name: "award",
    class: "fas fa-award",
    identifier: "fa-award",
    type: "solid",
    variant: "fas",
  },
  {
    name: "baby",
    class: "fas fa-baby",
    identifier: "fa-baby",
    type: "solid",
    variant: "fas",
  },
  {
    name: "baby carriage",
    class: "fas fa-baby-carriage",
    identifier: "fa-baby-carriage",
    type: "solid",
    variant: "fas",
  },
  {
    name: "backspace",
    class: "fas fa-backspace",
    identifier: "fa-backspace",
    type: "solid",
    variant: "fas",
  },
  {
    name: "backward",
    class: "fas fa-backward",
    identifier: "fa-backward",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bacon",
    class: "fas fa-bacon",
    identifier: "fa-bacon",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bacteria",
    class: "fas fa-bacteria",
    identifier: "fa-bacteria",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bacterium",
    class: "fas fa-bacterium",
    identifier: "fa-bacterium",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bahai",
    class: "fas fa-bahai",
    identifier: "fa-bahai",
    type: "solid",
    variant: "fas",
  },
  {
    name: "balance scale",
    class: "fas fa-balance-scale",
    identifier: "fa-balance-scale",
    type: "solid",
    variant: "fas",
  },
  {
    name: "balance scale-left",
    class: "fas fa-balance-scale-left",
    identifier: "fa-balance-scale-left",
    type: "solid",
    variant: "fas",
  },
  {
    name: "balance scale-right",
    class: "fas fa-balance-scale-right",
    identifier: "fa-balance-scale-right",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ban",
    class: "fas fa-ban",
    identifier: "fa-ban",
    type: "solid",
    variant: "fas",
  },
  {
    name: "band aid",
    class: "fas fa-band-aid",
    identifier: "fa-band-aid",
    type: "solid",
    variant: "fas",
  },
  {
    name: "barcode",
    class: "fas fa-barcode",
    identifier: "fa-barcode",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bars",
    class: "fas fa-bars",
    identifier: "fa-bars",
    type: "solid",
    variant: "fas",
  },
  {
    name: "building-shield",
    class: "fas fa-building-shield",
    identifier: "fa-building-shield",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bar chart",
    class: "fa fa-bar-chart",
    identifier: "fa-bar-chart",
    type: "solid",
    variant: "fa",
  },
  {
    name: "person-skiing-nordic",
    class: "fa-solid fa-person-skiing-nordic",
    identifier: "fa-person-skiing-nordic",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "arrows-spin",
    class: "fa-solid fa-arrows-spin",
    identifier: "fa-arrows-spin",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "lightbulb",
    class: "fa-solid fa-lightbulb",
    identifier: "fa-lightbulb",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "building-shield",
    class: "fa-solid fa-building-shield",
    identifier: "fa-building-shield",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "person-hiking",
    class: "fa-solid fa-person-hiking",
    identifier: "fa-person-hiking",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "person-swimming",
    class: "fa-solid fa-person-swimming",
    identifier: "fa-person-swimming",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "person-running",
    class: "fa-solid fa-person-running",
    identifier: "fa-person-running",
    type: "solid",
    variant: "fa-solid",
  },

  {
    name: "ticket",
    class: "fa fa-ticket",
    identifier: "fa-ticket",
    type: "solid",
    variant: "fa",
  },

  {
    name: "baseball ball",
    class: "fas fa-baseball-ball",
    identifier: "fa-baseball-ball",
    type: "solid",
    variant: "fas",
  },
  {
    name: "basketball ball",
    class: "fas fa-basketball-ball",
    identifier: "fa-basketball-ball",
    type: "solid",
    variant: "fas",
  },
  {
    name: "circle-check-1",
    class: "fas fa-circle-check",
    identifier: "fa-circle-check",
    type: "solid",
    variant: "fas",
  },

  {
    name: "bath",
    class: "fas fa-bath",
    identifier: "fa-bath",
    type: "solid",
    variant: "fas",
  },
  {
    name: "battery empty",
    class: "fas fa-battery-empty",
    identifier: "fa-battery-empty",
    type: "solid",
    variant: "fas",
  },
  {
    name: "battery full",
    class: "fas fa-battery-full",
    identifier: "fa-battery-full",
    type: "solid",
    variant: "fas",
  },
  {
    name: "battery half",
    class: "fas fa-battery-half",
    identifier: "fa-battery-half",
    type: "solid",
    variant: "fas",
  },
  {
    name: "battery quarter",
    class: "fas fa-battery-quarter",
    identifier: "fa-battery-quarter",
    type: "solid",
    variant: "fas",
  },
  {
    name: "battery three-quarters",
    class: "fas fa-battery-three-quarters",
    identifier: "fa-battery-three-quarters",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bed",
    class: "fas fa-bed",
    identifier: "fa-bed",
    type: "solid",
    variant: "fas",
  },
  {
    name: "beer",
    class: "fas fa-beer",
    identifier: "fa-beer",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bell",
    class: "fas fa-bell",
    identifier: "fa-bell",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bell slash",
    class: "fas fa-bell-slash",
    identifier: "fa-bell-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bezier curve",
    class: "fas fa-bezier-curve",
    identifier: "fa-bezier-curve",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bible",
    class: "fas fa-bible",
    identifier: "fa-bible",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bicycle",
    class: "fas fa-bicycle",
    identifier: "fa-bicycle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "biking",
    class: "fas fa-biking",
    identifier: "fa-biking",
    type: "solid",
    variant: "fas",
  },
  {
    name: "binoculars",
    class: "fas fa-binoculars",
    identifier: "fa-binoculars",
    type: "solid",
    variant: "fas",
  },
  {
    name: "biohazard",
    class: "fas fa-biohazard",
    identifier: "fa-biohazard",
    type: "solid",
    variant: "fas",
  },
  {
    name: "birthday cake",
    class: "fas fa-birthday-cake",
    identifier: "fa-birthday-cake",
    type: "solid",
    variant: "fas",
  },
  {
    name: "blender",
    class: "fas fa-blender",
    identifier: "fa-blender",
    type: "solid",
    variant: "fas",
  },
  {
    name: "blender phone",
    class: "fas fa-blender-phone",
    identifier: "fa-blender-phone",
    type: "solid",
    variant: "fas",
  },
  {
    name: "blind",
    class: "fas fa-blind",
    identifier: "fa-blind",
    type: "solid",
    variant: "fas",
  },
  {
    name: "blog",
    class: "fas fa-blog",
    identifier: "fa-blog",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bold",
    class: "fas fa-bold",
    identifier: "fa-bold",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bolt",
    class: "fas fa-bolt",
    identifier: "fa-bolt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bomb",
    class: "fas fa-bomb",
    identifier: "fa-bomb",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bone",
    class: "fas fa-bone",
    identifier: "fa-bone",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bong",
    class: "fas fa-bong",
    identifier: "fa-bong",
    type: "solid",
    variant: "fas",
  },
  {
    name: "book",
    class: "fas fa-book",
    identifier: "fa-book",
    type: "solid",
    variant: "fas",
  },
  {
    name: "book dead",
    class: "fas fa-book-dead",
    identifier: "fa-book-dead",
    type: "solid",
    variant: "fas",
  },
  {
    name: "book medical",
    class: "fas fa-book-medical",
    identifier: "fa-book-medical",
    type: "solid",
    variant: "fas",
  },
  {
    name: "book open",
    class: "fas fa-book-open",
    identifier: "fa-book-open",
    type: "solid",
    variant: "fas",
  },
  {
    name: "book reader",
    class: "fas fa-book-reader",
    identifier: "fa-book-reader",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bookmark",
    class: "fas fa-bookmark",
    identifier: "fa-bookmark",
    type: "solid",
    variant: "fas",
  },
  {
    name: "border all",
    class: "fas fa-border-all",
    identifier: "fa-border-all",
    type: "solid",
    variant: "fas",
  },
  {
    name: "border none",
    class: "fas fa-border-none",
    identifier: "fa-border-none",
    type: "solid",
    variant: "fas",
  },
  {
    name: "border style",
    class: "fas fa-border-style",
    identifier: "fa-border-style",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bowling ball",
    class: "fas fa-bowling-ball",
    identifier: "fa-bowling-ball",
    type: "solid",
    variant: "fas",
  },
  {
    name: "box",
    class: "fas fa-box",
    identifier: "fa-box",
    type: "solid",
    variant: "fas",
  },
  {
    name: "box open",
    class: "fas fa-box-open",
    identifier: "fa-box-open",
    type: "solid",
    variant: "fas",
  },
  {
    name: "box tissue",
    class: "fas fa-box-tissue",
    identifier: "fa-box-tissue",
    type: "solid",
    variant: "fas",
  },
  {
    name: "boxes",
    class: "fas fa-boxes",
    identifier: "fa-boxes",
    type: "solid",
    variant: "fas",
  },
  {
    name: "braille",
    class: "fas fa-braille",
    identifier: "fa-braille",
    type: "solid",
    variant: "fas",
  },
  {
    name: "brain",
    class: "fas fa-brain",
    identifier: "fa-brain",
    type: "solid",
    variant: "fas",
  },
  {
    name: "fa-brain",
    class: "fa-solid fa-brain",
    identifier: "fa-brain",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "fa-shuffle",
    class: "fa-solid fa-shuffle",
    identifier: "fa-shuffle",
    type: "solid",
    variant: "fa-solid",
  },

  {
    name: "solid-briefcase",
    class: "fa-solid fa-briefcase",
    identifier: "fa-briefcase",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "martini-glass",
    class: "fa-solid fa-martini-glass",
    identifier: "fa-martini-glass",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "bread slice",
    class: "fas fa-bread-slice",
    identifier: "fa-bread-slice",
    type: "solid",
    variant: "fas",
  },
  {
    name: "briefcase",
    class: "fas fa-briefcase",
    identifier: "fa-briefcase",
    type: "solid",
    variant: "fas",
  },
  {
    name: "briefcase medical",
    class: "fas fa-briefcase-medical",
    identifier: "fa-briefcase-medical",
    type: "solid",
    variant: "fas",
  },
  {
    name: "broadcast tower",
    class: "fas fa-broadcast-tower",
    identifier: "fa-broadcast-tower",
    type: "solid",
    variant: "fas",
  },
  {
    name: "broom",
    class: "fas fa-broom",
    identifier: "fa-broom",
    type: "solid",
    variant: "fas",
  },
  {
    name: "brush",
    class: "fas fa-brush",
    identifier: "fa-brush",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bug",
    class: "fas fa-bug",
    identifier: "fa-bug",
    type: "solid",
    variant: "fas",
  },
  {
    name: "building",
    class: "fas fa-building",
    identifier: "fa-building",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bullhorn",
    class: "fas fa-bullhorn",
    identifier: "fa-bullhorn",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bullseye",
    class: "fas fa-bullseye",
    identifier: "fa-bullseye",
    type: "solid",
    variant: "fas",
  },
  {
    name: "burn",
    class: "fas fa-burn",
    identifier: "fa-burn",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bus",
    class: "fas fa-bus",
    identifier: "fa-bus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "bus alt",
    class: "fas fa-bus-alt",
    identifier: "fa-bus-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "business time",
    class: "fas fa-business-time",
    identifier: "fa-business-time",
    type: "solid",
    variant: "fas",
  },
  {
    name: "calculator",
    class: "fas fa-calculator",
    identifier: "fa-calculator",
    type: "solid",
    variant: "fas",
  },
  {
    name: "calendar",
    class: "fas fa-calendar",
    identifier: "fa-calendar",
    type: "solid",
    variant: "fas",
  },
  {
    name: "calendar alt",
    class: "fas fa-calendar-alt",
    identifier: "fa-calendar-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "calendar check",
    class: "fas fa-calendar-check",
    identifier: "fa-calendar-check",
    type: "solid",
    variant: "fas",
  },
  {
    name: "calendar day",
    class: "fas fa-calendar-day",
    identifier: "fa-calendar-day",
    type: "solid",
    variant: "fas",
  },
  {
    name: "calendar minus",
    class: "fas fa-calendar-minus",
    identifier: "fa-calendar-minus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "calendar plus",
    class: "fas fa-calendar-plus",
    identifier: "fa-calendar-plus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "calendar times",
    class: "fas fa-calendar-times",
    identifier: "fa-calendar-times",
    type: "solid",
    variant: "fas",
  },
  {
    name: "calendar week",
    class: "fas fa-calendar-week",
    identifier: "fa-calendar-week",
    type: "solid",
    variant: "fas",
  },
  {
    name: "camera",
    class: "fas fa-camera",
    identifier: "fa-camera",
    type: "solid",
    variant: "fas",
  },
  {
    name: "camera retro",
    class: "fas fa-camera-retro",
    identifier: "fa-camera-retro",
    type: "solid",
    variant: "fas",
  },
  {
    name: "campground",
    class: "fas fa-campground",
    identifier: "fa-campground",
    type: "solid",
    variant: "fas",
  },
  {
    name: "candy cane",
    class: "fas fa-candy-cane",
    identifier: "fa-candy-cane",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cannabis",
    class: "fas fa-cannabis",
    identifier: "fa-cannabis",
    type: "solid",
    variant: "fas",
  },
  {
    name: "capsules",
    class: "fas fa-capsules",
    identifier: "fa-capsules",
    type: "solid",
    variant: "fas",
  },
  {
    name: "car",
    class: "fas fa-car",
    identifier: "fa-car",
    type: "solid",
    variant: "fas",
  },
  {
    name: "car alt",
    class: "fas fa-car-alt",
    identifier: "fa-car-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "car battery",
    class: "fas fa-car-battery",
    identifier: "fa-car-battery",
    type: "solid",
    variant: "fas",
  },
  {
    name: "car crash",
    class: "fas fa-car-crash",
    identifier: "fa-car-crash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "car side",
    class: "fas fa-car-side",
    identifier: "fa-car-side",
    type: "solid",
    variant: "fas",
  },
  {
    name: "caravan",
    class: "fas fa-caravan",
    identifier: "fa-caravan",
    type: "solid",
    variant: "fas",
  },
  {
    name: "caret down",
    class: "fas fa-caret-down",
    identifier: "fa-caret-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "caret left",
    class: "fas fa-caret-left",
    identifier: "fa-caret-left",
    type: "solid",
    variant: "fas",
  },
  {
    name: "caret right",
    class: "fas fa-caret-right",
    identifier: "fa-caret-right",
    type: "solid",
    variant: "fas",
  },
  {
    name: "caret square-down",
    class: "fas fa-caret-square-down",
    identifier: "fa-caret-square-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "caret square-left",
    class: "fas fa-caret-square-left",
    identifier: "fa-caret-square-left",
    type: "solid",
    variant: "fas",
  },
  {
    name: "caret square-right",
    class: "fas fa-caret-square-right",
    identifier: "fa-caret-square-right",
    type: "solid",
    variant: "fas",
  },
  {
    name: "caret square-up",
    class: "fas fa-caret-square-up",
    identifier: "fa-caret-square-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "caret up",
    class: "fas fa-caret-up",
    identifier: "fa-caret-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "carrot",
    class: "fas fa-carrot",
    identifier: "fa-carrot",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cart arrow-down",
    class: "fas fa-cart-arrow-down",
    identifier: "fa-cart-arrow-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cart plus",
    class: "fas fa-cart-plus",
    identifier: "fa-cart-plus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cash register",
    class: "fas fa-cash-register",
    identifier: "fa-cash-register",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cat",
    class: "fas fa-cat",
    identifier: "fa-cat",
    type: "solid",
    variant: "fas",
  },
  {
    name: "certificate",
    class: "fas fa-certificate",
    identifier: "fa-certificate",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chair",
    class: "fas fa-chair",
    identifier: "fa-chair",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chalkboard",
    class: "fas fa-chalkboard",
    identifier: "fa-chalkboard",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chalkboard teacher",
    class: "fas fa-chalkboard-teacher",
    identifier: "fa-chalkboard-teacher",
    type: "solid",
    variant: "fas",
  },
  {
    name: "charging station",
    class: "fas fa-charging-station",
    identifier: "fa-charging-station",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chart area",
    class: "fas fa-chart-area",
    identifier: "fa-chart-area",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chart bar",
    class: "fas fa-chart-bar",
    identifier: "fa-chart-bar",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chart line",
    class: "fas fa-chart-line",
    identifier: "fa-chart-line",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chart pie",
    class: "fas fa-chart-pie",
    identifier: "fa-chart-pie",
    type: "solid",
    variant: "fas",
  },
  {
    name: "close",
    class: "fas fa-close",
    identifier: "fa-close",
    type: "solid",
    variant: "fas",
  },
  // {
  //   name: "xmark",
  //   class: "fas fa-xmark",
  //   identifier: "fa-xmark",
  //   type: "solid",
  //   variant: "fas",
  // },
  {
    name: "check",
    class: "fas fa-check",
    identifier: "fa-check",
    type: "solid",
    variant: "fas",
  },
  {
    name: "check circle",
    class: "fas fa-check-circle",
    identifier: "fa-check-circle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "check double",
    class: "fas fa-check-double",
    identifier: "fa-check-double",
    type: "solid",
    variant: "fas",
  },
  {
    name: "check square",
    class: "fas fa-check-square",
    identifier: "fa-check-square",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cheese",
    class: "fas fa-cheese",
    identifier: "fa-cheese",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chess",
    class: "fas fa-chess",
    identifier: "fa-chess",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chess bishop",
    class: "fas fa-chess-bishop",
    identifier: "fa-chess-bishop",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chess board",
    class: "fas fa-chess-board",
    identifier: "fa-chess-board",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chess king",
    class: "fas fa-chess-king",
    identifier: "fa-chess-king",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chess knight",
    class: "fas fa-chess-knight",
    identifier: "fa-chess-knight",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chess pawn",
    class: "fas fa-chess-pawn",
    identifier: "fa-chess-pawn",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chess queen",
    class: "fas fa-chess-queen",
    identifier: "fa-chess-queen",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chess rook",
    class: "fas fa-chess-rook",
    identifier: "fa-chess-rook",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chevron circle-down",
    class: "fas fa-chevron-circle-down",
    identifier: "fa-chevron-circle-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chevron circle-left",
    class: "fas fa-chevron-circle-left",
    identifier: "fa-chevron-circle-left",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chevron circle-right",
    class: "fas fa-chevron-circle-right",
    identifier: "fa-chevron-circle-right",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chevron circle-up",
    class: "fas fa-chevron-circle-up",
    identifier: "fa-chevron-circle-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chevron down",
    class: "fas fa-chevron-down",
    identifier: "fa-chevron-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chevron left",
    class: "fas fa-chevron-left",
    identifier: "fa-chevron-left",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chevron right",
    class: "fas fa-chevron-right",
    identifier: "fa-chevron-right",
    type: "solid",
    variant: "fas",
  },
  {
    name: "chevron up",
    class: "fas fa-chevron-up",
    identifier: "fa-chevron-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "child",
    class: "fas fa-child",
    identifier: "fa-child",
    type: "solid",
    variant: "fas",
  },
  {
    name: "church",
    class: "fas fa-church",
    identifier: "fa-church",
    type: "solid",
    variant: "fas",
  },
  {
    name: "circle",
    class: "fas fa-circle",
    identifier: "fa-circle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "circle notch",
    class: "fas fa-circle-notch",
    identifier: "fa-circle-notch",
    type: "solid",
    variant: "fas",
  },
  {
    name: "city",
    class: "fas fa-city",
    identifier: "fa-city",
    type: "solid",
    variant: "fas",
  },
  {
    name: "clinic medical",
    class: "fas fa-clinic-medical",
    identifier: "fa-clinic-medical",
    type: "solid",
    variant: "fas",
  },
  {
    name: "clipboard",
    class: "fas fa-clipboard",
    identifier: "fa-clipboard",
    type: "solid",
    variant: "fas",
  },
  {
    name: "clipboard check",
    class: "fas fa-clipboard-check",
    identifier: "fa-clipboard-check",
    type: "solid",
    variant: "fas",
  },
  {
    name: "clipboard list",
    class: "fas fa-clipboard-list",
    identifier: "fa-clipboard-list",
    type: "solid",
    variant: "fas",
  },
  {
    name: "clock",
    class: "fas fa-clock",
    identifier: "fa-clock",
    type: "solid",
    variant: "fas",
  },
  {
    name: "clone",
    class: "fas fa-clone",
    identifier: "fa-clone",
    type: "solid",
    variant: "fas",
  },
  {
    name: "closed captioning",
    class: "fas fa-closed-captioning",
    identifier: "fa-closed-captioning",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cloud",
    class: "fas fa-cloud",
    identifier: "fa-cloud",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cloud download-alt",
    class: "fas fa-cloud-download-alt",
    identifier: "fa-cloud-download-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cloud meatball",
    class: "fas fa-cloud-meatball",
    identifier: "fa-cloud-meatball",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cloud moon",
    class: "fas fa-cloud-moon",
    identifier: "fa-cloud-moon",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cloud moon-rain",
    class: "fas fa-cloud-moon-rain",
    identifier: "fa-cloud-moon-rain",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cloud rain",
    class: "fas fa-cloud-rain",
    identifier: "fa-cloud-rain",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cloud showers-heavy",
    class: "fas fa-cloud-showers-heavy",
    identifier: "fa-cloud-showers-heavy",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cloud sun",
    class: "fas fa-cloud-sun",
    identifier: "fa-cloud-sun",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cloud sun-rain",
    class: "fas fa-cloud-sun-rain",
    identifier: "fa-cloud-sun-rain",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cloud upload-alt",
    class: "fas fa-cloud-upload-alt",
    identifier: "fa-cloud-upload-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cocktail",
    class: "fas fa-cocktail",
    identifier: "fa-cocktail",
    type: "solid",
    variant: "fas",
  },
  {
    name: "code",
    class: "fas fa-code",
    identifier: "fa-code",
    type: "solid",
    variant: "fas",
  },
  {
    name: "code branch",
    class: "fas fa-code-branch",
    identifier: "fa-code-branch",
    type: "solid",
    variant: "fas",
  },
  {
    name: "coffee",
    class: "fas fa-coffee",
    identifier: "fa-coffee",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cog",
    class: "fas fa-cog",
    identifier: "fa-cog",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cogs",
    class: "fas fa-cogs",
    identifier: "fa-cogs",
    type: "solid",
    variant: "fas",
  },
  {
    name: "coins",
    class: "fas fa-coins",
    identifier: "fa-coins",
    type: "solid",
    variant: "fas",
  },
  {
    name: "columns",
    class: "fas fa-columns",
    identifier: "fa-columns",
    type: "solid",
    variant: "fas",
  },
  {
    name: "comment",
    class: "fas fa-comment",
    identifier: "fa-comment",
    type: "solid",
    variant: "fas",
  },
  {
    name: "comment alt",
    class: "fas fa-comment-alt",
    identifier: "fa-comment-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "comment dollar",
    class: "fas fa-comment-dollar",
    identifier: "fa-comment-dollar",
    type: "solid",
    variant: "fas",
  },
  {
    name: "comment dots",
    class: "fas fa-comment-dots",
    identifier: "fa-comment-dots",
    type: "solid",
    variant: "fas",
  },
  {
    name: "comment medical",
    class: "fas fa-comment-medical",
    identifier: "fa-comment-medical",
    type: "solid",
    variant: "fas",
  },
  {
    name: "comment slash",
    class: "fas fa-comment-slash",
    identifier: "fa-comment-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "comments",
    class: "fas fa-comments",
    identifier: "fa-comments",
    type: "solid",
    variant: "fas",
  },
  {
    name: "comments dollar",
    class: "fas fa-comments-dollar",
    identifier: "fa-comments-dollar",
    type: "solid",
    variant: "fas",
  },
  {
    name: "compact disc",
    class: "fas fa-compact-disc",
    identifier: "fa-compact-disc",
    type: "solid",
    variant: "fas",
  },
  {
    name: "compass",
    class: "fas fa-compass",
    identifier: "fa-compass",
    type: "solid",
    variant: "fas",
  },
  {
    name: "compress",
    class: "fas fa-compress",
    identifier: "fa-compress",
    type: "solid",
    variant: "fas",
  },
  {
    name: "compress alt",
    class: "fas fa-compress-alt",
    identifier: "fa-compress-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "compress arrows-alt",
    class: "fas fa-compress-arrows-alt",
    identifier: "fa-compress-arrows-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "concierge bell",
    class: "fas fa-concierge-bell",
    identifier: "fa-concierge-bell",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cookie",
    class: "fas fa-cookie",
    identifier: "fa-cookie",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cookie bite",
    class: "fas fa-cookie-bite",
    identifier: "fa-cookie-bite",
    type: "solid",
    variant: "fas",
  },
  {
    name: "copy",
    class: "fas fa-copy",
    identifier: "fa-copy",
    type: "solid",
    variant: "fas",
  },
  {
    name: "copyright",
    class: "fas fa-copyright",
    identifier: "fa-copyright",
    type: "solid",
    variant: "fas",
  },
  {
    name: "couch",
    class: "fas fa-couch",
    identifier: "fa-couch",
    type: "solid",
    variant: "fas",
  },
  {
    name: "credit card",
    class: "fas fa-credit-card",
    identifier: "fa-credit-card",
    type: "solid",
    variant: "fas",
  },
  {
    name: "credit card alt",
    class: "fas fa-credit-card-alt",
    identifier: "fa-credit-card-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "circle-play",
    class: "fa-solid fa-circle-play",
    identifier: "fa-circle-play",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "solid-check",
    class: "fa-solid fa-check",
    identifier: "fa-check",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "share-nodes",
    class: "fa-solid fa-share-nodes",
    identifier: "fa-share-nodes",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "arrow-up-from-bracket",
    class: "fa-solid fa-arrow-up-from-bracket",
    identifier: "fa-arrow-up-from-bracket",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "share",
    class: "fa-solid fa-share",
    identifier: "fa-share",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "images",
    class: "fa-solid fa-images",
    identifier: "fa-images",
    type: "solid",
    variant: "fa-solid",
  },

  {
    name: "circle-check",
    class: "fa-solid fa-circle-check",
    identifier: "fa-circle-check",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "pencil",
    class: "fa-solid fa-pencil",
    identifier: "fa-pencil",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "angle-right",
    class: "fa-solid fa-angle-right",
    identifier: "fa-angle-right",
    type: "solid",
    variant: "fa-solid",
  },

  {
    name: "solid-circle",
    class: "fa-solid fa-circle",
    identifier: "fa-circle",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "credit-card",
    class: "fa-solid fa-credit-card",
    identifier: "fa-credit-card",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "shoping-cart",
    class: "fa-solid fa-cart-shopping",
    identifier: "fa-cart-shopping",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "calculator",
    class: "fa-solid fa-calculator",
    identifier: "fa-calculator",
    type: "solid",
    variant: "fa-solid",
  },

  {
    name: "chart-bar",
    class: "fa-solid fa-chart-bar",
    identifier: "fa-chart-bar",
    type: "solid",
    variant: "fa-solid",
  },

  {
    name: "crop",
    class: "fas fa-crop",
    identifier: "fa-crop",
    type: "solid",
    variant: "fas",
  },
  {
    name: "crop alt",
    class: "fas fa-crop-alt",
    identifier: "fa-crop-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cross",
    class: "fas fa-cross",
    identifier: "fa-cross",
    type: "solid",
    variant: "fas",
  },
  {
    name: "crosshairs",
    class: "fas fa-crosshairs",
    identifier: "fa-crosshairs",
    type: "solid",
    variant: "fas",
  },
  {
    name: "crow",
    class: "fas fa-crow",
    identifier: "fa-crow",
    type: "solid",
    variant: "fas",
  },
  {
    name: "crown",
    class: "fas fa-crown",
    identifier: "fa-crown",
    type: "solid",
    variant: "fas",
  },
  {
    name: "crutch",
    class: "fas fa-crutch",
    identifier: "fa-crutch",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cube",
    class: "fas fa-cube",
    identifier: "fa-cube",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cubes",
    class: "fas fa-cubes",
    identifier: "fa-cubes",
    type: "solid",
    variant: "fas",
  },
  {
    name: "cut",
    class: "fas fa-cut",
    identifier: "fa-cut",
    type: "solid",
    variant: "fas",
  },
  {
    name: "database",
    class: "fas fa-database",
    identifier: "fa-database",
    type: "solid",
    variant: "fas",
  },
  {
    name: "deaf",
    class: "fas fa-deaf",
    identifier: "fa-deaf",
    type: "solid",
    variant: "fas",
  },
  {
    name: "democrat",
    class: "fas fa-democrat",
    identifier: "fa-democrat",
    type: "solid",
    variant: "fas",
  },
  {
    name: "desktop",
    class: "fas fa-desktop",
    identifier: "fa-desktop",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dharmachakra",
    class: "fas fa-dharmachakra",
    identifier: "fa-dharmachakra",
    type: "solid",
    variant: "fas",
  },
  {
    name: "diagnoses",
    class: "fas fa-diagnoses",
    identifier: "fa-diagnoses",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dice",
    class: "fas fa-dice",
    identifier: "fa-dice",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dice d20",
    class: "fas fa-dice-d20",
    identifier: "fa-dice-d20",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dice d6",
    class: "fas fa-dice-d6",
    identifier: "fa-dice-d6",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dice five",
    class: "fas fa-dice-five",
    identifier: "fa-dice-five",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dice four",
    class: "fas fa-dice-four",
    identifier: "fa-dice-four",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dice one",
    class: "fas fa-dice-one",
    identifier: "fa-dice-one",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dice six",
    class: "fas fa-dice-six",
    identifier: "fa-dice-six",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dice three",
    class: "fas fa-dice-three",
    identifier: "fa-dice-three",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dice two",
    class: "fas fa-dice-two",
    identifier: "fa-dice-two",
    type: "solid",
    variant: "fas",
  },
  {
    name: "digital tachograph",
    class: "fas fa-digital-tachograph",
    identifier: "fa-digital-tachograph",
    type: "solid",
    variant: "fas",
  },
  {
    name: "directions",
    class: "fas fa-directions",
    identifier: "fa-directions",
    type: "solid",
    variant: "fas",
  },
  {
    name: "disease",
    class: "fas fa-disease",
    identifier: "fa-disease",
    type: "solid",
    variant: "fas",
  },
  {
    name: "divide",
    class: "fas fa-divide",
    identifier: "fa-divide",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dizzy",
    class: "fas fa-dizzy",
    identifier: "fa-dizzy",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dna",
    class: "fas fa-dna",
    identifier: "fa-dna",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dog",
    class: "fas fa-dog",
    identifier: "fa-dog",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dollar sign",
    class: "fas fa-dollar-sign",
    identifier: "fa-dollar-sign",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dolly",
    class: "fas fa-dolly",
    identifier: "fa-dolly",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dolly flatbed",
    class: "fas fa-dolly-flatbed",
    identifier: "fa-dolly-flatbed",
    type: "solid",
    variant: "fas",
  },
  {
    name: "donate",
    class: "fas fa-donate",
    identifier: "fa-donate",
    type: "solid",
    variant: "fas",
  },
  {
    name: "door closed",
    class: "fas fa-door-closed",
    identifier: "fa-door-closed",
    type: "solid",
    variant: "fas",
  },
  {
    name: "door open",
    class: "fas fa-door-open",
    identifier: "fa-door-open",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dot circle",
    class: "fas fa-dot-circle",
    identifier: "fa-dot-circle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dove",
    class: "fas fa-dove",
    identifier: "fa-dove",
    type: "solid",
    variant: "fas",
  },
  {
    name: "download",
    class: "fas fa-download",
    identifier: "fa-download",
    type: "solid",
    variant: "fas",
  },
  {
    name: "drafting compass",
    class: "fas fa-drafting-compass",
    identifier: "fa-drafting-compass",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dragon",
    class: "fas fa-dragon",
    identifier: "fa-dragon",
    type: "solid",
    variant: "fas",
  },
  {
    name: "draw polygon",
    class: "fas fa-draw-polygon",
    identifier: "fa-draw-polygon",
    type: "solid",
    variant: "fas",
  },
  {
    name: "drum",
    class: "fas fa-drum",
    identifier: "fa-drum",
    type: "solid",
    variant: "fas",
  },
  {
    name: "drum steelpan",
    class: "fas fa-drum-steelpan",
    identifier: "fa-drum-steelpan",
    type: "solid",
    variant: "fas",
  },
  {
    name: "drumstick bite",
    class: "fas fa-drumstick-bite",
    identifier: "fa-drumstick-bite",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dumbbell",
    class: "fas fa-dumbbell",
    identifier: "fa-dumbbell",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dumpster",
    class: "fas fa-dumpster",
    identifier: "fa-dumpster",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dumpster fire",
    class: "fas fa-dumpster-fire",
    identifier: "fa-dumpster-fire",
    type: "solid",
    variant: "fas",
  },
  {
    name: "dungeon",
    class: "fas fa-dungeon",
    identifier: "fa-dungeon",
    type: "solid",
    variant: "fas",
  },
  {
    name: "edit",
    class: "fas fa-edit",
    identifier: "fa-edit",
    type: "solid",
    variant: "fas",
  },
  {
    name: "egg",
    class: "fas fa-egg",
    identifier: "fa-egg",
    type: "solid",
    variant: "fas",
  },
  {
    name: "eject",
    class: "fas fa-eject",
    identifier: "fa-eject",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ellipsis h",
    class: "fas fa-ellipsis-h",
    identifier: "fa-ellipsis-h",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ellipsis v",
    class: "fas fa-ellipsis-v",
    identifier: "fa-ellipsis-v",
    type: "solid",
    variant: "fas",
  },
  {
    name: "envelope",
    class: "fas fa-envelope",
    identifier: "fa-envelope",
    type: "solid",
    variant: "fas",
  },
  {
    name: "envelope open",
    class: "fas fa-envelope-open",
    identifier: "fa-envelope-open",
    type: "solid",
    variant: "fas",
  },
  {
    name: "envelope open-text",
    class: "fas fa-envelope-open-text",
    identifier: "fa-envelope-open-text",
    type: "solid",
    variant: "fas",
  },
  {
    name: "envelope square",
    class: "fas fa-envelope-square",
    identifier: "fa-envelope-square",
    type: "solid",
    variant: "fas",
  },
  {
    name: "equals",
    class: "fas fa-equals",
    identifier: "fa-equals",
    type: "solid",
    variant: "fas",
  },
  {
    name: "eraser",
    class: "fas fa-eraser",
    identifier: "fa-eraser",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ethernet",
    class: "fas fa-ethernet",
    identifier: "fa-ethernet",
    type: "solid",
    variant: "fas",
  },
  {
    name: "euro sign",
    class: "fas fa-euro-sign",
    identifier: "fa-euro-sign",
    type: "solid",
    variant: "fas",
  },
  {
    name: "exchange alt",
    class: "fas fa-exchange-alt",
    identifier: "fa-exchange-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "exclamation",
    class: "fas fa-exclamation",
    identifier: "fa-exclamation",
    type: "solid",
    variant: "fas",
  },
  {
    name: "exclamation circle",
    class: "fas fa-exclamation-circle",
    identifier: "fa-exclamation-circle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "exclamation triangle",
    class: "fas fa-exclamation-triangle",
    identifier: "fa-exclamation-triangle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "expand",
    class: "fas fa-expand",
    identifier: "fa-expand",
    type: "solid",
    variant: "fas",
  },
  {
    name: "expand alt",
    class: "fas fa-expand-alt",
    identifier: "fa-expand-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "expand arrows-alt",
    class: "fas fa-expand-arrows-alt",
    identifier: "fa-expand-arrows-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "external link-alt",
    class: "fas fa-external-link-alt",
    identifier: "fa-external-link-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "external link-square-alt",
    class: "fas fa-external-link-square-alt",
    identifier: "fa-external-link-square-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "eye",
    class: "fas fa-eye",
    identifier: "fa-eye",
    type: "solid",
    variant: "fas",
  },
  {
    name: "eye dropper",
    class: "fas fa-eye-dropper",
    identifier: "fa-eye-dropper",
    type: "solid",
    variant: "fas",
  },
  {
    name: "eye slash",
    class: "fas fa-eye-slash",
    identifier: "fa-eye-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "fan",
    class: "fas fa-fan",
    identifier: "fa-fan",
    type: "solid",
    variant: "fas",
  },
  {
    name: "fast backward",
    class: "fas fa-fast-backward",
    identifier: "fa-fast-backward",
    type: "solid",
    variant: "fas",
  },
  {
    name: "fast forward",
    class: "fas fa-fast-forward",
    identifier: "fa-fast-forward",
    type: "solid",
    variant: "fas",
  },
  {
    name: "faucet",
    class: "fas fa-faucet",
    identifier: "fa-faucet",
    type: "solid",
    variant: "fas",
  },
  {
    name: "fax",
    class: "fas fa-fax",
    identifier: "fa-fax",
    type: "solid",
    variant: "fas",
  },
  {
    name: "feather",
    class: "fas fa-feather",
    identifier: "fa-feather",
    type: "solid",
    variant: "fas",
  },
  {
    name: "feather alt",
    class: "fas fa-feather-alt",
    identifier: "fa-feather-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "female",
    class: "fas fa-female",
    identifier: "fa-female",
    type: "solid",
    variant: "fas",
  },
  {
    name: "fighter jet",
    class: "fas fa-fighter-jet",
    identifier: "fa-fighter-jet",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file",
    class: "fas fa-file",
    identifier: "fa-file",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file-contract",
    class: "fa-solid fa-file-contract",
    identifier: "fa-file-contract",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "folder",
    class: "fa-solid fa-folder",
    identifier: "fa-folder",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "life-ring",
    class: "fa-solid fa-life-ring",
    identifier: "fa-life-ring",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "file alt",
    class: "fas fa-file-alt",
    identifier: "fa-file-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file archive",
    class: "fas fa-file-archive",
    identifier: "fa-file-archive",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file audio",
    class: "fas fa-file-audio",
    identifier: "fa-file-audio",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file code",
    class: "fas fa-file-code",
    identifier: "fa-file-code",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file contract",
    class: "fas fa-file-contract",
    identifier: "fa-file-contract",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file-contract",
    class: "fa-solid fa-file-contract",
    identifier: "fa-file-contract",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "newspaper",
    class: "fa-solid fa-newspaper",
    identifier: "fa-newspaper",
    type: "solid",
    variant: "fa-solid",
  },

  {
    name: "file csv",
    class: "fas fa-file-csv",
    identifier: "fa-file-csv",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file download",
    class: "fas fa-file-download",
    identifier: "fa-file-download",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file excel",
    class: "fas fa-file-excel",
    identifier: "fa-file-excel",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file export",
    class: "fas fa-file-export",
    identifier: "fa-file-export",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file image",
    class: "fas fa-file-image",
    identifier: "fa-file-image",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file import",
    class: "fas fa-file-import",
    identifier: "fa-file-import",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file invoice",
    class: "fas fa-file-invoice",
    identifier: "fa-file-invoice",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file invoice-dollar",
    class: "fas fa-file-invoice-dollar",
    identifier: "fa-file-invoice-dollar",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file medical",
    class: "fas fa-file-medical",
    identifier: "fa-file-medical",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file medical-alt",
    class: "fas fa-file-medical-alt",
    identifier: "fa-file-medical-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file pdf",
    class: "fas fa-file-pdf",
    identifier: "fa-file-pdf",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file powerpoint",
    class: "fas fa-file-powerpoint",
    identifier: "fa-file-powerpoint",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file prescription",
    class: "fas fa-file-prescription",
    identifier: "fa-file-prescription",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file signature",
    class: "fas fa-file-signature",
    identifier: "fa-file-signature",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file upload",
    class: "fas fa-file-upload",
    identifier: "fa-file-upload",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file video",
    class: "fas fa-file-video",
    identifier: "fa-file-video",
    type: "solid",
    variant: "fas",
  },
  {
    name: "file word",
    class: "fas fa-file-word",
    identifier: "fa-file-word",
    type: "solid",
    variant: "fas",
  },
  {
    name: "fill",
    class: "fas fa-fill",
    identifier: "fa-fill",
    type: "solid",
    variant: "fas",
  },
  {
    name: "fill drip",
    class: "fas fa-fill-drip",
    identifier: "fa-fill-drip",
    type: "solid",
    variant: "fas",
  },
  {
    name: "film",
    class: "fas fa-film",
    identifier: "fa-film",
    type: "solid",
    variant: "fas",
  },
  {
    name: "filter",
    class: "fas fa-filter",
    identifier: "fa-filter",
    type: "solid",
    variant: "fas",
  },
  {
    name: "fingerprint",
    class: "fas fa-fingerprint",
    identifier: "fa-fingerprint",
    type: "solid",
    variant: "fas",
  },
  {
    name: "fire",
    class: "fas fa-fire",
    identifier: "fa-fire",
    type: "solid",
    variant: "fas",
  },
  {
    name: "fire alt",
    class: "fas fa-fire-alt",
    identifier: "fa-fire-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "fire extinguisher",
    class: "fas fa-fire-extinguisher",
    identifier: "fa-fire-extinguisher",
    type: "solid",
    variant: "fas",
  },
  {
    name: "first aid",
    class: "fas fa-first-aid",
    identifier: "fa-first-aid",
    type: "solid",
    variant: "fas",
  },
  {
    name: "fish",
    class: "fas fa-fish",
    identifier: "fa-fish",
    type: "solid",
    variant: "fas",
  },
  {
    name: "fist raised",
    class: "fas fa-fist-raised",
    identifier: "fa-fist-raised",
    type: "solid",
    variant: "fas",
  },
  {
    name: "flag",
    class: "fas fa-flag",
    identifier: "fa-flag",
    type: "solid",
    variant: "fas",
  },
  {
    name: "flag checkered",
    class: "fas fa-flag-checkered",
    identifier: "fa-flag-checkered",
    type: "solid",
    variant: "fas",
  },
  {
    name: "flag usa",
    class: "fas fa-flag-usa",
    identifier: "fa-flag-usa",
    type: "solid",
    variant: "fas",
  },
  {
    name: "flask",
    class: "fas fa-flask",
    identifier: "fa-flask",
    type: "solid",
    variant: "fas",
  },
  {
    name: "flushed",
    class: "fas fa-flushed",
    identifier: "fa-flushed",
    type: "solid",
    variant: "fas",
  },
  {
    name: "folder",
    class: "fas fa-folder",
    identifier: "fa-folder",
    type: "solid",
    variant: "fas",
  },
  {
    name: "folder minus",
    class: "fas fa-folder-minus",
    identifier: "fa-folder-minus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "folder open",
    class: "fas fa-folder-open",
    identifier: "fa-folder-open",
    type: "solid",
    variant: "fas",
  },
  {
    name: "folder plus",
    class: "fas fa-folder-plus",
    identifier: "fa-folder-plus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "font",
    class: "fas fa-font",
    identifier: "fa-font",
    type: "solid",
    variant: "fas",
  },
  {
    name: "football ball",
    class: "fas fa-football-ball",
    identifier: "fa-football-ball",
    type: "solid",
    variant: "fas",
  },
  {
    name: "forward",
    class: "fas fa-forward",
    identifier: "fa-forward",
    type: "solid",
    variant: "fas",
  },
  {
    name: "frog",
    class: "fas fa-frog",
    identifier: "fa-frog",
    type: "solid",
    variant: "fas",
  },
  {
    name: "frown",
    class: "fas fa-frown",
    identifier: "fa-frown",
    type: "solid",
    variant: "fas",
  },
  {
    name: "frown open",
    class: "fas fa-frown-open",
    identifier: "fa-frown-open",
    type: "solid",
    variant: "fas",
  },
  {
    name: "funnel dollar",
    class: "fas fa-funnel-dollar",
    identifier: "fa-funnel-dollar",
    type: "solid",
    variant: "fas",
  },
  {
    name: "futbol",
    class: "fas fa-futbol",
    identifier: "fa-futbol",
    type: "solid",
    variant: "fas",
  },
  {
    name: "gamepad",
    class: "fas fa-gamepad",
    identifier: "fa-gamepad",
    type: "solid",
    variant: "fas",
  },
  {
    name: "gas pump",
    class: "fas fa-gas-pump",
    identifier: "fa-gas-pump",
    type: "solid",
    variant: "fas",
  },
  {
    name: "gavel",
    class: "fas fa-gavel",
    identifier: "fa-gavel",
    type: "solid",
    variant: "fas",
  },
  {
    name: "gem",
    class: "fas fa-gem",
    identifier: "fa-gem",
    type: "solid",
    variant: "fas",
  },
  {
    name: "genderless",
    class: "fas fa-genderless",
    identifier: "fa-genderless",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ghost",
    class: "fas fa-ghost",
    identifier: "fa-ghost",
    type: "solid",
    variant: "fas",
  },
  {
    name: "gift",
    class: "fas fa-gift",
    identifier: "fa-gift",
    type: "solid",
    variant: "fas",
  },
  {
    name: "gifts",
    class: "fas fa-gifts",
    identifier: "fa-gifts",
    type: "solid",
    variant: "fas",
  },
  {
    name: "glass cheers",
    class: "fas fa-glass-cheers",
    identifier: "fa-glass-cheers",
    type: "solid",
    variant: "fas",
  },
  {
    name: "glass martini",
    class: "fas fa-glass-martini",
    identifier: "fa-glass-martini",
    type: "solid",
    variant: "fas",
  },
  {
    name: "glass martini-alt",
    class: "fas fa-glass-martini-alt",
    identifier: "fa-glass-martini-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "glass whiskey",
    class: "fas fa-glass-whiskey",
    identifier: "fa-glass-whiskey",
    type: "solid",
    variant: "fas",
  },
  {
    name: "glasses",
    class: "fas fa-glasses",
    identifier: "fa-glasses",
    type: "solid",
    variant: "fas",
  },
  {
    name: "globe",
    class: "fas fa-globe",
    identifier: "fa-globe",
    type: "solid",
    variant: "fas",
  },
  {
    name: "globe africa",
    class: "fas fa-globe-africa",
    identifier: "fa-globe-africa",
    type: "solid",
    variant: "fas",
  },
  {
    name: "globe americas",
    class: "fas fa-globe-americas",
    identifier: "fa-globe-americas",
    type: "solid",
    variant: "fas",
  },
  {
    name: "globe asia",
    class: "fas fa-globe-asia",
    identifier: "fa-globe-asia",
    type: "solid",
    variant: "fas",
  },
  {
    name: "globe europe",
    class: "fas fa-globe-europe",
    identifier: "fa-globe-europe",
    type: "solid",
    variant: "fas",
  },
  {
    name: "golf ball",
    class: "fas fa-golf-ball",
    identifier: "fa-golf-ball",
    type: "solid",
    variant: "fas",
  },
  {
    name: "gopuram",
    class: "fas fa-gopuram",
    identifier: "fa-gopuram",
    type: "solid",
    variant: "fas",
  },
  {
    name: "graduation cap",
    class: "fas fa-graduation-cap",
    identifier: "fa-graduation-cap",
    type: "solid",
    variant: "fas",
  },
  {
    name: "greater than",
    class: "fas fa-greater-than",
    identifier: "fa-greater-than",
    type: "solid",
    variant: "fas",
  },
  {
    name: "greater than-equal",
    class: "fas fa-greater-than-equal",
    identifier: "fa-greater-than-equal",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grimace",
    class: "fas fa-grimace",
    identifier: "fa-grimace",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grin",
    class: "fas fa-grin",
    identifier: "fa-grin",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grin alt",
    class: "fas fa-grin-alt",
    identifier: "fa-grin-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grin beam",
    class: "fas fa-grin-beam",
    identifier: "fa-grin-beam",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grin beam-sweat",
    class: "fas fa-grin-beam-sweat",
    identifier: "fa-grin-beam-sweat",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grin hearts",
    class: "fas fa-grin-hearts",
    identifier: "fa-grin-hearts",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grin squint",
    class: "fas fa-grin-squint",
    identifier: "fa-grin-squint",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grin squint-tears",
    class: "fas fa-grin-squint-tears",
    identifier: "fa-grin-squint-tears",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grin stars",
    class: "fas fa-grin-stars",
    identifier: "fa-grin-stars",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grin tears",
    class: "fas fa-grin-tears",
    identifier: "fa-grin-tears",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grin tongue",
    class: "fas fa-grin-tongue",
    identifier: "fa-grin-tongue",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grin tongue-squint",
    class: "fas fa-grin-tongue-squint",
    identifier: "fa-grin-tongue-squint",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grin tongue-wink",
    class: "fas fa-grin-tongue-wink",
    identifier: "fa-grin-tongue-wink",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grin wink",
    class: "fas fa-grin-wink",
    identifier: "fa-grin-wink",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grip horizontal",
    class: "fas fa-grip-horizontal",
    identifier: "fa-grip-horizontal",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grip lines",
    class: "fas fa-grip-lines",
    identifier: "fa-grip-lines",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grip lines-vertical",
    class: "fas fa-grip-lines-vertical",
    identifier: "fa-grip-lines-vertical",
    type: "solid",
    variant: "fas",
  },
  {
    name: "grip vertical",
    class: "fas fa-grip-vertical",
    identifier: "fa-grip-vertical",
    type: "solid",
    variant: "fas",
  },
  {
    name: "guitar",
    class: "fas fa-guitar",
    identifier: "fa-guitar",
    type: "solid",
    variant: "fas",
  },
  {
    name: "h square",
    class: "fas fa-h-square",
    identifier: "fa-h-square",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hamburger",
    class: "fas fa-hamburger",
    identifier: "fa-hamburger",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hammer",
    class: "fas fa-hammer",
    identifier: "fa-hammer",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hamsa",
    class: "fas fa-hamsa",
    identifier: "fa-hamsa",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand holding",
    class: "fas fa-hand-holding",
    identifier: "fa-hand-holding",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand holding-heart",
    class: "fas fa-hand-holding-heart",
    identifier: "fa-hand-holding-heart",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand holding-medical",
    class: "fas fa-hand-holding-medical",
    identifier: "fa-hand-holding-medical",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand holding-usd",
    class: "fas fa-hand-holding-usd",
    identifier: "fa-hand-holding-usd",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand holding-water",
    class: "fas fa-hand-holding-water",
    identifier: "fa-hand-holding-water",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand lizard",
    class: "fas fa-hand-lizard",
    identifier: "fa-hand-lizard",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand middle-finger",
    class: "fas fa-hand-middle-finger",
    identifier: "fa-hand-middle-finger",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand paper",
    class: "fas fa-hand-paper",
    identifier: "fa-hand-paper",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand peace",
    class: "fas fa-hand-peace",
    identifier: "fa-hand-peace",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand point-down",
    class: "fas fa-hand-point-down",
    identifier: "fa-hand-point-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand point-left",
    class: "fas fa-hand-point-left",
    identifier: "fa-hand-point-left",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand point-right",
    class: "fas fa-hand-point-right",
    identifier: "fa-hand-point-right",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand point-up",
    class: "fas fa-hand-point-up",
    identifier: "fa-hand-point-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand pointer",
    class: "fas fa-hand-pointer",
    identifier: "fa-hand-pointer",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand rock",
    class: "fas fa-hand-rock",
    identifier: "fa-hand-rock",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand scissors",
    class: "fas fa-hand-scissors",
    identifier: "fa-hand-scissors",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand sparkles",
    class: "fas fa-hand-sparkles",
    identifier: "fa-hand-sparkles",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hand spock",
    class: "fas fa-hand-spock",
    identifier: "fa-hand-spock",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hands",
    class: "fas fa-hands",
    identifier: "fa-hands",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hands helping",
    class: "fas fa-hands-helping",
    identifier: "fa-hands-helping",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hands wash",
    class: "fas fa-hands-wash",
    identifier: "fa-hands-wash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "handshake",
    class: "fas fa-handshake",
    identifier: "fa-handshake",
    type: "solid",
    variant: "fas",
  },
  {
    name: "handshake alt-slash",
    class: "fas fa-handshake-alt-slash",
    identifier: "fa-handshake-alt-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "handshake slash",
    class: "fas fa-handshake-slash",
    identifier: "fa-handshake-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hanukiah",
    class: "fas fa-hanukiah",
    identifier: "fa-hanukiah",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hard hat",
    class: "fas fa-hard-hat",
    identifier: "fa-hard-hat",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hashtag",
    class: "fas fa-hashtag",
    identifier: "fa-hashtag",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hat cowboy",
    class: "fas fa-hat-cowboy",
    identifier: "fa-hat-cowboy",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hat cowboy-side",
    class: "fas fa-hat-cowboy-side",
    identifier: "fa-hat-cowboy-side",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hat wizard",
    class: "fas fa-hat-wizard",
    identifier: "fa-hat-wizard",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hdd",
    class: "fas fa-hdd",
    identifier: "fa-hdd",
    type: "solid",
    variant: "fas",
  },
  {
    name: "head side-cough",
    class: "fas fa-head-side-cough",
    identifier: "fa-head-side-cough",
    type: "solid",
    variant: "fas",
  },
  {
    name: "head side-cough-slash",
    class: "fas fa-head-side-cough-slash",
    identifier: "fa-head-side-cough-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "head side-mask",
    class: "fas fa-head-side-mask",
    identifier: "fa-head-side-mask",
    type: "solid",
    variant: "fas",
  },
  {
    name: "head side-virus",
    class: "fas fa-head-side-virus",
    identifier: "fa-head-side-virus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "heading",
    class: "fas fa-heading",
    identifier: "fa-heading",
    type: "solid",
    variant: "fas",
  },
  {
    name: "headphones",
    class: "fas fa-headphones",
    identifier: "fa-headphones",
    type: "solid",
    variant: "fas",
  },
  {
    name: "headphones alt",
    class: "fas fa-headphones-alt",
    identifier: "fa-headphones-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "headset",
    class: "fas fa-headset",
    identifier: "fa-headset",
    type: "solid",
    variant: "fas",
  },
  {
    name: "heart",
    class: "fas fa-heart",
    identifier: "fa-heart",
    type: "solid",
    variant: "fas",
  },
  {
    name: "heart broken",
    class: "fas fa-heart-broken",
    identifier: "fa-heart-broken",
    type: "solid",
    variant: "fas",
  },
  {
    name: "heartbeat",
    class: "fas fa-heartbeat",
    identifier: "fa-heartbeat",
    type: "solid",
    variant: "fas",
  },
  {
    name: "helicopter",
    class: "fas fa-helicopter",
    identifier: "fa-helicopter",
    type: "solid",
    variant: "fas",
  },
  {
    name: "highlighter",
    class: "fas fa-highlighter",
    identifier: "fa-highlighter",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hiking",
    class: "fas fa-hiking",
    identifier: "fa-hiking",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hippo",
    class: "fas fa-hippo",
    identifier: "fa-hippo",
    type: "solid",
    variant: "fas",
  },
  {
    name: "history",
    class: "fas fa-history",
    identifier: "fa-history",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hockey puck",
    class: "fas fa-hockey-puck",
    identifier: "fa-hockey-puck",
    type: "solid",
    variant: "fas",
  },
  {
    name: "holly berry",
    class: "fas fa-holly-berry",
    identifier: "fa-holly-berry",
    type: "solid",
    variant: "fas",
  },
  {
    name: "home",
    class: "fas fa-home",
    identifier: "fa-home",
    type: "solid",
    variant: "fas",
  },
  {
    name: "horse",
    class: "fas fa-horse",
    identifier: "fa-horse",
    type: "solid",
    variant: "fas",
  },
  {
    name: "horse head",
    class: "fas fa-horse-head",
    identifier: "fa-horse-head",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hospital",
    class: "fas fa-hospital",
    identifier: "fa-hospital",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hospital alt",
    class: "fas fa-hospital-alt",
    identifier: "fa-hospital-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hospital symbol",
    class: "fas fa-hospital-symbol",
    identifier: "fa-hospital-symbol",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hospital user",
    class: "fas fa-hospital-user",
    identifier: "fa-hospital-user",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hot tub",
    class: "fas fa-hot-tub",
    identifier: "fa-hot-tub",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hotdog",
    class: "fas fa-hotdog",
    identifier: "fa-hotdog",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hotel",
    class: "fas fa-hotel",
    identifier: "fa-hotel",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hourglass",
    class: "fas fa-hourglass",
    identifier: "fa-hourglass",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hourglass end",
    class: "fas fa-hourglass-end",
    identifier: "fa-hourglass-end",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hourglass half",
    class: "fas fa-hourglass-half",
    identifier: "fa-hourglass-half",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hourglass start",
    class: "fas fa-hourglass-start",
    identifier: "fa-hourglass-start",
    type: "solid",
    variant: "fas",
  },
  {
    name: "house damage",
    class: "fas fa-house-damage",
    identifier: "fa-house-damage",
    type: "solid",
    variant: "fas",
  },
  {
    name: "house user",
    class: "fas fa-house-user",
    identifier: "fa-house-user",
    type: "solid",
    variant: "fas",
  },
  {
    name: "hryvnia",
    class: "fas fa-hryvnia",
    identifier: "fa-hryvnia",
    type: "solid",
    variant: "fas",
  },
  {
    name: "i cursor",
    class: "fas fa-i-cursor",
    identifier: "fa-i-cursor",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ice cream",
    class: "fas fa-ice-cream",
    identifier: "fa-ice-cream",
    type: "solid",
    variant: "fas",
  },
  {
    name: "icicles",
    class: "fas fa-icicles",
    identifier: "fa-icicles",
    type: "solid",
    variant: "fas",
  },
  {
    name: "icons",
    class: "fas fa-icons",
    identifier: "fa-icons",
    type: "solid",
    variant: "fas",
  },
  {
    name: "id badge",
    class: "fas fa-id-badge",
    identifier: "fa-id-badge",
    type: "solid",
    variant: "fas",
  },
  {
    name: "id card",
    class: "fas fa-id-card",
    identifier: "fa-id-card",
    type: "solid",
    variant: "fas",
  },
  {
    name: "id card-alt",
    class: "fas fa-id-card-alt",
    identifier: "fa-id-card-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "igloo",
    class: "fas fa-igloo",
    identifier: "fa-igloo",
    type: "solid",
    variant: "fas",
  },
  {
    name: "image",
    class: "fas fa-image",
    identifier: "fa-image",
    type: "solid",
    variant: "fas",
  },
  {
    name: "images",
    class: "fas fa-images",
    identifier: "fa-images",
    type: "solid",
    variant: "fas",
  },
  {
    name: "inbox",
    class: "fas fa-inbox",
    identifier: "fa-inbox",
    type: "solid",
    variant: "fas",
  },
  {
    name: "indent",
    class: "fas fa-indent",
    identifier: "fa-indent",
    type: "solid",
    variant: "fas",
  },
  {
    name: "industry",
    class: "fas fa-industry",
    identifier: "fa-industry",
    type: "solid",
    variant: "fas",
  },
  {
    name: "infinity",
    class: "fas fa-infinity",
    identifier: "fa-infinity",
    type: "solid",
    variant: "fas",
  },
  {
    name: "info",
    class: "fas fa-info",
    identifier: "fa-info",
    type: "solid",
    variant: "fas",
  },
  {
    name: "info circle",
    class: "fas fa-info-circle",
    identifier: "fa-info-circle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "italic",
    class: "fas fa-italic",
    identifier: "fa-italic",
    type: "solid",
    variant: "fas",
  },
  {
    name: "jedi",
    class: "fas fa-jedi",
    identifier: "fa-jedi",
    type: "solid",
    variant: "fas",
  },
  {
    name: "joint",
    class: "fas fa-joint",
    identifier: "fa-joint",
    type: "solid",
    variant: "fas",
  },
  {
    name: "journal whills",
    class: "fas fa-journal-whills",
    identifier: "fa-journal-whills",
    type: "solid",
    variant: "fas",
  },
  {
    name: "kaaba",
    class: "fas fa-kaaba",
    identifier: "fa-kaaba",
    type: "solid",
    variant: "fas",
  },
  {
    name: "key",
    class: "fas fa-key",
    identifier: "fa-key",
    type: "solid",
    variant: "fas",
  },
  {
    name: "keyboard",
    class: "fas fa-keyboard",
    identifier: "fa-keyboard",
    type: "solid",
    variant: "fas",
  },
  {
    name: "khanda",
    class: "fas fa-khanda",
    identifier: "fa-khanda",
    type: "solid",
    variant: "fas",
  },
  {
    name: "kiss",
    class: "fas fa-kiss",
    identifier: "fa-kiss",
    type: "solid",
    variant: "fas",
  },
  {
    name: "kiss beam",
    class: "fas fa-kiss-beam",
    identifier: "fa-kiss-beam",
    type: "solid",
    variant: "fas",
  },
  {
    name: "kiss wink-heart",
    class: "fas fa-kiss-wink-heart",
    identifier: "fa-kiss-wink-heart",
    type: "solid",
    variant: "fas",
  },
  {
    name: "kiwi bird",
    class: "fas fa-kiwi-bird",
    identifier: "fa-kiwi-bird",
    type: "solid",
    variant: "fas",
  },
  {
    name: "landmark",
    class: "fas fa-landmark",
    identifier: "fa-landmark",
    type: "solid",
    variant: "fas",
  },
  {
    name: "language",
    class: "fas fa-language",
    identifier: "fa-language",
    type: "solid",
    variant: "fas",
  },
  {
    name: "laptop",
    class: "fas fa-laptop",
    identifier: "fa-laptop",
    type: "solid",
    variant: "fas",
  },
  {
    name: "laptop code",
    class: "fas fa-laptop-code",
    identifier: "fa-laptop-code",
    type: "solid",
    variant: "fas",
  },
  {
    name: "laptop house",
    class: "fas fa-laptop-house",
    identifier: "fa-laptop-house",
    type: "solid",
    variant: "fas",
  },
  {
    name: "laptop medical",
    class: "fas fa-laptop-medical",
    identifier: "fa-laptop-medical",
    type: "solid",
    variant: "fas",
  },
  {
    name: "laugh",
    class: "fas fa-laugh",
    identifier: "fa-laugh",
    type: "solid",
    variant: "fas",
  },
  {
    name: "laugh beam",
    class: "fas fa-laugh-beam",
    identifier: "fa-laugh-beam",
    type: "solid",
    variant: "fas",
  },
  {
    name: "laugh squint",
    class: "fas fa-laugh-squint",
    identifier: "fa-laugh-squint",
    type: "solid",
    variant: "fas",
  },
  {
    name: "laugh wink",
    class: "fas fa-laugh-wink",
    identifier: "fa-laugh-wink",
    type: "solid",
    variant: "fas",
  },
  {
    name: "layer group",
    class: "fas fa-layer-group",
    identifier: "fa-layer-group",
    type: "solid",
    variant: "fas",
  },
  {
    name: "leaf",
    class: "fas fa-leaf",
    identifier: "fa-leaf",
    type: "solid",
    variant: "fas",
  },
  {
    name: "lemon",
    class: "fas fa-lemon",
    identifier: "fa-lemon",
    type: "solid",
    variant: "fas",
  },
  {
    name: "less than",
    class: "fas fa-less-than",
    identifier: "fa-less-than",
    type: "solid",
    variant: "fas",
  },
  {
    name: "less than-equal",
    class: "fas fa-less-than-equal",
    identifier: "fa-less-than-equal",
    type: "solid",
    variant: "fas",
  },
  {
    name: "level down-alt",
    class: "fas fa-level-down-alt",
    identifier: "fa-level-down-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "level up-alt",
    class: "fas fa-level-up-alt",
    identifier: "fa-level-up-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "life ring",
    class: "fas fa-life-ring",
    identifier: "fa-life-ring",
    type: "solid",
    variant: "fas",
  },
  {
    name: "lightbulb",
    class: "fas fa-lightbulb",
    identifier: "fa-lightbulb",
    type: "solid",
    variant: "fas",
  },
  {
    name: "link",
    class: "fas fa-link",
    identifier: "fa-link",
    type: "solid",
    variant: "fas",
  },
  {
    name: "lira sign",
    class: "fas fa-lira-sign",
    identifier: "fa-lira-sign",
    type: "solid",
    variant: "fas",
  },
  {
    name: "list",
    class: "fas fa-list",
    identifier: "fa-list",
    type: "solid",
    variant: "fas",
  },
  {
    name: "list alt",
    class: "fas fa-list-alt",
    identifier: "fa-list-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "list ol",
    class: "fas fa-list-ol",
    identifier: "fa-list-ol",
    type: "solid",
    variant: "fas",
  },
  {
    name: "list ul",
    class: "fas fa-list-ul",
    identifier: "fa-list-ul",
    type: "solid",
    variant: "fas",
  },
  {
    name: "location arrow",
    class: "fas fa-location-arrow",
    identifier: "fa-location-arrow",
    type: "solid",
    variant: "fas",
  },
  {
    name: "lock",
    class: "fas fa-lock",
    identifier: "fa-lock",
    type: "solid",
    variant: "fas",
  },
  {
    name: "lock open",
    class: "fas fa-lock-open",
    identifier: "fa-lock-open",
    type: "solid",
    variant: "fas",
  },
  {
    name: "long arrow-alt-down",
    class: "fas fa-long-arrow-alt-down",
    identifier: "fa-long-arrow-alt-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "long arrow-alt-left",
    class: "fas fa-long-arrow-alt-left",
    identifier: "fa-long-arrow-alt-left",
    type: "solid",
    variant: "fas",
  },
  {
    name: "long arrow-alt-right",
    class: "fas fa-long-arrow-alt-right",
    identifier: "fa-long-arrow-alt-right",
    type: "solid",
    variant: "fas",
  },
  {
    name: "long arrow-alt-up",
    class: "fas fa-long-arrow-alt-up",
    identifier: "fa-long-arrow-alt-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "low vision",
    class: "fas fa-low-vision",
    identifier: "fa-low-vision",
    type: "solid",
    variant: "fas",
  },
  {
    name: "luggage cart",
    class: "fas fa-luggage-cart",
    identifier: "fa-luggage-cart",
    type: "solid",
    variant: "fas",
  },
  {
    name: "lungs",
    class: "fas fa-lungs",
    identifier: "fa-lungs",
    type: "solid",
    variant: "fas",
  },
  {
    name: "lungs virus",
    class: "fas fa-lungs-virus",
    identifier: "fa-lungs-virus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "magic",
    class: "fas fa-magic",
    identifier: "fa-magic",
    type: "solid",
    variant: "fas",
  },
  {
    name: "magnet",
    class: "fas fa-magnet",
    identifier: "fa-magnet",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mail bulk",
    class: "fas fa-mail-bulk",
    identifier: "fa-mail-bulk",
    type: "solid",
    variant: "fas",
  },
  {
    name: "male",
    class: "fas fa-male",
    identifier: "fa-male",
    type: "solid",
    variant: "fas",
  },
  {
    name: "map",
    class: "fas fa-map",
    identifier: "fa-map",
    type: "solid",
    variant: "fas",
  },
  {
    name: "map marked",
    class: "fas fa-map-marked",
    identifier: "fa-map-marked",
    type: "solid",
    variant: "fas",
  },
  {
    name: "map marked-alt",
    class: "fas fa-map-marked-alt",
    identifier: "fa-map-marked-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "map marker",
    class: "fas fa-map-marker",
    identifier: "fa-map-marker",
    type: "solid",
    variant: "fas",
  },
  {
    name: "map marker-alt",
    class: "fas fa-map-marker-alt",
    identifier: "fa-map-marker-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "map pin",
    class: "fas fa-map-pin",
    identifier: "fa-map-pin",
    type: "solid",
    variant: "fas",
  },
  {
    name: "map signs",
    class: "fas fa-map-signs",
    identifier: "fa-map-signs",
    type: "solid",
    variant: "fas",
  },
  {
    name: "marker",
    class: "fas fa-marker",
    identifier: "fa-marker",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mars",
    class: "fas fa-mars",
    identifier: "fa-mars",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mars double",
    class: "fas fa-mars-double",
    identifier: "fa-mars-double",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mars stroke",
    class: "fas fa-mars-stroke",
    identifier: "fa-mars-stroke",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mars stroke-h",
    class: "fas fa-mars-stroke-h",
    identifier: "fa-mars-stroke-h",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mars stroke-v",
    class: "fas fa-mars-stroke-v",
    identifier: "fa-mars-stroke-v",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mask",
    class: "fas fa-mask",
    identifier: "fa-mask",
    type: "solid",
    variant: "fas",
  },
  {
    name: "medal",
    class: "fas fa-medal",
    identifier: "fa-medal",
    type: "solid",
    variant: "fas",
  },
  {
    name: "medkit",
    class: "fas fa-medkit",
    identifier: "fa-medkit",
    type: "solid",
    variant: "fas",
  },
  {
    name: "meh",
    class: "fas fa-meh",
    identifier: "fa-meh",
    type: "solid",
    variant: "fas",
  },
  {
    name: "meh blank",
    class: "fas fa-meh-blank",
    identifier: "fa-meh-blank",
    type: "solid",
    variant: "fas",
  },
  {
    name: "meh rolling-eyes",
    class: "fas fa-meh-rolling-eyes",
    identifier: "fa-meh-rolling-eyes",
    type: "solid",
    variant: "fas",
  },
  {
    name: "memory",
    class: "fas fa-memory",
    identifier: "fa-memory",
    type: "solid",
    variant: "fas",
  },
  {
    name: "menorah",
    class: "fas fa-menorah",
    identifier: "fa-menorah",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mercury",
    class: "fas fa-mercury",
    identifier: "fa-mercury",
    type: "solid",
    variant: "fas",
  },
  {
    name: "meteor",
    class: "fas fa-meteor",
    identifier: "fa-meteor",
    type: "solid",
    variant: "fas",
  },
  {
    name: "microchip",
    class: "fas fa-microchip",
    identifier: "fa-microchip",
    type: "solid",
    variant: "fas",
  },
  {
    name: "microphone",
    class: "fas fa-microphone",
    identifier: "fa-microphone",
    type: "solid",
    variant: "fas",
  },
  {
    name: "microphone alt",
    class: "fas fa-microphone-alt",
    identifier: "fa-microphone-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "microphone alt-slash",
    class: "fas fa-microphone-alt-slash",
    identifier: "fa-microphone-alt-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "microphone slash",
    class: "fas fa-microphone-slash",
    identifier: "fa-microphone-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "microscope",
    class: "fas fa-microscope",
    identifier: "fa-microscope",
    type: "solid",
    variant: "fas",
  },
  {
    name: "minus",
    class: "fas fa-minus",
    identifier: "fa-minus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "minus circle",
    class: "fas fa-minus-circle",
    identifier: "fa-minus-circle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "minus square",
    class: "fas fa-minus-square",
    identifier: "fa-minus-square",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mitten",
    class: "fas fa-mitten",
    identifier: "fa-mitten",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mobile",
    class: "fas fa-mobile",
    identifier: "fa-mobile",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mobile alt",
    class: "fas fa-mobile-alt",
    identifier: "fa-mobile-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "money bill",
    class: "fas fa-money-bill",
    identifier: "fa-money-bill",
    type: "solid",
    variant: "fas",
  },
  {
    name: "money bill-alt",
    class: "fas fa-money-bill-alt",
    identifier: "fa-money-bill-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "money bill-wave",
    class: "fas fa-money-bill-wave",
    identifier: "fa-money-bill-wave",
    type: "solid",
    variant: "fas",
  },
  {
    name: "money bill-wave-alt",
    class: "fas fa-money-bill-wave-alt",
    identifier: "fa-money-bill-wave-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "money check",
    class: "fas fa-money-check",
    identifier: "fa-money-check",
    type: "solid",
    variant: "fas",
  },
  {
    name: "money check-alt",
    class: "fas fa-money-check-alt",
    identifier: "fa-money-check-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "monument",
    class: "fas fa-monument",
    identifier: "fa-monument",
    type: "solid",
    variant: "fas",
  },
  {
    name: "moon",
    class: "fas fa-moon",
    identifier: "fa-moon",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mortar pestle",
    class: "fas fa-mortar-pestle",
    identifier: "fa-mortar-pestle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mosque",
    class: "fas fa-mosque",
    identifier: "fa-mosque",
    type: "solid",
    variant: "fas",
  },
  {
    name: "motorcycle",
    class: "fas fa-motorcycle",
    identifier: "fa-motorcycle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mountain",
    class: "fas fa-mountain",
    identifier: "fa-mountain",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mouse",
    class: "fas fa-mouse",
    identifier: "fa-mouse",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mouse pointer",
    class: "fas fa-mouse-pointer",
    identifier: "fa-mouse-pointer",
    type: "solid",
    variant: "fas",
  },
  {
    name: "mug hot",
    class: "fas fa-mug-hot",
    identifier: "fa-mug-hot",
    type: "solid",
    variant: "fas",
  },
  {
    name: "music",
    class: "fas fa-music",
    identifier: "fa-music",
    type: "solid",
    variant: "fas",
  },
  {
    name: "network wired",
    class: "fas fa-network-wired",
    identifier: "fa-network-wired",
    type: "solid",
    variant: "fas",
  },
  {
    name: "neuter",
    class: "fas fa-neuter",
    identifier: "fa-neuter",
    type: "solid",
    variant: "fas",
  },
  {
    name: "newspaper",
    class: "fas fa-newspaper",
    identifier: "fa-newspaper",
    type: "solid",
    variant: "fas",
  },
  {
    name: "not equal",
    class: "fas fa-not-equal",
    identifier: "fa-not-equal",
    type: "solid",
    variant: "fas",
  },
  {
    name: "notes medical",
    class: "fas fa-notes-medical",
    identifier: "fa-notes-medical",
    type: "solid",
    variant: "fas",
  },
  {
    name: "object group",
    class: "fas fa-object-group",
    identifier: "fa-object-group",
    type: "solid",
    variant: "fas",
  },
  {
    name: "object ungroup",
    class: "fas fa-object-ungroup",
    identifier: "fa-object-ungroup",
    type: "solid",
    variant: "fas",
  },
  {
    name: "oil can",
    class: "fas fa-oil-can",
    identifier: "fa-oil-can",
    type: "solid",
    variant: "fas",
  },
  {
    name: "om",
    class: "fas fa-om",
    identifier: "fa-om",
    type: "solid",
    variant: "fas",
  },
  {
    name: "otter",
    class: "fas fa-otter",
    identifier: "fa-otter",
    type: "solid",
    variant: "fas",
  },
  {
    name: "outdent",
    class: "fas fa-outdent",
    identifier: "fa-outdent",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pager",
    class: "fas fa-pager",
    identifier: "fa-pager",
    type: "solid",
    variant: "fas",
  },
  {
    name: "paint brush",
    class: "fas fa-paint-brush",
    identifier: "fa-paint-brush",
    type: "solid",
    variant: "fas",
  },
  {
    name: "paint roller",
    class: "fas fa-paint-roller",
    identifier: "fa-paint-roller",
    type: "solid",
    variant: "fas",
  },
  {
    name: "palette",
    class: "fas fa-palette",
    identifier: "fa-palette",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pallet",
    class: "fas fa-pallet",
    identifier: "fa-pallet",
    type: "solid",
    variant: "fas",
  },
  {
    name: "paper plane",
    class: "fas fa-paper-plane",
    identifier: "fa-paper-plane",
    type: "solid",
    variant: "fas",
  },
  {
    name: "paperclip",
    class: "fas fa-paperclip",
    identifier: "fa-paperclip",
    type: "solid",
    variant: "fas",
  },
  {
    name: "parachute box",
    class: "fas fa-parachute-box",
    identifier: "fa-parachute-box",
    type: "solid",
    variant: "fas",
  },
  {
    name: "paragraph",
    class: "fas fa-paragraph",
    identifier: "fa-paragraph",
    type: "solid",
    variant: "fas",
  },
  {
    name: "parking",
    class: "fas fa-parking",
    identifier: "fa-parking",
    type: "solid",
    variant: "fas",
  },
  {
    name: "passport",
    class: "fas fa-passport",
    identifier: "fa-passport",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pastafarianism",
    class: "fas fa-pastafarianism",
    identifier: "fa-pastafarianism",
    type: "solid",
    variant: "fas",
  },
  {
    name: "paste",
    class: "fas fa-paste",
    identifier: "fa-paste",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pause",
    class: "fas fa-pause",
    identifier: "fa-pause",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pause circle",
    class: "fas fa-pause-circle",
    identifier: "fa-pause-circle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "paw",
    class: "fas fa-paw",
    identifier: "fa-paw",
    type: "solid",
    variant: "fas",
  },
  {
    name: "peace",
    class: "fas fa-peace",
    identifier: "fa-peace",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pen",
    class: "fas fa-pen",
    identifier: "fa-pen",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pen alt",
    class: "fas fa-pen-alt",
    identifier: "fa-pen-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pen fancy",
    class: "fas fa-pen-fancy",
    identifier: "fa-pen-fancy",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pen nib",
    class: "fas fa-pen-nib",
    identifier: "fa-pen-nib",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pen square",
    class: "fas fa-pen-square",
    identifier: "fa-pen-square",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pencil alt",
    class: "fas fa-pencil-alt",
    identifier: "fa-pencil-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pencil ruler",
    class: "fas fa-pencil-ruler",
    identifier: "fa-pencil-ruler",
    type: "solid",
    variant: "fas",
  },
  {
    name: "people arrows",
    class: "fas fa-people-arrows",
    identifier: "fa-people-arrows",
    type: "solid",
    variant: "fas",
  },
  {
    name: "people carry",
    class: "fas fa-people-carry",
    identifier: "fa-people-carry",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pepper hot",
    class: "fas fa-pepper-hot",
    identifier: "fa-pepper-hot",
    type: "solid",
    variant: "fas",
  },
  {
    name: "percent",
    class: "fas fa-percent",
    identifier: "fa-percent",
    type: "solid",
    variant: "fas",
  },
  {
    name: "percentage",
    class: "fas fa-percentage",
    identifier: "fa-percentage",
    type: "solid",
    variant: "fas",
  },
  {
    name: "person booth",
    class: "fas fa-person-booth",
    identifier: "fa-person-booth",
    type: "solid",
    variant: "fas",
  },
  {
    name: "phone",
    class: "fas fa-phone",
    identifier: "fa-phone",
    type: "solid",
    variant: "fas",
  },
  {
    name: "phone alt",
    class: "fas fa-phone-alt",
    identifier: "fa-phone-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "phone slash",
    class: "fas fa-phone-slash",
    identifier: "fa-phone-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "phone square",
    class: "fas fa-phone-square",
    identifier: "fa-phone-square",
    type: "solid",
    variant: "fas",
  },
  {
    name: "phone square-alt",
    class: "fas fa-phone-square-alt",
    identifier: "fa-phone-square-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "phone volume",
    class: "fas fa-phone-volume",
    identifier: "fa-phone-volume",
    type: "solid",
    variant: "fas",
  },
  {
    name: "photo video",
    class: "fas fa-photo-video",
    identifier: "fa-photo-video",
    type: "solid",
    variant: "fas",
  },
  {
    name: "piggy bank",
    class: "fas fa-piggy-bank",
    identifier: "fa-piggy-bank",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pills",
    class: "fas fa-pills",
    identifier: "fa-pills",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pizza slice",
    class: "fas fa-pizza-slice",
    identifier: "fa-pizza-slice",
    type: "solid",
    variant: "fas",
  },
  {
    name: "place of-worship",
    class: "fas fa-place-of-worship",
    identifier: "fa-place-of-worship",
    type: "solid",
    variant: "fas",
  },
  {
    name: "plane",
    class: "fas fa-plane",
    identifier: "fa-plane",
    type: "solid",
    variant: "fas",
  },
  {
    name: "plane arrival",
    class: "fas fa-plane-arrival",
    identifier: "fa-plane-arrival",
    type: "solid",
    variant: "fas",
  },
  {
    name: "plane departure",
    class: "fas fa-plane-departure",
    identifier: "fa-plane-departure",
    type: "solid",
    variant: "fas",
  },
  {
    name: "plane slash",
    class: "fas fa-plane-slash",
    identifier: "fa-plane-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "play",
    class: "fas fa-play",
    identifier: "fa-play",
    type: "solid",
    variant: "fas",
  },
  {
    name: "play circle",
    class: "fas fa-play-circle",
    identifier: "fa-play-circle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "plug",
    class: "fas fa-plug",
    identifier: "fa-plug",
    type: "solid",
    variant: "fas",
  },
  {
    name: "plus",
    class: "fas fa-plus",
    identifier: "fa-plus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "plus circle",
    class: "fas fa-plus-circle",
    identifier: "fa-plus-circle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "plus square",
    class: "fas fa-plus-square",
    identifier: "fa-plus-square",
    type: "solid",
    variant: "fas",
  },
  {
    name: "podcast",
    class: "fas fa-podcast",
    identifier: "fa-podcast",
    type: "solid",
    variant: "fas",
  },
  {
    name: "poll",
    class: "fas fa-poll",
    identifier: "fa-poll",
    type: "solid",
    variant: "fas",
  },
  {
    name: "poll h",
    class: "fas fa-poll-h",
    identifier: "fa-poll-h",
    type: "solid",
    variant: "fas",
  },
  {
    name: "poo",
    class: "fas fa-poo",
    identifier: "fa-poo",
    type: "solid",
    variant: "fas",
  },
  {
    name: "poo storm",
    class: "fas fa-poo-storm",
    identifier: "fa-poo-storm",
    type: "solid",
    variant: "fas",
  },
  {
    name: "poop",
    class: "fas fa-poop",
    identifier: "fa-poop",
    type: "solid",
    variant: "fas",
  },
  {
    name: "portrait",
    class: "fas fa-portrait",
    identifier: "fa-portrait",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pound sign",
    class: "fas fa-pound-sign",
    identifier: "fa-pound-sign",
    type: "solid",
    variant: "fas",
  },
  {
    name: "power off",
    class: "fas fa-power-off",
    identifier: "fa-power-off",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pray",
    class: "fas fa-pray",
    identifier: "fa-pray",
    type: "solid",
    variant: "fas",
  },
  {
    name: "praying hands",
    class: "fas fa-praying-hands",
    identifier: "fa-praying-hands",
    type: "solid",
    variant: "fas",
  },
  {
    name: "prescription",
    class: "fas fa-prescription",
    identifier: "fa-prescription",
    type: "solid",
    variant: "fas",
  },
  {
    name: "prescription bottle",
    class: "fas fa-prescription-bottle",
    identifier: "fa-prescription-bottle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "prescription bottle-alt",
    class: "fas fa-prescription-bottle-alt",
    identifier: "fa-prescription-bottle-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "print",
    class: "fas fa-print",
    identifier: "fa-print",
    type: "solid",
    variant: "fas",
  },
  {
    name: "procedures",
    class: "fas fa-procedures",
    identifier: "fa-procedures",
    type: "solid",
    variant: "fas",
  },
  {
    name: "project diagram",
    class: "fas fa-project-diagram",
    identifier: "fa-project-diagram",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pump medical",
    class: "fas fa-pump-medical",
    identifier: "fa-pump-medical",
    type: "solid",
    variant: "fas",
  },
  {
    name: "pump soap",
    class: "fas fa-pump-soap",
    identifier: "fa-pump-soap",
    type: "solid",
    variant: "fas",
  },
  {
    name: "puzzle piece",
    class: "fas fa-puzzle-piece",
    identifier: "fa-puzzle-piece",
    type: "solid",
    variant: "fas",
  },
  {
    name: "qrcode",
    class: "fas fa-qrcode",
    identifier: "fa-qrcode",
    type: "solid",
    variant: "fas",
  },
  {
    name: "question",
    class: "fas fa-question",
    identifier: "fa-question",
    type: "solid",
    variant: "fas",
  },
  {
    name: "question circle",
    class: "fas fa-question-circle",
    identifier: "fa-question-circle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "quidditch",
    class: "fas fa-quidditch",
    identifier: "fa-quidditch",
    type: "solid",
    variant: "fas",
  },
  {
    name: "quote left",
    class: "fas fa-quote-left",
    identifier: "fa-quote-left",
    type: "solid",
    variant: "fas",
  },
  {
    name: "quote right",
    class: "fas fa-quote-right",
    identifier: "fa-quote-right",
    type: "solid",
    variant: "fas",
  },
  {
    name: "quran",
    class: "fas fa-quran",
    identifier: "fa-quran",
    type: "solid",
    variant: "fas",
  },
  {
    name: "radiation",
    class: "fas fa-radiation",
    identifier: "fa-radiation",
    type: "solid",
    variant: "fas",
  },
  {
    name: "radiation alt",
    class: "fas fa-radiation-alt",
    identifier: "fa-radiation-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "rainbow",
    class: "fas fa-rainbow",
    identifier: "fa-rainbow",
    type: "solid",
    variant: "fas",
  },
  {
    name: "random",
    class: "fas fa-random",
    identifier: "fa-random",
    type: "solid",
    variant: "fas",
  },
  {
    name: "receipt",
    class: "fas fa-receipt",
    identifier: "fa-receipt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "record vinyl",
    class: "fas fa-record-vinyl",
    identifier: "fa-record-vinyl",
    type: "solid",
    variant: "fas",
  },
  {
    name: "recycle",
    class: "fas fa-recycle",
    identifier: "fa-recycle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "redo",
    class: "fas fa-redo",
    identifier: "fa-redo",
    type: "solid",
    variant: "fas",
  },
  {
    name: "redo alt",
    class: "fas fa-redo-alt",
    identifier: "fa-redo-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "registered",
    class: "fas fa-registered",
    identifier: "fa-registered",
    type: "solid",
    variant: "fas",
  },
  {
    name: "remove format",
    class: "fas fa-remove-format",
    identifier: "fa-remove-format",
    type: "solid",
    variant: "fas",
  },
  {
    name: "reply",
    class: "fas fa-reply",
    identifier: "fa-reply",
    type: "solid",
    variant: "fas",
  },
  {
    name: "reply all",
    class: "fas fa-reply-all",
    identifier: "fa-reply-all",
    type: "solid",
    variant: "fas",
  },
  {
    name: "republican",
    class: "fas fa-republican",
    identifier: "fa-republican",
    type: "solid",
    variant: "fas",
  },
  {
    name: "restroom",
    class: "fas fa-restroom",
    identifier: "fa-restroom",
    type: "solid",
    variant: "fas",
  },
  {
    name: "retweet",
    class: "fas fa-retweet",
    identifier: "fa-retweet",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ribbon",
    class: "fas fa-ribbon",
    identifier: "fa-ribbon",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ring",
    class: "fas fa-ring",
    identifier: "fa-ring",
    type: "solid",
    variant: "fas",
  },
  {
    name: "road",
    class: "fas fa-road",
    identifier: "fa-road",
    type: "solid",
    variant: "fas",
  },
  {
    name: "robot",
    class: "fas fa-robot",
    identifier: "fa-robot",
    type: "solid",
    variant: "fas",
  },
  {
    name: "rocket",
    class: "fas fa-rocket",
    identifier: "fa-rocket",
    type: "solid",
    variant: "fas",
  },
  {
    name: "route",
    class: "fas fa-route",
    identifier: "fa-route",
    type: "solid",
    variant: "fas",
  },
  {
    name: "rss",
    class: "fas fa-rss",
    identifier: "fa-rss",
    type: "solid",
    variant: "fas",
  },
  {
    name: "rss square",
    class: "fas fa-rss-square",
    identifier: "fa-rss-square",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ruble sign",
    class: "fas fa-ruble-sign",
    identifier: "fa-ruble-sign",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ruler",
    class: "fas fa-ruler",
    identifier: "fa-ruler",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ruler combined",
    class: "fas fa-ruler-combined",
    identifier: "fa-ruler-combined",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ruler horizontal",
    class: "fas fa-ruler-horizontal",
    identifier: "fa-ruler-horizontal",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ruler vertical",
    class: "fas fa-ruler-vertical",
    identifier: "fa-ruler-vertical",
    type: "solid",
    variant: "fas",
  },
  {
    name: "running",
    class: "fas fa-running",
    identifier: "fa-running",
    type: "solid",
    variant: "fas",
  },
  {
    name: "rupee sign",
    class: "fas fa-rupee-sign",
    identifier: "fa-rupee-sign",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sad cry",
    class: "fas fa-sad-cry",
    identifier: "fa-sad-cry",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sad tear",
    class: "fas fa-sad-tear",
    identifier: "fa-sad-tear",
    type: "solid",
    variant: "fas",
  },
  {
    name: "satellite",
    class: "fas fa-satellite",
    identifier: "fa-satellite",
    type: "solid",
    variant: "fas",
  },
  {
    name: "satellite dish",
    class: "fas fa-satellite-dish",
    identifier: "fa-satellite-dish",
    type: "solid",
    variant: "fas",
  },
  {
    name: "save",
    class: "fas fa-save",
    identifier: "fa-save",
    type: "solid",
    variant: "fas",
  },
  {
    name: "school",
    class: "fas fa-school",
    identifier: "fa-school",
    type: "solid",
    variant: "fas",
  },
  {
    name: "screwdriver",
    class: "fas fa-screwdriver",
    identifier: "fa-screwdriver",
    type: "solid",
    variant: "fas",
  },
  {
    name: "scroll",
    class: "fas fa-scroll",
    identifier: "fa-scroll",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sd card",
    class: "fas fa-sd-card",
    identifier: "fa-sd-card",
    type: "solid",
    variant: "fas",
  },
  {
    name: "search",
    class: "fas fa-search",
    identifier: "fa-search",
    type: "solid",
    variant: "fas",
  },
  {
    name: "search dollar",
    class: "fas fa-search-dollar",
    identifier: "fa-search-dollar",
    type: "solid",
    variant: "fas",
  },
  {
    name: "search location",
    class: "fas fa-search-location",
    identifier: "fa-search-location",
    type: "solid",
    variant: "fas",
  },
  {
    name: "search minus",
    class: "fas fa-search-minus",
    identifier: "fa-search-minus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "search plus",
    class: "fas fa-search-plus",
    identifier: "fa-search-plus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "seedling",
    class: "fas fa-seedling",
    identifier: "fa-seedling",
    type: "solid",
    variant: "fas",
  },
  {
    name: "server",
    class: "fas fa-server",
    identifier: "fa-server",
    type: "solid",
    variant: "fas",
  },
  {
    name: "shapes",
    class: "fas fa-shapes",
    identifier: "fa-shapes",
    type: "solid",
    variant: "fas",
  },
  {
    name: "share",
    class: "fas fa-share",
    identifier: "fa-share",
    type: "solid",
    variant: "fas",
  },
  {
    name: "share alt",
    class: "fas fa-share-alt",
    identifier: "fa-share-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "share alt-square",
    class: "fas fa-share-alt-square",
    identifier: "fa-share-alt-square",
    type: "solid",
    variant: "fas",
  },
  {
    name: "share square",
    class: "fas fa-share-square",
    identifier: "fa-share-square",
    type: "solid",
    variant: "fas",
  },
  {
    name: "shekel sign",
    class: "fas fa-shekel-sign",
    identifier: "fa-shekel-sign",
    type: "solid",
    variant: "fas",
  },
  {
    name: "shield alt",
    class: "fas fa-shield-alt",
    identifier: "fa-shield-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "shield virus",
    class: "fas fa-shield-virus",
    identifier: "fa-shield-virus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ship",
    class: "fas fa-ship",
    identifier: "fa-ship",
    type: "solid",
    variant: "fas",
  },
  {
    name: "shipping fast",
    class: "fas fa-shipping-fast",
    identifier: "fa-shipping-fast",
    type: "solid",
    variant: "fas",
  },
  {
    name: "shoe prints",
    class: "fas fa-shoe-prints",
    identifier: "fa-shoe-prints",
    type: "solid",
    variant: "fas",
  },
  {
    name: "shopping bag",
    class: "fas fa-shopping-bag",
    identifier: "fa-shopping-bag",
    type: "solid",
    variant: "fas",
  },
  {
    name: "shopping basket",
    class: "fas fa-shopping-basket",
    identifier: "fa-shopping-basket",
    type: "solid",
    variant: "fas",
  },
  {
    name: "shopping cart",
    class: "fas fa-shopping-cart",
    identifier: "fa-shopping-cart",
    type: "solid",
    variant: "fas",
  },
  {
    name: "shower",
    class: "fas fa-shower",
    identifier: "fa-shower",
    type: "solid",
    variant: "fas",
  },
  {
    name: "shuttle van",
    class: "fas fa-shuttle-van",
    identifier: "fa-shuttle-van",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sign",
    class: "fas fa-sign",
    identifier: "fa-sign",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sign in-alt",
    class: "fas fa-sign-in-alt",
    identifier: "fa-sign-in-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sign language",
    class: "fas fa-sign-language",
    identifier: "fa-sign-language",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sign out-alt",
    class: "fas fa-sign-out-alt",
    identifier: "fa-sign-out-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "signal",
    class: "fas fa-signal",
    identifier: "fa-signal",
    type: "solid",
    variant: "fas",
  },
  {
    name: "signature",
    class: "fas fa-signature",
    identifier: "fa-signature",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sim card",
    class: "fas fa-sim-card",
    identifier: "fa-sim-card",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sink",
    class: "fas fa-sink",
    identifier: "fa-sink",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sitemap",
    class: "fas fa-sitemap",
    identifier: "fa-sitemap",
    type: "solid",
    variant: "fas",
  },
  {
    name: "skating",
    class: "fas fa-skating",
    identifier: "fa-skating",
    type: "solid",
    variant: "fas",
  },
  {
    name: "skiing",
    class: "fas fa-skiing",
    identifier: "fa-skiing",
    type: "solid",
    variant: "fas",
  },
  {
    name: "skiing nordic",
    class: "fas fa-skiing-nordic",
    identifier: "fa-skiing-nordic",
    type: "solid",
    variant: "fas",
  },
  {
    name: "skull",
    class: "fas fa-skull",
    identifier: "fa-skull",
    type: "solid",
    variant: "fas",
  },
  {
    name: "skull crossbones",
    class: "fas fa-skull-crossbones",
    identifier: "fa-skull-crossbones",
    type: "solid",
    variant: "fas",
  },
  {
    name: "slash",
    class: "fas fa-slash",
    identifier: "fa-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sleigh",
    class: "fas fa-sleigh",
    identifier: "fa-sleigh",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sliders h",
    class: "fas fa-sliders-h",
    identifier: "fa-sliders-h",
    type: "solid",
    variant: "fas",
  },
  {
    name: "smile",
    class: "fas fa-smile",
    identifier: "fa-smile",
    type: "solid",
    variant: "fas",
  },
  {
    name: "smile beam",
    class: "fas fa-smile-beam",
    identifier: "fa-smile-beam",
    type: "solid",
    variant: "fas",
  },
  {
    name: "smile wink",
    class: "fas fa-smile-wink",
    identifier: "fa-smile-wink",
    type: "solid",
    variant: "fas",
  },
  {
    name: "smog",
    class: "fas fa-smog",
    identifier: "fa-smog",
    type: "solid",
    variant: "fas",
  },
  {
    name: "smoking",
    class: "fas fa-smoking",
    identifier: "fa-smoking",
    type: "solid",
    variant: "fas",
  },
  {
    name: "smoking ban",
    class: "fas fa-smoking-ban",
    identifier: "fa-smoking-ban",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sms",
    class: "fas fa-sms",
    identifier: "fa-sms",
    type: "solid",
    variant: "fas",
  },
  {
    name: "snowboarding",
    class: "fas fa-snowboarding",
    identifier: "fa-snowboarding",
    type: "solid",
    variant: "fas",
  },
  {
    name: "snowflake",
    class: "fas fa-snowflake",
    identifier: "fa-snowflake",
    type: "solid",
    variant: "fas",
  },
  {
    name: "snowman",
    class: "fas fa-snowman",
    identifier: "fa-snowman",
    type: "solid",
    variant: "fas",
  },
  {
    name: "snowplow",
    class: "fas fa-snowplow",
    identifier: "fa-snowplow",
    type: "solid",
    variant: "fas",
  },
  {
    name: "soap",
    class: "fas fa-soap",
    identifier: "fa-soap",
    type: "solid",
    variant: "fas",
  },
  {
    name: "socks",
    class: "fas fa-socks",
    identifier: "fa-socks",
    type: "solid",
    variant: "fas",
  },
  {
    name: "solar panel",
    class: "fas fa-solar-panel",
    identifier: "fa-solar-panel",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sort",
    class: "fas fa-sort",
    identifier: "fa-sort",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sort alpha-down",
    class: "fas fa-sort-alpha-down",
    identifier: "fa-sort-alpha-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sort alpha-down-alt",
    class: "fas fa-sort-alpha-down-alt",
    identifier: "fa-sort-alpha-down-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sort alpha-up",
    class: "fas fa-sort-alpha-up",
    identifier: "fa-sort-alpha-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sort alpha-up-alt",
    class: "fas fa-sort-alpha-up-alt",
    identifier: "fa-sort-alpha-up-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sort amount-down",
    class: "fas fa-sort-amount-down",
    identifier: "fa-sort-amount-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sort amount-down-alt",
    class: "fas fa-sort-amount-down-alt",
    identifier: "fa-sort-amount-down-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sort amount-up",
    class: "fas fa-sort-amount-up",
    identifier: "fa-sort-amount-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sort amount-up-alt",
    class: "fas fa-sort-amount-up-alt",
    identifier: "fa-sort-amount-up-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sort down",
    class: "fas fa-sort-down",
    identifier: "fa-sort-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sort numeric-down",
    class: "fas fa-sort-numeric-down",
    identifier: "fa-sort-numeric-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sort numeric-down-alt",
    class: "fas fa-sort-numeric-down-alt",
    identifier: "fa-sort-numeric-down-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sort numeric-up",
    class: "fas fa-sort-numeric-up",
    identifier: "fa-sort-numeric-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sort numeric-up-alt",
    class: "fas fa-sort-numeric-up-alt",
    identifier: "fa-sort-numeric-up-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sort up",
    class: "fas fa-sort-up",
    identifier: "fa-sort-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "spa",
    class: "fas fa-spa",
    identifier: "fa-spa",
    type: "solid",
    variant: "fas",
  },
  {
    name: "space shuttle",
    class: "fas fa-space-shuttle",
    identifier: "fa-space-shuttle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "spell check",
    class: "fas fa-spell-check",
    identifier: "fa-spell-check",
    type: "solid",
    variant: "fas",
  },
  {
    name: "spider",
    class: "fas fa-spider",
    identifier: "fa-spider",
    type: "solid",
    variant: "fas",
  },
  {
    name: "spinner",
    class: "fas fa-spinner",
    identifier: "fa-spinner",
    type: "solid",
    variant: "fas",
  },
  {
    name: "splotch",
    class: "fas fa-splotch",
    identifier: "fa-splotch",
    type: "solid",
    variant: "fas",
  },
  {
    name: "spray can",
    class: "fas fa-spray-can",
    identifier: "fa-spray-can",
    type: "solid",
    variant: "fas",
  },
  {
    name: "square",
    class: "fas fa-square",
    identifier: "fa-square",
    type: "solid",
    variant: "fas",
  },
  {
    name: "square full",
    class: "fas fa-square-full",
    identifier: "fa-square-full",
    type: "solid",
    variant: "fas",
  },
  {
    name: "square root-alt",
    class: "fas fa-square-root-alt",
    identifier: "fa-square-root-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "stamp",
    class: "fas fa-stamp",
    identifier: "fa-stamp",
    type: "solid",
    variant: "fas",
  },
  {
    name: "star",
    class: "fas fa-star",
    identifier: "fa-star",
    type: "solid",
    variant: "fas",
  },
  {
    name: "star and-crescent",
    class: "fas fa-star-and-crescent",
    identifier: "fa-star-and-crescent",
    type: "solid",
    variant: "fas",
  },
  {
    name: "star half",
    class: "fas fa-star-half",
    identifier: "fa-star-half",
    type: "solid",
    variant: "fas",
  },
  {
    name: "star half-alt",
    class: "fas fa-star-half-alt",
    identifier: "fa-star-half-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "star of-david",
    class: "fas fa-star-of-david",
    identifier: "fa-star-of-david",
    type: "solid",
    variant: "fas",
  },
  {
    name: "star of-life",
    class: "fas fa-star-of-life",
    identifier: "fa-star-of-life",
    type: "solid",
    variant: "fas",
  },
  {
    name: "step backward",
    class: "fas fa-step-backward",
    identifier: "fa-step-backward",
    type: "solid",
    variant: "fas",
  },
  {
    name: "step forward",
    class: "fas fa-step-forward",
    identifier: "fa-step-forward",
    type: "solid",
    variant: "fas",
  },
  {
    name: "stethoscope",
    class: "fas fa-stethoscope",
    identifier: "fa-stethoscope",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sticky note",
    class: "fas fa-sticky-note",
    identifier: "fa-sticky-note",
    type: "solid",
    variant: "fas",
  },
  {
    name: "stop",
    class: "fas fa-stop",
    identifier: "fa-stop",
    type: "solid",
    variant: "fas",
  },
  {
    name: "stop circle",
    class: "fas fa-stop-circle",
    identifier: "fa-stop-circle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "stopwatch",
    class: "fas fa-stopwatch",
    identifier: "fa-stopwatch",
    type: "solid",
    variant: "fas",
  },
  {
    name: "stopwatch 20",
    class: "fas fa-stopwatch-20",
    identifier: "fa-stopwatch-20",
    type: "solid",
    variant: "fas",
  },
  {
    name: "store",
    class: "fas fa-store",
    identifier: "fa-store",
    type: "solid",
    variant: "fas",
  },
  {
    name: "store alt",
    class: "fas fa-store-alt",
    identifier: "fa-store-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "store alt-slash",
    class: "fas fa-store-alt-slash",
    identifier: "fa-store-alt-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "store slash",
    class: "fas fa-store-slash",
    identifier: "fa-store-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "stream",
    class: "fas fa-stream",
    identifier: "fa-stream",
    type: "solid",
    variant: "fas",
  },
  {
    name: "street view",
    class: "fas fa-street-view",
    identifier: "fa-street-view",
    type: "solid",
    variant: "fas",
  },
  {
    name: "strikethrough",
    class: "fas fa-strikethrough",
    identifier: "fa-strikethrough",
    type: "solid",
    variant: "fas",
  },
  {
    name: "stroopwafel",
    class: "fas fa-stroopwafel",
    identifier: "fa-stroopwafel",
    type: "solid",
    variant: "fas",
  },
  {
    name: "subscript",
    class: "fas fa-subscript",
    identifier: "fa-subscript",
    type: "solid",
    variant: "fas",
  },
  {
    name: "subway",
    class: "fas fa-subway",
    identifier: "fa-subway",
    type: "solid",
    variant: "fas",
  },
  {
    name: "suitcase",
    class: "fas fa-suitcase",
    identifier: "fa-suitcase",
    type: "solid",
    variant: "fas",
  },
  {
    name: "suitcase rolling",
    class: "fas fa-suitcase-rolling",
    identifier: "fa-suitcase-rolling",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sun",
    class: "fas fa-sun",
    identifier: "fa-sun",
    type: "solid",
    variant: "fas",
  },
  {
    name: "superscript",
    class: "fas fa-superscript",
    identifier: "fa-superscript",
    type: "solid",
    variant: "fas",
  },
  {
    name: "surprise",
    class: "fas fa-surprise",
    identifier: "fa-surprise",
    type: "solid",
    variant: "fas",
  },
  {
    name: "swatchbook",
    class: "fas fa-swatchbook",
    identifier: "fa-swatchbook",
    type: "solid",
    variant: "fas",
  },
  {
    name: "swimmer",
    class: "fas fa-swimmer",
    identifier: "fa-swimmer",
    type: "solid",
    variant: "fas",
  },
  {
    name: "swimming pool",
    class: "fas fa-swimming-pool",
    identifier: "fa-swimming-pool",
    type: "solid",
    variant: "fas",
  },
  {
    name: "synagogue",
    class: "fas fa-synagogue",
    identifier: "fa-synagogue",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sync",
    class: "fas fa-sync",
    identifier: "fa-sync",
    type: "solid",
    variant: "fas",
  },
  {
    name: "sync alt",
    class: "fas fa-sync-alt",
    identifier: "fa-sync-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "syringe",
    class: "fas fa-syringe",
    identifier: "fa-syringe",
    type: "solid",
    variant: "fas",
  },
  {
    name: "table",
    class: "fas fa-table",
    identifier: "fa-table",
    type: "solid",
    variant: "fas",
  },
  {
    name: "table tennis",
    class: "fas fa-table-tennis",
    identifier: "fa-table-tennis",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tablet",
    class: "fas fa-tablet",
    identifier: "fa-tablet",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tablet alt",
    class: "fas fa-tablet-alt",
    identifier: "fa-tablet-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tablets",
    class: "fas fa-tablets",
    identifier: "fa-tablets",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tachometer alt",
    class: "fas fa-tachometer-alt",
    identifier: "fa-tachometer-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tag",
    class: "fas fa-tag",
    identifier: "fa-tag",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tags",
    class: "fas fa-tags",
    identifier: "fa-tags",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tape",
    class: "fas fa-tape",
    identifier: "fa-tape",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tasks",
    class: "fas fa-tasks",
    identifier: "fa-tasks",
    type: "solid",
    variant: "fas",
  },
  {
    name: "taxi",
    class: "fas fa-taxi",
    identifier: "fa-taxi",
    type: "solid",
    variant: "fas",
  },
  {
    name: "teeth",
    class: "fas fa-teeth",
    identifier: "fa-teeth",
    type: "solid",
    variant: "fas",
  },
  {
    name: "teeth open",
    class: "fas fa-teeth-open",
    identifier: "fa-teeth-open",
    type: "solid",
    variant: "fas",
  },
  {
    name: "temperature high",
    class: "fas fa-temperature-high",
    identifier: "fa-temperature-high",
    type: "solid",
    variant: "fas",
  },
  {
    name: "temperature low",
    class: "fas fa-temperature-low",
    identifier: "fa-temperature-low",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tenge",
    class: "fas fa-tenge",
    identifier: "fa-tenge",
    type: "solid",
    variant: "fas",
  },
  {
    name: "terminal",
    class: "fas fa-terminal",
    identifier: "fa-terminal",
    type: "solid",
    variant: "fas",
  },
  {
    name: "text height",
    class: "fas fa-text-height",
    identifier: "fa-text-height",
    type: "solid",
    variant: "fas",
  },
  {
    name: "text width",
    class: "fas fa-text-width",
    identifier: "fa-text-width",
    type: "solid",
    variant: "fas",
  },
  {
    name: "th",
    class: "fas fa-th",
    identifier: "fa-th",
    type: "solid",
    variant: "fas",
  },
  {
    name: "th large",
    class: "fas fa-th-large",
    identifier: "fa-th-large",
    type: "solid",
    variant: "fas",
  },
  {
    name: "th list",
    class: "fas fa-th-list",
    identifier: "fa-th-list",
    type: "solid",
    variant: "fas",
  },
  {
    name: "theater masks",
    class: "fas fa-theater-masks",
    identifier: "fa-theater-masks",
    type: "solid",
    variant: "fas",
  },
  {
    name: "thermometer",
    class: "fas fa-thermometer",
    identifier: "fa-thermometer",
    type: "solid",
    variant: "fas",
  },
  {
    name: "thermometer empty",
    class: "fas fa-thermometer-empty",
    identifier: "fa-thermometer-empty",
    type: "solid",
    variant: "fas",
  },
  {
    name: "thermometer full",
    class: "fas fa-thermometer-full",
    identifier: "fa-thermometer-full",
    type: "solid",
    variant: "fas",
  },
  {
    name: "thermometer half",
    class: "fas fa-thermometer-half",
    identifier: "fa-thermometer-half",
    type: "solid",
    variant: "fas",
  },
  {
    name: "thermometer quarter",
    class: "fas fa-thermometer-quarter",
    identifier: "fa-thermometer-quarter",
    type: "solid",
    variant: "fas",
  },
  {
    name: "thermometer three-quarters",
    class: "fas fa-thermometer-three-quarters",
    identifier: "fa-thermometer-three-quarters",
    type: "solid",
    variant: "fas",
  },
  {
    name: "thumbs down",
    class: "fas fa-thumbs-down",
    identifier: "fa-thumbs-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "thumbs up",
    class: "fas fa-thumbs-up",
    identifier: "fa-thumbs-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "thumbtack",
    class: "fas fa-thumbtack",
    identifier: "fa-thumbtack",
    type: "solid",
    variant: "fas",
  },
  {
    name: "ticket alt",
    class: "fas fa-ticket-alt",
    identifier: "fa-ticket-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "times",
    class: "fas fa-times",
    identifier: "fa-times",
    type: "solid",
    variant: "fas",
  },
  {
    name: "times circle",
    class: "fas fa-times-circle",
    identifier: "fa-times-circle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tint",
    class: "fas fa-tint",
    identifier: "fa-tint",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tint slash",
    class: "fas fa-tint-slash",
    identifier: "fa-tint-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tired",
    class: "fas fa-tired",
    identifier: "fa-tired",
    type: "solid",
    variant: "fas",
  },
  {
    name: "toggle off",
    class: "fas fa-toggle-off",
    identifier: "fa-toggle-off",
    type: "solid",
    variant: "fas",
  },
  {
    name: "toggle on",
    class: "fas fa-toggle-on",
    identifier: "fa-toggle-on",
    type: "solid",
    variant: "fas",
  },
  {
    name: "toilet",
    class: "fas fa-toilet",
    identifier: "fa-toilet",
    type: "solid",
    variant: "fas",
  },
  {
    name: "toilet paper",
    class: "fas fa-toilet-paper",
    identifier: "fa-toilet-paper",
    type: "solid",
    variant: "fas",
  },
  {
    name: "toilet paper-slash",
    class: "fas fa-toilet-paper-slash",
    identifier: "fa-toilet-paper-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "toolbox",
    class: "fas fa-toolbox",
    identifier: "fa-toolbox",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tools",
    class: "fas fa-tools",
    identifier: "fa-tools",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tooth",
    class: "fas fa-tooth",
    identifier: "fa-tooth",
    type: "solid",
    variant: "fas",
  },
  {
    name: "torah",
    class: "fas fa-torah",
    identifier: "fa-torah",
    type: "solid",
    variant: "fas",
  },
  {
    name: "torii gate",
    class: "fas fa-torii-gate",
    identifier: "fa-torii-gate",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tractor",
    class: "fas fa-tractor",
    identifier: "fa-tractor",
    type: "solid",
    variant: "fas",
  },
  {
    name: "trademark",
    class: "fas fa-trademark",
    identifier: "fa-trademark",
    type: "solid",
    variant: "fas",
  },
  {
    name: "traffic light",
    class: "fas fa-traffic-light",
    identifier: "fa-traffic-light",
    type: "solid",
    variant: "fas",
  },
  {
    name: "trailer",
    class: "fas fa-trailer",
    identifier: "fa-trailer",
    type: "solid",
    variant: "fas",
  },
  {
    name: "train",
    class: "fas fa-train",
    identifier: "fa-train",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tram",
    class: "fas fa-tram",
    identifier: "fa-tram",
    type: "solid",
    variant: "fas",
  },
  {
    name: "transgender",
    class: "fas fa-transgender",
    identifier: "fa-transgender",
    type: "solid",
    variant: "fas",
  },
  {
    name: "transgender alt",
    class: "fas fa-transgender-alt",
    identifier: "fa-transgender-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "trash",
    class: "fas fa-trash",
    identifier: "fa-trash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "trash alt",
    class: "fas fa-trash-alt",
    identifier: "fa-trash-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "trash restore",
    class: "fas fa-trash-restore",
    identifier: "fa-trash-restore",
    type: "solid",
    variant: "fas",
  },
  {
    name: "trash restore-alt",
    class: "fas fa-trash-restore-alt",
    identifier: "fa-trash-restore-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tree",
    class: "fas fa-tree",
    identifier: "fa-tree",
    type: "solid",
    variant: "fas",
  },
  {
    name: "trophy",
    class: "fas fa-trophy",
    identifier: "fa-trophy",
    type: "solid",
    variant: "fas",
  },
  {
    name: "fas-trophy",
    class: "fa-solid fa-trophy",
    identifier: "fa-trophy",
    type: "solid",
    variant: "fa-solid",
  },
  {
    name: "truck",
    class: "fas fa-truck",
    identifier: "fa-truck",
    type: "solid",
    variant: "fas",
  },
  {
    name: "truck loading",
    class: "fas fa-truck-loading",
    identifier: "fa-truck-loading",
    type: "solid",
    variant: "fas",
  },
  {
    name: "truck monster",
    class: "fas fa-truck-monster",
    identifier: "fa-truck-monster",
    type: "solid",
    variant: "fas",
  },
  {
    name: "truck moving",
    class: "fas fa-truck-moving",
    identifier: "fa-truck-moving",
    type: "solid",
    variant: "fas",
  },
  {
    name: "truck pickup",
    class: "fas fa-truck-pickup",
    identifier: "fa-truck-pickup",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tshirt",
    class: "fas fa-tshirt",
    identifier: "fa-tshirt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tty",
    class: "fas fa-tty",
    identifier: "fa-tty",
    type: "solid",
    variant: "fas",
  },
  {
    name: "tv",
    class: "fas fa-tv",
    identifier: "fa-tv",
    type: "solid",
    variant: "fas",
  },
  {
    name: "umbrella",
    class: "fas fa-umbrella",
    identifier: "fa-umbrella",
    type: "solid",
    variant: "fas",
  },
  {
    name: "umbrella beach",
    class: "fas fa-umbrella-beach",
    identifier: "fa-umbrella-beach",
    type: "solid",
    variant: "fas",
  },
  {
    name: "underline",
    class: "fas fa-underline",
    identifier: "fa-underline",
    type: "solid",
    variant: "fas",
  },
  {
    name: "undo",
    class: "fas fa-undo",
    identifier: "fa-undo",
    type: "solid",
    variant: "fas",
  },
  {
    name: "undo alt",
    class: "fas fa-undo-alt",
    identifier: "fa-undo-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "universal access",
    class: "fas fa-universal-access",
    identifier: "fa-universal-access",
    type: "solid",
    variant: "fas",
  },
  {
    name: "university",
    class: "fas fa-university",
    identifier: "fa-university",
    type: "solid",
    variant: "fas",
  },
  {
    name: "unlink",
    class: "fas fa-unlink",
    identifier: "fa-unlink",
    type: "solid",
    variant: "fas",
  },
  {
    name: "unlock",
    class: "fas fa-unlock",
    identifier: "fa-unlock",
    type: "solid",
    variant: "fas",
  },
  {
    name: "unlock alt",
    class: "fas fa-unlock-alt",
    identifier: "fa-unlock-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "upload",
    class: "fas fa-upload",
    identifier: "fa-upload",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user",
    class: "fas fa-user",
    identifier: "fa-user",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user alt",
    class: "fas fa-user-alt",
    identifier: "fa-user-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user alt-slash",
    class: "fas fa-user-alt-slash",
    identifier: "fa-user-alt-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user astronaut",
    class: "fas fa-user-astronaut",
    identifier: "fa-user-astronaut",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user check",
    class: "fas fa-user-check",
    identifier: "fa-user-check",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user circle",
    class: "fas fa-user-circle",
    identifier: "fa-user-circle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user clock",
    class: "fas fa-user-clock",
    identifier: "fa-user-clock",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user cog",
    class: "fas fa-user-cog",
    identifier: "fa-user-cog",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user edit",
    class: "fas fa-user-edit",
    identifier: "fa-user-edit",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user friends",
    class: "fas fa-user-friends",
    identifier: "fa-user-friends",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user graduate",
    class: "fas fa-user-graduate",
    identifier: "fa-user-graduate",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user injured",
    class: "fas fa-user-injured",
    identifier: "fa-user-injured",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user lock",
    class: "fas fa-user-lock",
    identifier: "fa-user-lock",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user md",
    class: "fas fa-user-md",
    identifier: "fa-user-md",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user minus",
    class: "fas fa-user-minus",
    identifier: "fa-user-minus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user ninja",
    class: "fas fa-user-ninja",
    identifier: "fa-user-ninja",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user nurse",
    class: "fas fa-user-nurse",
    identifier: "fa-user-nurse",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user plus",
    class: "fas fa-user-plus",
    identifier: "fa-user-plus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user secret",
    class: "fas fa-user-secret",
    identifier: "fa-user-secret",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user shield",
    class: "fas fa-user-shield",
    identifier: "fa-user-shield",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user slash",
    class: "fas fa-user-slash",
    identifier: "fa-user-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user tag",
    class: "fas fa-user-tag",
    identifier: "fa-user-tag",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user tie",
    class: "fas fa-user-tie",
    identifier: "fa-user-tie",
    type: "solid",
    variant: "fas",
  },
  {
    name: "user times",
    class: "fas fa-user-times",
    identifier: "fa-user-times",
    type: "solid",
    variant: "fas",
  },
  {
    name: "users",
    class: "fas fa-users",
    identifier: "fa-users",
    type: "solid",
    variant: "fas",
  },
  {
    name: "users cog",
    class: "fas fa-users-cog",
    identifier: "fa-users-cog",
    type: "solid",
    variant: "fas",
  },
  {
    name: "users slash",
    class: "fas fa-users-slash",
    identifier: "fa-users-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "utensil spoon",
    class: "fas fa-utensil-spoon",
    identifier: "fa-utensil-spoon",
    type: "solid",
    variant: "fas",
  },
  {
    name: "utensils",
    class: "fas fa-utensils",
    identifier: "fa-utensils",
    type: "solid",
    variant: "fas",
  },
  {
    name: "vector square",
    class: "fas fa-vector-square",
    identifier: "fa-vector-square",
    type: "solid",
    variant: "fas",
  },
  {
    name: "venus",
    class: "fas fa-venus",
    identifier: "fa-venus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "venus double",
    class: "fas fa-venus-double",
    identifier: "fa-venus-double",
    type: "solid",
    variant: "fas",
  },
  {
    name: "venus mars",
    class: "fas fa-venus-mars",
    identifier: "fa-venus-mars",
    type: "solid",
    variant: "fas",
  },
  {
    name: "vest",
    class: "fas fa-vest",
    identifier: "fa-vest",
    type: "solid",
    variant: "fas",
  },
  {
    name: "vest patches",
    class: "fas fa-vest-patches",
    identifier: "fa-vest-patches",
    type: "solid",
    variant: "fas",
  },
  {
    name: "vial",
    class: "fas fa-vial",
    identifier: "fa-vial",
    type: "solid",
    variant: "fas",
  },
  {
    name: "vials",
    class: "fas fa-vials",
    identifier: "fa-vials",
    type: "solid",
    variant: "fas",
  },
  {
    name: "video",
    class: "fas fa-video",
    identifier: "fa-video",
    type: "solid",
    variant: "fas",
  },
  {
    name: "video slash",
    class: "fas fa-video-slash",
    identifier: "fa-video-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "vihara",
    class: "fas fa-vihara",
    identifier: "fa-vihara",
    type: "solid",
    variant: "fas",
  },
  {
    name: "virus",
    class: "fas fa-virus",
    identifier: "fa-virus",
    type: "solid",
    variant: "fas",
  },
  {
    name: "virus slash",
    class: "fas fa-virus-slash",
    identifier: "fa-virus-slash",
    type: "solid",
    variant: "fas",
  },
  {
    name: "viruses",
    class: "fas fa-viruses",
    identifier: "fa-viruses",
    type: "solid",
    variant: "fas",
  },
  {
    name: "voicemail",
    class: "fas fa-voicemail",
    identifier: "fa-voicemail",
    type: "solid",
    variant: "fas",
  },
  {
    name: "volleyball ball",
    class: "fas fa-volleyball-ball",
    identifier: "fa-volleyball-ball",
    type: "solid",
    variant: "fas",
  },
  {
    name: "volume down",
    class: "fas fa-volume-down",
    identifier: "fa-volume-down",
    type: "solid",
    variant: "fas",
  },
  {
    name: "volume mute",
    class: "fas fa-volume-mute",
    identifier: "fa-volume-mute",
    type: "solid",
    variant: "fas",
  },
  {
    name: "volume off",
    class: "fas fa-volume-off",
    identifier: "fa-volume-off",
    type: "solid",
    variant: "fas",
  },
  {
    name: "volume up",
    class: "fas fa-volume-up",
    identifier: "fa-volume-up",
    type: "solid",
    variant: "fas",
  },
  {
    name: "vote yea",
    class: "fas fa-vote-yea",
    identifier: "fa-vote-yea",
    type: "solid",
    variant: "fas",
  },
  {
    name: "vr cardboard",
    class: "fas fa-vr-cardboard",
    identifier: "fa-vr-cardboard",
    type: "solid",
    variant: "fas",
  },
  {
    name: "walking",
    class: "fas fa-walking",
    identifier: "fa-walking",
    type: "solid",
    variant: "fas",
  },
  {
    name: "wallet",
    class: "fas fa-wallet",
    identifier: "fa-wallet",
    type: "solid",
    variant: "fas",
  },
  {
    name: "warehouse",
    class: "fas fa-warehouse",
    identifier: "fa-warehouse",
    type: "solid",
    variant: "fas",
  },
  {
    name: "water",
    class: "fas fa-water",
    identifier: "fa-water",
    type: "solid",
    variant: "fas",
  },
  {
    name: "wave square",
    class: "fas fa-wave-square",
    identifier: "fa-wave-square",
    type: "solid",
    variant: "fas",
  },
  {
    name: "weight",
    class: "fas fa-weight",
    identifier: "fa-weight",
    type: "solid",
    variant: "fas",
  },
  {
    name: "weight hanging",
    class: "fas fa-weight-hanging",
    identifier: "fa-weight-hanging",
    type: "solid",
    variant: "fas",
  },
  {
    name: "wheelchair",
    class: "fas fa-wheelchair",
    identifier: "fa-wheelchair",
    type: "solid",
    variant: "fas",
  },
  {
    name: "wifi",
    class: "fas fa-wifi",
    identifier: "fa-wifi",
    type: "solid",
    variant: "fas",
  },
  {
    name: "wind",
    class: "fas fa-wind",
    identifier: "fa-wind",
    type: "solid",
    variant: "fas",
  },
  {
    name: "window close",
    class: "fas fa-window-close",
    identifier: "fa-window-close",
    type: "solid",
    variant: "fas",
  },
  {
    name: "window maximize",
    class: "fas fa-window-maximize",
    identifier: "fa-window-maximize",
    type: "solid",
    variant: "fas",
  },
  {
    name: "window minimize",
    class: "fas fa-window-minimize",
    identifier: "fa-window-minimize",
    type: "solid",
    variant: "fas",
  },
  {
    name: "window restore",
    class: "fas fa-window-restore",
    identifier: "fa-window-restore",
    type: "solid",
    variant: "fas",
  },
  {
    name: "wine bottle",
    class: "fas fa-wine-bottle",
    identifier: "fa-wine-bottle",
    type: "solid",
    variant: "fas",
  },
  {
    name: "wine glass",
    class: "fas fa-wine-glass",
    identifier: "fa-wine-glass",
    type: "solid",
    variant: "fas",
  },
  {
    name: "wine glass-alt",
    class: "fas fa-wine-glass-alt",
    identifier: "fa-wine-glass-alt",
    type: "solid",
    variant: "fas",
  },
  {
    name: "won sign",
    class: "fas fa-won-sign",
    identifier: "fa-won-sign",
    type: "solid",
    variant: "fas",
  },
  {
    name: "wrench",
    class: "fas fa-wrench",
    identifier: "fa-wrench",
    type: "solid",
    variant: "fas",
  },
  {
    name: "x ray",
    class: "fas fa-x-ray",
    identifier: "fa-x-ray",
    type: "solid",
    variant: "fas",
  },
  {
    name: "yen sign",
    class: "fas fa-yen-sign",
    identifier: "fa-yen-sign",
    type: "solid",
    variant: "fas",
  },
  {
    name: "yin yang",
    class: "fas fa-yin-yang",
    identifier: "fa-yin-yang",
    type: "solid",
    variant: "fas",
  },
];
const regularFontAwesomeIcons = [
  {
    name: "address book",
    class: "far fa-address-book",
    identifier: "fa-address-book",
    type: "regular",
    variant: "far",
  },
  {
    name: "check-circle-o",
    class: "fa fa-check-circle-o",
    identifier: "fa-check-circle-o",
    type: "regular",
    variant: "fa",
  },
  {
    name: "address card",
    class: "far fa-address-card",
    identifier: "fa-address-card",
    type: "regular",
    variant: "far",
  },
  {
    name: "angry",
    class: "far fa-angry",
    identifier: "fa-angry",
    type: "regular",
    variant: "far",
  },
  {
    name: "arrow alt-circle-down",
    class: "far fa-arrow-alt-circle-down",
    identifier: "fa-arrow-alt-circle-down",
    type: "regular",
    variant: "far",
  },
  {
    name: "arrow alt-circle-left",
    class: "far fa-arrow-alt-circle-left",
    identifier: "fa-arrow-alt-circle-left",
    type: "regular",
    variant: "far",
  },
  {
    name: "arrow alt-circle-right",
    class: "far fa-arrow-alt-circle-right",
    identifier: "fa-arrow-alt-circle-right",
    type: "regular",
    variant: "far",
  },
  {
    name: "arrow alt-circle-up",
    class: "far fa-arrow-alt-circle-up",
    identifier: "fa-arrow-alt-circle-up",
    type: "regular",
    variant: "far",
  },
  {
    name: "bell",
    class: "far fa-bell",
    identifier: "fa-bell",
    type: "regular",
    variant: "far",
  },
  {
    name: "bell slash",
    class: "far fa-bell-slash",
    identifier: "fa-bell-slash",
    type: "regular",
    variant: "far",
  },
  {
    name: "bookmark",
    class: "far fa-bookmark",
    identifier: "fa-bookmark",
    type: "regular",
    variant: "far",
  },
  {
    name: "building",
    class: "far fa-building",
    identifier: "fa-building",
    type: "regular",
    variant: "far",
  },
  {
    name: "calendar",
    class: "far fa-calendar",
    identifier: "fa-calendar",
    type: "regular",
    variant: "far",
  },
  {
    name: "calendar alt",
    class: "far fa-calendar-alt",
    identifier: "fa-calendar-alt",
    type: "regular",
    variant: "far",
  },
  {
    name: "calendar check",
    class: "far fa-calendar-check",
    identifier: "fa-calendar-check",
    type: "regular",
    variant: "far",
  },
  {
    name: "calendar minus",
    class: "far fa-calendar-minus",
    identifier: "fa-calendar-minus",
    type: "regular",
    variant: "far",
  },
  {
    name: "calendar plus",
    class: "far fa-calendar-plus",
    identifier: "fa-calendar-plus",
    type: "regular",
    variant: "far",
  },
  {
    name: "calendar times",
    class: "far fa-calendar-times",
    identifier: "fa-calendar-times",
    type: "regular",
    variant: "far",
  },
  {
    name: "caret square-down",
    class: "far fa-caret-square-down",
    identifier: "fa-caret-square-down",
    type: "regular",
    variant: "far",
  },
  {
    name: "caret square-left",
    class: "far fa-caret-square-left",
    identifier: "fa-caret-square-left",
    type: "regular",
    variant: "far",
  },
  {
    name: "caret square-right",
    class: "far fa-caret-square-right",
    identifier: "fa-caret-square-right",
    type: "regular",
    variant: "far",
  },
  {
    name: "caret square-up",
    class: "far fa-caret-square-up",
    identifier: "fa-caret-square-up",
    type: "regular",
    variant: "far",
  },
  {
    name: "chart bar",
    class: "far fa-chart-bar",
    identifier: "fa-chart-bar",
    type: "regular",
    variant: "far",
  },
  {
    name: "check circle",
    class: "far fa-check-circle",
    identifier: "fa-check-circle",
    type: "regular",
    variant: "far",
  },
  {
    name: "check square",
    class: "far fa-check-square",
    identifier: "fa-check-square",
    type: "regular",
    variant: "far",
  },
  {
    name: "circle",
    class: "far fa-circle",
    identifier: "fa-circle",
    type: "regular",
    variant: "far",
  },
  {
    name: "clipboard",
    class: "far fa-clipboard",
    identifier: "fa-clipboard",
    type: "regular",
    variant: "far",
  },
  {
    name: "clock",
    class: "far fa-clock",
    identifier: "fa-clock",
    type: "regular",
    variant: "far",
  },
  {
    name: "clone",
    class: "far fa-clone",
    identifier: "fa-clone",
    type: "regular",
    variant: "far",
  },
  {
    name: "closed captioning",
    class: "far fa-closed-captioning",
    identifier: "fa-closed-captioning",
    type: "regular",
    variant: "far",
  },
  {
    name: "comment",
    class: "far fa-comment",
    identifier: "fa-comment",
    type: "regular",
    variant: "far",
  },
  {
    name: "comment alt",
    class: "far fa-comment-alt",
    identifier: "fa-comment-alt",
    type: "regular",
    variant: "far",
  },
  {
    name: "comment dots",
    class: "far fa-comment-dots",
    identifier: "fa-comment-dots",
    type: "regular",
    variant: "far",
  },
  {
    name: "comments",
    class: "far fa-comments",
    identifier: "fa-comments",
    type: "regular",
    variant: "far",
  },
  {
    name: "compass",
    class: "far fa-compass",
    identifier: "fa-compass",
    type: "regular",
    variant: "far",
  },
  {
    name: "copy",
    class: "far fa-copy",
    identifier: "fa-copy",
    type: "regular",
    variant: "far",
  },
  {
    name: "copyright",
    class: "far fa-copyright",
    identifier: "fa-copyright",
    type: "regular",
    variant: "far",
  },
  {
    name: "credit card",
    class: "far fa-credit-card",
    identifier: "fa-credit-card",
    type: "regular",
    variant: "far",
  },
  {
    name: "dizzy",
    class: "far fa-dizzy",
    identifier: "fa-dizzy",
    type: "regular",
    variant: "far",
  },
  {
    name: "dot circle",
    class: "far fa-dot-circle",
    identifier: "fa-dot-circle",
    type: "regular",
    variant: "far",
  },
  {
    name: "edit",
    class: "far fa-edit",
    identifier: "fa-edit",
    type: "regular",
    variant: "far",
  },
  {
    name: "envelope",
    class: "far fa-envelope",
    identifier: "fa-envelope",
    type: "regular",
    variant: "far",
  },
  {
    name: "envelope open",
    class: "far fa-envelope-open",
    identifier: "fa-envelope-open",
    type: "regular",
    variant: "far",
  },
  {
    name: "eye",
    class: "far fa-eye",
    identifier: "fa-eye",
    type: "regular",
    variant: "far",
  },
  {
    name: "eye slash",
    class: "far fa-eye-slash",
    identifier: "fa-eye-slash",
    type: "regular",
    variant: "far",
  },
  {
    name: "file",
    class: "far fa-file",
    identifier: "fa-file",
    type: "regular",
    variant: "far",
  },
  {
    name: "file alt",
    class: "far fa-file-alt",
    identifier: "fa-file-alt",
    type: "regular",
    variant: "far",
  },
  {
    name: "file archive",
    class: "far fa-file-archive",
    identifier: "fa-file-archive",
    type: "regular",
    variant: "far",
  },
  {
    name: "file audio",
    class: "far fa-file-audio",
    identifier: "fa-file-audio",
    type: "regular",
    variant: "far",
  },
  {
    name: "file code",
    class: "far fa-file-code",
    identifier: "fa-file-code",
    type: "regular",
    variant: "far",
  },
  {
    name: "file excel",
    class: "far fa-file-excel",
    identifier: "fa-file-excel",
    type: "regular",
    variant: "far",
  },
  {
    name: "file image",
    class: "far fa-file-image",
    identifier: "fa-file-image",
    type: "regular",
    variant: "far",
  },
  {
    name: "file pdf",
    class: "far fa-file-pdf",
    identifier: "fa-file-pdf",
    type: "regular",
    variant: "far",
  },
  {
    name: "file powerpoint",
    class: "far fa-file-powerpoint",
    identifier: "fa-file-powerpoint",
    type: "regular",
    variant: "far",
  },
  {
    name: "file video",
    class: "far fa-file-video",
    identifier: "fa-file-video",
    type: "regular",
    variant: "far",
  },
  {
    name: "file word",
    class: "far fa-file-word",
    identifier: "fa-file-word",
    type: "regular",
    variant: "far",
  },
  {
    name: "flag",
    class: "far fa-flag",
    identifier: "fa-flag",
    type: "regular",
    variant: "far",
  },
  {
    name: "flushed",
    class: "far fa-flushed",
    identifier: "fa-flushed",
    type: "regular",
    variant: "far",
  },
  {
    name: "folder",
    class: "far fa-folder",
    identifier: "fa-folder",
    type: "regular",
    variant: "far",
  },
  {
    name: "folder open",
    class: "far fa-folder-open",
    identifier: "fa-folder-open",
    type: "regular",
    variant: "far",
  },
  {
    name: "frown",
    class: "far fa-frown",
    identifier: "fa-frown",
    type: "regular",
    variant: "far",
  },
  {
    name: "frown open",
    class: "far fa-frown-open",
    identifier: "fa-frown-open",
    type: "regular",
    variant: "far",
  },
  {
    name: "futbol",
    class: "far fa-futbol",
    identifier: "fa-futbol",
    type: "regular",
    variant: "far",
  },
  {
    name: "gem",
    class: "far fa-gem",
    identifier: "fa-gem",
    type: "regular",
    variant: "far",
  },
  {
    name: "grimace",
    class: "far fa-grimace",
    identifier: "fa-grimace",
    type: "regular",
    variant: "far",
  },
  {
    name: "grin",
    class: "far fa-grin",
    identifier: "fa-grin",
    type: "regular",
    variant: "far",
  },
  {
    name: "grin alt",
    class: "far fa-grin-alt",
    identifier: "fa-grin-alt",
    type: "regular",
    variant: "far",
  },
  {
    name: "grin beam",
    class: "far fa-grin-beam",
    identifier: "fa-grin-beam",
    type: "regular",
    variant: "far",
  },
  {
    name: "grin beam-sweat",
    class: "far fa-grin-beam-sweat",
    identifier: "fa-grin-beam-sweat",
    type: "regular",
    variant: "far",
  },
  {
    name: "grin hearts",
    class: "far fa-grin-hearts",
    identifier: "fa-grin-hearts",
    type: "regular",
    variant: "far",
  },
  {
    name: "grin squint",
    class: "far fa-grin-squint",
    identifier: "fa-grin-squint",
    type: "regular",
    variant: "far",
  },
  {
    name: "grin squint-tears",
    class: "far fa-grin-squint-tears",
    identifier: "fa-grin-squint-tears",
    type: "regular",
    variant: "far",
  },
  {
    name: "grin stars",
    class: "far fa-grin-stars",
    identifier: "fa-grin-stars",
    type: "regular",
    variant: "far",
  },
  {
    name: "grin tears",
    class: "far fa-grin-tears",
    identifier: "fa-grin-tears",
    type: "regular",
    variant: "far",
  },
  {
    name: "grin tongue",
    class: "far fa-grin-tongue",
    identifier: "fa-grin-tongue",
    type: "regular",
    variant: "far",
  },
  {
    name: "grin tongue-squint",
    class: "far fa-grin-tongue-squint",
    identifier: "fa-grin-tongue-squint",
    type: "regular",
    variant: "far",
  },
  {
    name: "grin tongue-wink",
    class: "far fa-grin-tongue-wink",
    identifier: "fa-grin-tongue-wink",
    type: "regular",
    variant: "far",
  },
  {
    name: "grin wink",
    class: "far fa-grin-wink",
    identifier: "fa-grin-wink",
    type: "regular",
    variant: "far",
  },
  {
    name: "hand lizard",
    class: "far fa-hand-lizard",
    identifier: "fa-hand-lizard",
    type: "regular",
    variant: "far",
  },
  {
    name: "hand paper",
    class: "far fa-hand-paper",
    identifier: "fa-hand-paper",
    type: "regular",
    variant: "far",
  },
  {
    name: "hand peace",
    class: "far fa-hand-peace",
    identifier: "fa-hand-peace",
    type: "regular",
    variant: "far",
  },
  {
    name: "hand point-down",
    class: "far fa-hand-point-down",
    identifier: "fa-hand-point-down",
    type: "regular",
    variant: "far",
  },
  {
    name: "hand point-left",
    class: "far fa-hand-point-left",
    identifier: "fa-hand-point-left",
    type: "regular",
    variant: "far",
  },
  {
    name: "hand point-right",
    class: "far fa-hand-point-right",
    identifier: "fa-hand-point-right",
    type: "regular",
    variant: "far",
  },
  {
    name: "hand point-up",
    class: "far fa-hand-point-up",
    identifier: "fa-hand-point-up",
    type: "regular",
    variant: "far",
  },
  {
    name: "hand pointer",
    class: "far fa-hand-pointer",
    identifier: "fa-hand-pointer",
    type: "regular",
    variant: "far",
  },
  {
    name: "hand rock",
    class: "far fa-hand-rock",
    identifier: "fa-hand-rock",
    type: "regular",
    variant: "far",
  },
  {
    name: "hand scissors",
    class: "far fa-hand-scissors",
    identifier: "fa-hand-scissors",
    type: "regular",
    variant: "far",
  },
  {
    name: "hand spock",
    class: "far fa-hand-spock",
    identifier: "fa-hand-spock",
    type: "regular",
    variant: "far",
  },
  {
    name: "handshake",
    class: "far fa-handshake",
    identifier: "fa-handshake",
    type: "regular",
    variant: "far",
  },
  {
    name: "hdd",
    class: "far fa-hdd",
    identifier: "fa-hdd",
    type: "regular",
    variant: "far",
  },
  {
    name: "heart",
    class: "far fa-heart",
    identifier: "fa-heart",
    type: "regular",
    variant: "far",
  },
  {
    name: "hospital",
    class: "far fa-hospital",
    identifier: "fa-hospital",
    type: "regular",
    variant: "far",
  },
  {
    name: "hourglass",
    class: "far fa-hourglass",
    identifier: "fa-hourglass",
    type: "regular",
    variant: "far",
  },
  {
    name: "id badge",
    class: "far fa-id-badge",
    identifier: "fa-id-badge",
    type: "regular",
    variant: "far",
  },
  {
    name: "id card",
    class: "far fa-id-card",
    identifier: "fa-id-card",
    type: "regular",
    variant: "far",
  },
  {
    name: "image",
    class: "far fa-image",
    identifier: "fa-image",
    type: "regular",
    variant: "far",
  },
  {
    name: "images",
    class: "far fa-images",
    identifier: "fa-images",
    type: "regular",
    variant: "far",
  },
  {
    name: "keyboard",
    class: "far fa-keyboard",
    identifier: "fa-keyboard",
    type: "regular",
    variant: "far",
  },
  {
    name: "kiss",
    class: "far fa-kiss",
    identifier: "fa-kiss",
    type: "regular",
    variant: "far",
  },
  {
    name: "kiss beam",
    class: "far fa-kiss-beam",
    identifier: "fa-kiss-beam",
    type: "regular",
    variant: "far",
  },
  {
    name: "kiss wink-heart",
    class: "far fa-kiss-wink-heart",
    identifier: "fa-kiss-wink-heart",
    type: "regular",
    variant: "far",
  },
  {
    name: "laugh",
    class: "far fa-laugh",
    identifier: "fa-laugh",
    type: "regular",
    variant: "far",
  },
  {
    name: "laugh beam",
    class: "far fa-laugh-beam",
    identifier: "fa-laugh-beam",
    type: "regular",
    variant: "far",
  },
  {
    name: "laugh squint",
    class: "far fa-laugh-squint",
    identifier: "fa-laugh-squint",
    type: "regular",
    variant: "far",
  },
  {
    name: "laugh wink",
    class: "far fa-laugh-wink",
    identifier: "fa-laugh-wink",
    type: "regular",
    variant: "far",
  },
  {
    name: "lemon",
    class: "far fa-lemon",
    identifier: "fa-lemon",
    type: "regular",
    variant: "far",
  },
  {
    name: "life ring",
    class: "far fa-life-ring",
    identifier: "fa-life-ring",
    type: "regular",
    variant: "far",
  },
  {
    name: "lightbulb",
    class: "far fa-lightbulb",
    identifier: "fa-lightbulb",
    type: "regular",
    variant: "far",
  },
  {
    name: "list alt",
    class: "far fa-list-alt",
    identifier: "fa-list-alt",
    type: "regular",
    variant: "far",
  },
  {
    name: "map",
    class: "far fa-map",
    identifier: "fa-map",
    type: "regular",
    variant: "far",
  },
  {
    name: "meh",
    class: "far fa-meh",
    identifier: "fa-meh",
    type: "regular",
    variant: "far",
  },
  {
    name: "meh blank",
    class: "far fa-meh-blank",
    identifier: "fa-meh-blank",
    type: "regular",
    variant: "far",
  },
  {
    name: "meh rolling-eyes",
    class: "far fa-meh-rolling-eyes",
    identifier: "fa-meh-rolling-eyes",
    type: "regular",
    variant: "far",
  },
  {
    name: "minus square",
    class: "far fa-minus-square",
    identifier: "fa-minus-square",
    type: "regular",
    variant: "far",
  },
  {
    name: "money bill-alt",
    class: "far fa-money-bill-alt",
    identifier: "fa-money-bill-alt",
    type: "regular",
    variant: "far",
  },
  {
    name: "moon",
    class: "far fa-moon",
    identifier: "fa-moon",
    type: "regular",
    variant: "far",
  },
  {
    name: "newspaper",
    class: "far fa-newspaper",
    identifier: "fa-newspaper",
    type: "regular",
    variant: "far",
  },
  {
    name: "object group",
    class: "far fa-object-group",
    identifier: "fa-object-group",
    type: "regular",
    variant: "far",
  },
  {
    name: "object ungroup",
    class: "far fa-object-ungroup",
    identifier: "fa-object-ungroup",
    type: "regular",
    variant: "far",
  },
  {
    name: "paper plane",
    class: "far fa-paper-plane",
    identifier: "fa-paper-plane",
    type: "regular",
    variant: "far",
  },
  {
    name: "pause circle",
    class: "far fa-pause-circle",
    identifier: "fa-pause-circle",
    type: "regular",
    variant: "far",
  },
  {
    name: "play circle",
    class: "far fa-play-circle",
    identifier: "fa-play-circle",
    type: "regular",
    variant: "far",
  },
  {
    name: "plus square",
    class: "far fa-plus-square",
    identifier: "fa-plus-square",
    type: "regular",
    variant: "far",
  },
  {
    name: "question circle",
    class: "far fa-question-circle",
    identifier: "fa-question-circle",
    type: "regular",
    variant: "far",
  },
  {
    name: "registered",
    class: "far fa-registered",
    identifier: "fa-registered",
    type: "regular",
    variant: "far",
  },
  {
    name: "sad cry",
    class: "far fa-sad-cry",
    identifier: "fa-sad-cry",
    type: "regular",
    variant: "far",
  },
  {
    name: "sad tear",
    class: "far fa-sad-tear",
    identifier: "fa-sad-tear",
    type: "regular",
    variant: "far",
  },
  {
    name: "save",
    class: "far fa-save",
    identifier: "fa-save",
    type: "regular",
    variant: "far",
  },
  {
    name: "share square",
    class: "far fa-share-square",
    identifier: "fa-share-square",
    type: "regular",
    variant: "far",
  },
  {
    name: "smile",
    class: "far fa-smile",
    identifier: "fa-smile",
    type: "regular",
    variant: "far",
  },
  {
    name: "smile beam",
    class: "far fa-smile-beam",
    identifier: "fa-smile-beam",
    type: "regular",
    variant: "far",
  },
  {
    name: "smile wink",
    class: "far fa-smile-wink",
    identifier: "fa-smile-wink",
    type: "regular",
    variant: "far",
  },
  {
    name: "snowflake",
    class: "far fa-snowflake",
    identifier: "fa-snowflake",
    type: "regular",
    variant: "far",
  },
  {
    name: "square",
    class: "far fa-square",
    identifier: "fa-square",
    type: "regular",
    variant: "far",
  },
  {
    name: "star",
    class: "far fa-star",
    identifier: "fa-star",
    type: "regular",
    variant: "far",
  },
  {
    name: "star half",
    class: "far fa-star-half",
    identifier: "fa-star-half",
    type: "regular",
    variant: "far",
  },
  {
    name: "sticky note",
    class: "far fa-sticky-note",
    identifier: "fa-sticky-note",
    type: "regular",
    variant: "far",
  },
  {
    name: "stop circle",
    class: "far fa-stop-circle",
    identifier: "fa-stop-circle",
    type: "regular",
    variant: "far",
  },
  {
    name: "sun",
    class: "far fa-sun",
    identifier: "fa-sun",
    type: "regular",
    variant: "far",
  },
  {
    name: "surprise",
    class: "far fa-surprise",
    identifier: "fa-surprise",
    type: "regular",
    variant: "far",
  },
  {
    name: "thumbs down",
    class: "far fa-thumbs-down",
    identifier: "fa-thumbs-down",
    type: "regular",
    variant: "far",
  },
  {
    name: "thumbs up",
    class: "far fa-thumbs-up",
    identifier: "fa-thumbs-up",
    type: "regular",
    variant: "far",
  },
  {
    name: "times circle",
    class: "far fa-times-circle",
    identifier: "fa-times-circle",
    type: "regular",
    variant: "far",
  },
  {
    name: "tired",
    class: "far fa-tired",
    identifier: "fa-tired",
    type: "regular",
    variant: "far",
  },
  {
    name: "trash alt",
    class: "far fa-trash-alt",
    identifier: "fa-trash-alt",
    type: "regular",
    variant: "far",
  },
  {
    name: "user",
    class: "far fa-user",
    identifier: "fa-user",
    type: "regular",
    variant: "far",
  },
  {
    name: "user circle",
    class: "far fa-user-circle",
    identifier: "fa-user-circle",
    type: "regular",
    variant: "far",
  },
  {
    name: "window close",
    class: "far fa-window-close",
    identifier: "fa-window-close",
    type: "regular",
    variant: "far",
  },
  {
    name: "window maximize",
    class: "far fa-window-maximize",
    identifier: "fa-window-maximize",
    type: "regular",
    variant: "far",
  },
  {
    name: "window minimize",
    class: "far fa-window-minimize",
    identifier: "fa-window-minimize",
    type: "regular",
    variant: "far",
  },
  {
    name: "window restore",
    class: "far fa-window-restore",
    identifier: "fa-window-restore",
    type: "regular",
    variant: "far",
  },
];
const brandsFontAwesomeIcons = [
  {
    name: "500px",
    class: "fab fa-500px",
    identifier: "fa-500px",
    type: "brands",
    variant: "fab",
  },
  {
    name: "accessible icon",
    class: "fab fa-accessible-icon",
    identifier: "fa-accessible-icon",
    type: "brands",
    variant: "fab",
  },
  {
    name: "accusoft",
    class: "fab fa-accusoft",
    identifier: "fa-accusoft",
    type: "brands",
    variant: "fab",
  },
  {
    name: "acquisitions incorporated",
    class: "fab fa-acquisitions-incorporated",
    identifier: "fa-acquisitions-incorporated",
    type: "brands",
    variant: "fab",
  },
  {
    name: "adn",
    class: "fab fa-adn",
    identifier: "fa-adn",
    type: "brands",
    variant: "fab",
  },
  {
    name: "adversal",
    class: "fab fa-adversal",
    identifier: "fa-adversal",
    type: "brands",
    variant: "fab",
  },
  {
    name: "affiliatetheme",
    class: "fab fa-affiliatetheme",
    identifier: "fa-affiliatetheme",
    type: "brands",
    variant: "fab",
  },
  {
    name: "airbnb",
    class: "fab fa-airbnb",
    identifier: "fa-airbnb",
    type: "brands",
    variant: "fab",
  },
  {
    name: "algolia",
    class: "fab fa-algolia",
    identifier: "fa-algolia",
    type: "brands",
    variant: "fab",
  },
  {
    name: "alipay",
    class: "fab fa-alipay",
    identifier: "fa-alipay",
    type: "brands",
    variant: "fab",
  },
  {
    name: "amazon",
    class: "fab fa-amazon",
    identifier: "fa-amazon",
    type: "brands",
    variant: "fab",
  },
  {
    name: "amazon pay",
    class: "fab fa-amazon-pay",
    identifier: "fa-amazon-pay",
    type: "brands",
    variant: "fab",
  },
  {
    name: "amilia",
    class: "fab fa-amilia",
    identifier: "fa-amilia",
    type: "brands",
    variant: "fab",
  },
  {
    name: "android",
    class: "fab fa-android",
    identifier: "fa-android",
    type: "brands",
    variant: "fab",
  },
  {
    name: "angellist",
    class: "fab fa-angellist",
    identifier: "fa-angellist",
    type: "brands",
    variant: "fab",
  },
  {
    name: "angrycreative",
    class: "fab fa-angrycreative",
    identifier: "fa-angrycreative",
    type: "brands",
    variant: "fab",
  },
  {
    name: "angular",
    class: "fab fa-angular",
    identifier: "fa-angular",
    type: "brands",
    variant: "fab",
  },
  {
    name: "app store",
    class: "fab fa-app-store",
    identifier: "fa-app-store",
    type: "brands",
    variant: "fab",
  },
  {
    name: "app store-ios",
    class: "fab fa-app-store-ios",
    identifier: "fa-app-store-ios",
    type: "brands",
    variant: "fab",
  },
  {
    name: "apper",
    class: "fab fa-apper",
    identifier: "fa-apper",
    type: "brands",
    variant: "fab",
  },
  {
    name: "apple",
    class: "fab fa-apple",
    identifier: "fa-apple",
    type: "brands",
    variant: "fab",
  },
  {
    name: "apple pay",
    class: "fab fa-apple-pay",
    identifier: "fa-apple-pay",
    type: "brands",
    variant: "fab",
  },
  {
    name: "artstation",
    class: "fab fa-artstation",
    identifier: "fa-artstation",
    type: "brands",
    variant: "fab",
  },
  {
    name: "asymmetrik",
    class: "fab fa-asymmetrik",
    identifier: "fa-asymmetrik",
    type: "brands",
    variant: "fab",
  },
  {
    name: "atlassian",
    class: "fab fa-atlassian",
    identifier: "fa-atlassian",
    type: "brands",
    variant: "fab",
  },
  {
    name: "audible",
    class: "fab fa-audible",
    identifier: "fa-audible",
    type: "brands",
    variant: "fab",
  },
  {
    name: "autoprefixer",
    class: "fab fa-autoprefixer",
    identifier: "fa-autoprefixer",
    type: "brands",
    variant: "fab",
  },
  {
    name: "avianex",
    class: "fab fa-avianex",
    identifier: "fa-avianex",
    type: "brands",
    variant: "fab",
  },
  {
    name: "aviato",
    class: "fab fa-aviato",
    identifier: "fa-aviato",
    type: "brands",
    variant: "fab",
  },
  {
    name: "aws",
    class: "fab fa-aws",
    identifier: "fa-aws",
    type: "brands",
    variant: "fab",
  },
  {
    name: "bandcamp",
    class: "fab fa-bandcamp",
    identifier: "fa-bandcamp",
    type: "brands",
    variant: "fab",
  },
  {
    name: "battle net",
    class: "fab fa-battle-net",
    identifier: "fa-battle-net",
    type: "brands",
    variant: "fab",
  },
  {
    name: "behance",
    class: "fab fa-behance",
    identifier: "fa-behance",
    type: "brands",
    variant: "fab",
  },
  {
    name: "behance square",
    class: "fab fa-behance-square",
    identifier: "fa-behance-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "bimobject",
    class: "fab fa-bimobject",
    identifier: "fa-bimobject",
    type: "brands",
    variant: "fab",
  },
  {
    name: "bitbucket",
    class: "fab fa-bitbucket",
    identifier: "fa-bitbucket",
    type: "brands",
    variant: "fab",
  },
  {
    name: "bitcoin",
    class: "fab fa-bitcoin",
    identifier: "fa-bitcoin",
    type: "brands",
    variant: "fab",
  },
  {
    name: "bity",
    class: "fab fa-bity",
    identifier: "fa-bity",
    type: "brands",
    variant: "fab",
  },
  {
    name: "black tie",
    class: "fab fa-black-tie",
    identifier: "fa-black-tie",
    type: "brands",
    variant: "fab",
  },
  {
    name: "blackberry",
    class: "fab fa-blackberry",
    identifier: "fa-blackberry",
    type: "brands",
    variant: "fab",
  },
  {
    name: "blogger",
    class: "fab fa-blogger",
    identifier: "fa-blogger",
    type: "brands",
    variant: "fab",
  },
  {
    name: "blogger b",
    class: "fab fa-blogger-b",
    identifier: "fa-blogger-b",
    type: "brands",
    variant: "fab",
  },
  {
    name: "bluetooth",
    class: "fab fa-bluetooth",
    identifier: "fa-bluetooth",
    type: "brands",
    variant: "fab",
  },
  {
    name: "bluetooth b",
    class: "fab fa-bluetooth-b",
    identifier: "fa-bluetooth-b",
    type: "brands",
    variant: "fab",
  },
  {
    name: "bootstrap",
    class: "fab fa-bootstrap",
    identifier: "fa-bootstrap",
    type: "brands",
    variant: "fab",
  },
  {
    name: "btc",
    class: "fab fa-btc",
    identifier: "fa-btc",
    type: "brands",
    variant: "fab",
  },
  {
    name: "buffer",
    class: "fab fa-buffer",
    identifier: "fa-buffer",
    type: "brands",
    variant: "fab",
  },
  {
    name: "buromobelexperte",
    class: "fab fa-buromobelexperte",
    identifier: "fa-buromobelexperte",
    type: "brands",
    variant: "fab",
  },
  {
    name: "buy n-large",
    class: "fab fa-buy-n-large",
    identifier: "fa-buy-n-large",
    type: "brands",
    variant: "fab",
  },
  {
    name: "buysellads",
    class: "fab fa-buysellads",
    identifier: "fa-buysellads",
    type: "brands",
    variant: "fab",
  },
  {
    name: "canadian maple-leaf",
    class: "fab fa-canadian-maple-leaf",
    identifier: "fa-canadian-maple-leaf",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cc amazon-pay",
    class: "fab fa-cc-amazon-pay",
    identifier: "fa-cc-amazon-pay",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cc amex",
    class: "fab fa-cc-amex",
    identifier: "fa-cc-amex",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cc apple-pay",
    class: "fab fa-cc-apple-pay",
    identifier: "fa-cc-apple-pay",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cc diners-club",
    class: "fab fa-cc-diners-club",
    identifier: "fa-cc-diners-club",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cc discover",
    class: "fab fa-cc-discover",
    identifier: "fa-cc-discover",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cc jcb",
    class: "fab fa-cc-jcb",
    identifier: "fa-cc-jcb",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cc mastercard",
    class: "fab fa-cc-mastercard",
    identifier: "fa-cc-mastercard",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cc paypal",
    class: "fab fa-cc-paypal",
    identifier: "fa-cc-paypal",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cc stripe",
    class: "fab fa-cc-stripe",
    identifier: "fa-cc-stripe",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cc visa",
    class: "fab fa-cc-visa",
    identifier: "fa-cc-visa",
    type: "brands",
    variant: "fab",
  },
  {
    name: "centercode",
    class: "fab fa-centercode",
    identifier: "fa-centercode",
    type: "brands",
    variant: "fab",
  },
  {
    name: "centos",
    class: "fab fa-centos",
    identifier: "fa-centos",
    type: "brands",
    variant: "fab",
  },
  {
    name: "chrome",
    class: "fab fa-chrome",
    identifier: "fa-chrome",
    type: "brands",
    variant: "fab",
  },
  {
    name: "chromecast",
    class: "fab fa-chromecast",
    identifier: "fa-chromecast",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cloudflare",
    class: "fab fa-cloudflare",
    identifier: "fa-cloudflare",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cloudscale",
    class: "fab fa-cloudscale",
    identifier: "fa-cloudscale",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cloudsmith",
    class: "fab fa-cloudsmith",
    identifier: "fa-cloudsmith",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cloudversify",
    class: "fab fa-cloudversify",
    identifier: "fa-cloudversify",
    type: "brands",
    variant: "fab",
  },
  {
    name: "codepen",
    class: "fab fa-codepen",
    identifier: "fa-codepen",
    type: "brands",
    variant: "fab",
  },
  {
    name: "codiepie",
    class: "fab fa-codiepie",
    identifier: "fa-codiepie",
    type: "brands",
    variant: "fab",
  },
  {
    name: "confluence",
    class: "fab fa-confluence",
    identifier: "fa-confluence",
    type: "brands",
    variant: "fab",
  },
  {
    name: "connectdevelop",
    class: "fab fa-connectdevelop",
    identifier: "fa-connectdevelop",
    type: "brands",
    variant: "fab",
  },
  {
    name: "contao",
    class: "fab fa-contao",
    identifier: "fa-contao",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cotton bureau",
    class: "fab fa-cotton-bureau",
    identifier: "fa-cotton-bureau",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cpanel",
    class: "fab fa-cpanel",
    identifier: "fa-cpanel",
    type: "brands",
    variant: "fab",
  },
  {
    name: "creative commons",
    class: "fab fa-creative-commons",
    identifier: "fa-creative-commons",
    type: "brands",
    variant: "fab",
  },
  {
    name: "creative commons-by",
    class: "fab fa-creative-commons-by",
    identifier: "fa-creative-commons-by",
    type: "brands",
    variant: "fab",
  },
  {
    name: "creative commons-nc",
    class: "fab fa-creative-commons-nc",
    identifier: "fa-creative-commons-nc",
    type: "brands",
    variant: "fab",
  },
  {
    name: "creative commons-nc-eu",
    class: "fab fa-creative-commons-nc-eu",
    identifier: "fa-creative-commons-nc-eu",
    type: "brands",
    variant: "fab",
  },
  {
    name: "creative commons-nc-jp",
    class: "fab fa-creative-commons-nc-jp",
    identifier: "fa-creative-commons-nc-jp",
    type: "brands",
    variant: "fab",
  },
  {
    name: "creative commons-nd",
    class: "fab fa-creative-commons-nd",
    identifier: "fa-creative-commons-nd",
    type: "brands",
    variant: "fab",
  },
  {
    name: "creative commons-pd",
    class: "fab fa-creative-commons-pd",
    identifier: "fa-creative-commons-pd",
    type: "brands",
    variant: "fab",
  },
  {
    name: "creative commons-pd-alt",
    class: "fab fa-creative-commons-pd-alt",
    identifier: "fa-creative-commons-pd-alt",
    type: "brands",
    variant: "fab",
  },
  {
    name: "creative commons-remix",
    class: "fab fa-creative-commons-remix",
    identifier: "fa-creative-commons-remix",
    type: "brands",
    variant: "fab",
  },
  {
    name: "creative commons-sa",
    class: "fab fa-creative-commons-sa",
    identifier: "fa-creative-commons-sa",
    type: "brands",
    variant: "fab",
  },
  {
    name: "creative commons-sampling",
    class: "fab fa-creative-commons-sampling",
    identifier: "fa-creative-commons-sampling",
    type: "brands",
    variant: "fab",
  },
  {
    name: "creative commons-sampling-plus",
    class: "fab fa-creative-commons-sampling-plus",
    identifier: "fa-creative-commons-sampling-plus",
    type: "brands",
    variant: "fab",
  },
  {
    name: "creative commons-share",
    class: "fab fa-creative-commons-share",
    identifier: "fa-creative-commons-share",
    type: "brands",
    variant: "fab",
  },
  {
    name: "creative commons-zero",
    class: "fab fa-creative-commons-zero",
    identifier: "fa-creative-commons-zero",
    type: "brands",
    variant: "fab",
  },
  {
    name: "critical role",
    class: "fab fa-critical-role",
    identifier: "fa-critical-role",
    type: "brands",
    variant: "fab",
  },
  {
    name: "css3",
    class: "fab fa-css3",
    identifier: "fa-css3",
    type: "brands",
    variant: "fab",
  },
  {
    name: "css3 alt",
    class: "fab fa-css3-alt",
    identifier: "fa-css3-alt",
    type: "brands",
    variant: "fab",
  },
  {
    name: "cuttlefish",
    class: "fab fa-cuttlefish",
    identifier: "fa-cuttlefish",
    type: "brands",
    variant: "fab",
  },
  {
    name: "d and-d",
    class: "fab fa-d-and-d",
    identifier: "fa-d-and-d",
    type: "brands",
    variant: "fab",
  },
  {
    name: "d and-d-beyond",
    class: "fab fa-d-and-d-beyond",
    identifier: "fa-d-and-d-beyond",
    type: "brands",
    variant: "fab",
  },
  {
    name: "dailymotion",
    class: "fab fa-dailymotion",
    identifier: "fa-dailymotion",
    type: "brands",
    variant: "fab",
  },
  {
    name: "dashcube",
    class: "fab fa-dashcube",
    identifier: "fa-dashcube",
    type: "brands",
    variant: "fab",
  },
  {
    name: "deezer",
    class: "fab fa-deezer",
    identifier: "fa-deezer",
    type: "brands",
    variant: "fab",
  },
  {
    name: "delicious",
    class: "fab fa-delicious",
    identifier: "fa-delicious",
    type: "brands",
    variant: "fab",
  },
  {
    name: "deploydog",
    class: "fab fa-deploydog",
    identifier: "fa-deploydog",
    type: "brands",
    variant: "fab",
  },
  {
    name: "deskpro",
    class: "fab fa-deskpro",
    identifier: "fa-deskpro",
    type: "brands",
    variant: "fab",
  },
  {
    name: "dev",
    class: "fab fa-dev",
    identifier: "fa-dev",
    type: "brands",
    variant: "fab",
  },
  {
    name: "deviantart",
    class: "fab fa-deviantart",
    identifier: "fa-deviantart",
    type: "brands",
    variant: "fab",
  },
  {
    name: "dhl",
    class: "fab fa-dhl",
    identifier: "fa-dhl",
    type: "brands",
    variant: "fab",
  },
  {
    name: "diaspora",
    class: "fab fa-diaspora",
    identifier: "fa-diaspora",
    type: "brands",
    variant: "fab",
  },
  {
    name: "digg",
    class: "fab fa-digg",
    identifier: "fa-digg",
    type: "brands",
    variant: "fab",
  },
  {
    name: "digital ocean",
    class: "fab fa-digital-ocean",
    identifier: "fa-digital-ocean",
    type: "brands",
    variant: "fab",
  },
  {
    name: "discord",
    class: "fab fa-discord",
    identifier: "fa-discord",
    type: "brands",
    variant: "fab",
  },
  {
    name: "discourse",
    class: "fab fa-discourse",
    identifier: "fa-discourse",
    type: "brands",
    variant: "fab",
  },
  {
    name: "dochub",
    class: "fab fa-dochub",
    identifier: "fa-dochub",
    type: "brands",
    variant: "fab",
  },
  {
    name: "docker",
    class: "fab fa-docker",
    identifier: "fa-docker",
    type: "brands",
    variant: "fab",
  },
  {
    name: "draft2digital",
    class: "fab fa-draft2digital",
    identifier: "fa-draft2digital",
    type: "brands",
    variant: "fab",
  },
  {
    name: "dribbble",
    class: "fab fa-dribbble",
    identifier: "fa-dribbble",
    type: "brands",
    variant: "fab",
  },
  {
    name: "dribbble square",
    class: "fab fa-dribbble-square",
    identifier: "fa-dribbble-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "dropbox",
    class: "fab fa-dropbox",
    identifier: "fa-dropbox",
    type: "brands",
    variant: "fab",
  },
  {
    name: "drupal",
    class: "fab fa-drupal",
    identifier: "fa-drupal",
    type: "brands",
    variant: "fab",
  },
  {
    name: "dyalog",
    class: "fab fa-dyalog",
    identifier: "fa-dyalog",
    type: "brands",
    variant: "fab",
  },
  {
    name: "earlybirds",
    class: "fab fa-earlybirds",
    identifier: "fa-earlybirds",
    type: "brands",
    variant: "fab",
  },
  {
    name: "ebay",
    class: "fab fa-ebay",
    identifier: "fa-ebay",
    type: "brands",
    variant: "fab",
  },
  {
    name: "edge",
    class: "fab fa-edge",
    identifier: "fa-edge",
    type: "brands",
    variant: "fab",
  },
  {
    name: "edge legacy",
    class: "fab fa-edge-legacy",
    identifier: "fa-edge-legacy",
    type: "brands",
    variant: "fab",
  },
  {
    name: "elementor",
    class: "fab fa-elementor",
    identifier: "fa-elementor",
    type: "brands",
    variant: "fab",
  },
  {
    name: "ello",
    class: "fab fa-ello",
    identifier: "fa-ello",
    type: "brands",
    variant: "fab",
  },
  {
    name: "ember",
    class: "fab fa-ember",
    identifier: "fa-ember",
    type: "brands",
    variant: "fab",
  },
  {
    name: "empire",
    class: "fab fa-empire",
    identifier: "fa-empire",
    type: "brands",
    variant: "fab",
  },
  {
    name: "envira",
    class: "fab fa-envira",
    identifier: "fa-envira",
    type: "brands",
    variant: "fab",
  },
  {
    name: "erlang",
    class: "fab fa-erlang",
    identifier: "fa-erlang",
    type: "brands",
    variant: "fab",
  },
  {
    name: "ethereum",
    class: "fab fa-ethereum",
    identifier: "fa-ethereum",
    type: "brands",
    variant: "fab",
  },
  {
    name: "etsy",
    class: "fab fa-etsy",
    identifier: "fa-etsy",
    type: "brands",
    variant: "fab",
  },
  {
    name: "evernote",
    class: "fab fa-evernote",
    identifier: "fa-evernote",
    type: "brands",
    variant: "fab",
  },
  {
    name: "expeditedssl",
    class: "fab fa-expeditedssl",
    identifier: "fa-expeditedssl",
    type: "brands",
    variant: "fab",
  },
  {
    name: "facebook",
    class: "fab fa-facebook",
    identifier: "fa-facebook",
    type: "brands",
    variant: "fab",
  },
  {
    name: "facebook f",
    class: "fab fa-facebook-f",
    identifier: "fa-facebook-f",
    type: "brands",
    variant: "fab",
  },
  {
    name: "facebook messenger",
    class: "fab fa-facebook-messenger",
    identifier: "fa-facebook-messenger",
    type: "brands",
    variant: "fab",
  },
  {
    name: "facebook square",
    class: "fab fa-facebook-square",
    identifier: "fa-facebook-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "facebook square",
    class: "fa-brands fa-square-facebook",
    identifier: "fa-square-facebook",
    type: "brands",
    variant: "fa-brands",
  },
  {
    name: "fantasy flight-games",
    class: "fab fa-fantasy-flight-games",
    identifier: "fa-fantasy-flight-games",
    type: "brands",
    variant: "fab",
  },
  {
    name: "fedex",
    class: "fab fa-fedex",
    identifier: "fa-fedex",
    type: "brands",
    variant: "fab",
  },
  {
    name: "fedora",
    class: "fab fa-fedora",
    identifier: "fa-fedora",
    type: "brands",
    variant: "fab",
  },
  {
    name: "figma",
    class: "fab fa-figma",
    identifier: "fa-figma",
    type: "brands",
    variant: "fab",
  },
  {
    name: "firefox",
    class: "fab fa-firefox",
    identifier: "fa-firefox",
    type: "brands",
    variant: "fab",
  },
  {
    name: "firefox browser",
    class: "fab fa-firefox-browser",
    identifier: "fa-firefox-browser",
    type: "brands",
    variant: "fab",
  },
  {
    name: "first order",
    class: "fab fa-first-order",
    identifier: "fa-first-order",
    type: "brands",
    variant: "fab",
  },
  {
    name: "first order-alt",
    class: "fab fa-first-order-alt",
    identifier: "fa-first-order-alt",
    type: "brands",
    variant: "fab",
  },
  {
    name: "firstdraft",
    class: "fab fa-firstdraft",
    identifier: "fa-firstdraft",
    type: "brands",
    variant: "fab",
  },
  {
    name: "flickr",
    class: "fab fa-flickr",
    identifier: "fa-flickr",
    type: "brands",
    variant: "fab",
  },
  {
    name: "flipboard",
    class: "fab fa-flipboard",
    identifier: "fa-flipboard",
    type: "brands",
    variant: "fab",
  },
  {
    name: "fly",
    class: "fab fa-fly",
    identifier: "fa-fly",
    type: "brands",
    variant: "fab",
  },
  {
    name: "font awesome",
    class: "fab fa-font-awesome",
    identifier: "fa-font-awesome",
    type: "brands",
    variant: "fab",
  },
  {
    name: "font awesome-alt",
    class: "fab fa-font-awesome-alt",
    identifier: "fa-font-awesome-alt",
    type: "brands",
    variant: "fab",
  },
  {
    name: "font awesome-flag",
    class: "fab fa-font-awesome-flag",
    identifier: "fa-font-awesome-flag",
    type: "brands",
    variant: "fab",
  },
  {
    name: "fonticons",
    class: "fab fa-fonticons",
    identifier: "fa-fonticons",
    type: "brands",
    variant: "fab",
  },
  {
    name: "fonticons fi",
    class: "fab fa-fonticons-fi",
    identifier: "fa-fonticons-fi",
    type: "brands",
    variant: "fab",
  },
  {
    name: "fort awesome",
    class: "fab fa-fort-awesome",
    identifier: "fa-fort-awesome",
    type: "brands",
    variant: "fab",
  },
  {
    name: "fort awesome-alt",
    class: "fab fa-fort-awesome-alt",
    identifier: "fa-fort-awesome-alt",
    type: "brands",
    variant: "fab",
  },
  {
    name: "forumbee",
    class: "fab fa-forumbee",
    identifier: "fa-forumbee",
    type: "brands",
    variant: "fab",
  },
  {
    name: "foursquare",
    class: "fab fa-foursquare",
    identifier: "fa-foursquare",
    type: "brands",
    variant: "fab",
  },
  {
    name: "free code-camp",
    class: "fab fa-free-code-camp",
    identifier: "fa-free-code-camp",
    type: "brands",
    variant: "fab",
  },
  {
    name: "freebsd",
    class: "fab fa-freebsd",
    identifier: "fa-freebsd",
    type: "brands",
    variant: "fab",
  },
  {
    name: "fulcrum",
    class: "fab fa-fulcrum",
    identifier: "fa-fulcrum",
    type: "brands",
    variant: "fab",
  },
  {
    name: "galactic republic",
    class: "fab fa-galactic-republic",
    identifier: "fa-galactic-republic",
    type: "brands",
    variant: "fab",
  },
  {
    name: "galactic senate",
    class: "fab fa-galactic-senate",
    identifier: "fa-galactic-senate",
    type: "brands",
    variant: "fab",
  },
  {
    name: "get pocket",
    class: "fab fa-get-pocket",
    identifier: "fa-get-pocket",
    type: "brands",
    variant: "fab",
  },
  {
    name: "gg",
    class: "fab fa-gg",
    identifier: "fa-gg",
    type: "brands",
    variant: "fab",
  },
  {
    name: "gg circle",
    class: "fab fa-gg-circle",
    identifier: "fa-gg-circle",
    type: "brands",
    variant: "fab",
  },
  {
    name: "git",
    class: "fab fa-git",
    identifier: "fa-git",
    type: "brands",
    variant: "fab",
  },
  {
    name: "git alt",
    class: "fab fa-git-alt",
    identifier: "fa-git-alt",
    type: "brands",
    variant: "fab",
  },
  {
    name: "git square",
    class: "fab fa-git-square",
    identifier: "fa-git-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "github",
    class: "fab fa-github",
    identifier: "fa-github",
    type: "brands",
    variant: "fab",
  },
  {
    name: "github alt",
    class: "fab fa-github-alt",
    identifier: "fa-github-alt",
    type: "brands",
    variant: "fab",
  },
  {
    name: "github square",
    class: "fab fa-github-square",
    identifier: "fa-github-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "gitkraken",
    class: "fab fa-gitkraken",
    identifier: "fa-gitkraken",
    type: "brands",
    variant: "fab",
  },
  {
    name: "gitlab",
    class: "fab fa-gitlab",
    identifier: "fa-gitlab",
    type: "brands",
    variant: "fab",
  },
  {
    name: "gitter",
    class: "fab fa-gitter",
    identifier: "fa-gitter",
    type: "brands",
    variant: "fab",
  },
  {
    name: "glide",
    class: "fab fa-glide",
    identifier: "fa-glide",
    type: "brands",
    variant: "fab",
  },
  {
    name: "glide g",
    class: "fab fa-glide-g",
    identifier: "fa-glide-g",
    type: "brands",
    variant: "fab",
  },
  {
    name: "gofore",
    class: "fab fa-gofore",
    identifier: "fa-gofore",
    type: "brands",
    variant: "fab",
  },
  {
    name: "goodreads",
    class: "fab fa-goodreads",
    identifier: "fa-goodreads",
    type: "brands",
    variant: "fab",
  },
  {
    name: "goodreads g",
    class: "fab fa-goodreads-g",
    identifier: "fa-goodreads-g",
    type: "brands",
    variant: "fab",
  },
  {
    name: "google",
    class: "fab fa-google",
    identifier: "fa-google",
    type: "brands",
    variant: "fab",
  },
  {
    name: "google drive",
    class: "fab fa-google-drive",
    identifier: "fa-google-drive",
    type: "brands",
    variant: "fab",
  },
  {
    name: "google pay",
    class: "fab fa-google-pay",
    identifier: "fa-google-pay",
    type: "brands",
    variant: "fab",
  },
  {
    name: "google play",
    class: "fab fa-google-play",
    identifier: "fa-google-play",
    type: "brands",
    variant: "fab",
  },
  {
    name: "google plus",
    class: "fab fa-google-plus",
    identifier: "fa-google-plus",
    type: "brands",
    variant: "fab",
  },
  {
    name: "google plus-g",
    class: "fab fa-google-plus-g",
    identifier: "fa-google-plus-g",
    type: "brands",
    variant: "fab",
  },
  {
    name: "google plus-square",
    class: "fab fa-google-plus-square",
    identifier: "fa-google-plus-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "google wallet",
    class: "fab fa-google-wallet",
    identifier: "fa-google-wallet",
    type: "brands",
    variant: "fab",
  },
  {
    name: "gratipay",
    class: "fab fa-gratipay",
    identifier: "fa-gratipay",
    type: "brands",
    variant: "fab",
  },
  {
    name: "grav",
    class: "fab fa-grav",
    identifier: "fa-grav",
    type: "brands",
    variant: "fab",
  },
  {
    name: "gripfire",
    class: "fab fa-gripfire",
    identifier: "fa-gripfire",
    type: "brands",
    variant: "fab",
  },
  {
    name: "grunt",
    class: "fab fa-grunt",
    identifier: "fa-grunt",
    type: "brands",
    variant: "fab",
  },
  {
    name: "guilded",
    class: "fab fa-guilded",
    identifier: "fa-guilded",
    type: "brands",
    variant: "fab",
  },
  {
    name: "gulp",
    class: "fab fa-gulp",
    identifier: "fa-gulp",
    type: "brands",
    variant: "fab",
  },
  {
    name: "hacker news",
    class: "fab fa-hacker-news",
    identifier: "fa-hacker-news",
    type: "brands",
    variant: "fab",
  },
  {
    name: "hacker news-square",
    class: "fab fa-hacker-news-square",
    identifier: "fa-hacker-news-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "hackerrank",
    class: "fab fa-hackerrank",
    identifier: "fa-hackerrank",
    type: "brands",
    variant: "fab",
  },
  {
    name: "hips",
    class: "fab fa-hips",
    identifier: "fa-hips",
    type: "brands",
    variant: "fab",
  },
  {
    name: "hire a-helper",
    class: "fab fa-hire-a-helper",
    identifier: "fa-hire-a-helper",
    type: "brands",
    variant: "fab",
  },
  {
    name: "hive",
    class: "fab fa-hive",
    identifier: "fa-hive",
    type: "brands",
    variant: "fab",
  },
  {
    name: "hooli",
    class: "fab fa-hooli",
    identifier: "fa-hooli",
    type: "brands",
    variant: "fab",
  },
  {
    name: "hornbill",
    class: "fab fa-hornbill",
    identifier: "fa-hornbill",
    type: "brands",
    variant: "fab",
  },
  {
    name: "hotjar",
    class: "fab fa-hotjar",
    identifier: "fa-hotjar",
    type: "brands",
    variant: "fab",
  },
  {
    name: "houzz",
    class: "fab fa-houzz",
    identifier: "fa-houzz",
    type: "brands",
    variant: "fab",
  },
  {
    name: "html5",
    class: "fab fa-html5",
    identifier: "fa-html5",
    type: "brands",
    variant: "fab",
  },
  {
    name: "hubspot",
    class: "fab fa-hubspot",
    identifier: "fa-hubspot",
    type: "brands",
    variant: "fab",
  },
  {
    name: "ideal",
    class: "fab fa-ideal",
    identifier: "fa-ideal",
    type: "brands",
    variant: "fab",
  },
  {
    name: "imdb",
    class: "fab fa-imdb",
    identifier: "fa-imdb",
    type: "brands",
    variant: "fab",
  },
  {
    name: "innosoft",
    class: "fab fa-innosoft",
    identifier: "fa-innosoft",
    type: "brands",
    variant: "fab",
  },
  {
    name: "instagram",
    class: "fab fa-instagram",
    identifier: "fa-instagram",
    type: "brands",
    variant: "fab",
  },
  {
    name: "instagram square",
    class: "fab fa-instagram-square",
    identifier: "fa-instagram-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "instalod",
    class: "fab fa-instalod",
    identifier: "fa-instalod",
    type: "brands",
    variant: "fab",
  },
  {
    name: "intercom",
    class: "fab fa-intercom",
    identifier: "fa-intercom",
    type: "brands",
    variant: "fab",
  },
  {
    name: "internet explorer",
    class: "fab fa-internet-explorer",
    identifier: "fa-internet-explorer",
    type: "brands",
    variant: "fab",
  },
  {
    name: "invision",
    class: "fab fa-invision",
    identifier: "fa-invision",
    type: "brands",
    variant: "fab",
  },
  {
    name: "ioxhost",
    class: "fab fa-ioxhost",
    identifier: "fa-ioxhost",
    type: "brands",
    variant: "fab",
  },
  {
    name: "itch io",
    class: "fab fa-itch-io",
    identifier: "fa-itch-io",
    type: "brands",
    variant: "fab",
  },
  {
    name: "itunes",
    class: "fab fa-itunes",
    identifier: "fa-itunes",
    type: "brands",
    variant: "fab",
  },
  {
    name: "itunes note",
    class: "fab fa-itunes-note",
    identifier: "fa-itunes-note",
    type: "brands",
    variant: "fab",
  },
  {
    name: "java",
    class: "fab fa-java",
    identifier: "fa-java",
    type: "brands",
    variant: "fab",
  },
  {
    name: "jedi order",
    class: "fab fa-jedi-order",
    identifier: "fa-jedi-order",
    type: "brands",
    variant: "fab",
  },
  {
    name: "jenkins",
    class: "fab fa-jenkins",
    identifier: "fa-jenkins",
    type: "brands",
    variant: "fab",
  },
  {
    name: "jira",
    class: "fab fa-jira",
    identifier: "fa-jira",
    type: "brands",
    variant: "fab",
  },
  {
    name: "joget",
    class: "fab fa-joget",
    identifier: "fa-joget",
    type: "brands",
    variant: "fab",
  },
  {
    name: "joomla",
    class: "fab fa-joomla",
    identifier: "fa-joomla",
    type: "brands",
    variant: "fab",
  },
  {
    name: "js",
    class: "fab fa-js",
    identifier: "fa-js",
    type: "brands",
    variant: "fab",
  },
  {
    name: "js square",
    class: "fab fa-js-square",
    identifier: "fa-js-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "jsfiddle",
    class: "fab fa-jsfiddle",
    identifier: "fa-jsfiddle",
    type: "brands",
    variant: "fab",
  },
  {
    name: "kaggle",
    class: "fab fa-kaggle",
    identifier: "fa-kaggle",
    type: "brands",
    variant: "fab",
  },
  {
    name: "keybase",
    class: "fab fa-keybase",
    identifier: "fa-keybase",
    type: "brands",
    variant: "fab",
  },
  {
    name: "keycdn",
    class: "fab fa-keycdn",
    identifier: "fa-keycdn",
    type: "brands",
    variant: "fab",
  },
  {
    name: "kickstarter",
    class: "fab fa-kickstarter",
    identifier: "fa-kickstarter",
    type: "brands",
    variant: "fab",
  },
  {
    name: "kickstarter k",
    class: "fab fa-kickstarter-k",
    identifier: "fa-kickstarter-k",
    type: "brands",
    variant: "fab",
  },
  {
    name: "korvue",
    class: "fab fa-korvue",
    identifier: "fa-korvue",
    type: "brands",
    variant: "fab",
  },
  {
    name: "laravel",
    class: "fab fa-laravel",
    identifier: "fa-laravel",
    type: "brands",
    variant: "fab",
  },
  {
    name: "lastfm",
    class: "fab fa-lastfm",
    identifier: "fa-lastfm",
    type: "brands",
    variant: "fab",
  },
  {
    name: "lastfm square",
    class: "fab fa-lastfm-square",
    identifier: "fa-lastfm-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "leanpub",
    class: "fab fa-leanpub",
    identifier: "fa-leanpub",
    type: "brands",
    variant: "fab",
  },
  {
    name: "less",
    class: "fab fa-less",
    identifier: "fa-less",
    type: "brands",
    variant: "fab",
  },
  {
    name: "line",
    class: "fab fa-line",
    identifier: "fa-line",
    type: "brands",
    variant: "fab",
  },
  {
    name: "linkedin",
    class: "fab fa-linkedin",
    identifier: "fa-linkedin",
    type: "brands",
    variant: "fab",
  },
  {
    name: "linkedin in",
    class: "fab fa-linkedin-in",
    identifier: "fa-linkedin-in",
    type: "brands",
    variant: "fab",
  },
  {
    name: "linode",
    class: "fab fa-linode",
    identifier: "fa-linode",
    type: "brands",
    variant: "fab",
  },
  {
    name: "linux",
    class: "fab fa-linux",
    identifier: "fa-linux",
    type: "brands",
    variant: "fab",
  },
  {
    name: "lyft",
    class: "fab fa-lyft",
    identifier: "fa-lyft",
    type: "brands",
    variant: "fab",
  },
  {
    name: "magento",
    class: "fab fa-magento",
    identifier: "fa-magento",
    type: "brands",
    variant: "fab",
  },
  {
    name: "mailchimp",
    class: "fab fa-mailchimp",
    identifier: "fa-mailchimp",
    type: "brands",
    variant: "fab",
  },
  {
    name: "mandalorian",
    class: "fab fa-mandalorian",
    identifier: "fa-mandalorian",
    type: "brands",
    variant: "fab",
  },
  {
    name: "markdown",
    class: "fab fa-markdown",
    identifier: "fa-markdown",
    type: "brands",
    variant: "fab",
  },
  {
    name: "mastodon",
    class: "fab fa-mastodon",
    identifier: "fa-mastodon",
    type: "brands",
    variant: "fab",
  },
  {
    name: "maxcdn",
    class: "fab fa-maxcdn",
    identifier: "fa-maxcdn",
    type: "brands",
    variant: "fab",
  },
  {
    name: "mdb",
    class: "fab fa-mdb",
    identifier: "fa-mdb",
    type: "brands",
    variant: "fab",
  },
  {
    name: "medapps",
    class: "fab fa-medapps",
    identifier: "fa-medapps",
    type: "brands",
    variant: "fab",
  },
  {
    name: "medium",
    class: "fab fa-medium",
    identifier: "fa-medium",
    type: "brands",
    variant: "fab",
  },
  {
    name: "medium m",
    class: "fab fa-medium-m",
    identifier: "fa-medium-m",
    type: "brands",
    variant: "fab",
  },
  {
    name: "medrt",
    class: "fab fa-medrt",
    identifier: "fa-medrt",
    type: "brands",
    variant: "fab",
  },
  {
    name: "meetup",
    class: "fab fa-meetup",
    identifier: "fa-meetup",
    type: "brands",
    variant: "fab",
  },
  {
    name: "megaport",
    class: "fab fa-megaport",
    identifier: "fa-megaport",
    type: "brands",
    variant: "fab",
  },
  {
    name: "mendeley",
    class: "fab fa-mendeley",
    identifier: "fa-mendeley",
    type: "brands",
    variant: "fab",
  },
  {
    name: "microblog",
    class: "fab fa-microblog",
    identifier: "fa-microblog",
    type: "brands",
    variant: "fab",
  },
  {
    name: "microsoft",
    class: "fab fa-microsoft",
    identifier: "fa-microsoft",
    type: "brands",
    variant: "fab",
  },
  {
    name: "mix",
    class: "fab fa-mix",
    identifier: "fa-mix",
    type: "brands",
    variant: "fab",
  },
  {
    name: "mixcloud",
    class: "fab fa-mixcloud",
    identifier: "fa-mixcloud",
    type: "brands",
    variant: "fab",
  },
  {
    name: "mixer",
    class: "fab fa-mixer",
    identifier: "fa-mixer",
    type: "brands",
    variant: "fab",
  },
  {
    name: "mizuni",
    class: "fab fa-mizuni",
    identifier: "fa-mizuni",
    type: "brands",
    variant: "fab",
  },
  {
    name: "modx",
    class: "fab fa-modx",
    identifier: "fa-modx",
    type: "brands",
    variant: "fab",
  },
  {
    name: "monero",
    class: "fab fa-monero",
    identifier: "fa-monero",
    type: "brands",
    variant: "fab",
  },
  {
    name: "napster",
    class: "fab fa-napster",
    identifier: "fa-napster",
    type: "brands",
    variant: "fab",
  },
  {
    name: "neos",
    class: "fab fa-neos",
    identifier: "fa-neos",
    type: "brands",
    variant: "fab",
  },
  {
    name: "nimblr",
    class: "fab fa-nimblr",
    identifier: "fa-nimblr",
    type: "brands",
    variant: "fab",
  },
  {
    name: "node",
    class: "fab fa-node",
    identifier: "fa-node",
    type: "brands",
    variant: "fab",
  },
  {
    name: "node js",
    class: "fab fa-node-js",
    identifier: "fa-node-js",
    type: "brands",
    variant: "fab",
  },
  {
    name: "npm",
    class: "fab fa-npm",
    identifier: "fa-npm",
    type: "brands",
    variant: "fab",
  },
  {
    name: "ns8",
    class: "fab fa-ns8",
    identifier: "fa-ns8",
    type: "brands",
    variant: "fab",
  },
  {
    name: "nutritionix",
    class: "fab fa-nutritionix",
    identifier: "fa-nutritionix",
    type: "brands",
    variant: "fab",
  },
  {
    name: "octopus deploy",
    class: "fab fa-octopus-deploy",
    identifier: "fa-octopus-deploy",
    type: "brands",
    variant: "fab",
  },
  {
    name: "odnoklassniki",
    class: "fab fa-odnoklassniki",
    identifier: "fa-odnoklassniki",
    type: "brands",
    variant: "fab",
  },
  {
    name: "odnoklassniki square",
    class: "fab fa-odnoklassniki-square",
    identifier: "fa-odnoklassniki-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "old republic",
    class: "fab fa-old-republic",
    identifier: "fa-old-republic",
    type: "brands",
    variant: "fab",
  },
  {
    name: "opencart",
    class: "fab fa-opencart",
    identifier: "fa-opencart",
    type: "brands",
    variant: "fab",
  },
  {
    name: "openid",
    class: "fab fa-openid",
    identifier: "fa-openid",
    type: "brands",
    variant: "fab",
  },
  {
    name: "opera",
    class: "fab fa-opera",
    identifier: "fa-opera",
    type: "brands",
    variant: "fab",
  },
  {
    name: "optin monster",
    class: "fab fa-optin-monster",
    identifier: "fa-optin-monster",
    type: "brands",
    variant: "fab",
  },
  {
    name: "orcid",
    class: "fab fa-orcid",
    identifier: "fa-orcid",
    type: "brands",
    variant: "fab",
  },
  {
    name: "osi",
    class: "fab fa-osi",
    identifier: "fa-osi",
    type: "brands",
    variant: "fab",
  },
  {
    name: "page4",
    class: "fab fa-page4",
    identifier: "fa-page4",
    type: "brands",
    variant: "fab",
  },
  {
    name: "pagelines",
    class: "fab fa-pagelines",
    identifier: "fa-pagelines",
    type: "brands",
    variant: "fab",
  },
  {
    name: "palfed",
    class: "fab fa-palfed",
    identifier: "fa-palfed",
    type: "brands",
    variant: "fab",
  },
  {
    name: "patreon",
    class: "fab fa-patreon",
    identifier: "fa-patreon",
    type: "brands",
    variant: "fab",
  },
  {
    name: "paypal",
    class: "fab fa-paypal",
    identifier: "fa-paypal",
    type: "brands",
    variant: "fab",
  },
  {
    name: "penny arcade",
    class: "fab fa-penny-arcade",
    identifier: "fa-penny-arcade",
    type: "brands",
    variant: "fab",
  },
  {
    name: "perbyte",
    class: "fab fa-perbyte",
    identifier: "fa-perbyte",
    type: "brands",
    variant: "fab",
  },
  {
    name: "periscope",
    class: "fab fa-periscope",
    identifier: "fa-periscope",
    type: "brands",
    variant: "fab",
  },
  {
    name: "phabricator",
    class: "fab fa-phabricator",
    identifier: "fa-phabricator",
    type: "brands",
    variant: "fab",
  },
  {
    name: "phoenix framework",
    class: "fab fa-phoenix-framework",
    identifier: "fa-phoenix-framework",
    type: "brands",
    variant: "fab",
  },
  {
    name: "phoenix squadron",
    class: "fab fa-phoenix-squadron",
    identifier: "fa-phoenix-squadron",
    type: "brands",
    variant: "fab",
  },
  {
    name: "php",
    class: "fab fa-php",
    identifier: "fa-php",
    type: "brands",
    variant: "fab",
  },
  {
    name: "pied piper",
    class: "fab fa-pied-piper",
    identifier: "fa-pied-piper",
    type: "brands",
    variant: "fab",
  },
  {
    name: "pied piper-alt",
    class: "fab fa-pied-piper-alt",
    identifier: "fa-pied-piper-alt",
    type: "brands",
    variant: "fab",
  },
  {
    name: "pied piper-hat",
    class: "fab fa-pied-piper-hat",
    identifier: "fa-pied-piper-hat",
    type: "brands",
    variant: "fab",
  },
  {
    name: "pied piper-pp",
    class: "fab fa-pied-piper-pp",
    identifier: "fa-pied-piper-pp",
    type: "brands",
    variant: "fab",
  },
  {
    name: "pied piper-square",
    class: "fab fa-pied-piper-square",
    identifier: "fa-pied-piper-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "pinterest",
    class: "fab fa-pinterest",
    identifier: "fa-pinterest",
    type: "brands",
    variant: "fab",
  },
  {
    name: "pinterest p",
    class: "fab fa-pinterest-p",
    identifier: "fa-pinterest-p",
    type: "brands",
    variant: "fab",
  },
  {
    name: "pinterest square",
    class: "fab fa-pinterest-square",
    identifier: "fa-pinterest-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "playstation",
    class: "fab fa-playstation",
    identifier: "fa-playstation",
    type: "brands",
    variant: "fab",
  },
  {
    name: "product hunt",
    class: "fab fa-product-hunt",
    identifier: "fa-product-hunt",
    type: "brands",
    variant: "fab",
  },
  {
    name: "pushed",
    class: "fab fa-pushed",
    identifier: "fa-pushed",
    type: "brands",
    variant: "fab",
  },
  {
    name: "python",
    class: "fab fa-python",
    identifier: "fa-python",
    type: "brands",
    variant: "fab",
  },
  {
    name: "qq",
    class: "fab fa-qq",
    identifier: "fa-qq",
    type: "brands",
    variant: "fab",
  },
  {
    name: "quinscape",
    class: "fab fa-quinscape",
    identifier: "fa-quinscape",
    type: "brands",
    variant: "fab",
  },
  {
    name: "quora",
    class: "fab fa-quora",
    identifier: "fa-quora",
    type: "brands",
    variant: "fab",
  },
  {
    name: "r project",
    class: "fab fa-r-project",
    identifier: "fa-r-project",
    type: "brands",
    variant: "fab",
  },
  {
    name: "raspberry pi",
    class: "fab fa-raspberry-pi",
    identifier: "fa-raspberry-pi",
    type: "brands",
    variant: "fab",
  },
  {
    name: "ravelry",
    class: "fab fa-ravelry",
    identifier: "fa-ravelry",
    type: "brands",
    variant: "fab",
  },
  {
    name: "react",
    class: "fab fa-react",
    identifier: "fa-react",
    type: "brands",
    variant: "fab",
  },
  {
    name: "reacteurope",
    class: "fab fa-reacteurope",
    identifier: "fa-reacteurope",
    type: "brands",
    variant: "fab",
  },
  {
    name: "readme",
    class: "fab fa-readme",
    identifier: "fa-readme",
    type: "brands",
    variant: "fab",
  },
  {
    name: "rebel",
    class: "fab fa-rebel",
    identifier: "fa-rebel",
    type: "brands",
    variant: "fab",
  },
  {
    name: "red river",
    class: "fab fa-red-river",
    identifier: "fa-red-river",
    type: "brands",
    variant: "fab",
  },
  {
    name: "reddit",
    class: "fab fa-reddit",
    identifier: "fa-reddit",
    type: "brands",
    variant: "fab",
  },
  {
    name: "reddit alien",
    class: "fab fa-reddit-alien",
    identifier: "fa-reddit-alien",
    type: "brands",
    variant: "fab",
  },
  {
    name: "reddit square",
    class: "fab fa-reddit-square",
    identifier: "fa-reddit-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "redhat",
    class: "fab fa-redhat",
    identifier: "fa-redhat",
    type: "brands",
    variant: "fab",
  },
  {
    name: "renren",
    class: "fab fa-renren",
    identifier: "fa-renren",
    type: "brands",
    variant: "fab",
  },
  {
    name: "replyd",
    class: "fab fa-replyd",
    identifier: "fa-replyd",
    type: "brands",
    variant: "fab",
  },
  {
    name: "researchgate",
    class: "fab fa-researchgate",
    identifier: "fa-researchgate",
    type: "brands",
    variant: "fab",
  },
  {
    name: "resolving",
    class: "fab fa-resolving",
    identifier: "fa-resolving",
    type: "brands",
    variant: "fab",
  },
  {
    name: "rev",
    class: "fab fa-rev",
    identifier: "fa-rev",
    type: "brands",
    variant: "fab",
  },
  {
    name: "rocketchat",
    class: "fab fa-rocketchat",
    identifier: "fa-rocketchat",
    type: "brands",
    variant: "fab",
  },
  {
    name: "rockrms",
    class: "fab fa-rockrms",
    identifier: "fa-rockrms",
    type: "brands",
    variant: "fab",
  },
  {
    name: "rust",
    class: "fab fa-rust",
    identifier: "fa-rust",
    type: "brands",
    variant: "fab",
  },
  {
    name: "safari",
    class: "fab fa-safari",
    identifier: "fa-safari",
    type: "brands",
    variant: "fab",
  },
  {
    name: "salesforce",
    class: "fab fa-salesforce",
    identifier: "fa-salesforce",
    type: "brands",
    variant: "fab",
  },
  {
    name: "sass",
    class: "fab fa-sass",
    identifier: "fa-sass",
    type: "brands",
    variant: "fab",
  },
  {
    name: "schlix",
    class: "fab fa-schlix",
    identifier: "fa-schlix",
    type: "brands",
    variant: "fab",
  },
  {
    name: "scribd",
    class: "fab fa-scribd",
    identifier: "fa-scribd",
    type: "brands",
    variant: "fab",
  },
  {
    name: "searchengin",
    class: "fab fa-searchengin",
    identifier: "fa-searchengin",
    type: "brands",
    variant: "fab",
  },
  {
    name: "sellcast",
    class: "fab fa-sellcast",
    identifier: "fa-sellcast",
    type: "brands",
    variant: "fab",
  },
  {
    name: "sellsy",
    class: "fab fa-sellsy",
    identifier: "fa-sellsy",
    type: "brands",
    variant: "fab",
  },
  {
    name: "servicestack",
    class: "fab fa-servicestack",
    identifier: "fa-servicestack",
    type: "brands",
    variant: "fab",
  },
  {
    name: "shirtsinbulk",
    class: "fab fa-shirtsinbulk",
    identifier: "fa-shirtsinbulk",
    type: "brands",
    variant: "fab",
  },
  {
    name: "shopify",
    class: "fab fa-shopify",
    identifier: "fa-shopify",
    type: "brands",
    variant: "fab",
  },
  {
    name: "shopware",
    class: "fab fa-shopware",
    identifier: "fa-shopware",
    type: "brands",
    variant: "fab",
  },
  {
    name: "simplybuilt",
    class: "fab fa-simplybuilt",
    identifier: "fa-simplybuilt",
    type: "brands",
    variant: "fab",
  },
  {
    name: "sistrix",
    class: "fab fa-sistrix",
    identifier: "fa-sistrix",
    type: "brands",
    variant: "fab",
  },
  {
    name: "sith",
    class: "fab fa-sith",
    identifier: "fa-sith",
    type: "brands",
    variant: "fab",
  },
  {
    name: "sketch",
    class: "fab fa-sketch",
    identifier: "fa-sketch",
    type: "brands",
    variant: "fab",
  },
  {
    name: "skyatlas",
    class: "fab fa-skyatlas",
    identifier: "fa-skyatlas",
    type: "brands",
    variant: "fab",
  },
  {
    name: "skype",
    class: "fab fa-skype",
    identifier: "fa-skype",
    type: "brands",
    variant: "fab",
  },
  {
    name: "slack",
    class: "fab fa-slack",
    identifier: "fa-slack",
    type: "brands",
    variant: "fab",
  },
  {
    name: "slack hash",
    class: "fab fa-slack-hash",
    identifier: "fa-slack-hash",
    type: "brands",
    variant: "fab",
  },
  {
    name: "slideshare",
    class: "fab fa-slideshare",
    identifier: "fa-slideshare",
    type: "brands",
    variant: "fab",
  },
  {
    name: "snapchat",
    class: "fab fa-snapchat",
    identifier: "fa-snapchat",
    type: "brands",
    variant: "fab",
  },
  {
    name: "snapchat ghost",
    class: "fab fa-snapchat-ghost",
    identifier: "fa-snapchat-ghost",
    type: "brands",
    variant: "fab",
  },
  {
    name: "snapchat square",
    class: "fab fa-snapchat-square",
    identifier: "fa-snapchat-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "soundcloud",
    class: "fab fa-soundcloud",
    identifier: "fa-soundcloud",
    type: "brands",
    variant: "fab",
  },
  {
    name: "sourcetree",
    class: "fab fa-sourcetree",
    identifier: "fa-sourcetree",
    type: "brands",
    variant: "fab",
  },
  {
    name: "speakap",
    class: "fab fa-speakap",
    identifier: "fa-speakap",
    type: "brands",
    variant: "fab",
  },
  {
    name: "speaker deck",
    class: "fab fa-speaker-deck",
    identifier: "fa-speaker-deck",
    type: "brands",
    variant: "fab",
  },
  {
    name: "spotify",
    class: "fab fa-spotify",
    identifier: "fa-spotify",
    type: "brands",
    variant: "fab",
  },
  {
    name: "squarespace",
    class: "fab fa-squarespace",
    identifier: "fa-squarespace",
    type: "brands",
    variant: "fab",
  },
  {
    name: "stack exchange",
    class: "fab fa-stack-exchange",
    identifier: "fa-stack-exchange",
    type: "brands",
    variant: "fab",
  },
  {
    name: "stack overflow",
    class: "fab fa-stack-overflow",
    identifier: "fa-stack-overflow",
    type: "brands",
    variant: "fab",
  },
  {
    name: "stackpath",
    class: "fab fa-stackpath",
    identifier: "fa-stackpath",
    type: "brands",
    variant: "fab",
  },
  {
    name: "staylinked",
    class: "fab fa-staylinked",
    identifier: "fa-staylinked",
    type: "brands",
    variant: "fab",
  },
  {
    name: "steam",
    class: "fab fa-steam",
    identifier: "fa-steam",
    type: "brands",
    variant: "fab",
  },
  {
    name: "steam square",
    class: "fab fa-steam-square",
    identifier: "fa-steam-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "steam symbol",
    class: "fab fa-steam-symbol",
    identifier: "fa-steam-symbol",
    type: "brands",
    variant: "fab",
  },
  {
    name: "sticker mule",
    class: "fab fa-sticker-mule",
    identifier: "fa-sticker-mule",
    type: "brands",
    variant: "fab",
  },
  {
    name: "strava",
    class: "fab fa-strava",
    identifier: "fa-strava",
    type: "brands",
    variant: "fab",
  },
  {
    name: "stripe",
    class: "fab fa-stripe",
    identifier: "fa-stripe",
    type: "brands",
    variant: "fab",
  },
  {
    name: "stripe s",
    class: "fab fa-stripe-s",
    identifier: "fa-stripe-s",
    type: "brands",
    variant: "fab",
  },
  {
    name: "studiovinari",
    class: "fab fa-studiovinari",
    identifier: "fa-studiovinari",
    type: "brands",
    variant: "fab",
  },
  {
    name: "stumbleupon",
    class: "fab fa-stumbleupon",
    identifier: "fa-stumbleupon",
    type: "brands",
    variant: "fab",
  },
  {
    name: "stumbleupon circle",
    class: "fab fa-stumbleupon-circle",
    identifier: "fa-stumbleupon-circle",
    type: "brands",
    variant: "fab",
  },
  {
    name: "superpowers",
    class: "fab fa-superpowers",
    identifier: "fa-superpowers",
    type: "brands",
    variant: "fab",
  },
  {
    name: "supple",
    class: "fab fa-supple",
    identifier: "fa-supple",
    type: "brands",
    variant: "fab",
  },
  {
    name: "suse",
    class: "fab fa-suse",
    identifier: "fa-suse",
    type: "brands",
    variant: "fab",
  },
  {
    name: "swift",
    class: "fab fa-swift",
    identifier: "fa-swift",
    type: "brands",
    variant: "fab",
  },
  {
    name: "symfony",
    class: "fab fa-symfony",
    identifier: "fa-symfony",
    type: "brands",
    variant: "fab",
  },
  {
    name: "teamspeak",
    class: "fab fa-teamspeak",
    identifier: "fa-teamspeak",
    type: "brands",
    variant: "fab",
  },
  {
    name: "telegram",
    class: "fab fa-telegram",
    identifier: "fa-telegram",
    type: "brands",
    variant: "fab",
  },
  {
    name: "telegram plane",
    class: "fab fa-telegram-plane",
    identifier: "fa-telegram-plane",
    type: "brands",
    variant: "fab",
  },
  {
    name: "tencent weibo",
    class: "fab fa-tencent-weibo",
    identifier: "fa-tencent-weibo",
    type: "brands",
    variant: "fab",
  },
  {
    name: "the red-yeti",
    class: "fab fa-the-red-yeti",
    identifier: "fa-the-red-yeti",
    type: "brands",
    variant: "fab",
  },
  {
    name: "themeco",
    class: "fab fa-themeco",
    identifier: "fa-themeco",
    type: "brands",
    variant: "fab",
  },
  {
    name: "themeisle",
    class: "fab fa-themeisle",
    identifier: "fa-themeisle",
    type: "brands",
    variant: "fab",
  },
  {
    name: "think peaks",
    class: "fab fa-think-peaks",
    identifier: "fa-think-peaks",
    type: "brands",
    variant: "fab",
  },
  {
    name: "tiktok",
    class: "fab fa-tiktok",
    identifier: "fa-tiktok",
    type: "brands",
    variant: "fab",
  },
  {
    name: "trade federation",
    class: "fab fa-trade-federation",
    identifier: "fa-trade-federation",
    type: "brands",
    variant: "fab",
  },
  {
    name: "trello",
    class: "fab fa-trello",
    identifier: "fa-trello",
    type: "brands",
    variant: "fab",
  },
  {
    name: "tumblr",
    class: "fab fa-tumblr",
    identifier: "fa-tumblr",
    type: "brands",
    variant: "fab",
  },
  {
    name: "tumblr square",
    class: "fab fa-tumblr-square",
    identifier: "fa-tumblr-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "twitch",
    class: "fab fa-twitch",
    identifier: "fa-twitch",
    type: "brands",
    variant: "fab",
  },
  {
    name: "twitter",
    class: "fab fa-twitter",
    identifier: "fa-twitter",
    type: "brands",
    variant: "fab",
  },
  {
    name: "twitter square",
    class: "fab fa-twitter-square",
    identifier: "fa-twitter-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "twitter square",
    class: "fa-brands fa-square-twitter",
    identifier: "fa-square-twitter",
    type: "brands",
    variant: "fa-brands",
  },
  {
    name: "typo3",
    class: "fab fa-typo3",
    identifier: "fa-typo3",
    type: "brands",
    variant: "fab",
  },
  {
    name: "uber",
    class: "fab fa-uber",
    identifier: "fa-uber",
    type: "brands",
    variant: "fab",
  },
  {
    name: "ubuntu",
    class: "fab fa-ubuntu",
    identifier: "fa-ubuntu",
    type: "brands",
    variant: "fab",
  },
  {
    name: "uikit",
    class: "fab fa-uikit",
    identifier: "fa-uikit",
    type: "brands",
    variant: "fab",
  },
  {
    name: "umbraco",
    class: "fab fa-umbraco",
    identifier: "fa-umbraco",
    type: "brands",
    variant: "fab",
  },
  {
    name: "uncharted",
    class: "fab fa-uncharted",
    identifier: "fa-uncharted",
    type: "brands",
    variant: "fab",
  },
  {
    name: "uniregistry",
    class: "fab fa-uniregistry",
    identifier: "fa-uniregistry",
    type: "brands",
    variant: "fab",
  },
  {
    name: "unity",
    class: "fab fa-unity",
    identifier: "fa-unity",
    type: "brands",
    variant: "fab",
  },
  {
    name: "unsplash",
    class: "fab fa-unsplash",
    identifier: "fa-unsplash",
    type: "brands",
    variant: "fab",
  },
  {
    name: "untappd",
    class: "fab fa-untappd",
    identifier: "fa-untappd",
    type: "brands",
    variant: "fab",
  },
  {
    name: "ups",
    class: "fab fa-ups",
    identifier: "fa-ups",
    type: "brands",
    variant: "fab",
  },
  {
    name: "usb",
    class: "fab fa-usb",
    identifier: "fa-usb",
    type: "brands",
    variant: "fab",
  },
  {
    name: "usps",
    class: "fab fa-usps",
    identifier: "fa-usps",
    type: "brands",
    variant: "fab",
  },
  {
    name: "ussunnah",
    class: "fab fa-ussunnah",
    identifier: "fa-ussunnah",
    type: "brands",
    variant: "fab",
  },
  {
    name: "vaadin",
    class: "fab fa-vaadin",
    identifier: "fa-vaadin",
    type: "brands",
    variant: "fab",
  },
  {
    name: "viacoin",
    class: "fab fa-viacoin",
    identifier: "fa-viacoin",
    type: "brands",
    variant: "fab",
  },
  {
    name: "viadeo",
    class: "fab fa-viadeo",
    identifier: "fa-viadeo",
    type: "brands",
    variant: "fab",
  },
  {
    name: "viadeo square",
    class: "fab fa-viadeo-square",
    identifier: "fa-viadeo-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "viber",
    class: "fab fa-viber",
    identifier: "fa-viber",
    type: "brands",
    variant: "fab",
  },
  {
    name: "vimeo",
    class: "fab fa-vimeo",
    identifier: "fa-vimeo",
    type: "brands",
    variant: "fab",
  },
  {
    name: "vimeo square",
    class: "fab fa-vimeo-square",
    identifier: "fa-vimeo-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "vimeo v",
    class: "fab fa-vimeo-v",
    identifier: "fa-vimeo-v",
    type: "brands",
    variant: "fab",
  },
  {
    name: "fa-vimeo-v",
    class: "fa-brands fa-vimeo-v",
    identifier: "fa-vimeo-v",
    type: "brands",
    variant: "fa-brands",
  },
  {
    name: "vine",
    class: "fab fa-vine",
    identifier: "fa-vine",
    type: "brands",
    variant: "fab",
  },
  {
    name: "vk",
    class: "fab fa-vk",
    identifier: "fa-vk",
    type: "brands",
    variant: "fab",
  },
  {
    name: "vnv",
    class: "fab fa-vnv",
    identifier: "fa-vnv",
    type: "brands",
    variant: "fab",
  },
  {
    name: "vuejs",
    class: "fab fa-vuejs",
    identifier: "fa-vuejs",
    type: "brands",
    variant: "fab",
  },
  {
    name: "watchman monitoring",
    class: "fab fa-watchman-monitoring",
    identifier: "fa-watchman-monitoring",
    type: "brands",
    variant: "fab",
  },
  {
    name: "waze",
    class: "fab fa-waze",
    identifier: "fa-waze",
    type: "brands",
    variant: "fab",
  },
  {
    name: "weebly",
    class: "fab fa-weebly",
    identifier: "fa-weebly",
    type: "brands",
    variant: "fab",
  },
  {
    name: "weibo",
    class: "fab fa-weibo",
    identifier: "fa-weibo",
    type: "brands",
    variant: "fab",
  },
  {
    name: "weixin",
    class: "fab fa-weixin",
    identifier: "fa-weixin",
    type: "brands",
    variant: "fab",
  },
  {
    name: "whatsapp",
    class: "fab fa-whatsapp",
    identifier: "fa-whatsapp",
    type: "brands",
    variant: "fab",
  },
  {
    name: "whatsapp square",
    class: "fab fa-whatsapp-square",
    identifier: "fa-whatsapp-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "whmcs",
    class: "fab fa-whmcs",
    identifier: "fa-whmcs",
    type: "brands",
    variant: "fab",
  },
  {
    name: "wikipedia w",
    class: "fab fa-wikipedia-w",
    identifier: "fa-wikipedia-w",
    type: "brands",
    variant: "fab",
  },
  {
    name: "windows",
    class: "fab fa-windows",
    identifier: "fa-windows",
    type: "brands",
    variant: "fab",
  },
  {
    name: "wix",
    class: "fab fa-wix",
    identifier: "fa-wix",
    type: "brands",
    variant: "fab",
  },
  {
    name: "wizards of-the-coast",
    class: "fab fa-wizards-of-the-coast",
    identifier: "fa-wizards-of-the-coast",
    type: "brands",
    variant: "fab",
  },
  {
    name: "wodu",
    class: "fab fa-wodu",
    identifier: "fa-wodu",
    type: "brands",
    variant: "fab",
  },
  {
    name: "wolf pack-battalion",
    class: "fab fa-wolf-pack-battalion",
    identifier: "fa-wolf-pack-battalion",
    type: "brands",
    variant: "fab",
  },
  {
    name: "wordpress",
    class: "fab fa-wordpress",
    identifier: "fa-wordpress",
    type: "brands",
    variant: "fab",
  },
  {
    name: "wordpress simple",
    class: "fab fa-wordpress-simple",
    identifier: "fa-wordpress-simple",
    type: "brands",
    variant: "fab",
  },
  {
    name: "wpbeginner",
    class: "fab fa-wpbeginner",
    identifier: "fa-wpbeginner",
    type: "brands",
    variant: "fab",
  },
  {
    name: "wpexplorer",
    class: "fab fa-wpexplorer",
    identifier: "fa-wpexplorer",
    type: "brands",
    variant: "fab",
  },
  {
    name: "wpforms",
    class: "fab fa-wpforms",
    identifier: "fa-wpforms",
    type: "brands",
    variant: "fab",
  },
  {
    name: "wpressr",
    class: "fab fa-wpressr",
    identifier: "fa-wpressr",
    type: "brands",
    variant: "fab",
  },
  {
    name: "xbox",
    class: "fab fa-xbox",
    identifier: "fa-xbox",
    type: "brands",
    variant: "fab",
  },
  {
    name: "xing",
    class: "fab fa-xing",
    identifier: "fa-xing",
    type: "brands",
    variant: "fab",
  },
  {
    name: "xing square",
    class: "fab fa-xing-square",
    identifier: "fa-xing-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "y combinator",
    class: "fab fa-y-combinator",
    identifier: "fa-y-combinator",
    type: "brands",
    variant: "fab",
  },
  {
    name: "yahoo",
    class: "fab fa-yahoo",
    identifier: "fa-yahoo",
    type: "brands",
    variant: "fab",
  },
  {
    name: "yammer",
    class: "fab fa-yammer",
    identifier: "fa-yammer",
    type: "brands",
    variant: "fab",
  },
  {
    name: "yandex",
    class: "fab fa-yandex",
    identifier: "fa-yandex",
    type: "brands",
    variant: "fab",
  },
  {
    name: "yandex international",
    class: "fab fa-yandex-international",
    identifier: "fa-yandex-international",
    type: "brands",
    variant: "fab",
  },
  {
    name: "yarn",
    class: "fab fa-yarn",
    identifier: "fa-yarn",
    type: "brands",
    variant: "fab",
  },
  {
    name: "yelp",
    class: "fab fa-yelp",
    identifier: "fa-yelp",
    type: "brands",
    variant: "fab",
  },
  {
    name: "yoast",
    class: "fab fa-yoast",
    identifier: "fa-yoast",
    type: "brands",
    variant: "fab",
  },
  {
    name: "youtube",
    class: "fab fa-youtube",
    identifier: "fa-youtube",
    type: "brands",
    variant: "fab",
  },
  {
    name: "youtube square",
    class: "fab fa-youtube-square",
    identifier: "fa-youtube-square",
    type: "brands",
    variant: "fab",
  },
  {
    name: "zhihu",
    class: "fab fa-zhihu",
    identifier: "fa-zhihu",
    type: "brands",
    variant: "fab",
  },
];
//total 1608 icons
export const fontAwesomeIcons = [
  ...solidFontAwesomeIcons,
  ...regularFontAwesomeIcons,
  ...brandsFontAwesomeIcons,
];
