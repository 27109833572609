import React from "react";
import { useBuilder } from "../../../../../../../hooks/builder-context/BuilderContext";
import { google_font_family_classes } from "../../../../../../../ui_libs/Constants/constant";

const HeadingsFontFamily = ({
  label,
  font_family_value,
  handleChange,
  name,
}) => {
  const { rootValues } = useBuilder();
  return (
    <div className="col-6 ">
      <div className="mb-3">
        <label className="custom-label ">{label}</label>
        <select
          value={font_family_value}
          className="form-select form-select-lg custom-label"
          name={name}
          onChange={handleChange}
        >
          {google_font_family_classes.map((data, index) => {
            return (
              <option
                className="custom-label"
                key={index}
                value={data.css_rule == "'Roboto', sans-serif" ? "'Montserrat', sans-serif" : data.css_rule}
              >
                {data.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default HeadingsFontFamily;
