import { Icon } from "@iconify/react";
import { Tooltip, Typography } from "@mui/material";
import React from "react";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import CustomSpacingWithSlider from "../../Custom-Spacing/CustomSpacingWithSlider";

export default function ListStyle({
  handleChangeInClass,
  classes,
  listdirection,
  handleResetSpacing,
  padding_classes,
  Reset,
  updateElementSpacing,
  handleUpdateSpaceClass,
  handleGetSpaceClass,
  handleGetCustomSpace,
  margin_classes,
}) {
  return (
    <div>
      <div className="col-12 px-3 mb-2">
        <div className="col">
          <label className="form-label custom-label">List Direction:</label>
        </div>
        <div className="col-12">
          {classes.map((val, index) => (
            <div key={index} class="form-check-inline">
              <Tooltip title={val.label} arrow placement="top">
                <div
                  style={{
                    border:
                      listdirection === val.value
                        ? "1px solid #023373"
                        : "1px solid #e6e6e6",
                    padding: "0px 16px",
                    cursor: "pointer",
                    borderRadius: "2px",
                  }}
                  onClick={() =>
                    handleChangeInClass("listdirection", val.value, classes)
                  }
                >
                  <Icon icon={val.icon_name} rotate={val.rotate} />
                </div>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
      <div className="col-12 px-3 mb-2">
        <div className="row">
          <div className="col">
            <Typography variant="body2">Padding</Typography>
          </div>
          {/* <div className="col d-flex justify-content-end">
            <Tooltip title="Reset Paddings" arrow placement="top">
              <span>
                <RotateLeftIcon
                  className="pointer"
                  onClick={() => handleResetSpacing()}
                />
              </span>
            </Tooltip>
          </div> */}
        </div>
        <div className="">
          {/* top */}
          <CustomSpacingWithSlider
            ResetValue={Reset}
            myclass={padding_classes}
            updateSelectedElementSpacing={updateElementSpacing}
            updateSelectedElementClass={handleUpdateSpaceClass}
            getSelectedElementClassByType={handleGetSpaceClass}
            getSelectedElementStyleByProperty={handleGetCustomSpace}
            spacing_type="padding"
            dimention="padding-top"
            label="top"
            paddingClass="wp-pt-"
          />

          {/* bottom */}
          <CustomSpacingWithSlider
            ResetValue={Reset}
            myclass={padding_classes}
            updateSelectedElementSpacing={updateElementSpacing}
            updateSelectedElementClass={handleUpdateSpaceClass}
            getSelectedElementClassByType={handleGetSpaceClass}
            getSelectedElementStyleByProperty={handleGetCustomSpace}
            spacing_type="padding"
            dimention="padding-bottom"
            label="bottom"
            paddingClass="wp-pb-"
          />

          {/* left */}
          <CustomSpacingWithSlider
            ResetValue={Reset}
            myclass={padding_classes}
            updateSelectedElementSpacing={updateElementSpacing}
            updateSelectedElementClass={handleUpdateSpaceClass}
            getSelectedElementClassByType={handleGetSpaceClass}
            getSelectedElementStyleByProperty={handleGetCustomSpace}
            spacing_type="padding"
            dimention="padding-left"
            label="left"
            paddingClass="wp-pl-"
          />

          {/* right */}
          <CustomSpacingWithSlider
            ResetValue={Reset}
            myclass={padding_classes}
            updateSelectedElementSpacing={updateElementSpacing}
            updateSelectedElementClass={handleUpdateSpaceClass}
            getSelectedElementClassByType={handleGetSpaceClass}
            getSelectedElementStyleByProperty={handleGetCustomSpace}
            spacing_type="padding"
            dimention="padding-right"
            label="right"
            paddingClass="wp-pr-"
          />
        </div>
      </div>
      <div className="col-12 px-3 mb-2">
        <div className="col mb-1">
          <Typography variant="body2">Margin</Typography>
        </div>
        <CustomSpacingWithSlider
          ResetValue={Reset}
          myclass={margin_classes}
          updateSelectedElementSpacing={updateElementSpacing}
          updateSelectedElementClass={handleUpdateSpaceClass}
          getSelectedElementClassByType={handleGetSpaceClass}
          getSelectedElementStyleByProperty={handleGetCustomSpace}
          spacing_type="marginTop"
          dimention="margin-top"
          label="top"
          paddingClass="wp-mt-"
        />
        <CustomSpacingWithSlider
          ResetValue={Reset}
          myclass={margin_classes}
          updateSelectedElementSpacing={updateElementSpacing}
          updateSelectedElementClass={handleUpdateSpaceClass}
          getSelectedElementClassByType={handleGetSpaceClass}
          getSelectedElementStyleByProperty={handleGetCustomSpace}
          spacing_type="marginBottom"
          dimention="margin-bottom"
          label="bottom"
          paddingClass="wp-mb-"
        />
        <CustomSpacingWithSlider
          ResetValue={Reset}
          myclass={margin_classes}
          updateSelectedElementSpacing={updateElementSpacing}
          updateSelectedElementClass={handleUpdateSpaceClass}
          getSelectedElementClassByType={handleGetSpaceClass}
          getSelectedElementStyleByProperty={handleGetCustomSpace}
          spacing_type="marginLeft"
          dimention="margin-left"
          label="left"
          paddingClass="wp-ml-"
        />
        <CustomSpacingWithSlider
          ResetValue={Reset}
          myclass={margin_classes}
          updateSelectedElementSpacing={updateElementSpacing}
          updateSelectedElementClass={handleUpdateSpaceClass}
          getSelectedElementClassByType={handleGetSpaceClass}
          getSelectedElementStyleByProperty={handleGetCustomSpace}
          spacing_type="marginRight"
          dimention="margin-right"
          label="right"
          paddingClass="wp-mr-"
        />
      </div>
    </div>
  );
}
