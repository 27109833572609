import {
  Button,
  Divider,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import CloseIcon from "@mui/icons-material/Close";
import {
  FontWeightBar,
  IconSelect,
  ImageAlignBar,
  MarginSpacing,
  PaddingSpacing,
  TextAlignBar,
  TextDecorationBar,
  TextItalicBar,
  TextTransformBar,
} from "../Right-Menu/components";
import { fontAwesomeIcons } from "../../../../ui_libs/Constants/fontAwesomeIcons";
import {
  animate_animation_list,
  button_alignment_classes,
  button_radius_classes,
  button_variant_classes,
  border_width_classes,
  colors,
  font_sizes_classes,
  font_weight_classes,
  google_font_family_classes,
  image_alignment_classes,
  image_size_classes,
  image_transform_classes,
  italic_classes,
  line_height_classes,
  text_alignment_classes,
  text_decoration_classes,
  text_transform_classes,
  button_width_classes,
} from "../../../../ui_libs/Constants/constant";
import ButtonProperty from "../Right-Menu/components/Buton-Property/ButtonProperty";
import AnimationSelect from "../Right-Theme-Menu/components/Animations/AnimationSelect";
import MuiSelectClasses from "../Right-Theme-Menu/components/MuiSelect/MuiSelectClasses";
import ButtonTransformBar from "../Right-Menu/components/ButtonRadius-Transform-Bar/ButtonTransformBar";
import ButtonVariant from "../Right-Menu/components/Button-Variant/ButtonVariant";
import BorderWidth from "../Right-Menu/components/Border-Width/BorderWidth";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { FormMenu } from "../Right-Section-Settings-Menu/components";
import {
  _find_element_has_tag,
  _find_section_of_element,
} from "../../../../hooks/builder-context/utils";
import ButtonWidth from "./component/ButtonWidth";
import GenerateGradient from "../../../../components/Generate-Gradient/GenerateGradient";
import { _get_book_a_call_event_listing } from "../../../../DAL/BookACallEvents/BookACall";

const ButtonEditor = () => {
  const previewIconRef = useRef();
  const {
    buttonEditorAnchorEl,
    setButtonEditorAnchorEl,
    getSelectedElement,
    iconEditorPosition,
    handleChangeInEditableInputs,
    handleRemoveElement,
    updateSelectedElementClass,
    setSelectedElementAttributes,
    handleUpdateElement,
    getSelectedElementAttributes,
    setRightMenuOpen,
    rootValues,
    selectedElementAttributes,
    getEditAbleInputs,
    SaveActionToStack,
    updateParentLink,
    updateParentLinkTarget,
    updateParentLinkToFunnel,
    getParentLink,
    getSelectedElementStyleByProperty,
    selectedElement,
    getSelectedElementClassByType,
    getIsDevState,
    getSelectedSectionForm,
    setSelectedSectionSettingId,
    funnelDetails,
    updateAttributeOfSelectedElement,
    setBookACallEventListing,
    bookACallEventListing,
  } = useBuilder();
  const [textColor, setTextColor] = useState(rootValues.primary_text_color);
  const { enqueueSnackbar } = useSnackbar();
  const [discardChanges, setDiscardChanges] = useState(false);
  const [isform, setIsform] = useState(false);
  const [hideOnClickMenu, setHideOnClickMenu] = useState(false);
  const [stepMessageShow, setStepMessageShow] = useState(false);

  const [classes, setClasses] = useState({
    fontFamily: "",
    fontSize: "",
    fontWeight: "",
    animation: "",
    textTransform: "",
    textDecoration: "",
    lineHeight: "",
    textAlign: "",
    italic: "",
    imageAlign: "",
    imageTransform: "",
    objectFit: "",
    imageSize: "",
    buttonwidth: "",
  });
  const [selectedTab, setSelectedTab] = useState(0);
  const [ResetBorder, setResetBorder] = useState(false);
  const [LinkType, setLinkType] = useState("custom");
  const [sectionDatasets, setSectionDatasets] = useState([]);
  const [selectValue, setSelectValue] = useState("");
  const [groupPage_list, setGroupPage_list] = useState([]);

  const getIconClass = () => {
    let element = getSelectedElement();
    let className = element?.getAttribute("class");
    return className;
  };
  const handleSave = () => {
    if (isParentAnchorTag()) {
      updateParentLink(selectedElementAttributes.href);
      updateParentLinkTarget(selectedElementAttributes.target);
      updateParentLinkToFunnel(selectedElementAttributes);
    } else {
      handleUpdateElement();
    }
    enqueueSnackbar("Link Updated Successfully", {
      variant: "success",
    });
    setSelectValue("");
  };

  const handleClose = () => {
    setButtonEditorAnchorEl(null);
    setSelectedTab(0);
    setIsform(false);
    setHideOnClickMenu(false);
  };

  const handleRemove = () => {
    handleRemoveElement();
    handleClose();
  };
  const isParentAnchorTag = () => {
    let isParentAnchor = false;
    let element = getSelectedElement();

    if (element) {
      if (element.parentElement) {
        if (element.parentElement.nodeName === "A") {
          isParentAnchor = true;
        }
      }
    }

    return isParentAnchor;
  };
  const handleChangeInClass = (key, value, class_list) => {
    setClasses({ ...classes, [key]: value });
    const payload = {
      class_list,
      value,
    };
    updateSelectedElementClass(payload);
  };
  const handleChangeButtonAlignment = (key, value, class_list) => {
    let element = getSelectedElement();
    let Max_Count = 1;
    if (element.tagName == "BUTTON" && element.parentNode.tagName == "A") {
      Max_Count = 2;
    }
    Remove_wp_Row_Div(element);
    while (element.parentNode.children.length == 1) {
      element = element.parentNode;
      Remove_wp_Row_Div(element);
    }
    let multiple_button = [
      ...element.getElementsByTagName("a"),
      ...element.getElementsByTagName("button"),
    ];

    if (multiple_button.length > Max_Count) {
      setClasses({ ...classes, [key]: value });
      return "";
    }

    class_list.map((cl) => {
      if (element && element.classList) {
        element.classList.remove(cl.value);
      }
    });
    if (value) {
      element.classList.add("d-flex");
      element.classList.add(value);
    } else {
      element.classList.remove("d-flex");
    }
    setClasses({ ...classes, [key]: value });
  };
  const handleChangeInAnimation = (key, value, identifier, class_list) => {
    setClasses({ ...classes, [key]: value });
    const payload = {
      class_list,
      value: value,
      identifier: identifier,
    };
    updateSelectedElementClass(payload);
    updateAttributeOfSelectedElement("data-animation", value);
  };
  const Remove_wp_Row_Div = (element) => {
    const wp_row_block_list = element.querySelector(".wb-preview-div");

    if (wp_row_block_list) {
      wp_row_block_list.remove();
    }
  };
  const getproperty = (property) => {
    let element = getSelectedElement();
    let computedStyles = window.getComputedStyle(element);
    let color = computedStyles.getPropertyValue(property);
    return color;
  };
  const ResetProperty = (property) => {
    let element = getSelectedElement();
    element.style.removeProperty(property);
    return getproperty(property);
  };
  const handleChangeProperty = (property, value) => {
    SaveActionToStack();
    let element = getSelectedElement();
    if (value == rootValues.secondary_color) {
      element.style.setProperty(
        property,
        `var(--wb-website-theme-secondary-color)`,
        "important"
      );

      return;
    }
    element.style.setProperty(property, value, "important");
  };

  const handleResetBorder = () => {
    let element = getSelectedElement();
    element.style.removeProperty("border-width");
    updateSelectedElementClass({
      class_list: border_width_classes(),
      value: "",
    });
    if (selectedElementAttributes.type === "A" || isParentAnchorTag()) {
      element.style.removeProperty("border-style");
    }
    setResetBorder(!ResetBorder);
  };
  const updateSelectedElementBorderWidth = (property, name, value) => {
    let element = getSelectedElement();
    if (value == 0) {
      element.style.removeProperty(property);
      if (selectedElementAttributes.type === "A" || isParentAnchorTag()) {
        element.style.removeProperty("border-style");
      }
    } else {
      value = value + "rem";
      element.style.setProperty(property, value, "important");
      if (selectedElementAttributes.type === "A" || isParentAnchorTag()) {
        property = property.replace("width", "style");
        element.style.setProperty(property, "solid", "important");
      }
    }
  };
  const updateSelectedElementBorderWidthClass = (property) => {
    const payload = {
      class_list: property.class_list,
      value: property.value,
    };
    let element = getSelectedElement();
    if (property.value == "") {
      element.classList.add("border-0");
    } else {
      element.classList.remove("border-0");
    }
    updateSelectedElementClass(payload);
  };

  const getSelectedElementBorderWidth = (property) => {
    const value = getSelectedElementStyleByProperty({
      property: `${property.property}`,
    });
    let val = value.replace("rem", "");
    val = val * 16;
    return val;
  };

  const getSelectedElementBorderWidthClass = (property) => {
    return getSelectedElementClassByType({
      class_list: property.class_list,
    });
  };

  const handleChangeLinkType = (e) => {
    setSelectValue(e.target.value);
    setLinkType(e.target.value);
    if (e.target.value === "redirect-to-next-step") {
      rootValues.page_list.map((page) => {
        if (page._id === rootValues.page_id) {
          if (page.order === groupPage_list.length) {
            setStepMessageShow(true);
          } else {
            setSelectedElementAttributes({
              ...selectedElementAttributes,
              ["href"]: "",
              ["target"]: "",
              ["class"]: "move-to-next",
              ["book_a_call_event_id"]: "",
            });
            setStepMessageShow(false);
          }
        }
      });
    } else {
      setStepMessageShow(false);
      setSelectedElementAttributes({
        ...selectedElementAttributes,
        ["href"]: "",
        ["target"]: "",
        ["class"]: "",
        ["book_a_call_event_id"]: "",
      });
    }
  };

  const handleChangeInSelect = (e) => {
    setSelectValue(e.target.value);

    setSelectedElementAttributes({
      ...selectedElementAttributes,
      ["href"]: e.target.value,
      ["target"]: "",
      ["book_a_call_event_id"]: "",
    });
  };
  const handleChangeInEvent = (value) => {
    setSelectValue(value);

    setSelectedElementAttributes({
      ...selectedElementAttributes,
      ["href"]: "#",
      ["target"]: "",
      ["class"]: "",
      ["book_a_call_event_id"]: value,
    });
  };

  const handleInputChange = (e) => {
    setSelectValue(e.target.value);

    setSelectedElementAttributes({
      ...selectedElementAttributes,
      ["href"]: e.target.value,
      ["book_a_call_event_id"]: "",
    });
  };

  const handleHref = (action) => {
    setSelectValue(action);
    if (action == "add") {
      setSelectedElementAttributes({
        ...selectedElementAttributes,
        ["target"]: "_blank",
      });

      // update parent too if available
      // if (isParentAnchorTag()) {
      //   updateParentLinkTarget("_blank");
      // }
    }
    if (action == "remove") {
      delete selectedElementAttributes["target"];
      setSelectedElementAttributes({ ...selectedElementAttributes });
      // update parent too if available
      // if (isParentAnchorTag()) {
      //   updateParentLinkTarget("");
      // }
    }
  };

  const get_preview_div_sections = () => {
    let datasets = [];
    const preview_div_sections = document.getElementById("preview-div");
    let sections = preview_div_sections.getElementsByTagName("section");
    for (let index = 0; index < sections.length; index++) {
      let section = sections.item(index);

      datasets.push({
        ...section.dataset,
        section_id: "#" + section.dataset.section_id,
        section_title: section.dataset.section_title
          ? section.dataset.section_title !== "undefined"
            ? section.dataset.section_title
            : index + 1
          : index + 1,
      });
    }
    setSectionDatasets(datasets);
  };
  const setAttributeStates = (data) => {
    console.log(data, "ndndndndnndndndn");
    let obj = {};
    let attributes = [];
    obj["type"] = data.type;
    data.attributes.forEach((attr) => {
      attributes.push(attr);
    });
    attributes.forEach((atr) => {
      obj[atr.name] = atr.value;
    });

    // get parent value if parent is anchor tag
    if (isParentAnchorTag()) {
      const link = getParentLink();
      obj.href = link.href;
      obj.target = link.target;
      obj.class = link.classList;
    }
    setLinkType("custom");
    if (obj.href) {
      // check pages link
      rootValues.page_list.map((page) => {
        if (page.page_slug == obj.href.split(".")[0]) {
          setLinkType("pages");
        }
      });

      // check section link
      if (findLinks(obj.href)) {
        setLinkType("section");
      }

      // abcdef
    }

    if (obj.class?.includes("move-to-next")) {
      setLinkType("redirect-to-next-step");
    }
    if (obj.class?.includes("wp-book-a-call-modal-button")) {
      setLinkType("book_a_call_event");
    }
    setSelectedElementAttributes(obj);
    if (obj.name && obj.name?.includes("wb-payment-plan-button")) {
      setHideOnClickMenu(true);
    }
  };
  const findLinks = (link) => {
    let is_match = false;
    sectionDatasets.forEach((section) => {
      if (section.section_id === link) {
        is_match = true;
      }
    });
    return is_match;
  };

  const handleGetEventListing = async () => {
    const resp = await _get_book_a_call_event_listing(rootValues.page_group_id);
    console.log(resp, "_get_book_a_call_event_listing");
    if (resp.code === 200) {
      setBookACallEventListing(resp.data);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (buttonEditorAnchorEl) {
      get_preview_div_sections();
      let attributesData = null;
      if (
        buttonEditorAnchorEl.target.tagName === "SPAN" &&
        isParentAnchorTag(buttonEditorAnchorEl.target)
      ) {
        attributesData = getSelectedElementAttributes(
          buttonEditorAnchorEl.target.parentElement
        );
      } else {
        attributesData = getSelectedElementAttributes();
      }
      setAttributeStates(attributesData);
      if (rootValues.page_group_id) {
        handleGetEventListing();
      }
    }
    setSelectValue("");
  }, [buttonEditorAnchorEl, discardChanges]);

  const SelectedTab1 = () => {
    return (
      <Stack spacing={3} sx={{ minHeight: 200, padding: 2 }}>
        <div className="col-12">
          <div>
            <textarea
              className="w-100 form-control"
              id="edit-able-text-input"
              label="Text"
              multiline
              rows={4}
              value={getEditAbleInputs("text")}
              onChange={(e) =>
                handleChangeInEditableInputs("text", e.target.value)
              }
              autoFocus
            ></textarea>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip title="Update Text" placement="top" arrow>
                <Button
                  size="small"
                  variant="contained"
                  type="button"
                  className="my-2"
                  onClick={() => {
                    handleUpdateElement();
                    enqueueSnackbar("Text Updated", { variant: "success" });
                  }}
                  sx={{ padding: "0.1rem 0.75rem", fontSize: "0.8rem" }}
                >
                  Update
                </Button>
              </Tooltip>
            </div>
          </div>
          {/* font-fmily */}
          <div className="col-12">
            <div className="mb-3">
              <MuiSelectClasses
                label="family"
                handleChange={handleChangeInClass}
                data={google_font_family_classes}
                value={classes.fontFamily}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              {/* font-size */}
              <div className="mb-3">
                <label
                  for="edit-able-text-input"
                  className="form-label custom-label"
                >
                  Font Size
                </label>

                <select
                  className="w-100 form-control custom-icon-select"
                  value={classes.fontSize}
                  onChange={(e) =>
                    handleChangeInClass(
                      "fontSize",
                      e.target.value,
                      font_sizes_classes
                    )
                  }
                >
                  <option value="">Reset</option>
                  {font_sizes_classes.map((fs, i) => (
                    <option key={i} value={fs.value}>
                      {fs.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Line height */}
            <div className="col-6">
              <div className="mb-3">
                <label
                  for="edit-able-text-input"
                  className="form-label custom-label"
                >
                  Line Height
                </label>

                <select
                  className="w-100 form-control custom-icon-select"
                  value={classes.lineHeight}
                  onChange={(e) =>
                    handleChangeInClass(
                      "lineHeight",
                      e.target.value,
                      line_height_classes
                    )
                  }
                >
                  <option value="">Reset</option>
                  {line_height_classes.map((fs, i) => (
                    <option key={i} value={fs.value}>
                      {fs.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-12">
            <ButtonProperty
              GetProperty={getproperty}
              label={"Text Color"}
              ResetProperty={ResetProperty}
              property={"color"}
              handleChangeProperty={handleChangeProperty}
              colors={colors}
              rootValues={rootValues}
            />
          </div>
        </div>
      </Stack>
    );
  };
  const SelectedTab2 = () => {
    return (
      <Stack spacing={2} sx={{ minHeight: 200, padding: 2 }}>
        <div className="col-12">
          <div>
            <label className="form-label custom-label">Border Radius</label>
          </div>
          <ButtonTransformBar
            ButtonTransform={classes.imageTransform}
            handleChangeInClass={handleChangeInClass}
            classesList={button_radius_classes}
          />
        </div>
        <div>
          <div className="row mt-0">
            {/* Text Align */}
            <div className="col-6">
              <label
                for="edit-able-text-input"
                className="form-label custom-label"
              >
                Text Align
              </label>
              <TextAlignBar
                textAlign={classes.textAlign}
                handleChangeInClass={handleChangeInClass}
                classesList={text_alignment_classes}
              />
            </div>
            {/* float alignment */}
            <div className="col-6">
              <label
                for="edit-able-text-input"
                className="form-label custom-label"
              >
                Float Alignment
              </label>
              <ImageAlignBar
                imageAlign={classes.imageAlign}
                handleChangeInClass={handleChangeButtonAlignment}
                classesList={button_alignment_classes}
                // classesList={image_alignment_classes}
              />
            </div>
          </div>
          {/* font-weight */}
          <div className="col-12 pt-1">
            <FontWeightBar
              fontWeight={classes.fontWeight}
              handleChangeInClass={handleChangeInClass}
              classesList={font_weight_classes}
            />
          </div>
          {/* text_transform */}
          <div className="col-12 pt-1">
            <TextTransformBar
              textTransform={classes.textTransform}
              handleChangeInClass={handleChangeInClass}
              classesList={text_transform_classes}
            />
          </div>
          {/* text_decoration */}
          <div className="col-12 pt-1">
            <TextDecorationBar
              textDecoration={classes.textDecoration}
              handleChangeInClass={handleChangeInClass}
              classesList={text_decoration_classes}
            />
          </div>
          {/* text_italic */}
          <div className="col-12 pt-1">
            <TextItalicBar
              textItalic={classes.italic}
              handleChangeInClass={handleChangeInClass}
              classesList={italic_classes}
            />
          </div>
        </div>
        <div className="col-12 mb-3">
          <div>
            <label className="form-label custom-label">Button Variant</label>
          </div>
          <ButtonVariant
            handleChangeInClass={handleChangeInClass}
            classesList={button_variant_classes}
          />
        </div>

        {/* background color */}
        <ButtonProperty
          GetProperty={getproperty}
          label={"Background Color"}
          ResetProperty={ResetProperty}
          property={"background-color"}
          handleChangeProperty={handleChangeProperty}
          colors={colors}
        />

        <GenerateGradient
          handleAddGradient={handleChangeProperty}
          getColumnproperty={() => {
            let backgroundImage = getproperty("background-image");
            if (backgroundImage) {
              const gradientPattern =
                /^(\s*((linear|radial|conic|repeating-linear|repeating-radial|repeating-conic)-gradient\(.+?\)|((rgba?|hsla?|#?[A-Fa-f0-9]{6}|#?[A-Fa-f0-9]{3})\(.+?\))\s*\d*%?\s*,?\s*)+)+$/;

              if (!gradientPattern.test(backgroundImage)) {
                backgroundImage = "";
              }
            }
            return backgroundImage;
          }}
          removeColumnproperty={ResetProperty}
        />

        {/* border color */}
        <ButtonProperty
          GetProperty={getproperty}
          label={"Boder Color"}
          ResetProperty={ResetProperty}
          property={"border-color"}
          handleChangeProperty={handleChangeProperty}
          colors={colors}
        />

        <BorderWidth
          handleResetBorder={handleResetBorder}
          ResetBorder={ResetBorder}
          border_classes={border_width_classes}
          updateSelectedElementBorderWidth={updateSelectedElementBorderWidth}
          updateSelectedElementBorderWidthClass={
            updateSelectedElementBorderWidthClass
          }
          getSelectedElementBorderWidthClass={
            getSelectedElementBorderWidthClass
          }
          getSelectedElementBorderWidth={getSelectedElementBorderWidth}
        />
      </Stack>
    );
  };
  const SelectedTab3 = () => {
    return (
      <Stack spacing={3} sx={{ minHeight: 200, padding: 2 }}>
        <div className="col-12 mb-2">
          <Typography>Link to</Typography>

          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12 mb-3">
                <div>
                  <div className="custom">
                    <select
                      value={LinkType}
                      onChange={(e) => handleChangeLinkType(e)}
                      name="linkSelect"
                      id="linkSelect"
                      className="form-control custom-icon-select form-control-sm pt-1"
                    >
                      <option value="custom">Custom Link</option>
                      <option value="pages">Pages</option>
                      <option value="section">Section</option>
                      {rootValues.page_group_id !== "" &&
                        funnelDetails?.page_group_type !==
                          "generic_website" && (
                          <option value="redirect-to-next-step">
                            Redirect To Next Step
                          </option>
                        )}
                      {rootValues.page_group_id !== "" && (
                        <option value="book_a_call_event">
                          Attach Book A Call Event Modal
                        </option>
                      )}
                    </select>
                  </div>
                  {stepMessageShow && (
                    <div className="mt-2">
                      <span style={{ fontSize: "12px" }}>
                        There are no additional steps after this page.*
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {LinkType === "section" && (
              <div className="row align-items-center">
                <div className="col-12 ">
                  <div>
                    <span>
                      <Tooltip arrow placement="top" title="Link">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-link"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                          <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                        </svg>
                      </Tooltip>
                    </span>
                    <div className="custom">
                      <select
                        value={selectedElementAttributes?.href?.replace(
                          "/",
                          ""
                        )}
                        onChange={(e) => handleChangeInSelect(e)}
                        name="linkSelect"
                        id="linkSelect"
                        className="form-control custom-icon-select form-control-sm"
                      >
                        <option value={"#"}>Select any section </option>
                        {!sectionDatasets.length < 1 &&
                          sectionDatasets.map((data, i) => {
                            return (
                              <option key={i} value={data.section_id}>
                                {data.section_title}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {LinkType === "pages" && (
              <div className="row align-items-center">
                <div className="col-9 ">
                  <div>
                    <span>
                      <Tooltip arrow placement="top" title="Link">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-link"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                          <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                        </svg>
                      </Tooltip>
                    </span>
                    <div className="custom">
                      <select
                        value={selectedElementAttributes?.href?.replace(
                          "/",
                          ""
                        )}
                        onChange={(e) => handleChangeInSelect(e)}
                        name="linkSelect"
                        id="linkSelect"
                        className=" form-control custom-icon-select form-control-sm"
                      >
                        <option value={"#"}>Select any page </option>
                        {groupPage_list?.length > 0 &&
                          groupPage_list.map((page, i) => {
                            return (
                              <option key={i} value={page.page_slug + ".html"}>
                                {page.page_name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-3 mt-1 pt-3 d-flex justify-content-end">
                  <span>
                    <Tooltip title={"Reset"} placement="top" arrow>
                      <span
                        className={`wb-icons-box ${
                          !selectedElementAttributes.target &&
                          "wb-icons-box-active"
                        }`}
                        onClick={(e) => handleHref("remove")}
                      >
                        <Icon icon="ant-design:stop-outlined" />
                      </span>
                    </Tooltip>
                  </span>
                  <span>
                    <Tooltip title={"Open in new tab"} placement="top" arrow>
                      <span
                        onClick={(e) => handleHref("add")}
                        className={
                          selectedElementAttributes.target === "_blank"
                            ? "wb-icons-box-active"
                            : "wb-icons-box"
                        }
                      >
                        <Icon icon="ic:baseline-open-in-new" />
                      </span>
                    </Tooltip>
                  </span>
                </div>
              </div>
            )}
            {/* {LinkType === "redirect-to-next-step" && (
              <div className="col-3 mt-1 pt-3 d-flex justify-content-end">
                <span>
                  <Tooltip title={"Reset"} placement="top" arrow>
                    <span
                      className={`wb-icons-box ${
                        !selectedElementAttributes.target &&
                        "wb-icons-box-active"
                      }`}
                      onClick={(e) => handleHref("remove")}
                    >
                      <Icon icon="ant-design:stop-outlined" />
                    </span>
                  </Tooltip>
                </span>
                <span>
                  <Tooltip title={"Open in new tab"} placement="top" arrow>
                    <span
                      onClick={(e) => handleHref("add")}
                      className={
                        selectedElementAttributes.target === "_blank"
                          ? "wb-icons-box-active"
                          : "wb-icons-box"
                      }
                    >
                      <Icon icon="ic:baseline-open-in-new" />
                    </span>
                  </Tooltip>
                </span>
              </div>
            )} */}

            {LinkType === "custom" && (
              <>
                <div className="row align-items-center">
                  <div className="col-9">
                    <div>
                      <span>
                        <Tooltip arrow placement="top" title="Link">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-link"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                            <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                          </svg>
                        </Tooltip>
                      </span>
                      <div className="custom">
                        <input
                          value={selectedElementAttributes.href}
                          onChange={(e) => handleInputChange(e)}
                          name="link"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-3 mt-1 pt-3 d-flex justify-content-end">
                    <span>
                      <Tooltip title={"Reset"} placement="top" arrow>
                        <span
                          className={`wb-icons-box ${
                            !selectedElementAttributes.target &&
                            "wb-icons-box-active"
                          }`}
                          onClick={(e) => handleHref("remove")}
                        >
                          <Icon icon="ant-design:stop-outlined" />
                        </span>
                      </Tooltip>
                    </span>
                    <span>
                      <Tooltip title={"Open in new tab"} placement="top" arrow>
                        <span
                          onClick={(e) => handleHref("add")}
                          className={
                            selectedElementAttributes.target === "_blank"
                              ? "wb-icons-box-active"
                              : "wb-icons-box"
                          }
                        >
                          <Icon icon="ic:baseline-open-in-new" />
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                </div>
              </>
            )}

            {LinkType === "book_a_call_event" && (
              <div className="row align-items-center">
                <div className="col-12 ">
                  <div>
                    <span>
                      <Tooltip arrow placement="top" title="Link">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-link"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                          <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                        </svg>
                      </Tooltip>
                    </span>
                    <div className="custom">
                      <select
                        value={selectedElementAttributes?.book_a_call_event_id}
                        onChange={(e) => handleChangeInEvent(e.target.value)}
                        name="linkSelect"
                        id="linkSelect"
                        className="form-control custom-icon-select form-control-sm"
                      >
                        <option hidden value={"#"}>
                          Select Event
                        </option>
                        {bookACallEventListing.length > 0 &&
                          bookACallEventListing.map((data, i) => {
                            return (
                              <option key={i} value={data._id}>
                                {data.event_title}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "0.8rem",
              }}
            >
              {selectValue !== "" && (
                <Tooltip title="Discard Changes" placement="top" arrow>
                  <Button
                    className="me-2"
                    variant="outlined"
                    onClick={() => setDiscardChanges(!discardChanges)}
                    style={{ padding: "0.1rem 0.75rem", fontSize: "0.8rem" }}
                  >
                    Discard
                  </Button>
                </Tooltip>
              )}
              <Tooltip title="Update Link" placement="top" arrow>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  style={{ padding: "0.1rem 0.75rem", fontSize: "0.8rem" }}
                >
                  Update
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </Stack>
    );
  };
  const SelectedTab4 = () => {
    return (
      <Stack spacing={3} sx={{ minHeight: 200, padding: 2 }}>
        <div className="col-12 mb-2">
          <div className="mb-3">
            <AnimationSelect
              label="animation"
              handleChange={handleChangeInAnimation}
              data={animate_animation_list}
              value={classes.animation}
              showFrequent={true}
            />
          </div>
        </div>
      </Stack>
    );
  };
  const SelectedTab5 = () => {
    return (
      <Stack spacing={1} sx={{ minHeight: 200, padding: 2 }}>
        <div>
          <PaddingSpacing />
        </div>
        <div>
          <MarginSpacing />
        </div>
        <div>
          <div className="col">
            <label className="form-label custom-label">Width</label>
          </div>
          <ButtonWidth
            handleChangeInClass={handleChangeInClass}
            classesList={button_width_classes}
            buttonwidth={classes.buttonwidth}
          />
        </div>
      </Stack>
    );
  };

  const handleGetClassByType = () => {
    let classes_data = {
      fontSize: getSelectedElementClassByType({
        class_list: font_sizes_classes,
      }),
      fontWeight: getSelectedElementClassByType({
        class_list: font_weight_classes,
      }),
      textTransform: getSelectedElementClassByType({
        class_list: text_transform_classes,
      }),
      textDecoration: getSelectedElementClassByType({
        class_list: text_decoration_classes,
      }),
      lineHeight: getSelectedElementClassByType({
        class_list: line_height_classes,
      }),
      textAlign: getSelectedElementClassByType({
        class_list: text_alignment_classes,
      }),
      fontFamily: getSelectedElementClassByType({
        class_list: google_font_family_classes,
      }),
      animation: getSelectedElementClassByType({
        class_list: animate_animation_list,
      }),
      italic: getSelectedElementClassByType({
        class_list: italic_classes,
      }),
      imageAlign: getSelectedElementClassByType({
        class_list: image_alignment_classes,
      }),
      imageTransform: getSelectedElementClassByType({
        class_list: image_transform_classes,
      }),
      imageSize: getSelectedElementClassByType({
        class_list: image_size_classes,
      }),
      buttonwidth: getSelectedElementClassByType({
        class_list: button_width_classes,
      }),
    };
    classes_data = { ...GetButtonAlignProperty(classes_data) };

    setClasses(classes_data);
  };
  const GetButtonAlignProperty = (classes_data) => {
    let element = getSelectedElement();
    if (element.tagName == "A" || element.tagName == "BUTTON") {
      classes_data = {
        ...classes_data,
        imageAlign: handleGetButtonAlignment(button_alignment_classes),
      };
    }
    return classes_data;
  };

  const handleGetButtonAlignment = (class_list) => {
    let value = "";
    let element = getSelectedElement();
    Remove_wp_Row_Div(element);
    while (element.parentNode.children.length == 1) {
      element = element.parentNode;
      Remove_wp_Row_Div(element);
    }
    class_list.map((cl) => {
      if (
        element &&
        element.classList &&
        element.classList.contains(cl.value)
      ) {
        value = cl.value;
      }
    });
    return value;
  };

  useEffect(() => {
    if (selectedElement && buttonEditorAnchorEl !== null) {
      handleGetClassByType();
      let form = _find_element_has_tag(selectedElement, "FORM");
      if (form) {
        setIsform(true);
        let section = _find_section_of_element(form);
        const id = section.getAttribute("id");
        setSelectedSectionSettingId(id);
      }
    }
  }, [buttonEditorAnchorEl]);

  useEffect(() => {
    setStepMessageShow(false);
  }, [Boolean(buttonEditorAnchorEl)]);

  useEffect(() => {
    if (rootValues) {
      let values = rootValues;
      let list = [];
      if (
        values.page_group_id !== "" &&
        funnelDetails?.page_group_type !== "generic_website"
      ) {
        values?.page_list?.map((page) => {
          if (page.page_group_id?._id === values.page_group_id) {
            list.push(page);
          }
          if (page.page_group_id === values.page_group_id) {
            list.push(page);
          }
        });
      } else {
        list = values.page_list;
      }
      console.log(values, list, "njenjcnececneiniceicienicei");
      setGroupPage_list(list);
    }
  }, [rootValues]);

  return (
    <>
      <Popover
        // anchorEl={iconAnchorEl}
        id="account-menu"
        open={Boolean(buttonEditorAnchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "auto",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0,
            ml: iconEditorPosition === "left" ? 4 : -4,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
            },
            "& .MuiList-root": {
              padding: "0",
            },
          },
        }}
        transformOrigin={{ horizontal: iconEditorPosition, vertical: "center" }}
        anchorOrigin={{
          horizontal: buttonEditorAnchorEl?.pageX,
          vertical: buttonEditorAnchorEl?.pageY,
        }}
      >
        <Box sx={{ width: 392, height: 470 }}>
          <Stack direction={"column"}>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                position: "sticky",
                top: "0",
                background: "#fff",
                zIndex: 100,
                padding: "8px 0",
              }}
            >
              <Stack direction={"row"} sx={{ paddingX: 2 }}>
                <h5 className="mb-0">Button settings</h5>
              </Stack>
              <div className="d-flex">
                <Tooltip title="Remove Button" arrow placement="top">
                  <IconButton
                    onClick={handleRemove}
                    size="small"
                    className="m-0 p-0"
                  >
                    <DeleteOutlineIcon sx={{ color: "red" }} className="p-1" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Close" arrow placement="top">
                  <IconButton
                    onClick={handleClose}
                    size="small"
                    className="mx-2 m-0 p-0"
                  >
                    <CloseIcon className="p-1" />
                  </IconButton>
                </Tooltip>
              </div>
            </Stack>
            <div
              style={{
                position: "sticky",
                top: "35px",
                background: "#fff",
                zIndex: 100,
                borderBottom: "1px Solid #022859",
              }}
              className="mb-2"
            >
              <div className="d-flex mx-2">
                <div
                  className={
                    selectedTab === 0
                      ? "image-editor-button image-editor-button-active"
                      : "image-editor-button"
                  }
                  onClick={() => setSelectedTab(0)}
                >
                  <span>Button</span>
                </div>
                <div
                  className={
                    selectedTab === 1
                      ? "image-editor-button image-editor-button-active"
                      : "image-editor-button"
                  }
                  onClick={() => setSelectedTab(1)}
                >
                  <span>Style</span>
                </div>
                {!hideOnClickMenu && (
                  <div
                    className={
                      selectedTab === 2
                        ? "image-editor-button image-editor-button-active"
                        : "image-editor-button"
                    }
                    onClick={() => setSelectedTab(2)}
                  >
                    <span>On Click</span>
                  </div>
                )}
                <div
                  className={
                    selectedTab === 3
                      ? "image-editor-button image-editor-button-active"
                      : "image-editor-button"
                  }
                  onClick={() => setSelectedTab(3)}
                >
                  <span>Animation</span>
                </div>
                {getIsDevState() && (
                  <div
                    className={
                      selectedTab === 4
                        ? "image-editor-button image-editor-button-active"
                        : "image-editor-button"
                    }
                    onClick={() => setSelectedTab(4)}
                  >
                    <span>Dev Mode</span>
                  </div>
                )}
              </div>
              {/* <Divider className="mt-0 mx-3 mb-2" /> */}
            </div>

            {selectedTab == 0 && SelectedTab1()}
            {selectedTab == 1 && SelectedTab2()}
            {selectedTab == 2 &&
              (isform ? (
                <div className="px-2 pb-4">
                  <FormMenu
                    getFormData={getSelectedSectionForm}
                    defaultExpanded={true}
                  />
                </div>
              ) : (
                SelectedTab3()
              ))}
            {selectedTab == 3 && SelectedTab4()}
            {selectedTab == 4 && SelectedTab5()}
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

export default ButtonEditor;
