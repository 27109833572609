import React from "react";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import TemplateSearch from "./TemplateSearch";
import { s3builderSource } from "../../../config/config";
import Loader from "../../../components/Loader/Loader";
import { _get_user } from "../../../DAL/localStorage/localStorage";
import SearchNotFound from "../../../components/SearchNotFound";
import {
  _templates_list,
  _page_category_list,
  _templates_category_list,
} from "../../../DAL/template/template";
import NotFound from "../../../components/NotFound";

export default function TemplateList({
  handleCheckAddTemplate,
  isPageLoading,
  setIsPageLoading,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [templateType, setTemplatesType] = useState("free");
  const [allTemplates, setAllTemplates] = useState();
  const [templatesList, setTemplatesList] = useState([]);
  const [pageCategoryListResp, setPageCategoryListResp] = useState();
  const [filteredTemplatesList, setFilteredTemplatesList] = useState([]);

  const [categoriesList, setCategoriesList] = useState([]);
  const [pageCategoriesList, setPageCategoriesList] = useState([]);
  const [selectedcategory, setSelectedcategory] = useState("All Pages");

  const GetTemplateCategory = async () => {
    const resp = await _templates_category_list();
    if (resp.code == 200) {
      setCategoriesList(resp.category);
    } else {
      return;
    }
  };
  const fetchAvailableTemplates = async () => {
    const user = _get_user();
    const postData = {
      app_type: `${user?.type}`,
    };
    const resp = await _templates_list(postData);
    if (resp.code === 200) {
      const data = [];
      const temp = resp.templates.filter((template) => {
        if (template.name == "Blank Theme") {
          data.push(template);
        } else {
          return template;
        }
      });
      data.push(...temp);
      setTemplatesList(data);
      setFilteredTemplatesList(data);
      GetTemplateType(data);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
      setIsPageLoading(false);
    }
  };

  const GetTemplateType = async (data) => {
    if (data) {
      let List = { free: [], paid: [] };
      data.map((template) => {
        if (template.type == 0) {
          List.free.push(template);
        } else List.paid.push(template);
      });
      setAllTemplates({ ...List });
      templateType === "free"
        ? (setFilteredTemplatesList([...List.free]),
          setTemplatesList([...List.free]))
        : (setFilteredTemplatesList([...List.paid]),
          setTemplatesList([...List.paid]));
      const resp = await _page_category_list();
      if (resp.code == 200) {
        setPageCategoryListResp(resp);
        GetPageCategoryList(List.free, resp);
        setIsPageLoading(false);
      } else {
        enqueueSnackbar(resp.message, { variant: "error" });
        setIsPageLoading(false);
      }
    }
  };

  const GetPageCategoryList = async (
    templates,
    resp = pageCategoryListResp
  ) => {
    let array = [];
    array.push({
      name: "All Pages",
      templates: templates,
      order: 0,
      _id: "alltemplatesxcsvbhgbffg6hfjs",
    });
    resp.template_category.map((category) => {
      let templates_list = [];

      templates.map((template) => {
        if (
          template.template_category._id &&
          template.template_category._id === category._id
        ) {
          templates_list.push(template);
        }
      });
      array.push({
        ...category,
        templates: templates_list,
      });
    });
    setPageCategoriesList(array);
    changeTemplatesToShow(array[0]);
  };

  const changeTemplatesToShow = (category) => {
    setSelectedcategory(category.name);
    setTemplatesList(category.templates);
    setFilteredTemplatesList(category.templates);
  };

  useEffect(() => {
    GetTemplateCategory();
    fetchAvailableTemplates();
  }, []);

  if (isPageLoading) {
    return (
      <div style={{ minHeight: "74vh" }}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="row">
        <div
          className="col-2 border-right border-top px-0"
          style={{
            position: "sticky",
            background: "#f0f4f7",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              style: "flex",
              flexDirection: "column",
              padding: "0px",
              overflow: "auto",
              minHeight: "75vh",
            }}
          >
            {pageCategoriesList.map((category, i) => (
              <div
                key={category._id}
                onClick={() => changeTemplatesToShow(category)}
                className="p-2 border-bottom"
              >
                <span
                  style={{
                    display: "flex",
                    padding: "5px 5px 5px 30px",
                    height: "40px",
                    alignItems: "center",
                    borderRadius: "30px",
                  }}
                  className={
                    selectedcategory === category.name
                      ? "pointer wb-add-section-modal-selected text-capitalize"
                      : "pointer wb-add-section-modal text-capitalize"
                  }
                >
                  {category.name}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="col-10">
          <div className="row">
            <TemplateSearch
              categoriesList={categoriesList}
              templatesList={templatesList}
              setTemplatesList={setTemplatesList}
              templateType={templateType}
              allTemplates={allTemplates}
              setTemplatesType={setTemplatesType}
              setFilteredTemplatesList={setFilteredTemplatesList}
              GetPageCategoryList={GetPageCategoryList}
            />

            <div
              style={{
                display: "flex",
                overflow: "auto",
                flexWrap: "wrap",
              }}
            >
              {filteredTemplatesList.length === 0 ? (
                <div className="w-100 my-5 py-5" align="center" colSpan={12}>
                  <NotFound
                    searchQuery={filteredTemplatesList}
                    title={"There is no template in this category."}
                    icon="fluent-mdl2:reopen-pages"
                  />
                </div>
              ) : (
                filteredTemplatesList.map((template) => (
                  <div
                    key={template._id}
                    className="col-lg-4 col-md-6 col-sm-12 mb-3 "
                  >
                    <div className="create-project-card mx-3 p-0 mb-2">
                      <div style={{ height: "200px", width: "100%" }}>
                        <img
                          src={s3builderSource + "/" + template.image}
                          alt=""
                          width="100%"
                          height="198px"
                          className="create-project-image border-bottom"
                        />
                        <div className="create-project-edit-button">
                          <Button
                            variant="contained"
                            sx={{
                              borderRadius: "30px",
                              padding: "5px 23px",
                              margin: "0 8px 12px 0",
                            }}
                            onClick={() => {
                              handleCheckAddTemplate(template);
                            }}
                          >
                            <Icon icon="mi:computer" className="me-1" />
                            Choose Template
                          </Button>
                          {template.name !== "Blank Theme" && (
                            <Button
                              variant="contained"
                              sx={{
                                borderRadius: "30px",
                                padding: "5px 23px",
                                margin: "0 8px 12px 0",
                              }}
                              onClick={() => {
                                window.open(
                                  s3builderSource +
                                    "/" +
                                    template._id +
                                    "/" +
                                    "index.html"
                                );
                              }}
                            >
                              <Icon icon="mdi:eye" className="me-1" />
                              Preview Template
                            </Button>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "0 25px",
                          background: "#fff",
                        }}
                      >
                        <span
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            width: "90%",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          {template.name}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
