import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";
import { appLogoIcon, vissiion_logo_icon } from "../assets";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

let user = sessionStorage.getItem("userType");

export default function Logo({ sx }) {
  return (
    <Box
      component="img"
      src={
        user === "3"
          ? sessionStorage.getItem("brand_logo") !== "null"
            ? sessionStorage.getItem("brand_logo")
            : vissiion_logo_icon
          : appLogoIcon
      }
      sx={{ width: 120, ...sx }}
    />
  );
}
