import { Stack, Tooltip, Typography, capitalize } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Icon } from "@iconify/react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useBuilder } from "../../../../../hooks/builder-context/BuilderContext";
import { useSnackbar } from "notistack";
import { list_all_plan_by_project_id } from "../../../../../DAL/user-created-plans/userCreatedPlans";
import {
  _find_element_has_class,
  _find_parent_row_of_element,
} from "../../../../../hooks/builder-context/utils";

// plan listing from strip
// wb-plan-card
const activePaymentElementListName = [
  { name: "plan_name", value: "wb-plan-name" },
  {
    name: "image",
    value: "wb-plan-image",
  },
  { name: "amount", value: "wb-plan-price" },
  { name: "interval_time", value: "wb-plan-duration" },
  { name: "currency_type", value: "wb-plan-curency" },
  { name: "plan_subtitle", value: "wb-plan-description" },
  {
    name: "button",
    value: "wb-payment-plan-button",
  },
  {
    name: "option_active",
    value: "wb-plan-option-active",
  },
  {
    name: "option_inactive",
    value: "wb-plan-option-inactive",
  },
  {
    name: "option_icon_active",
    value: "wb-plan-option-icon-active",
  },
  {
    name: "option_icon_inactive",
    value: "wb-plan-option-icon-inactive",
  },
  {
    name: "option_label",
    value: "wb-plan-option-label",
  },

  {
    name: "discount",
    value: "wb-plan-discount",
  },
  {
    name: "ribon",
    value: "wb-plan-ribon",
  },
];
const activePaymentPlansListTest = [
  {
    _id: "6465c599ee098b47d003df50",
    project: "641431fb5609a03968902730",
    user: "641405b9dcb48a1a24c16444",
    plan_name: "basic",
    plan_subtitle: "this is a plan",
    amount: 100,
    currency_type: "$",
    payment_type: "recurring",
    interval_time: "month",
    order: 2,
    status: true,
    createdAt: "2023-05-18T06:28:41.193Z",
    updatedAt: "2023-05-18T06:28:41.193Z",
  },
  {
    _id: "6465c86aee098b47d003dfe0",
    project: "641431fb5609a03968902730",
    user: "641405b9dcb48a1a24c16444",
    plan_name: "pro",
    plan_subtitle: "this is a plan",
    amount: 200,
    currency_type: "$",
    payment_type: "one_time",
    interval_time: "month",
    order: 3,
    status: true,
    createdAt: "2023-05-18T06:40:42.549Z",
    updatedAt: "2023-05-18T06:40:42.549Z",
  },
  {
    _id: "6465c9c08f39fa36349967d6",
    project: "641431fb5609a03968902730",
    user: "641405b9dcb48a1a24c16444",
    plan_name: "Ultra",
    plan_subtitle: "this is a plan",
    amount: 300,
    currency_type: "$",
    payment_type: "recurring",
    interval_time: "Month",
    order: 4,
    status: true,
    createdAt: "2023-05-18T06:46:24.014Z",
    updatedAt: "2023-05-18T06:46:24.014Z",
  },
];

let optionTypes = {
  inactive: null,
  active: null,
};

export default function ManagePaymentCard() {
  const { enqueueSnackbar } = useSnackbar();

  // context
  const {
    setPlanSettingsMenuOpen,
    getSelectedSectionPlanByID,
    SaveActionToStack,
    SelectedRow,
    setSelectedRow,
    rootValues,
    getParentNode,
    ReRenderHtml,
    activePaymentPlansList,
    setActivePaymentPlansList,
    handleRemoveElementAndCheckCarousel,
  } = useBuilder();
  // states
  const [paymentCardsElements, setPaymentCardsElements] = useState([]);
  const [paymentCardsElementsParent, setPaymentCardsElementsParent] = useState(
    []
  );
  const [changeInCards, setChangeInCards] = useState(false);
  const [availablePaymentCardElementList, setAvailablePaymentCardElementList] =
    useState({});
  const [selectedPaymentPage, setSelectedPaymentPage] = useState("index");

  /// Functions

  const handleDragEnd = ({ source, destination, ...rest }) => {
    if (source && destination && source.index != destination.index) {
      let newList = [...paymentCardsElementsParent];
      newList.splice(destination.index, 0, newList.splice(source.index, 1)[0]);

      let newdata = "";
      for (let i = 0; i < newList.length; i++) {
        newdata += newList[i].outerHTML;
      }
      SelectedRow.innerHTML = newdata;
      ReRenderHtml();
      setChangeInCards(!changeInCards);
    } else {
      console.log("ERROR", source, destination);
    }
  };
  const handleRemoveColumn = (index) => {
    SaveActionToStack();
    // let column_list = SelectedRow.children;
    let column_list = paymentCardsElements;
    let element = getParentNode(column_list[index]);
    handleRemoveElementAndCheckCarousel(element);
    // element.remove();
    setChangeInCards(!changeInCards);
  };

  const handleMoveup = (source) => {
    let destination = source - 1;
    if (source > 0) {
      let newList = [...paymentCardsElementsParent];
      newList.splice(destination, 0, newList.splice(source, 1)[0]);
      let newdata = "";
      for (let i = 0; i < newList.length; i++) {
        newdata += newList[i].outerHTML;
      }
      SelectedRow.innerHTML = newdata;
      ReRenderHtml();
      setChangeInCards(!changeInCards);
    }
  };

  const handleMovedown = (source) => {
    let destination = source + 1;
    if (source < Children.length) {
      let newList = [...paymentCardsElementsParent];
      newList.splice(destination, 0, newList.splice(source, 1)[0]);
      let newdata = "";
      for (let i = 0; i < newList.length; i++) {
        newdata += newList[i].outerHTML;
      }
      SelectedRow.innerHTML = newdata;
      ReRenderHtml();
      setChangeInCards(!changeInCards);
    }
  };

  const handleDuplicate = (index, plan) => {
    let source = index;
    let _selectedRow = SelectedRow;
    let newList = [...paymentCardsElementsParent];
    let isCrouselItem = _find_element_has_class(
      paymentCardsElementsParent[source],
      "carousel-item"
    );
    if (isCrouselItem) {
      let planCards = isCrouselItem.getElementsByClassName("wb-plan-card");
      let newArr = [];
      if (planCards.length > 0) {
        for (let i = 0; i < planCards.length; i++) {
          newArr.push(planCards[i].parentNode);
        }
      }
      newList = [...newArr];
      source = newList.indexOf(plan.parentNode);
      _selectedRow = _find_parent_row_of_element(newArr[0]);
    }

    let destination = source + 1;

    newList.splice(destination, 0, newList[source].cloneNode(true));
    console.log(
      newList,
      paymentCardsElementsParent,
      destination,
      plan.parentNode,
      source,
      newList[destination],
      "newListnewList"
    );
    newList[destination].setAttribute("block-name", `Block-${newList.length}`);
    let newdata = "";
    for (let i = 0; i < newList.length; i++) {
      newdata += newList[i].outerHTML;
    }
    _selectedRow.innerHTML = newdata;
    ReRenderHtml();
    setChangeInCards(!changeInCards);
  };

  const getPlanIndexFromID = (value) => {
    let result = -1;
    activePaymentPlansList.map((plan, index) => {
      if (plan._id == value) {
        result = index;
      }
    });

    return result;
  };
  const handleSelectPlan = (element, e) => {
    const { value } = e.target;
    if (value == -1) return;
    let planIndex = getPlanIndexFromID(value);
    findElementsContainPlanAttributes(element, planIndex, value);
    setChangeInCards(!changeInCards);
  };
  const findElementsContainPlanAttributes = (element, planIndex, planID) => {
    SaveActionToStack();
    let availableSelectedCardElementsList = [];
    activePaymentElementListName.map((elementName) => {
      let value = getElementContainProperty(element, "name", elementName.value);
      if (value) {
        availableSelectedCardElementsList[elementName.name] = value[0];
      }
    });
    Object.keys(availableSelectedCardElementsList).map((value) => {
      if (activePaymentPlansList[planIndex][value] != null) {
        availableSelectedCardElementsList[
          value
        ].innerHTML = `${activePaymentPlansList[planIndex][value]}`;
        availableSelectedCardElementsList[value].classList.add(
          "text-uppercase"
        );
      }
    });

    // handlePlanOptions(availableSelectedCardElementsList, planIndex);
    if (availableSelectedCardElementsList.button) {
      availableSelectedCardElementsList.button.href = `${selectedPaymentPage}.html?plan_id=${activePaymentPlansList[planIndex]._id}`;
    }
    element.setAttribute("active_plan_id", planID);
    setChangeInCards(!changeInCards);
  };
  const handlePlanOptions = (availableSelectedCardElementsList, planIndex) => {
    let optionParentWraper =
      availableSelectedCardElementsList.option_active.parentNode;
    let newOptionParentWraper = document.createElement("div");
    activePaymentPlansList[planIndex].option.map((option) => {
      let newActiveOption;
      if (option.status) {
        newActiveOption =
          availablePaymentCardElementList.option_active.cloneNode(true);
      } else {
        newActiveOption =
          availablePaymentCardElementList.option_inactive.cloneNode(true);
      }
      let optionLabel = getElementContainProperty(
        newActiveOption,
        "name",
        "wb-plan-option-label"
      );

      if (optionLabel) {
        optionLabel.innerHTML = option.label;
        newOptionParentWraper.append(newActiveOption);
      }
    });
    optionParentWraper.innerHTML = newOptionParentWraper.innerHTML;
  };

  const getElementContainProperty = (element, property, value) => {
    if (!element) return null;
    let result = [];
    result = element.querySelectorAll(`[${property}=${value}]`);

    return result.length > 0 ? result : null;
  };
  const getElementSelectedPlanValue = (element) => {
    let value = -1;
    if (element) {
      value = element.getAttribute("active_plan_id");
    }
    return value ? value : -1;
  };

  const getselectedPaymentPage = (availablePaymentCardElementList) => {
    let result = "";
    if (
      availablePaymentCardElementList &&
      availablePaymentCardElementList.button &&
      availablePaymentCardElementList.button.href
    ) {
      let link = availablePaymentCardElementList.button.href;
      rootValues.page_list.map((page) => {
        if (link.includes(page.page_slug)) {
          result = page.page_slug;
        }
      });
    }

    setSelectedPaymentPage(result);

    return selectedPaymentPage;
  };
  const handleChangePaymentPage = (e) => {
    let value = e.target.value;
    setSelectedPaymentPage(value);
    if (paymentCardsElements?.length > 0) {
      paymentCardsElements.map((card) => {
        let button = getElementContainProperty(
          card,
          "name",
          "wb-payment-plan-button"
        );
        if (button && button.length > 0 && button[0].href) {
          let href = button[0].href;
          let startIndex = href.indexOf(".html");
          let result = href.substring(startIndex, href.length);
          button[0].href = `${value}${result}`;
        }
      });
    }
    setChangeInCards(!changeInCards);
  };
  const getSignAgainstCurrency = (plan, plansList) => {
    switch (plan.currency_type) {
      case "EUR":
        plan.currency_type = "€";
        break;
      case "GBP":
        plan.currency_type = "£";
        break;
      case "CNY":
        plan.currency_type = "¥";
        break;
      case "RUB":
        plan.currency_type = "₽";
        break;

      default:
        plan.currency_type = "$";
        break;
    }
    plansList.push(plan);
  };
  const getPlanList = async () => {
    let resp = await list_all_plan_by_project_id(rootValues.project);
    if (resp.code == 200) {
      let plansList = [];
      resp.data.map((plan) => {
        getSignAgainstCurrency(plan, plansList);
      });
      let list = [];
      plansList.map((plan) => {
        if (
          plan.page_group_id &&
          plan.page_group_id === rootValues.page_group_id
        ) {
          list.push(plan);
        }
      });
      if (list.length > 0) {
        setActivePaymentPlansList(list);
      } else {
        setActivePaymentPlansList(plansList);
      }
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    // setActivePaymentPlansList([...activePaymentPlansListTest]);
  };

  // useeffect

  useEffect(() => {
    getPlanList();
  }, []);

  useEffect(() => {
    const { payment_plan_card } = getSelectedSectionPlanByID();

    let newArr = [];
    setPaymentCardsElements([...payment_plan_card]);
    if (payment_plan_card.length > 0) {
      for (let i = 0; i < payment_plan_card.length; i++) {
        newArr.push(payment_plan_card[i].parentNode);
      }
      setSelectedRow(payment_plan_card[0].parentNode.parentNode);
    }
    setPaymentCardsElementsParent(newArr);
  }, [changeInCards]);

  useEffect(() => {
    if (paymentCardsElements && paymentCardsElements.length > 0) {
      for (let index = 0; index < paymentCardsElements.length; index++) {
        activePaymentElementListName.map((elementName) => {
          let value = getElementContainProperty(
            paymentCardsElements[index],
            "name",
            elementName.value
          );
          if (value) {
            availablePaymentCardElementList[elementName.name] = value[0];
          }
        });
      }
      getselectedPaymentPage(availablePaymentCardElementList);
      setAvailablePaymentCardElementList({
        ...availablePaymentCardElementList,
      });
    }
  }, [paymentCardsElements]);

  return (
    <div className="row ">
      <div className="col-12">
        <DragDropContext onDragEnd={(props) => handleDragEnd(props)}>
          <Droppable droppableId="doprable1">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {paymentCardsElements.map((plan, index) => (
                  <Draggable
                    draggable={false}
                    key={index}
                    draggableId={`dragable-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={`${
                          snapshot.isDragging ? "shadow" : null
                        } row border m-1 mt-2 pb-3 p-1 align-items-center column-block-list`}
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        style={{
                          ...provided.draggableProps.style,
                          background: snapshot.isDragging ? "white" : null,
                        }}
                      >
                        <div className="mb-2 d-flex justify-content-between">
                          <Typography variant="subtitle2">
                            {`Card ${index + 1}:`}
                          </Typography>
                          <div className="d-flex row-reverse justify-content-end">
                            <span>
                              <Tooltip
                                arrow
                                placement="top"
                                title="Move block up"
                              >
                                <ArrowUpwardIcon
                                  sx={{
                                    height: "18px",
                                    width: "18px",
                                  }}
                                  className="edit-able-section-actions-delete"
                                  onClick={() => handleMoveup(index)}
                                />
                              </Tooltip>

                              <Tooltip
                                arrow
                                placement="top"
                                title="Move block down"
                              >
                                <ArrowDownwardIcon
                                  sx={{
                                    height: "18px",
                                    width: "18px",
                                  }}
                                  className="edit-able-section-actions-delete"
                                  onClick={() => handleMovedown(index)}
                                />
                              </Tooltip>

                              <Tooltip
                                arrow
                                placement="top"
                                title="Duplicate block"
                              >
                                <Icon
                                  icon="heroicons-outline:document-duplicate"
                                  width="16"
                                  height="16"
                                  className="edit-able-section-actions-delete"
                                  onClick={() => handleDuplicate(index, plan)}
                                />
                              </Tooltip>

                              <Tooltip
                                arrow
                                placement="top"
                                title="Remove block"
                              >
                                <HighlightOffIcon
                                  sx={{
                                    height: "18px",
                                    width: "18px",
                                  }}
                                  className="edit-able-section-actions-delete"
                                  onClick={() => {
                                    handleRemoveColumn(index);
                                  }}
                                />
                              </Tooltip>
                            </span>
                          </div>
                        </div>

                        <div className="col-12">
                          <span style={{ fontSize: "14px" }}>
                            {`Select plan:`}
                          </span>

                          <div className="col-12 mt-2">
                            <select
                              value={getElementSelectedPlanValue(plan)}
                              onChange={(e) => handleSelectPlan(plan, e)}
                              className="form-select form-select-sm"
                            >
                              <option index hidden value="">
                                select one plan
                              </option>
                              {activePaymentPlansList.map(
                                (plan, plan_index) => (
                                  <option
                                    key={`${index}-${plan_index}`}
                                    value={plan._id}
                                  >
                                    {plan.plan_name}
                                  </option>
                                )
                              )}
                            </select>
                            {activePaymentPlansList.map(
                              (planList, plan_index) => (
                                <div>
                                  {planList._id ===
                                    getElementSelectedPlanValue(plan) && (
                                    <div
                                      className="d-flex justify-content-between mt-2"
                                      style={{ fontSize: "12px" }}
                                    >
                                      Plan Detail:
                                      <div>
                                        <span>
                                          {planList.currency_type}
                                          {planList.amount}/{" "}
                                          {planList.interval_time
                                            ? capitalize(planList.interval_time)
                                            : "One time"}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}
