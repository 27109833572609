import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { margin_classes } from "../../../../../../ui_libs/Constants/constant";
import CustomSpacing from "../../../Custom-Spacing/CustomSpacing";
import CustomSpacingWithSlider from "../../../Custom-Spacing/CustomSpacingWithSlider";
function Margins({
  handleGetMarginCustom,
  handleUpdateMarginCustom,
  handleGetMarginClass,
  handleupdateMarginClass,
}) {
  //done custom
  const handleGetCustomSpacing = (property) => {
    const value = handleGetMarginCustom(property.property);
    return value ? value / 16 + "rem" : "";
  };

  const handleUpdateCustomSpacing = (...property) => {
    handleUpdateMarginCustom(property[0], property[2] * 16);
  };

  const handleGetClassSpacing = (myproperty) => {
    const value = handleGetMarginClass(myproperty);
    return value ? value : 0;
  };

  const handleUpdateClassSpacing = (property) => {
    handleupdateMarginClass(property);
  };

  return (
    <>
      {/* <Accordion className="card">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Margin</Typography>
      </AccordionSummary>
      <AccordionDetails> */}
      <div className="col-12 mb-1">
        <span className="custom-label">Margin:</span>
      </div>
      <div className="">
        <CustomSpacingWithSlider
          myclass={margin_classes}
          updateSelectedElementSpacing={handleUpdateCustomSpacing}
          updateSelectedElementClass={handleUpdateClassSpacing}
          getSelectedElementClassByType={handleGetClassSpacing}
          getSelectedElementStyleByProperty={handleGetCustomSpacing}
          spacing_type="margin-top"
          dimention="margin-top"
          label="top"
          paddingClass={"wp-mt-"}
        />

        <CustomSpacingWithSlider
          myclass={margin_classes}
          updateSelectedElementSpacing={handleUpdateCustomSpacing}
          updateSelectedElementClass={handleUpdateClassSpacing}
          getSelectedElementClassByType={handleGetClassSpacing}
          getSelectedElementStyleByProperty={handleGetCustomSpacing}
          spacing_type="margin-bottom"
          dimention="margin-bottom"
          label="bottom"
          paddingClass={"wp-mb-"}
        />

        <CustomSpacingWithSlider
          myclass={margin_classes}
          updateSelectedElementSpacing={handleUpdateCustomSpacing}
          updateSelectedElementClass={handleUpdateClassSpacing}
          getSelectedElementClassByType={handleGetClassSpacing}
          getSelectedElementStyleByProperty={handleGetCustomSpacing}
          spacing_type="margin-left"
          dimention="margin-left"
          label="left"
          paddingClass={"wp-ml-"}
        />

        <CustomSpacingWithSlider
          myclass={margin_classes}
          updateSelectedElementSpacing={handleUpdateCustomSpacing}
          updateSelectedElementClass={handleUpdateClassSpacing}
          getSelectedElementClassByType={handleGetClassSpacing}
          getSelectedElementStyleByProperty={handleGetCustomSpacing}
          spacing_type="margin-right"
          dimention="margin-right"
          label="right"
          paddingClass={"wp-mr-"}
        />
      </div>
      {/* </AccordionDetails>
    </Accordion> */}
    </>
  );
}

export default Margins;
