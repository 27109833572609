import { motion } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container } from "@mui/material";
// components
import { MotionContainer, varBounceIn } from "../components/animate";
import Page from "../components/Page";
import { client_side_url } from "../config/config";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "80%",
  alignItems: "center",
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(0),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  const GotoClientPortal = () => {
    const baseURL = sessionStorage.getItem("client_base_url");
    const paramsPath = sessionStorage.getItem("source_link");
    let user = sessionStorage.getItem("userType");

    if (paramsPath !== "" && paramsPath !== null) {
      window.location.href = paramsPath;
    } else if (baseURL && user === "3") {
      window.location.href = baseURL + "website-pages";
    } else if (baseURL) {
      window.location.href = baseURL + "/projects";
    } else {
      window.location.href = client_side_url;
    }
  };
  return (
    <RootStyle title="404 Page Not Found | Minimal-UI">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Sorry, page not found!
              </Typography>
            </motion.div>
            <Typography sx={{ color: "text.secondary" }}>
              Sorry, we couldn’t find the page you’re looking for. Perhaps
              you’ve mistyped the URL? Be sure to check your spelling.
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/illustrations/illustration_404.svg"
                sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              />
            </motion.div>

            <Button
              size="large"
              variant="contained"
              onClick={() => GotoClientPortal()}
            >
              Go Back
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
