import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "../../config/config";
import {
  _get_active_helping_categories,
  _get_helping_articles,
} from "../../DAL/helping-articles/helping_articles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import Loader from "../../components/Loader/Loader";
function HelpingArticles() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleClickCategory = (category) => {
    navigate("/helping-articles/" + category._id);
  };

  const fetchAvailableTemplates = async () => {
    const resp = await _get_helping_articles(params.category_id);
    if (resp.code === 200) {
      setArticles(resp.helping_article);
      setIsLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchAvailableTemplates();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="container">
        <div className="row pt-3">
          <div className="col-12 ">
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
              aria-label="delete"
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-12 mb-4 text-center">
            <h5>Welcome To Inovate Builder Help Center</h5>
          </div>

          <div className="col-12 mt-2 mb-4">
            {articles.length === 0 && (
              <div className="text-center">
                <span>No Data Exist</span>
              </div>
            )}
            {articles.map((article) => (
              <Accordion key={article._id} className="card mb-3">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <div className="main-dashboard-website-name text-capitalize">
                      {article.name}
                    </div>
                    <p>{article.short_description}</p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    dangerouslySetInnerHTML={{ __html: article.description }}
                  ></div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default HelpingArticles;
