import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Tooltip from "@mui/material/Tooltip";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Icon } from "@iconify/react";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import { useSnackbar } from "notistack";
import { Button, FormControlLabel, Switch } from "@mui/material";
import DropdownMenuList from "./DropdownMenuList";
import CustomPopover from "../../../../../../components/CustomPopover";

function MenuList({
  getSelectedSectionLists,
  handleReplaceListElements,
  handleClose,
  expandedTrue = null,
}) {
  const [list, setList] = useState(getSelectedSectionLists());
  const [SectionsDataset, setSectionsDataset] = useState([]);
  const { rootValues, funnelDetails, ReRenderHtml } = useBuilder();
  const [linkTypeList, setLinkTypeList] = useState([]);
  const [groupPage_list, setGroupPage_list] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const handleChangeLinkType = (e, i) => {
    let data = linkTypeList;
    data[i][e.target.name] = e.target.value;
    setLinkTypeList([...data]);
  };
  const handleChange = (e, i) => {
    if (e.target.value == "custom") {
    }
    let value = e.target.value;
    if (linkTypeList[i].type === "page") {
      value = value.replace("/", "");
    }
    let data = list.data;
    data[i][e.target.name] = value;
    data[i]["target"] = "";
    setList({ ...list, data });
  };

  const handleChangeTarget = (value, i) => {
    let data = list.data;
    data[i]["target"] = value;
    setList({ ...list, data });
  };
  const handleChangeActive = (value, i) => {
    let data = list.data;

    data.map((li) => {
      if (li.classes?.contains("active")) {
        li.classes.remove("active");
      }
    });
    if (value) {
      data[i].classes.add("active");
    }
    setList({ ...list, data });
  };

  const ShowDropdownItems = (value, i) => {
    let data = list.data;

    data[i].show_dropDown_items = value;

    setList({ ...list, data });
  };

  const findLinks = (sections) => {
    list?.data?.map((li) => {
      let obj = {
        type: "custom",
      };

      sections.map((section) => {
        if (section.section_id === li.link) {
          obj = {
            type: "section",
          };
        }
      });
      rootValues.page_list.map((page) => {
        if (page.page_slug + ".html" == li.link) {
          obj = {
            type: "page",
          };
        }
      });

      setLinkTypeList((prev) => [...prev, obj]);
    });
  };
  const handleRemove = (i) => {
    let data = list.data.map((l) => l);
    data.splice(i, 1);
    setList({ ...list, data });
  };
  const handleRemoveDropdown = (i) => {
    let data = list.data.map((l) => l);
    data[i].children = [];
    data[i].link = "#";
    data[i].target = "";
    setList({ ...list, data });
    handleSave();
    setList(getSelectedSectionLists());
  };
  const get_preview_div_sections = () => {
    let datasets = [];
    const preview_div_sections = document.getElementById("preview-div");
    let sections = preview_div_sections.getElementsByTagName("section");
    for (let index = 0; index < sections.length; index++) {
      let section = sections.item(index);

      datasets.push({
        ...section.dataset,
        section_id: "#" + section.dataset.section_id,
        section_title: section.dataset.section_title
          ? section.dataset.section_title !== "undefined"
            ? section.dataset.section_title
            : index + 1
          : index + 1,
      });
    }
    setSectionsDataset(datasets);
    findLinks(datasets);
  };
  const handleAddNew = () => {
    // setList({
    //   ...list,
    //   data: [
    //     ...list.data,
    //     {
    //       text: "",
    //       link: "",
    //       children: [],
    //       show_dropDown_items: false,
    //     },
    //   ],
    // });
    let data = list.data;
    data.push({
      text: "NavItem",
      link: "",
      children: [],
      show_dropDown_items: false,
    });
    setList({ ...list, data });
    let typelist = [...linkTypeList];
    typelist.push({ type: "custom" });
    setLinkTypeList([...typelist]);
    handleSave();
    let updatedData = getSelectedSectionLists();
    setList(updatedData);
  };

  const handleAddDropdown = (i) => {
    let code = `<ul class="dropdown-menu"><li><a class="dropdown-item wb-dropdown-element" href="#">Dropdown Item</a></li></ul>`;

    const parser = new DOMParser();
    const doc = parser.parseFromString(code, "text/html");
    const ulElement = doc.querySelector("ul");

    let children_array = [];
    children_array.push(ulElement);
    let data = list.data;
    data[i]["children"] = children_array;
    data[i]["link"] = "#";
    data[i].target = "";
    setList({ ...list, data });
    handleSave();
    let updatedData = getSelectedSectionLists();
    updatedData.data[i].show_dropDown_items = true;
    setList(updatedData);
  };
  const handleDragEnd = ({ source, destination, ...rest }) => {
    if (source && destination && source.index != destination.index) {
      let newList = [...list.data];
      newList.splice(destination.index, 0, newList.splice(source.index, 1)[0]);

      setList({ ...list, data: newList });
    } else {
      console.log("ERROR", source, destination);
    }
  };
  const create_class_list = (element, class_list = []) => {
    let index = 0;
    if (element[index]?.class_name?.includes("wp-list-block")) {
      index = 1;
    }
    class_list.push({
      class_name: element[index].class_name,
      tag_name: element[index].main_element.tagName,
    });
    if (element[index].sub_elements.length > 0) {
      create_class_list(element[index].sub_elements, class_list);
    }
    return class_list;
  };

  const handleSave = () => {
    const main = create_class_list(list.list);

    let created_dom_array = [];

    let ul;
    let li;
    let a;

    const elements = list.data.map((data, index) => {
      let target_index = 0;
      let ul_child_append = false;

      if (data.children.length > 0) {
        for (let index = 0; index < data.children.length; index++) {
          if (data.children[index].tagName == "UL") {
            target_index = index;
            ul_child_append = true;
          }
        }
      }
      //
      const element = main.map((el_info, i) => {
        if (i === 0 && index === 0) {
          ul = document.createElement(el_info.tag_name);
          ul.className = el_info.class_name;
        }

        if (i === 1) {
          li = document.createElement(el_info.tag_name);
          let element_classes = el_info.class_name;
          if (ul_child_append === true) {
            if (!element_classes.includes("dropdown")) {
              element_classes = "dropdown " + element_classes;
            }
          } else {
            element_classes = element_classes.replace("dropdown", "");
          }
          li.className = element_classes;
          ul.appendChild(li);
        }

        if (i === 2) {
          a = document.createElement(el_info.tag_name);
          let anchor_classes = el_info.class_name.replace("active", "");

          if (data.classes?.contains("active")) {
            anchor_classes = anchor_classes + " active";
          }
          rootValues.page_list.map((page) => {
            if (page.page_slug + ".html" === data.link) {
              a.href = page.page_slug + ".html";
            } else {
              a.href = data.link;
            }
          });
          a.target = data.target;
          a.innerText = data.text;

          if (ul_child_append === true) {
            if (!anchor_classes.includes("dropdown-toggle")) {
              anchor_classes = "dropdown-toggle wb-disable " + anchor_classes;
            }
            anchor_classes = anchor_classes.replace("active", "");
            a.setAttribute("data-bs-toggle", "dropdown");
            a.setAttribute("aria-expanded", "false");
            a.href = "#";
          } else {
            anchor_classes = anchor_classes
              .replace("dropdown-toggle", "")
              .replace("wb-disable", "");
          }
          a.className = anchor_classes;
          li.appendChild(a);
          if (ul_child_append === true) {
            li.appendChild(data.children[target_index]);
          }
        }
      });

      return element;
    });

    const current_element = document.getElementsByClassName(
      main[0].class_name
    )[0];
    handleReplaceListElements(current_element, ul);
    enqueueSnackbar("Nav List Updated", { variant: "success" });
    ReRenderHtml();
    return ul;
  };

  const ADD_DROPDOWN_MENU_OPTIONS = [
    {
      label: "Add Dropdown",
      icon: "gg:play-list-add",
      handleClick: handleAddDropdown,
    },
    {
      label: "Remove Link",
      icon: "material-symbols:delete-outline",
      handleClick: handleRemove,
    },
  ];
  const REMOVE_DROPDOWN_MENU_OPTIONS = [
    {
      label: "Remove Dropdown",
      icon: "gg:play-list",
      handleClick: handleRemoveDropdown,
    },
    {
      label: "Remove Link",
      icon: "material-symbols:delete-outline",
      handleClick: handleRemove,
    },
  ];
  useEffect(() => {
    get_preview_div_sections();
  }, []);

  useEffect(() => {
    if (rootValues) {
      let values = rootValues;
      let list = [];
      if (
        funnelDetails?.page_group_type !== "generic_website" &&
        values.page_group_id !== ""
      ) {
        values?.page_list?.map((page) => {
          if (page.page_group_id?._id === values.page_group_id) {
            list.push(page);
          }
          if (page.page_group_id === values.page_group_id) {
            list.push(page);
          }
        });
      } else {
        list = values.page_list;
      }
      setGroupPage_list(list);
    }
  }, [rootValues]);

  return (
    <>
      {/* <Accordion className="card" defaultExpanded={expandedTrue}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Menu</Typography>
      </AccordionSummary>
      <AccordionDetails> */}
      <div className="col-12 mb-1">
        <span className="custom-label">Menu:</span>
      </div>
      <div className="dragdrop">
        <DragDropContext onDragEnd={(props) => handleDragEnd(props)}>
          <Droppable droppableId="doprable1">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {list.data.map((li, i) => {
                  return (
                    <>
                      <Draggable
                        key={i}
                        draggableId={`dragable-${i}`}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={`${
                              snapshot.isDragging ? "shadow" : null
                            } row border m-1 mt-2  px-1 py-2 align-items-center`}
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              background: snapshot.isDragging ? "white" : null,
                            }}
                          >
                            <>
                              {/* <div className="col-12 mt-1">
                                <div className="row justify-content-end">
                                  <span
                                    className="ms-auto d-inline-block"
                                    style={{ maxWidth: "2.5rem" }}
                                  >
                                    <button
                                      type="button"
                                      className="btn  extra-small-btn p-1"
                                      onClick={() => handleRemove(i)}
                                    >
                                      <Tooltip
                                        arrow
                                        placement="top"
                                        title="Remove"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="red"
                                          className="bi bi-x-circle"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                      </Tooltip>
                                    </button>
                                  </span>
                                </div>
                              </div> */}
                              <div
                                className={
                                  li.children.length > 0 ? "col-12" : "col-6"
                                }
                              >
                                <span>Title:</span>
                                <input
                                  value={li.text}
                                  onChange={(e) => handleChange(e, i)}
                                  name="text"
                                  className="form-control form-control-sm"
                                />
                              </div>

                              {li.children.length > 0 ? (
                                <div className="col-12">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                      <span>Dropdown Items:</span>
                                      <FormControlLabel
                                        style={{ marginLeft: 10 }}
                                        control={
                                          <Switch
                                            color="primary"
                                            checked={li.show_dropDown_items}
                                            onChange={(e) =>
                                              ShowDropdownItems(
                                                e.target.checked,
                                                i
                                              )
                                            }
                                            name="show_dropDown_items"
                                          />
                                        }
                                      />
                                    </div>
                                    <div>
                                      <CustomPopover
                                        data={i}
                                        menu={REMOVE_DROPDOWN_MENU_OPTIONS}
                                      />
                                    </div>
                                  </div>
                                  {li.show_dropDown_items && (
                                    <div className="ps-3">
                                      <DropdownMenuList
                                        listChild={li.children}
                                        groupPage_list={groupPage_list}
                                        SectionsDataset={SectionsDataset}
                                        listItemindex={i}
                                        handleRemoveDropdown={
                                          handleRemoveDropdown
                                        }
                                        ShowDropdownItems={ShowDropdownItems}
                                      />
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <>
                                  <div className="col-6 ">
                                    <label htmlFor="selectLink">
                                      Choose Link:
                                    </label>
                                    <select
                                      onChange={(e) =>
                                        handleChangeLinkType(e, i)
                                      }
                                      value={
                                        linkTypeList[i]?.type &&
                                        linkTypeList[i]?.type
                                      }
                                      name="type"
                                      id="selectLink"
                                      className=" form-control custom-icon-select form-control-sm"
                                    >
                                      <option value="custom">
                                        Custom Link
                                      </option>
                                      <option value="page">Page</option>
                                      <option value="section">Section</option>
                                    </select>
                                  </div>

                                  {linkTypeList[i]?.type &&
                                    linkTypeList[i]?.type === "custom" && (
                                      <>
                                        <div className="col-6">
                                          <div>
                                            <span>
                                              <Tooltip
                                                arrow
                                                placement="top"
                                                title="Link"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  className="bi bi-link"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                                                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                                                </svg>
                                              </Tooltip>
                                            </span>
                                            <div className="custom">
                                              <input
                                                value={li.link}
                                                onChange={(e) =>
                                                  handleChange(e, i)
                                                }
                                                name="link"
                                                className="form-control form-control-sm"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6 mt-1 pt-3">
                                          <Tooltip
                                            title={"Reset"}
                                            placement="top"
                                            arrow
                                          >
                                            <span
                                              className={
                                                li.target === ""
                                                  ? "wb-icons-box-active"
                                                  : "wb-icons-box"
                                              }
                                              onClick={(e) =>
                                                handleChangeTarget("", i)
                                              }
                                            >
                                              <Icon icon="ant-design:stop-outlined" />
                                            </span>
                                          </Tooltip>
                                          <Tooltip
                                            title={"Open in new tab"}
                                            placement="top"
                                            arrow
                                          >
                                            <span
                                              onClick={(e) =>
                                                handleChangeTarget("_blank", i)
                                              }
                                              className={
                                                li.target === "_blank"
                                                  ? "wb-icons-box-active"
                                                  : "wb-icons-box"
                                              }
                                            >
                                              <Icon icon="ic:baseline-open-in-new" />
                                            </span>
                                          </Tooltip>
                                        </div>
                                      </>
                                    )}
                                  {linkTypeList[i]?.type &&
                                    linkTypeList[i]?.type === "page" && (
                                      <>
                                        <div className="col-6">
                                          <div>
                                            <span>
                                              <Tooltip
                                                arrow
                                                placement="top"
                                                title="Link"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  className="bi bi-link"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                                                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                                                </svg>
                                              </Tooltip>
                                            </span>
                                            <div className="custom">
                                              <select
                                                onChange={(e) =>
                                                  handleChange(e, i)
                                                }
                                                value={li.link.replace("/", "")}
                                                name="link"
                                                id="page_link"
                                                className=" form-control custom-icon-select form-control-sm"
                                              >
                                                <option value={"#"}>
                                                  Select any page
                                                </option>
                                                {groupPage_list?.length > 0 &&
                                                  groupPage_list?.map(
                                                    (page, i) => {
                                                      return (
                                                        <option
                                                          key={i}
                                                          value={
                                                            page.page_slug +
                                                            ".html"
                                                          }
                                                        >
                                                          {page.page_name}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6 mt-1 pt-3">
                                          <Tooltip
                                            title={"Reset"}
                                            placement="top"
                                            arrow
                                          >
                                            <span
                                              className={
                                                li.target === ""
                                                  ? "wb-icons-box-active"
                                                  : "wb-icons-box"
                                              }
                                              onClick={(e) =>
                                                handleChangeTarget("", i)
                                              }
                                            >
                                              <Icon icon="ant-design:stop-outlined" />
                                            </span>
                                          </Tooltip>
                                          <Tooltip
                                            title={"Open in new tab"}
                                            placement="top"
                                            arrow
                                          >
                                            <span
                                              onClick={(e) =>
                                                handleChangeTarget("_blank", i)
                                              }
                                              className={
                                                li.target === "_blank"
                                                  ? "wb-icons-box-active"
                                                  : "wb-icons-box"
                                              }
                                            >
                                              <Icon icon="ic:baseline-open-in-new" />
                                            </span>
                                          </Tooltip>
                                        </div>
                                      </>
                                    )}
                                  {linkTypeList[i]?.type &&
                                    linkTypeList[i]?.type === "section" && (
                                      <>
                                        <div className="col-12">
                                          <div>
                                            <span>
                                              <Tooltip
                                                arrow
                                                placement="top"
                                                title="Link"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  className="bi bi-link"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                                                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                                                </svg>
                                              </Tooltip>
                                            </span>
                                            <div className="custom">
                                              <select
                                                onChange={(e) =>
                                                  handleChange(e, i)
                                                }
                                                value={li.link}
                                                name="link"
                                                id="section_link"
                                                className=" form-control custom-icon-select form-control-sm"
                                              >
                                                <option value={"#"}>
                                                  Select a section
                                                </option>
                                                {SectionsDataset &&
                                                  SectionsDataset.map(
                                                    (dataset, i) => {
                                                      return (
                                                        <option
                                                          key={i}
                                                          value={
                                                            dataset.section_id
                                                          }
                                                        >
                                                          {
                                                            dataset.section_title
                                                          }
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                  {li.classes && (
                                    <div className="col-12">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                          <span>Navlink show Active:</span>
                                          <FormControlLabel
                                            style={{ marginLeft: 10 }}
                                            control={
                                              <Switch
                                                color="primary"
                                                checked={li.classes?.contains(
                                                  "active"
                                                )}
                                                onChange={(e) =>
                                                  handleChangeActive(
                                                    e.target.checked,
                                                    i
                                                  )
                                                }
                                                name="active_status"
                                              />
                                            }
                                          />
                                        </div>
                                        <div>
                                          <CustomPopover
                                            data={i}
                                            menu={ADD_DROPDOWN_MENU_OPTIONS}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          </div>
                        )}
                      </Draggable>
                    </>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="row">
          <div className="col-12 mt-2 text-center">
            {list?.data?.length > 0 && (
              <Button
                variant="contained"
                type="button"
                className="me-3"
                onClick={() => {
                  handleSave();
                  handleClose();
                }}
              >
                Update
              </Button>
            )}

            <Button
              variant="outlined"
              type="button"
              className=""
              onClick={handleAddNew}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
      {/* </AccordionDetails>
    </Accordion> */}
    </>
  );
}

export default MenuList;
