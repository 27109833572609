import { Tooltip } from "@mui/material";
import React from "react";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";
import { Icon } from "@iconify/react";

const DrawerMenuHeader = ({
  label = "Setting",
  setState = null,
  handleRemove = null,
}) => {
  const { SaveActionToStack } = useBuilder();
  return (
    <div className="px-3 mb-2">
      <div
        className="col-12 py-2 border-bottom"
        style={{
          position: "sticky",
          zIndex: "100",
          background: "white",
          top: "0%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h6 className="mb-0">{label}</h6>
        <div style={{ display: "flex" }}>
          {handleRemove && (
            <Tooltip title="Remove" placement="bottom" arrow>
              <div
                className="icon me-2"
                onClick={() => {
                  SaveActionToStack();
                  handleRemove();
                }}
              >
                <Icon  icon="material-symbols:delete-outline-sharp" style={{
                  color:"#f23105"
                }}></Icon>
              </div>
            </Tooltip>
          )}
          {setState && (
            <Tooltip title="Close" placement="bottom" arrow>
              <div className="icon me-2" onClick={() => setState(false)}>
                <i class="fa fa-xmark"></i>
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default DrawerMenuHeader;
