import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Container, Typography } from "@mui/material";
//

import { _login } from "../DAL/login/login";

// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
import AuthSocial from "../components/authentication/AuthSocial";
import {
  _set_is_dev,
  _set_token,
  _set_user,
} from "../DAL/localStorage/localStorage";
import AuthLayout from "../layouts/AuthLayout";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleSubmitLogin = async (postData) => {
    setLoading(true);

    const result = await _login(postData);
    if (result.code === 200) {
      _set_token(result.token);
      _set_user(result.user);
      navigate("/projects");
      setLoading(false);
    } else {
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  return (
    <RootStyle title="Inovate Builder">
      <MHidden width="mdDown">
        <AuthLayout></AuthLayout>
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Welcome To Dynamite Digital Designer Center
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Enter your details below.
            </Typography>
          </Stack>
          {/* <AuthSocial /> */}

          <LoginForm isLoading={loading} onHandleLogin={handleSubmitLogin} />

          {/* <MHidden width="smUp"> */}
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            Don’t have an account?&nbsp;
            <Link variant="subtitle2" to="/register">
              Get started
            </Link>
          </Typography>
          {/* </MHidden> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
