import React, { useEffect, useState } from "react";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";

const BowSettingButton = () => {
  const {
    setBoxSettingButtonAnchorEl,
    boxSettingButtonAnchorEl,
    BoxSettingClick,
  } = useBuilder();

  const [element, setElement] = useState(null);
  const [showButton, setShowButton] = useState(false);

  const handleClick = () => {
    BoxSettingClick(element);
  };

  useEffect(() => {
    if (boxSettingButtonAnchorEl) {
      let _element = boxSettingButtonAnchorEl;
      const editButton = document.querySelector(
        ".wb-box-setting-setting-button"
      );
      editButton.addEventListener("mouseenter", (e) => {
        // _element.style.outline = "1px solid blue";
        setShowButton(true);
      });
      editButton.addEventListener("mouseleave", (e) => {
        setShowButton(false);
        // _element.style.outline = "none";
      });
      setElement(_element);
    }
  }, [boxSettingButtonAnchorEl]);

  return (
    <>
      <div
        className="wb-box-setting-setting-button"
        style={{
          visibility:
            boxSettingButtonAnchorEl || showButton ? "visible" : "hidden",
        }}
        onClick={() => handleClick()}
      >
        <i
          class="wp-list-click fa-solid fa-gear"
          style={{
            width: "max-content",
            borderBottomRightRadius: "2px",
            transform: "translateX(-50%)",
            borderBottomLeftRadius: "2px",
          }}
        >
          {" "}
          Box
        </i>
      </div>
    </>
  );
};

export default BowSettingButton;
