import React, { useEffect, useState } from "react";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";

const LayoutSettingButton = () => {
  const {
    setLayoutSettingButtonAnchorEl,
    layoutSettingButtonAnchorEl,
    handleOpenLayoutSetting,
  } = useBuilder();

  const [element, setElement] = useState(null);
  const [showButton, setShowButton] = useState(false);

  const handleClick = () => {
    handleOpenLayoutSetting(element);
  };

  useEffect(() => {
    if (layoutSettingButtonAnchorEl) {
      let element = layoutSettingButtonAnchorEl;
      const editButton = document.querySelector(
        ".wb-layout-setting-setting-button"
      );
      editButton.addEventListener("mouseenter", (e) => {
        // element.style.outline = "1px solid blue";
        setShowButton(true);
      });
      editButton.addEventListener("mouseleave", (e) => {
        setShowButton(false);
        // element.style.outline = "none";
      });
      setElement(element);
    }
  }, [layoutSettingButtonAnchorEl]);

  return (
    <>
      <div
        className="wb-layout-setting-setting-button"
        style={{
          visibility:
            layoutSettingButtonAnchorEl || showButton ? "visible" : "hidden",
        }}
        onClick={() => handleClick()}
      >
        <i
          class="wp-list-click fa-solid fa-gear"
          style={{
            width: "max-content",
          }}
        >
          {" "}
          Layout
        </i>
      </div>
    </>
  );
};

export default LayoutSettingButton;
