import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  sizeOptions,
  font_weight,
  line_Height,
} from "./AdvancedSectionConstants";
import {
  options,
  google_font_family_classes,
  defaultFontSizes,
  defaultFontWeights,
} from "../../../../../../../ui_libs/Constants/constant";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Select from "react-select";
import { useBuilder } from "../../../../../../../hooks/builder-context/BuilderContext";
import { Tooltip } from "@mui/material";
import ReactSelect from "../../ReactSelect/ReactSelect";
import MuiSelect from "../../MuiSelect/MuiSelect";
import HeadingsFontFamily from "./HeadingsFontFamily";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0px 5px",
  },
  // heading: {
  //   fontSize: theme.typography.pxToRem(15),
  //   fontWeight: theme.typography.fontWeightRegular,
  // },
  btn: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  fit: {
    width: "fit-content",
  },
}));

export default function ThemeText() {
  const {
    rootValues,
    handleChangeRootValue,
    setDefaultRootValues,
    getIsDevState,
  } = useBuilder();
  const classes = useStyles();
  const optionsData = sizeOptions();
  const handleReset = (defaultValues) => {
    setDefaultRootValues(defaultValues);
  };

  const handleChangeHeadingFontFamily = (e) => {
    console.log(e,"changeFontValue")
    if (e.target.value == "") {
      e.target.value = "font_montserrat";
    }
    handleChangeRootValue(e.target.name, e.target.value);
  };
  return (
    <div className={classes.root}>
      {/* <Accordion className="card"> */}
      {/* <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Typography</Typography>
        </AccordionSummary> */}
      {/* <AccordionDetails> */}
      <div className="col-12">
        <div className="row">
          <div className="col-12 fw-bold custom-label mt-2">Font-Family:</div>
          <div className="col-12 mt-2">
            <div className="mb-3 mt-1">
              <MuiSelect
                label="Fonts"
                type="cssRule"
                handleChange={handleChangeRootValue}
                data={google_font_family_classes}
                currentFamily={rootValues.font_family.replace(" ","")}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col">
                <p className="fw-bold custom-label mb-2">Font Size</p>
              </div>
              <div className="col d-flex justify-content-end">
                <Tooltip title="Reset to Default" arrow placement="top">
                  <span>
                    <RotateLeftIcon
                      className={classes.btn}
                      onClick={() => handleReset(defaultFontSizes)}
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="col-6 ">
            <div className="mb-3">
              <label className="custom-label" htmlFor="h1FontSize">
                H1
              </label>
              <select
                value={rootValues.theme_h1_font_size.replace(" ", "")}
                onChange={(e) =>
                  handleChangeRootValue(e.target.name, e.target.value)
                }
                className="form-select form-select-sm"
                name="theme_h1_font_size"
                id="theme_h1_font_size"
              >
                {optionsData.map((data, index) => {
                  return (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-6 ">
            <div className="mb-3">
              <label className="custom-label" htmlFor="h2FontSize">
                H2
              </label>
              <select
                value={rootValues.theme_h2_font_size.replace(" ", "")}
                onChange={(e) =>
                  handleChangeRootValue(e.target.name, e.target.value)
                }
                className="form-select form-select-sm"
                name="theme_h2_font_size"
                id="h2FontSize"
              >
                {optionsData.map((data, index) => {
                  return (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-6 ">
            <div className="mb-3">
              {" "}
              <label className="custom-label" htmlFor="h3FontSize">
                H3
              </label>
              <select
                value={rootValues.theme_h3_font_size.replace(" ", "")}
                onChange={(e) =>
                  handleChangeRootValue(e.target.name, e.target.value)
                }
                className="form-select form-select-sm"
                name="theme_h3_font_size"
                id="h3FontSize"
              >
                {optionsData.map((data, index) => {
                  return (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-6 ">
            <div className="mb-3">
              <label className="custom-label" htmlFor="h4FontSize">
                H4
              </label>
              <select
                value={rootValues.theme_h4_font_size.replace(" ", "")}
                onChange={(e) => {
                  handleChangeRootValue(e.target.name, e.target.value);
                }}
                className="form-select form-select-sm"
                name="theme_h4_font_size"
                id="h1FontSize"
              >
                {optionsData.map((data, index) => {
                  return (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-6 ">
            <div className="mb-3">
              <label className="custom-label" htmlFor="pFontSize">
                P
              </label>
              <select
                value={rootValues.theme_p_font_size.replace(" ", "")}
                onChange={(e) =>
                  handleChangeRootValue(e.target.name, e.target.value)
                }
                className="form-select form-select-sm"
                name="theme_p_font_size"
                id="pFontSize"
              >
                {optionsData.map((data, index) => {
                  return (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>{" "}
          <div className="col-6 ">
            <div className="mb-3">
              <label className="custom-label" htmlFor="pFontWeight">
                Line Height
              </label>
              <select
                value={rootValues.theme_line_height}
                onChange={(e) =>
                  handleChangeRootValue(e.target.name, e.target.value)
                }
                className="form-select form-select-sm"
                name="theme_line_height"
                id="lineHeight"
              >
                {line_Height.map((data, index) => {
                  return (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {getIsDevState() && (
            <>
              <label className="col-12 fw-bold custom-label mt-2 mb-3">
                Heading Font Family
              </label>
              {[1, 2, 3, 4].map((item) => (
                <HeadingsFontFamily
                  label={`H${item}`}
                  font_family_value={rootValues[`theme_h${item}_font_family`]}
                  handleChange={handleChangeHeadingFontFamily}
                  name={`theme_h${item}_font_family`}
                />
              ))}
            </>
          )}
          <div className="col-12">
            <div className="row">
              <div className="col">
                <p className="fw-bold custom-label mb-2">Font Weight</p>
              </div>
              <div className="col d-flex justify-content-end">
                <Tooltip title="Reset to Default" arrow placement="top">
                  <span>
                    <RotateLeftIcon
                      className={classes.btn}
                      onClick={() => handleReset(defaultFontWeights)}
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="col-6 ">
            <div className="mb-3">
              <label className="custom-label" htmlFor="h1fontWeight">
                H1
              </label>
              <select
                value={rootValues.theme_h1_font_weight}
                onChange={(e) =>
                  handleChangeRootValue(e.target.name, e.target.value)
                }
                className="form-select form-select-sm"
                name="theme_h1_font_weight"
                id="h1fontWeight"
              >
                {font_weight.map((data, index) => {
                  return (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-6 ">
            <div className="mb-3">
              <label className="custom-label" htmlFor="h2fontWeight">
                H2
              </label>
              <select
                value={rootValues.theme_h2_font_weight}
                onChange={(e) =>
                  handleChangeRootValue(e.target.name, e.target.value)
                }
                className="form-select form-select-sm"
                name="theme_h2_font_weight"
                id="h2fontWeight"
              >
                {font_weight.map((data, index) => {
                  return (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-6 ">
            <div className="mb-3">
              <label className="custom-label" htmlFor="h3fontWeight">
                H3
              </label>
              <select
                value={rootValues.theme_h3_font_weight}
                onChange={(e) =>
                  handleChangeRootValue(e.target.name, e.target.value)
                }
                className="form-select form-select-sm"
                name="theme_h3_font_weight"
                id="h3fontWeight"
              >
                {font_weight.map((data, index) => {
                  return (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mb-3">
              <label className="custom-label" htmlFor="h4fontWeight">
                H4
              </label>
              <select
                value={rootValues.theme_h4_font_weight}
                onChange={(e) =>
                  handleChangeRootValue(e.target.name, e.target.value)
                }
                className="form-select form-select-sm"
                name="theme_h4_font_weight"
                id="h4fontWeight"
              >
                {font_weight.map((data, index) => {
                  return (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-6 ">
            <div className="mb-3">
              <label className="custom-label" htmlFor="pFontWeight">
                P
              </label>
              <select
                value={rootValues.theme_p_font_weight}
                onChange={(e) =>
                  handleChangeRootValue(e.target.name, e.target.value)
                }
                className="form-select form-select-sm"
                name="theme_p_font_weight"
                id="pFontWeight"
              >
                {font_weight.map((data, index) => {
                  return (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {/* <div className="col-12 mb-3">
                <div className="d-flex justify-content-center">
                  <button onClick={handleReset(defaultFontSizes)} className="btn btn-sm btn-secondary">
                    Default
                  </button>
                </div>
              </div> */}
        </div>
      </div>
      {/* </AccordionDetails> */}
      {/* </Accordion> */}
    </div>
  );
}
