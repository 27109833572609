import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
  Popover,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { s3baseUrl, s3builderSource } from "../../../../config/config";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import SearchIcon from "@mui/icons-material/Search";
import {
  _section_type,
  _sections_list_against_type,
} from "../../../../DAL/sections/section";
import { _get_user, _is_dev } from "../../../../DAL/localStorage/localStorage";
import { createFilter } from "react-search-input";
import NotFound from "../../../../components/NotFound";

function AddSectionDialog({}) {
  const {
    addSectionDialog,
    setAddSectionDialog,
    selectedMenu,
    setSelectedMenu,
    indexForAddSection,
    handleSectionOnSelect,
    getIsDevState,
    rootValues,
  } = useBuilder();
  const { enqueueSnackbar } = useSnackbar();
  const [sectionType, setSectionType] = useState([]);
  const [isSectionListLoading, setIsSectionListLoading] = useState(true);
  const [sectionTypeLoading, setsectionTypeLoading] = useState(true);
  const [sectionsList, setSectionsList] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [previewImage, setPreviewImage] = useState("");

  const handleClose = () => {
    setAddSectionDialog(false);
    setSearchInput("");
    setSelectedMenu("");
  };
  const fetchSectionsAgainstType = async (section_type_id) => {
    if (!section_type_id) {
      return;
    }

    const user = _get_user();
    const postData = {
      type: "Type",
      id: section_type_id,
      app_type: `${user?.type}`,
    };
    setIsSectionListLoading(true);
    const resp = await _sections_list_against_type(postData);
    if (resp.code === 200) {
      setIsSectionListLoading(false);
      getIsDevState()
        ? setSectionsList(resp.section)
        : sortSection(resp.section);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const sortSection = (section) => {
    let array = [];
    section.map((sec) => {
      if (sec.is_published) {
        array.push(sec);
      }
    });
    setSectionsList([...array]);
  };

  const fetchSectionsType = async () => {
    setsectionTypeLoading(true);
    const resp = await _section_type();
    if (resp.code === 200) {
      let array = resp.section_type;
      if (rootValues?.page_group_id == "" && _is_dev() != true) {
        const indexToRemove = array.findIndex(
          (obj) => obj.name === "Book A Call"
        );
        if (indexToRemove !== -1) {
          array.splice(indexToRemove, 1);
        }
      }
      setSectionType(array);
      showSectionsInDrawer(resp.section_type[0]);
      fetchSectionsAgainstType(resp.section_type[0]._id);
      setsectionTypeLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const showSectionsInDrawer = (menu) => {
    setSelectedMenu(menu);
    fetchSectionsAgainstType(menu._id);
  };
  const getSectionsList = () => {
    return sectionsList;
  };

  const getFilteredList = getSectionsList().filter(
    createFilter(searchInput, ["name"])
  );
  const handleChooseSection = (section) => {
    handleSectionOnSelect(
      section,
      indexForAddSection > 0 ? indexForAddSection : undefined,
      s3builderSource
    );

    handleClose();
  };

  const handlePreview = (e, image) => {
    setAnchorEl(e.currentTarget);
    setPreviewImage(image);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    addSectionDialog && fetchSectionsType();
  }, [addSectionDialog]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={addSectionDialog}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderTop: "5px solid #023373",
            },
          },
        }}
      >
        <div
          className="border-bottom"
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflow: "hidden",
          }}
        >
          <DialogTitle>
            <Stack>Choose Sections</Stack>
          </DialogTitle>
          <div
            className="icon"
            style={{ margin: "16px 24px" }}
            onClick={handleClose}
          >
            <i class="fa fa-xmark"></i>
          </div>
        </div>
        <div
          style={{
            height: "85vh",
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
          }}
        >
          <div
            className=""
            style={{
              backgroundColor: "#f0f4f7",
              width: "20%",
              overflow: "auto",
            }}
          >
            <div
              style={{
                style: "flex",
                flexDirection: "column",
                padding: "0px",
              }}
            >
              {sectionTypeLoading
                ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((val) => (
                    <div
                      key={val}
                      className="pointer mb-0 col-12"
                      style={{ padding: "2px 8px" }}
                    >
                      <div className="text-center mb-0">
                        <span className="mb-0">
                          <Skeleton variant="text" height={40} />
                        </span>
                      </div>
                    </div>
                  ))
                : sectionType.map((menu, i) => (
                    <div
                      key={menu._id}
                      onClick={() => showSectionsInDrawer(menu)}
                      className={
                        selectedMenu === menu
                          ? "pointer border-bottom d-flex wb-add-section-modal-selected"
                          : "pointer border-bottom d-flex wb-add-section-modal"
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "0px 0px 0px 20px",
                        height: "35px",
                        alignItems: "center",
                        pointerEvents: isSectionListLoading && "none",
                      }}
                    >
                      <img
                        width="22"
                        height="22"
                        className="ms-2"
                        src={s3builderSource + "/" + menu.image}
                      ></img>

                      <span className="ms-2 sidebar-title text-capitalize">
                        {menu.name}
                      </span>
                    </div>
                  ))}
            </div>
          </div>
          <div
            className="d-flex flex-column"
            style={{ padding: "10px 24px 20px", width: "80%" }}
          >
            <div className="d-flex justify-content-between align-items-center pb-2">
              <h5 className="m-0 text-capitalize">{selectedMenu.name}</h5>
              <div className="">
                <TextField
                  InputProps={{
                    style: {
                      borderRadius: "30px",
                      backgroundColor: "white",
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ color: "#bebebe" }}
                      >
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label={null}
                  variant="standard"
                  id="outlined-required"
                  placeholder="Search..."
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
            </div>
            <div
              className=""
              style={{
                padding: "5px 24px 50px 5px",
                marginRight: "-24px",
                overflow: "auto",
              }}
            >
              {selectedMenu && (
                <div
                  style={{
                    paddingBottom: "2rem",

                    width: "100%",
                  }}
                >
                  {isSectionListLoading ? (
                    <div className="wb-left-menu-wrapper row">
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((val) => (
                        <div
                          key={val}
                          className="mb-1 pointer col-4"
                          style={{ padding: 5 }}
                        >
                          <Skeleton variant="rect" height={170} />

                          <div className="text-center">
                            <span>
                              <Skeleton variant="text" height={40} />
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="row">
                      {getFilteredList.length > 0 ? (
                        getFilteredList.map((section, index) => (
                          <div className="col-4" key={index}>
                            <div className="my-1 border">
                              {section.image ? (
                                <div
                                  className="position-relative section-image pointer"
                                  onClick={() => {
                                    handleChooseSection(section);
                                  }}
                                >
                                  <img
                                    height={170}
                                    width={"100%"}
                                    src={s3builderSource + "/" + section.image}
                                    alt=""
                                  />
                                  <div
                                    className="add-section-button"
                                    style={{ borderRadius: "30px" }}
                                  >
                                    <Button
                                      variant="outlined"
                                      onClick={() => {
                                        handleChooseSection(section);
                                      }}
                                      sx={{
                                        borderColor: "#023373",
                                        background: "transparent",
                                        color: "#023373",
                                        borderRadius: "30px",
                                        padding: "auto 10px",
                                      }}
                                    >
                                      Add Section
                                    </Button>
                                  </div>
                                  <div
                                    className="image-icon"
                                    style={{ top: "2%" }}
                                    onMouseEnter={(e) =>
                                      handlePreview(e, section.image)
                                    }
                                    onMouseLeave={() => handlePopoverClose()}
                                  >
                                    <i class="fa-solid fa-eye"></i>
                                  </div>
                                </div>
                              ) : (
                                <Skeleton
                                  variant="rect"
                                  width={"100%"}
                                  height={170}
                                />
                              )}
                              <div className="text-center border-top custom-label pb-1 pt-1">
                                <span className="">{section.name}</span>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div
                          align="center"
                          colSpan={12}
                          style={{ marginTop: "50px" }}
                        >
                          <NotFound
                            searchQuery={getFilteredList}
                            title={"Section Not Found"}
                            icon="fa-solid:layer-group"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
              marginLeft: "5px",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div style={{ width: "40vw", height: "100%" }}>
              <img
                src={s3builderSource + "/" + previewImage}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  border: "8px solid white",
                }}
                alt={s3builderSource + "/" + previewImage}
              />
            </div>
          </Popover>
        </div>
      </Dialog>
    </>
  );
}

export default AddSectionDialog;
