import React, { useEffect, useState } from "react";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";

const ListSettingButton = () => {
  const { setListButtonAnchorEl, listButtonAnchorEl, handleAddListListner } =
    useBuilder();

  const [element, setElement] = useState(null);
  const [showButton, setShowButton] = useState(false);

  const handleClick = () => {
    handleAddListListner(element);
  };

  useEffect(() => {
    if (listButtonAnchorEl) {
      let element = listButtonAnchorEl;
      const editButton = document.querySelector(".wb-list-setting-button");
      editButton.addEventListener("mouseenter", (e) => {
        element.style.outline = "1px solid blue";
        setShowButton(true);
      });
      editButton.addEventListener("mouseleave", (e) => {
        setShowButton(false);
        element.style.outline = "none";
      });
      setElement(element);
    }
  }, [listButtonAnchorEl]);

  return (
    <>
      <div
        className="wb-list-setting-button"
        style={{
          visibility: listButtonAnchorEl || showButton ? "visible" : "hidden",
        }}
        onClick={() => handleClick()}
      >
        <i
          class="wp-list-click fa-solid fa-gear"
          style={{ width: "max-content" }}
        >
          {" "}
          List
        </i>
        {/* <Box>
          <List
            component="nav"
            aria-label="secondary mailbox folder"
            onClick={handleClose}
            sx={{ padding: "0 0" }}
          >
            {rightMenuList?.map((list, index) => (
              <>
                <div key={index}>
                  {list?.divider == true && <Divider className="my-2" />}

                  <ListItemButton
                    disabled={list.disable}
                    onClick={list.handleClick}
                    sx={{
                      borderRadius: "8px",
                      cursor: "pointer",
                      marginInline: "8px",
                      padding: "2px",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor:
                          list.backgroundColor && list.backgroundColor,
                      },
                    }}
                  >
                    <Icon
                      icon="mingcute:settings-6-line"
                      className=""
                      style={{
                        color: list.color ? list.color : "#023373",
                      }}
                    />
                    {editButtonPopupText && (
                      <span className="ms-2">Edit Image</span>
                    )}
                  </ListItemButton>
                </div>
              </>
            ))}
          </List>
        </Box> */}
      </div>
    </>
  );
};

export default ListSettingButton;
