import React, { useEffect, useState } from "react";

import {
  Box,
  Menu,
  Typography,
  Link,
  ListItemButton,
  List,
  ListItemText,
  Button,
  Divider,
  Tooltip,
  Stack,
} from "@mui/material";
import { Icon } from "@iconify/react";

const ExitMenuPopup = ({ menuAnchorEl, setMenuAnchorEl, menuList }) => {
  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      <Menu
        id="menu-popup"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiMenu-paper": {
            padding: "0px",
            margin: "-10px 0",
            borderRadius: "8px",
            zIndex: (theme) => theme.zIndex.drawer + 10,
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <Box>
          <List
            component="nav"
            aria-label="secondary mailbox folder"
            onClick={handleClose}
            sx={{ padding: "0 0" }}
          >
            {menuList?.map((list, index) => (
              <>
                <div key={index}>
                  {list?.divider == true && <Divider className="my-2" />}

                  <ListItemButton
                    // disabled={list.disable}
                    onClick={list.handleClick}
                    sx={{
                      borderRadius: "8px",
                      cursor: "pointer",
                      marginInline: "8px",
                      padding: "2px 30px 2px 8px",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor:
                          list.backgroundColor && list.backgroundColor,
                      },
                    }}
                  >
                    <Icon
                      icon={list.icon}
                      className="me-3"
                      style={{
                        color: list.color ? list.color : "#023373",
                      }}
                    />
                    <ListItemText
                      sx={{
                        ".MuiListItemText-primary": {
                          fontSize: "14px",
                          color: list.color ? list.color : "#023373",
                        },
                      }}
                      primary={list.label}
                    />
                  </ListItemButton>
                </div>
              </>
            ))}
          </List>
        </Box>
      </Menu>
    </>
  );
};

export default ExitMenuPopup;
