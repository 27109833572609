import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import {
  _set_is_dev,
  _set_token,
  _set_user,
} from "../../DAL/localStorage/localStorage";
import {
  _get_user_profile,
  _get_user_profile_by_custom_token,
  _get_user_token_with_thirdparty_token,
} from "../../DAL/login/login";
import {
  _get_user_created_designs,
  _get_user_design_details,
} from "../../DAL/projects/projects";
import PageMetaTitles from "../../components/Page";
import { Helmet } from "react-helmet-async";

function ClientPortalController(props) {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const queryParams = useLocation();
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const query = new URLSearchParams(url.search);
  let project_id = params.project_id;
  let page_id = params.page_id;

  const [user, setUser] = useState(1);
  const pushToBuilder = async () => {
    navigate(`/builder/${project_id}/${page_id}`);
  };

  const check_builder_url = async () => {
    sessionStorage.clear();
    sessionStorage.clear();

    const userType = new URLSearchParams(queryParams.search).get("user_type");
    let token = params?.token;
    if (userType) {
      project_id = new URLSearchParams(queryParams.search).get("project_id");
      page_id = new URLSearchParams(queryParams.search).get("page_id");
      let brand_logo = new URLSearchParams(queryParams.search).get(
        "brand_logo"
      );
      let brand_favicon = new URLSearchParams(queryParams.search).get(
        "brand_favicon"
      );

      sessionStorage.setItem("brand_logo", brand_logo);
      sessionStorage.setItem("brand_favicon", brand_favicon);

      console.log(userType, token, "queryqueryquer");
      setUser(3);
      const postData = {
        type: 3,
      };

      const vissiion_token = new URLSearchParams(queryParams.search).get(
        "user_token"
      );

      const resp = await _get_user_token_with_thirdparty_token(
        vissiion_token,
        postData
      );

      if (resp.code === 200) {
        console.log(resp);
        token = resp.content;
      }
    }

    if (token) {
      fetchProfile(token);
      return;
    }
  };

  const fetchProfile = async (token) => {
    const resp = await _get_user_profile_by_custom_token(token);

    const user = { ...resp.Dashboard.user, ...resp.Dashboard.user_detail };

    if (resp.code === 200) {
      _set_token(token);
      _set_user(user);
      sessionStorage.setItem("client_base_url", document.referrer);
      sessionStorage.setItem("userType", resp.Dashboard.user.type);

      const link = query.get("source_link");
      console.log(url, query, link, "ndcndjnjdncjdjncjdncndjncjdjcndnjc");
      if (link !== null && link !== "") {
        sessionStorage.setItem("source_link", link);
      }

      console.log(
        sessionStorage.getItem("source_link"),
        "dnjndjjcjcjdcnndcjdcndcnjn"
      );
      // sessionStorage.setItem("client_end_point", JSON.parse(params.url));

      pushToBuilder();
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    check_builder_url();
  }, []);
  return (
    <div>
      {user === 3 ? (
        <PageMetaTitles
          title="Vissioon | Builder"
          favIcon={
            sessionStorage.getItem("brand_favicon") !== "null"
              ? sessionStorage.getItem("brand_favicon")
              : "../favicon/vissiion-favicon.png"
          }
        />
      ) : (
        <PageMetaTitles
          title="Wezily | Builder"
          favIcon={"../favicon/wezily-favIcon.png"}
        />
      )}
      <Loader />
    </div>
  );
}

export default ClientPortalController;
