import React, { useEffect, useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import {
  padding_classes,
  margin_classes,
} from "../../../../ui_libs/Constants/constant";
import { Grid, Input, Slider, Tooltip } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import TuneIcon from "@mui/icons-material/Tune";

const customSteps = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 24, 28, 32, 40,
  45, 50, 55, 60, 65, 70, 75, 80,
];
const customStepsMargin = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

function CustomSpacingWithSlider({
  updateSelectedElementSpacing,
  updateSelectedElementClass,
  getSelectedElementClassByType,
  getSelectedElementStyleByProperty,
  spacing_type,
  label,
  dimention,
  myclass,
  ResetValue,
  label_,
  paddingClass,
}) {
  // reset
  // classes
  const [ClassValue, setClassValue] = useState("");
  const [classValueNumber, setClassValueNumber] = useState("");
  // custom  vlaues
  const [CustomValue, setCustomValue] = useState("");
  // check display custom values or  classes
  const [ShowCustomValue, setShowCustomValue] = useState(false);

  const mapValueToStep = (value) => {
    let minDiff = Infinity;
    let closestStep = 0;

    let steps = spacing_type === "margin" ? customStepsMargin : customSteps;

    steps.forEach((step) => {
      const diff = Math.abs(value - step);
      if (diff < minDiff) {
        minDiff = diff;
        closestStep = step;
      }
    });

    return closestStep;
  };

  const handleChangeInClass = (key, sliderValue, class_list) => {
    let value = "";
    if (sliderValue === "") {
      value = paddingClass + "";
      setCustomValue(0);
      setShowCustomValue(false);
      updateSelectedElementSpacing(spacing_type, [key], "");
      setClassValue(value);
      const payload = {
        class_list,
        value,
      };

      updateSelectedElementClass(payload);
      return;
    }
    if (sliderValue === 0) {
      value = paddingClass + "0";
      setCustomValue(0);
      updateSelectedElementSpacing(spacing_type, [key], "");
      setClassValue(value);
      const payload = {
        class_list,
        value,
      };

      updateSelectedElementClass(payload);
      return;
    }
    // if user select  custom
    if (sliderValue == "custom" || sliderValue == "select_custom") {
      setShowCustomValue(true);
      setClassValue("0");
      value = "";
      const payload = {
        class_list,
        value,
      };

      updateSelectedElementClass(payload);
    }

    // if select class value
    else {
      if (sliderValue !== "") {
        const stepValue = mapValueToStep(sliderValue);
        if (stepValue) {
          value = paddingClass + stepValue?.toString();
        }
      } else {
        value = "";
      }
      setCustomValue(0);
      updateSelectedElementSpacing(spacing_type, [key], "");
      setClassValue(value);
      const payload = {
        class_list,
        value,
      };
      updateSelectedElementClass(payload);
    }
  };

  const handleCustomChange = (e) => {
    setCustomValue(e.target.value);
    const px_to_rem = e.target.value / 16;
    updateSelectedElementSpacing(spacing_type, e.target.name, px_to_rem);
  };

  useEffect(() => {
    // console.log("type=================", spacing_type)
    // initialize classes value

    let classval = getSelectedElementClassByType({
      class_list: myclass(label),
    });

    console.log(classval, "paddinf Value");

    setClassValue(classval);

    // initialize custom values
    const customval = getSelectedElementStyleByProperty({
      property: `${dimention}`,
    });
    let convertion_to_px =
      customval == undefined || customval == ""
        ? "0"
        : customval.replace("rem", "") * 16;

    // push pixel values to state
    // console.log("====================>", convertion_to_px)
    setCustomValue(convertion_to_px);
    if (convertion_to_px !== "0") {
      setShowCustomValue(true);
    }
  }, [ResetValue]);

  useEffect(() => {
    if (ClassValue) {
      const numberPart = ClassValue?.replace(paddingClass, "");
      const numericValue = parseInt(numberPart);

      if (numberPart === "0" || numberPart === "") {
        setClassValueNumber(0);
      } else {
        setClassValueNumber(numericValue);
      }
      console.log(numericValue, numberPart, ClassValue, "numericvalue");
    }
  }, [ClassValue]);

  return (
    <>
      <div className="col">
        <Grid>
          <label
            for="edit-able-text-input"
            className="text-capitalize form-label custom-label mb-0"
          >
            {label_ ? label_ : label}:
          </label>
        </Grid>
        <Grid container spacing={2} alignItems="center" className="ps-2 mt-0">
          <Grid xs className="me-3">
            {!ShowCustomValue && (
              <div className="mt-2 ms-3">
                <Slider
                  value={classValueNumber}
                  onChange={(e) =>
                    handleChangeInClass(label, e.target.value, myclass(label))
                  }
                  aria-labelledby="input-slider"
                  min={0}
                  max={spacing_type === "margin" ? 12 : 80}
                  step={1}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  size="small"
                />
              </div>
            )}
            {ShowCustomValue && (
              <div
                className="text-capitalize col px-2 pt-2"
                style={{ marginBottom: "8px" }}
              >
                <input
                  // onBlur={() => setShowCustomValue(false)}
                  type="number"
                  onChange={handleCustomChange}
                  name={label}
                  value={CustomValue}
                  autoFocus={ShowCustomValue}
                  className="form-control form-control-sm-custom"
                />
              </div>
            )}
          </Grid>

          {/* <Grid item>
              <Input
                value={classValueNumber}
                size="small"
                onChange={(e) =>
                  handleChangeInClass(label, e.target.value, myclass(label))
                }
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 80,

                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid> */}
          <Grid className="me-3">
            <span>
              {ShowCustomValue ? (
                <Tooltip title="Slider" arrow placement="top">
                  <TuneIcon
                    className="pointer"
                    onClick={(e) =>
                      handleChangeInClass(label, "", myclass(label))
                    }
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Custom" arrow placement="top">
                  <DriveFileRenameOutlineIcon
                    className="pointer"
                    onClick={(e) =>
                      handleChangeInClass(label, "custom", myclass(label))
                    }
                  />
                </Tooltip>
              )}
            </span>
          </Grid>
          <Grid>
            <Tooltip title="Reset" arrow placement="top">
              <span>
                <RotateLeftIcon
                  className="pointer"
                  onClick={(e) =>
                    handleChangeInClass(label, "", myclass(label))
                  }
                />
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
export default CustomSpacingWithSlider;
