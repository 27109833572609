import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { _get_user_profile } from "../../../../DAL/login/login";
import Typography from "@mui/material/Typography";
import { Button, IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import ExitBuilderConfirmation from "../../../../components/ExitBuilderConfirmation";
import CustomConfirmation from "../../../../components/CustomConfirmation";
import AddNewPage from "../../../../layouts/MainEditorLayout/components/AddNewPage/AddNewPage";
import { Icon } from "@iconify/react";
import DescriptionIcon from "@mui/icons-material/Description";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Menu from "@mui/material/Menu";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Settings, SettingsRemote } from "@mui/icons-material";
import { Close, Done } from "@mui/icons-material";
import { _get_user_design_details } from "../../../../DAL/projects/projects";
import { useSnackbar } from "notistack";
import LimitationModal from "../../../../components/LimitationModal/LimitationModal";
import { s3baseUrl, s3builderSource } from "../../../../config/config";
import { website_placeholder_img } from "../../../../assets";

function FunnelPages(props) {
  const { leaveHoverDrawer, selectedlistItem } = props;

  const {
    getSelectedSections,
    handleSaveTemplateToServer,
    CheckChangeExsistInHTML,
    selectedSections,
    handleOpenSectionSettings,
    handleRemoveSelection,
    handleUpSelection,
    handleDuplicateSection,
    updateSelectedSectionTitle,
    getSelectedSectionDataset,
    setSelectedSectionSettingId,
    getSelectedSectionFormByID,
    handleOpenFormSetting,
    handleDownSelection,
    rootValues,
    handleUpdateStateFromDOM,
    SelectedPage,
    setSelectedSectionsIndex,
    selectedSectionsIndex,
    customerSubscriptionDetail,
    funnelGroup,
    isSaveLoading,
    funnelDetails,
  } = useBuilder();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [list, setList] = useState(getSelectedSections());
  const [projectDetail, setProjectDetail] = useState({});

  const [OpenNewPageDailogue, setOpenNewPageDailogue] = useState(false);
  const [isPageFromFunnel, setIsPageFromFunnel] = useState(false);
  const [OpenPageDuplicateDailogue, setOpenPageDuplicateDailogue] =
    useState(false);

  const [FinalHTML, setFinalHTML] = useState("");
  const [FinalCSS, setFinalCSS] = useState("");
  const [FinalJS, setFinalJS] = useState("");
  const [PageValue, setPageValue] = useState("");
  const [ChangePageAlert, setChangePageAlert] = useState(false);
  const [visibleIcon, setVisibleIcon] = useState(false);
  const [iconIndex, setIconIndex] = useState("");
  const [TargetSlug, setTargetSlug] = useState("");
  const [anchorEl, setAnchorEl] = useState("");
  const [sectionToChange, setSectionToChange] = useState(null);
  const [optionMenuOpen, setOptionMenuOpen] = useState(false);
  const [delmsg, setDelmsg] = useState(false);

  const [title, setTitle] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [inputFocus, setInputFocus] = useState(false);
  const [limitModal, setLimitModal] = useState(false);
  const [groupPage_list, setGroupPage_list] = useState([]);

  const handleSelectedSectionDataset = () => {
    setIsDisabled(false);
    setTimeout(() => {
      setInputFocus(true);
    }, 0);
  };

  const handleUpdate = () => {
    updateSelectedSectionTitle(
      title === "" ? sectionToChange.section.section_title : title
    );
    setIsDisabled(true);
    setInputFocus(false);
  };

  const handleMenuOptions = (e, section, i) => {
    setSectionToChange({ ...sectionToChange, section });
    setIconIndex(i);
    setSelectedSectionsIndex(i);
    setOptionMenuOpen(true);
    setVisibleIcon(true);
    setAnchorEl(e.currentTarget);
    setTitle(section.section_title);
  };
  const handleClose = () => {
    setAnchorEl("");
    setOptionMenuOpen(false);
    setVisibleIcon(false);
  };
  const handleDragStart = (e) => {
    document.getElementById(
      selectedSections[e.source.index].section_id
    ).style.outline = "2px solid red";
  };
  const handleDragEnd = ({ source, destination, ...rest }) => {
    document.getElementById(
      selectedSections[source.index].section_id
    ).style.outline = "";
    if (source && destination && source.index != destination.index) {
      // let newList = [...list];

      const newList = selectedSections.map((sec) => {
        return sec;
      });
      newList.splice(destination.index, 0, newList.splice(source.index, 1)[0]);

      setList({ ...list, newList });
      handleUpdateStateFromDOM(newList);
    } else {
      console.log("ERROR", source, destination);
    }
  };
  const handledeletesection = () => {
    handleRemoveSelection(sectionToChange.section.section_id);
    setDelmsg(false);
  };
  const handleCheckAddPage = async (e) => {
    e.preventDefault();
    if (
      customerSubscriptionDetail.plan_id.pages_count >
      projectDetail.project_pages_count
    ) {
      handleDuplicatePage();
    } else {
      setLimitModal(true);
      // handleClose();
    }
  };

  const handleDuplicatePage = () => {
    let html = collectTargetHTML();
    let css = collectTargetCSS();
    let js = collectTargetJS();
    setFinalHTML(html);
    setFinalCSS(css);
    setFinalJS(js);
    rootValues.page_list.map((page) => {
      if (page.page_slug == SelectedPage) {
        setPageValue({ ...page });
      }
    });
    setOpenPageDuplicateDailogue(true);
  };

  const handleSavePageandChangePage = async () => {
    let result = await handleSaveTemplateToServer({ preview: false });
    if (result) {
      handleChangePage();
    }
  };
  const getFormData = (id) => {
    let { form, status } = getSelectedSectionFormByID(id);
    if (form && form.length > 0) {
      let form_seting_value = form[0].getAttribute("enable_form_setting");

      if (form_seting_value == false || form_seting_value == "false") {
        status = false;
      }
    }
    return { form, status };
  };
  const handleChangePage = (value = null) => {
    value = value ? value : TargetSlug;
    rootValues.page_list.map((page) => {
      if (page.page_slug == value) {
        navigate("/builder-redirect-controller", {
          state: {
            project_id: rootValues._id,
            page_id: page._id,
          },
        });
      }
    });
  };
  const handlechangepage = (value) => {
    if (value == "") {
      rootValues.page_list.map((page) => {
        if (page.page_slug == SelectedPage) {
          setPageValue({ ...page });
        }
      });
      setOpenNewPageDailogue(true);
    } else if (value == "button") {
      return;
    } else {
      rootValues.page_list.map((page) => {
        if (page.page_slug == value) {
          setTargetSlug(value);
          if (CheckChangeExsistInHTML()) {
            setChangePageAlert(true);
          } else {
            handleChangePage(value);
          }

          // navigate('/builder-redirect-controller', {
          //   state: {
          //     project_id: rootValues._id,
          //     page_id: page._id,
          //   },
          // })
        }
      });
    }
  };

  if (!sessionStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }

  const collectTargetHTML = () => {
    let collect_html = "";
    getSelectedSections().forEach((section) => {
      collect_html += section.section_html + "\n\n";
    });

    return collect_html ? collect_html : `/* No HTML */`;
  };
  const collectTargetCSS = () => {
    let collect_css = "";
    getSelectedSections().forEach((section) => {
      collect_css += section.section_css + "\n\n";
    });

    return collect_css ? collect_css : `/* No CSS */`;
  };

  const collectTargetJS = () => {
    let collect_js = "";
    getSelectedSections().forEach((section) => {
      collect_js += section.section_js;
    });
    ///asdasd
    return collect_js ? collect_js : `/* No JS */`;
  };

  const GetProjectDomainDetails = async () => {
    const resp = await _get_user_design_details(rootValues.project);
    if (resp.code == 200) {
      setProjectDetail(resp.Project);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (ChangePageAlert) {
      GetProjectDomainDetails();
    }
  }, [ChangePageAlert]);
  useEffect(() => {
    if (rootValues) {
      let values = rootValues;
      let list = [];
      if (
        funnelDetails?.page_group_type !== "generic_website" &&
        values.page_group_id !== ""
      ) {
        values.page_list.map((page) => {
          if (page.page_group_id?._id === values.page_group_id) {
            list.push(page);
          }
          if (page.page_group_id === values.page_group_id) {
            list.push(page);
          }
        });
        setIsPageFromFunnel(true);
      } else {
        list = values.page_list;
      }
      console.log(values, list, "njenjcnececneiniceicienicei");
      setGroupPage_list(list);
    }
  }, [rootValues]);

  return (
    <>
      {/* add page dailogue */}
      <AddNewPage
        open={OpenNewPageDailogue}
        setOpen={setOpenNewPageDailogue}
        Page={PageValue}
        CheckChanges={true}
      />

      {/* duplicate Page */}
      <AddNewPage
        duplicate={true}
        open={OpenPageDuplicateDailogue}
        Page={PageValue}
        setOpen={setOpenPageDuplicateDailogue}
        FinalHTML={FinalHTML}
        FinalCSS={FinalCSS}
        FinalJS={FinalJS}
        CheckChanges={true}
      />
      {/* page cahnge */}
      <ExitBuilderConfirmation
        open={ChangePageAlert}
        setOpen={setChangePageAlert}
        handleAgree={() => handleChangePage(TargetSlug)}
        handleSavePageAndExit={handleSavePageandChangePage}
        // title="You might have unsaved changes please save otherwise you might lose your page data."
        title="Leaving page without saving data"
        option1={"Continue"}
        option2={"Save and Continue"}
      />

      <LimitationModal
        open={limitModal}
        setOpen={setLimitModal}
        limitationModalTitle={"page"}
      />
      <CustomConfirmation
        open={delmsg}
        setOpen={setDelmsg}
        handleAgree={handledeletesection}
        title="Are you sure you want to remove this Section?"
      />

      {selectedlistItem === "Funnel" && (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              overflow: "hidden",
            }}
            className="transition"
          >
            <div
              className="border-bottom"
              style={{
                lineHeight: "41px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 8px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              <div>{selectedlistItem}</div>
              <div className="icon" onClick={leaveHoverDrawer}>
                <i class="fa fa-xmark"></i>
              </div>
            </div>
            <div className="overflow-auto">
              <div
                className="pt-2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0px 8px 75px",
                  fontSize: "14px",
                  fontWeight: "500",
                  overflowY: "auto",
                }}
              >
                {funnelGroup.map((page, index) => (
                  <>
                    {console.log(page, "jjejfnejfekfenfeknfkekfekkfenkfe")}

                    <div
                      style={{
                        color:
                          page._id === rootValues.page_id
                            ? "#023373"
                            : "#e8eaec",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="funnel-circle"
                        style={{
                          background:
                            page._id === rootValues.page_id && "#023373",
                          color: page._id === rootValues.page_id && "#e8eaec",
                        }}
                      >
                        <span>{index + 1}</span>
                      </div>

                      {/* <Icon icon="bi:circle-fill" fontSize={25} /> */}
                      <Icon
                        icon="ci:line-m"
                        fontSize={40}
                        style={{ marginBlock: "-10px" }}
                      />
                    </div>

                    <div className="funnel-card">
                      <div className="d-flex flex-column">
                        <div
                          className="create-project-card mb-2"
                          style={{
                            height: "",
                            width: "100%",
                            boxShadow: "none",
                            opacity: page._id !== rootValues.page_id && "0.6",
                            pointerEvents: isSaveLoading && "none",
                          }}
                          onClick={(e) => {
                            if (rootValues.page_slug !== page.page_slug) {
                              handlechangepage(page.page_slug);
                            }
                          }}
                        >
                          <div
                            style={{
                              height: "",
                              width: "100%",
                              boxShadow: "none",
                            }}
                          >
                            <img
                              src={
                                page.image
                                  ? s3baseUrl + "/" + page.image
                                  : website_placeholder_img
                              }
                              alt={""}
                              width="100%"
                              height="100px"
                              className="create-project-image"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  s3builderSource + "/" + page.image;
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "8px 12px",
                            }}
                            className="text-capitalize border-top"
                          >
                            <div
                              style={{
                                width: 200,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "noWrap",
                              }}
                            >
                              {page.page_name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        color: "#e8eaec",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Icon
                        icon="ci:line-m"
                        fontSize={40}
                        style={{ marginBlock: "-10px" }}
                      />
                      {index === groupPage_list.length - 1 && (
                        <Button
                          size="small"
                          variant="outlined"
                          disabled
                          sx={{ borderRadius: "0px" }}
                        >
                          End of Funnel
                        </Button>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default FunnelPages;
