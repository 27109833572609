import React, { useEffect, useState } from "react";

import {
  Box,
  ListItemButton,
  List,
  ListItemText,
  Divider,
} from "@mui/material";

import { Icon } from "@iconify/react";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";

const EditButtonPopup = () => {
  const {
    setEditButtonPopupAnchorEl,
    editButtonPopupAnchorEl,
    selectedElement,
    setSelectedElement,
    handleElementClick,
    setImageAnchorEl,
    editButtonPopupText,
  } = useBuilder();

  const [rightMenuList, setRightMenuList] = useState();
  const [imageElement, setImageElement] = useState(null);

  const handleClose = () => {
    setEditButtonPopupAnchorEl(null);
  };
  const handleOpen = (e) => {
    e.preventDefault();
    const Project_Menu_list = [];
    Project_Menu_list.push({
      label: ``,
      icon: "mingcute:settings-6-line",
      divider: false,
      handleClick: () => handleElementClick(e, imageElement),
    });

    setRightMenuList(Project_Menu_list);
  };

  useEffect(() => {
    if (editButtonPopupAnchorEl) {
      const editButton = document.querySelector(".edit-button-wrapper");

      editButton.addEventListener("mouseenter", (e) => {
        setEditButtonPopupAnchorEl(e);
      });
      editButton.addEventListener("mouseleave", (e) => {
        setEditButtonPopupAnchorEl(null);
      });

      setImageElement(editButtonPopupAnchorEl.target);

      handleOpen(editButtonPopupAnchorEl);
    }
  }, [editButtonPopupAnchorEl]);

  return (
    <>
      <div
        className="edit-button-wrapper"
        style={{
          visibility: editButtonPopupAnchorEl ? "visible" : "hidden",
        }}
      >
        <Box>
          <List
            component="nav"
            aria-label="secondary mailbox folder"
            onClick={handleClose}
            sx={{ padding: "0 0" }}
          >
            {rightMenuList?.map((list, index) => (
              <>
                <div key={index}>
                  {list?.divider == true && <Divider className="my-2" />}

                  <ListItemButton
                    disabled={list.disable}
                    onClick={list.handleClick}
                    sx={{
                      borderRadius: "8px",
                      cursor: "pointer",
                      marginInline: "8px",
                      padding: "2px",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor:
                          list.backgroundColor && list.backgroundColor,
                      },
                    }}
                  >
                    <Icon
                      icon="mingcute:settings-6-line"
                      className=""
                      style={{
                        color: list.color ? list.color : "#023373",
                      }}
                    />
                    {editButtonPopupText && (
                      <span className="ms-2">Edit Image</span>
                    )}
                  </ListItemButton>
                </div>
              </>
            ))}
          </List>
        </Box>
      </div>
    </>
  );
};

export default EditButtonPopup;
