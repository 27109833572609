// here we add all base urls and keys

// Base URLS
export const base_uri = process.env.REACT_APP_API_BASE_URL;
export const vissioon_base_url = process.env.REACT_APP_VISSIOON_API_BASE_URL;
export const source_base_uri = process.env.REACT_APP_API_SOURCE_BASE_URL;
export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;
export const s3builderSource = process.env.REACT_APP_S3_SOURCE_BASE_URL;
export const tinyMCEKey = process.env.REACT_APP_TINY_MCE_KEY;
export const base_folder = "users";
export const app_type = "1";

export const asserts_base_url =
  process.env.REACT_APP_S3_SOURCE_ASSERTS_BASE_URL;

export const client_side_url = process.env.REACT_APP_CLIENT_SIDE_URL;

// Keys
// export const secret_key = process.env.REACT_APP_API_SECRET_KEY;
