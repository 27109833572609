import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";

import { Icon } from "@iconify/react";

import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InlineInputField from "../../../Right-Menu/components/Inline-Input-Field/InlineInputField";
import { set } from "lodash";
import { list_all_plan_by_project_id } from "../../../../../../DAL/user-created-plans/userCreatedPlans";
function FormMenu({ getFormData, defaultExpanded = false }) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    rootValues,
    ReRenderHtml,
    activePaymentPlansList,
    setActivePaymentPlansList,
    funnelGroup,
    funnelDetails,
  } = useBuilder();
  const [formData, setformData] = useState({
    on_success_page: "",
    success_message: "",
    failure_message: "",
  });
  const [linkType, setLinkType] = useState("");
  const [groupPage_list, setGroupPage_list] = useState([]);
  const [NewElement, setNewElement] = useState({});
  const [ElementIndex, setElementIndex] = useState(-1);
  const [stepMessageShow, setStepMessageShow] = useState(false);
  const [ElemnetsList, setElemnetsList] = useState([
    {
      label: "First Name",
      value: {
        name: "first_name",
        type: "text",
        tag: "input",
        placeholder: "First Name",
      },
    },
    {
      label: "Last Name",
      value: {
        placeholder: "last_name",
        type: "text",
        tag: "input",
        placeholder: "Last Name",
      },
    },
    {
      label: "Email",
      value: {
        placeholder: "email",
        type: "email",
        tag: "input",
        placeholder: "Email",
      },
    },
    {
      label: "Message Box",
      value: {
        placeholder: "message",
        type: "text",
        tag: "textarea",
        placeholder: "Message",
      },
    },
    {
      label: "File Upload",
      value: {
        placeholder: "File",
        type: "file",
        tag: "input",
        placeholder: "Upload File",
      },
    },
  ]);
  const [Change, setChange] = useState(false);
  const [Form, setForm] = useState("");
  const handleChangeInSelect = (e) => {
    if (e.target.value === "move-to-next") {
      rootValues.page_list.map((page) => {
        if (page._id === rootValues.page_id) {
          if (page.order === groupPage_list.length) {
            setStepMessageShow(true);
          } else {
            setformData({ ...formData, [e.target.name]: e.target.value });
            Form.dataset[e.target.name] = e.target.value;
            setChange(!Change);
            setStepMessageShow(false);
          }
        }
      });
    } else {
      setformData({ ...formData, [e.target.name]: e.target.value });
      Form.dataset[e.target.name] = e.target.value;
      setChange(!Change);
      setStepMessageShow(false);
    }
  };
  const hadle_Add_Form_Element = () => {
    const element_div = document.createElement("div");
    if (NewElement.tag == "textarea") {
      element_div.classList.add("form-group");
    } else {
      element_div.classList.add("col-md-6");
      element_div.classList.add("mb-3");
    }

    // create elemnt
    const element = document.createElement(NewElement.tag);
    element.classList.add("form-control");
    element.setAttribute("name", NewElement.name);
    element.setAttribute("placeholder", NewElement.placeholder);
    element.setAttribute("type", NewElement.type);

    element_div.append(element);
    Form.getElementsByClassName("row")[0].prepend(element_div);
    ReRenderHtml();
    enqueueSnackbar("Input added successfully", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };
  const handleChangeAttribute = (name, val) => {
    setformData({ ...formData, [name]: val });
    Form.dataset[name] = val;

    // if value empty than remove from dataset
    if (val == "") {
      Form.removeAttribute(`data-${name}`);
      return;
    }
    // setChange(!Change)
  };
  const handleChangeSelect = (index) => {
    setElementIndex(index);
    setNewElement({ ...ElemnetsList[index].value });
  };

  const getSignAgainstCurrency = (plan, plansList) => {
    switch (plan.currency_type) {
      case "EUR":
        plan.currency_type = "€";
        break;
      case "GBP":
        plan.currency_type = "£";
        break;
      case "CNY":
        plan.currency_type = "¥";
        break;
      case "RUB":
        plan.currency_type = "₽";
        break;

      default:
        plan.currency_type = "$";
        break;
    }
    plansList.push(plan);
  };

  const getPlanList = async () => {
    let resp = await list_all_plan_by_project_id(rootValues.project);
    if (resp.code == 200) {
      let plansList = [];
      resp.data.map((plan) => {
        getSignAgainstCurrency(plan, plansList);
      });
      let list = [];
      plansList.map((plan) => {
        if (
          plan.page_group_id &&
          plan.page_group_id === rootValues.page_group_id
        ) {
          list.push(plan);
        }
      });
      if (list.length > 0) {
        setActivePaymentPlansList(list);
      } else {
        setActivePaymentPlansList(plansList);
      }
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    // setActivePaymentPlansList([...activePaymentPlansListTest]);
  };

  useEffect(() => {
    getPlanList();
    const { form } = getFormData();

    // get on success redirect page
    formData.on_success_page = form[0].dataset.on_success_page
      ? form[0].dataset.on_success_page
      : "";
    setLinkType(
      form[0].dataset.on_success_page
        ? form[0].dataset.on_success_page === "move-to-next"
          ? form[0].dataset.on_success_page
          : "pages"
        : ""
    );

    console.log(form[0].dataset.on_success_page, "cjdnjcndnjcdcdjcd");

    // get on success redirect page
    formData.default_plan = form[0].dataset.default_plan
      ? form[0].dataset.default_plan
      : "";

    // get on success message
    formData.success_message = form[0].dataset.success_message
      ? form[0].dataset.success_message
      : "";
    // get on faliure message
    formData.failure_message = form[0].dataset.failure_message
      ? form[0].dataset.failure_message
      : "";

    setForm(form[0]);
  }, [Change]);

  useEffect(() => {
    if (rootValues) {
      let values = rootValues;
      let list = [];
      if (
        funnelDetails?.page_group_type !== "generic_website" &&
        values.page_group_id !== ""
      ) {
        values?.page_list?.map((page) => {
          if (page.page_group_id?._id === values.page_group_id) {
            list.push(page);
          }
          if (page.page_group_id === values.page_group_id) {
            list.push(page);
          }
        });
      } else {
        list = values.page_list;
      }
      console.log(values, list, "njenjcnececneiniceicienicei");
      setGroupPage_list(list);
    }
  }, [rootValues]);

  return (
    <>
      {/* form submittion setting */}
      <Accordion defaultExpanded={defaultExpanded} className="card">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Form Submittion Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            {/* success message */}
            <div className="col-12 mb-4">
              <div className="row align-items-center">
                <div className="col ">
                  <span>
                    <label
                      for="edit-able-href-input"
                      className="col d-flex  form-label custom-label"
                    >
                      Success Message
                    </label>
                  </span>
                  <span>
                    <InlineInputField
                      placeholder="Success Message"
                      value={formData.success_message}
                      handleUpdate={(val) =>
                        handleChangeAttribute("success_message", val)
                      }
                    />
                  </span>
                </div>
              </div>
            </div>

            {/* faliure message */}
            <div className="col-12 mb-4">
              <div className="row align-items-center">
                <div className="col ">
                  <span>
                    <label
                      for="edit-able-href-input"
                      className="col d-flex  form-label custom-label"
                    >
                      Faliure Message
                    </label>
                  </span>

                  <span>
                    <InlineInputField
                      placeholder="Faliure Message"
                      value={formData.failure_message}
                      handleUpdate={(val) =>
                        handleChangeAttribute("failure_message", val)
                      }
                    />
                  </span>
                </div>
              </div>
            </div>

            {/* link page */}
            <div className="col-12 mb-3">
              <div className="row align-items-center">
                <div className="col ">
                  <span>
                    <label
                      for="edit-able-href-input"
                      className="col d-flex  form-label custom-label"
                    >
                      On successfully submit form
                    </label>
                  </span>
                  {/* on successful submit form */}
                  <span>
                    <label
                      for="edit-able-href-input"
                      className="col d-flex  form-label custom-label mb-0"
                    >
                      Link to
                    </label>
                  </span>
                  <span style={{ height: "16px" }}>
                    <Tooltip arrow placement="top" title="Link">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-link"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                        <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                      </svg>
                    </Tooltip>
                  </span>
                  {funnelGroup.length > 0 &&
                  funnelDetails?.page_group_type !== "generic_website" ? (
                    <>
                      <span className="">
                        <select
                          // value={selectedElementAttributes?.href?.replace("/", "")}
                          onChange={(e) => {
                            e.target.value === "pages"
                              ? (setLinkType(e.target.value),
                                setStepMessageShow(false))
                              : (handleChangeInSelect(e),
                                setLinkType(e.target.value));
                          }}
                          name="on_success_page"
                          id="linkSelect"
                          className=" form-control custom-icon-select form-control-sm"
                          value={linkType}
                        >
                          <option hidden value={""}>
                            Select Link To{" "}
                          </option>

                          <option value={"move-to-next"}>
                            Redirect To Next Step
                          </option>

                          <option value={"pages"}>Select From Pages</option>
                        </select>
                        {stepMessageShow && (
                          <div className="mt-2">
                            <span style={{ fontSize: "12px" }}>
                              There are no additional steps after this page.*
                            </span>
                          </div>
                        )}
                      </span>
                      {(linkType === "pages" ||
                        formData.on_success_page !== "move-to-next") &&
                        formData.on_success_page && (
                          <>
                            <span className="">
                              <label
                                for="edit-able-href-input"
                                className="col d-flex  form-label custom-label mt-2 mb-1"
                              >
                                Select Page
                              </label>
                            </span>
                            <span>
                              <select
                                // value={selectedElementAttributes?.href?.replace("/", "")}
                                onChange={(e) => handleChangeInSelect(e)}
                                name="on_success_page"
                                id="linkSelect"
                                className=" form-control custom-icon-select form-control-sm"
                                value={formData.on_success_page}
                              >
                                <option hidden value={""}>
                                  Select any page{" "}
                                </option>

                                {groupPage_list?.length > 0 &&
                                  groupPage_list?.map((page, i) => {
                                    return (
                                      <option
                                        key={i}
                                        value={`/${page.page_slug}.html`}
                                      >
                                        {page.page_name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </span>
                          </>
                        )}
                    </>
                  ) : (
                    <>
                      <span>
                        <select
                          // value={selectedElementAttributes?.href?.replace("/", "")}
                          onChange={(e) => handleChangeInSelect(e)}
                          name="on_success_page"
                          id="linkSelect"
                          className=" form-control custom-icon-select form-control-sm"
                          value={formData.on_success_page}
                        >
                          <option hidden value={""}>
                            Select any page{" "}
                          </option>

                          {groupPage_list?.length > 0 &&
                            groupPage_list?.map((page, i) => {
                              return (
                                <option
                                  key={i}
                                  value={`/${page.page_slug}.html`}
                                >
                                  {page.page_name}
                                </option>
                              );
                            })}
                        </select>
                      </span>
                    </>
                  )}

                  {/* on unsuccessfull submit */}
                  {/* <span>
                  <label
                    for="edit-able-href-input"
                    className="col d-flex  form-label custom-label pt-4"
                  >
                    On successfully submit form
                  </label>
                </span>
                
                <span>
                  <select
                    // value={selectedElementAttributes?.href?.replace("/", "")}
                    onChange={(e) => handleChangeInSelect(e)}
                    name="linkSelect"
                    id="linkSelect"
                    className=" form-control custom-icon-select form-control-sm"
                    value={formData.on_success_page}
                  >
                    <option value={""}>Select any page </option>
                    {rootValues.page_list.length > 0 &&
                      rootValues.page_list.map((page, i) => {
                        return (
                          <option
                            key={i}
                            value={"/" + page.page_slug + ".html"}
                          >
                            {page.page_name}
                          </option>
                        );
                      })}
                  </select>
                </span> */}
                </div>
              </div>
            </div>

            {activePaymentPlansList?.length > 0 && (
              <>
                {/* link default paymnet */}
                <div className="col-12">
                  <div className="row align-items-center">
                    <div className="col">
                      <span>
                        <label
                          for="edit-able-href-input"
                          className="col d-flex  form-label custom-label"
                        >
                          Default Payment Plan
                        </label>
                      </span>
                      <span>
                        <select
                          onChange={(e) => handleChangeInSelect(e)}
                          name="default_plan"
                          id="linkSelect"
                          className=" form-control custom-icon-select form-control-sm"
                          value={formData.default_plan}
                        >
                          <option hidden value={""}>
                            Select any plan{" "}
                          </option>
                          {activePaymentPlansList.length > 0 &&
                            activePaymentPlansList.map((plan, i) => {
                              return (
                                <option key={i} value={`${plan._id}`}>
                                  {plan.plan_name}
                                </option>
                              );
                            })}
                        </select>
                      </span>

                      {/* on unsuccessfull submit */}
                      {/* <span>
                  <label
                    for="edit-able-href-input"
                    className="col d-flex  form-label custom-label pt-4"
                  >
                    On successfully submit form
                  </label>
                </span>
                
                <span>
                  <select
                    // value={selectedElementAttributes?.href?.replace("/", "")}
                    onChange={(e) => handleChangeInSelect(e)}
                    name="linkSelect"
                    id="linkSelect"
                    className=" form-control custom-icon-select form-control-sm"
                    value={formData.on_success_page}
                  >
                    <option value={""}>Select any page </option>
                    {rootValues.page_list.length > 0 &&
                      rootValues.page_list.map((page, i) => {
                        return (
                          <option
                            key={i}
                            value={"/" + page.page_slug + ".html"}
                          >
                            {page.page_name}
                          </option>
                        );
                      })}
                  </select>
                </span> */}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default FormMenu;
