import React, { useEffect, useRef } from "react";
import { website_placeholder_img } from "../../assets";
import {
  app_type,
  asserts_base_url,
  s3baseUrl,
  s3builderSource,
} from "../../config/config";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { IconButton, Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";

import {
  _get_user_created_designs,
  _get_user_design_details,
} from "../../DAL/projects/projects";
import Loader from "../../components/Loader/Loader";
import {
  _create_user_design_page,
  _remove_user_design_page,
  _update_user_design_page,
} from "../../DAL/projects-pages/projectspages";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { _get_user } from "../../DAL/localStorage/localStorage";
import {
  _save_page_version,
  _get_version_list,
  _use_page_version,
} from "../../DAL/projects-pages/projectspages";
import CustomConfirmation from "../../components/CustomConfirmation";
import AddNewPage from "../../layouts/MainEditorLayout/components/AddNewPage/AddNewPage";
import EditPageName from "../../layouts/MainEditorLayout/components/EditPageName/EditPageName";
import CustomPopover from "../../components/CustomPopover";
import AddNewVersion from "../../layouts/MainEditorLayout/components/AddNewVersion/AddNewVersion";
function ProjectPagesList(props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [Pages, setPages] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [RemovePageDailogue, setRemovePageDailogue] = useState(false);
  const [TargetPageID, setTargetPageID] = useState("");
  const [OpenNewPageDailogue, setOpenNewPageDailogue] = useState(false);
  const [SelectedPage, setSelectedPage] = useState("");
  const [EditPage, setEditPage] = useState(false);
  const [TargetPage, setTargetPage] = useState("");
  const [OpenPageDuplicateDailogue, setOpenPageDuplicateDailogue] =
    useState(false);
  const [FinalHTML, setFinalHTML] = useState("");
  const [FinalCSS, setFinalCSS] = useState("");
  const [FinalJS, setFinalJS] = useState("");
  const [isUseVesion, setisUseVesion] = useState(false);
  const [OpenSaveVersionDailogue, setOpenSaveVersionDailogue] = useState(false);
  const [CurrentVersion, setCurrentVersion] = useState(null);
  const [VersionList, setVersionList] = useState([]);
  var myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");

  var myInit = {
    method: "GET",
    headers: myHeaders,
  };
  const fetchFileFromURL = (url) => {
    return new Promise(async (resolve, reject) => {
      const resp = await fetch(url, myInit);
      if (resp.status === 200) {
        const data = await resp.text();
        resolve(data);
      } else {
        console.error(resp);
        resolve("");
      }
    });
  };
  const fetchAvailablePages = async () => {
    setisLoading(true);

    const user = _get_user();
    const postData = {
      app_type: `${user?.type}`,
    };
    const project_resp = await _get_user_created_designs();

    if (project_resp.code == 200) {
      const resp = await _get_user_design_details(params.project_id);
      if (resp.code === 200) {
        if (resp.Pages.length == 0) {
          navigate("/choose-template", {
            state: {
              project_id: params.project_id,
            },
          });
        }
        setPages(resp.Pages);
        setisLoading(false);
      } else {
        enqueueSnackbar(resp.message, { variant: "error" });
      }
    } else {
      enqueueSnackbar(project_resp.message, { variant: "error" });
    }
  };
  const handleSelectOpenPage = (Page) => {
    const project_id = params?.project_id;
    const page_id = Page._id;
    navigate(`/builder/${project_id}/${page_id}`, {
      state: null,
    });
  };
  const handleRemovePage = async (_multiple_hit) => {
    if (_multiple_hit) {
      const resp = await _remove_user_design_page(TargetPageID);
      if (resp.code == 200) {
        fetchAvailablePages();
        setRemovePageDailogue(false);
      } else {
        enqueueSnackbar(resp.message, { variant: "error" });
      }
    }
  };
  const handleEditPageName = (Page) => {
    setSelectedPage(Page);
    setEditPage(true);
  };
  const handlePreviewPage = (Page) => {
    window.open(`${s3baseUrl}/${params.project_id}/${Page.page_slug}.html`);
  };
  const handleremovePage = (Page) => {
    setTargetPageID(Page._id);
    setRemovePageDailogue(true);
  };
  const openPageSettings = (Page) => {
    navigate(`/page-settings/${params.project_id}/${Page._id}`, {
      state: {
        Project: location.state.Project,
        Page: Page,
      },
    });
  };
  const handleDuplicatePage = async (Page) => {
    setSelectedPage(Page);

    // fetch html
    const section_html = await fetchFileFromURL(
      `${s3baseUrl}/${params.project_id}/${Page.page_slug}.html`
    );
    // fetch css
    const final_css = await fetchFileFromURL(
      `${s3baseUrl}/${params.project_id}/css/${
        Page.page_slug == "index" ? "style" : Page.page_slug
      }.css`
    );
    // fetch js
    const final_js = await fetchFileFromURL(
      `${s3baseUrl}/${params.project_id}/js/${Page.page_slug}.js`
    );
    const div = document.createElement("div");
    div.innerHTML = section_html;
    const sectionslist = div.getElementsByTagName("section");
    let final_html = "";
    for (let index = 0; index < sectionslist.length; index++) {
      final_html += sectionslist[index].outerHTML + "\n\n";
    }
    setFinalHTML(final_html);
    setFinalCSS(final_css);
    setFinalJS(final_js);
    setOpenPageDuplicateDailogue(true);
  };

  const handleViewVersions = (page) => {
    navigate(`/projects/${params.project_id}/${page._id}/version`, {
      state: {
        page,
      },
    });
  };

  const handleSaveVersion = async (page) => {
    setTargetPage(page);

    setOpenSaveVersionDailogue(true);
  };

  const handleAddNewVersion = async (name, useversion) => {
    setisUseVesion(true);
    const formData = new FormData();
    formData.append("page_id", TargetPage._id);
    formData.append("version_name", name);
    if (useversion) {
      formData.append("version_id", TargetPage.version);
    } else {
      if (VersionList.length >= 3) {
        enqueueSnackbar("Unable to save version.Already 3 pages available", {
          variant: "error",
        });
        setisUseVesion(false);

        return;
      }
    }

    const save_res = await _save_page_version(formData);

    if (save_res.code === 200) {
      enqueueSnackbar("Page version saved.", { variant: "success" });
    } else {
      enqueueSnackbar(save_res.message, { variant: "error" });
    }
    setisUseVesion(false);
  };
  const MENU_OPTIONS = (row, index) => {
    if (row.page_slug == "index") {
      return [
        {
          label: "Preview Page",
          icon: "carbon:view",
          handleClick: handlePreviewPage,
        },
        {
          label: "Edit Page Name",
          icon: "ant-design:edit-twotone",
          handleClick: handleEditPageName,
        },
        {
          label: "Duplicate Page",
          icon: "fa:copy",
          handleClick: handleDuplicatePage,
        },
        {
          label: "Save Page Version",
          icon: "material-symbols:save",
          handleClick: handleSaveVersion,
        },
        {
          label: "View Page Versions",
          icon: "ic:outline-format-list-numbered",
          disable: row.page_version_count > 0 ? false : true,
          handleClick: handleViewVersions,
        },
        {
          label: "Page Setting",
          icon: "ant-design:setting-twotone",
          handleClick: openPageSettings,
        },
      ];
    } else {
      return [
        {
          label: "Preview Page",
          icon: "carbon:view",
          handleClick: handlePreviewPage,
        },
        {
          label: "Edit Page Name",
          icon: "ant-design:edit-twotone",
          handleClick: handleEditPageName,
        },
        {
          label: "Duplicate Page",
          icon: "fa:copy",
          handleClick: handleDuplicatePage,
        },
        {
          label: "Save Page Version",
          icon: "material-symbols:save",
          handleClick: handleSaveVersion,
        },
        {
          label: "View Page Versions",
          icon: "ic:outline-format-list-numbered",
          disable: row.page_version_count > 0 ? false : true,
          handleClick: handleViewVersions,
        },
        {
          label: "Page Setting",
          icon: "ant-design:setting-twotone",
          handleClick: openPageSettings,
        },
        {
          label: "Delete Page",
          icon: "ant-design:delete-twotone",
          handleClick: handleremovePage,
        },
      ];
    }
  };

  useEffect(() => {
    fetchAvailablePages();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      {isUseVesion && (
        <div className="Loader">
          <Loader />
        </div>
      )}

      <AddNewPage
        fetchAvailablePages={fetchAvailablePages}
        project_id={params.project_id}
        open={OpenNewPageDailogue}
        setOpen={setOpenNewPageDailogue}
        Page={Pages[0]}
      />

      {/* duplicate Page */}
      <AddNewPage
        fetchAvailablePages={fetchAvailablePages}
        project_id={params.project_id}
        duplicate={true}
        open={OpenPageDuplicateDailogue}
        setOpen={setOpenPageDuplicateDailogue}
        Page={SelectedPage}
        FinalHTML={FinalHTML}
        FinalCSS={FinalCSS}
        FinalJS={FinalJS}
      />
      {/* {OpenSaveVersionDailogue && ( */}
        <AddNewVersion
          reload={fetchAvailablePages}
          open={OpenSaveVersionDailogue}
          setOpen={setOpenSaveVersionDailogue}
          Page_id={TargetPage._id}
          SaveHTML={false}
          version={TargetPage.version ? TargetPage.version : ""}
        />
      {/* )} */}
      <EditPageName
        fetchAvailablePages={fetchAvailablePages}
        project_id={params.project_id}
        open={EditPage}
        Page={SelectedPage}
        setOpen={setEditPage}
      />

      <CustomConfirmation
        open={RemovePageDailogue}
        setOpen={setRemovePageDailogue}
        handleAgree={handleRemovePage}
        title="Are sure you want to remove this page?"
      />
      <div
        style={isUseVesion ? { opacity: 0.3, pointerEvents: "none" } : {}}
        className="container "
      >
        {/* <div className="container"> */}
        <div className="row mt-2">
          <div className="col-12 mb-3">
            <IconButton
              onClick={() => {
                navigate("/projects");
              }}
              aria-label="delete"
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className=" d-flex  align-items-center justify-content-center ">
            <div>
              <h5 className="pt-2">Available Pages Of Project</h5>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              onClick={() => setOpenNewPageDailogue(true)}
              className="main-dashboard-website-action-btn"
            >
              Add New Page
            </button>
          </div>

          <div className="col-12 mt-2 mb-5">
            <div className="row">
              {/* {Pages.map((Page) => ( */}
              {Pages.map((Page, index) => (
                <div
                  key={Page._id}
                  className="col-12 col-sm-6 col-lg-4 mt-4 page_card"
                >
                  <div className="border position-relative">
                    <div className="page_menu_option">
                      <CustomPopover
                        color={"#ffff"}
                        data={Page}
                        menu={MENU_OPTIONS(Page, index)}
                      />{" "}
                    </div>
                    <div>
                      <img
                        className="main-dashboard-website-placeholder"
                        onError={(e) => {
                          if (
                            e.target.src !=
                            asserts_base_url + "/" + Page.image
                          ) {
                            e.target.src = asserts_base_url + "/" + Page.image;
                          }
                          else
                          {
                            e.target.src=website_placeholder_img
                          }
                        }}
                        src={
                          Page.image
                            ? s3baseUrl + "/" + Page.image
                            : website_placeholder_img
                        }
                        // src={website_placeholder_img}
                        width="100%"
                        height="140%"
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                      <div className="main-dashboard-website-action rounded-card">
                        <button
                          onClick={() => handleSelectOpenPage(Page)}
                          className="main-dashboard-website-action-btn visible-parent-hover mb-2"
                        >
                          Open Page in Builder
                        </button>

                        {/* <a
                          href={`${s3baseUrl}/${Project._id}/${Page.page_slug}.html`}
                          target="_blank"
                        >
                          <button className="main-dashboard-website-action-btn visible-parent-hover">
                            Preview Page
                          </button>
                        </a>
                        <button
                          onClick={() => {
                            setTargetPageID(Page._id);
                            setRemovePageDailogue(true);
                          }}
                          className="main-dashboard-website-action-btn visible-parent-hover mt-2"
                        >
                          Remove Page
                        </button> */}
                      </div>
                      <div class="p-2">
                        <div class="main-dashboard-website-name text-capitalize">
                          {Page.page_name}
                          <span class="float-end"></span>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              ))}
              {/* add new Page */}
              {/* <div className="col-12 col-sm-6 col-lg-4 mt-4">
                <div className="border position-relative">
                  <div>
                    <img
                      className="main-dashboard-website-placeholder"
                      src={website_placeholder_img}
                      width="100%"
                      height="140%"
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                    <div className="main-dashboard-website-action rounded-card">
                      <button
                        onClick={() => setOpenNewPageDailogue(true)}
                        className="main-dashboard-website-action-btn visible-parent-hover mb-2"
                      >
                        Add New Page
                      </button>
                    </div>
                    <div class="p-2">
                      <div class="main-dashboard-website-name text-capitalize">
                        Add New Page
                        <span class="float-end"></span>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectPagesList;
