import { Icon } from "@iconify/react";
import { Tooltip } from "@mui/material";
import React from "react";

export const RowDivManipulationWithIcon = ({
  label,
  classlist,
  value,
  handleChangeRowClass,
}) => {
  return (
    <div className="col-12 mb-2">
      <div className="col-12 mb-1">
        <span className="custom-label">{label}</span>
      </div>
      <div className="col-12">
        {classlist.map((val, index) => (
          <div key={index} class="form-check-inline">
            <Tooltip title={val.label} arrow placement="top">
              <div
                style={{
                  border:
                    value === val.value || value === val?.extra_class
                      ? "1px solid #023373"
                      : "1px solid #e6e6e6",
                  padding: "0px 16px",
                  cursor: "pointer",
                  borderRadius: "2px",
                }}
                onClick={() => handleChangeRowClass(val.value, classlist)}
              >
                <Icon icon={val.icon_name} />
              </div>
            </Tooltip>
            {/* <input
              class="form-check-input"
              type="radio"
              id={val.value}
              checked={value === val.value || value === val?.extra_class}
              onClick={() => handleChangeRowClass(val.value, classlist)}
            />
            <label
              className="form-check-label pointer custom-label"
              htmlFor={val.value}
            >
              {val.label}
            </label> */}
          </div>
        ))}
      </div>
    </div>
  );
};
