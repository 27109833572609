import { Icon } from "@iconify/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ActiveCampaignURLandKeyModal from "../../../../../Website-Settings/components/Active-Campaign/components/ActiveCampaignURLandKeyModal";
import ActiveCampaign from "../../../../../Website-Settings/components/Active-Campaign/Active_Compaign_Page";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import { _get_user } from "../../../../../../DAL/localStorage/localStorage";

function ThirdPary({
  // active compain
  Page,
  setPage,
  activeCompaignData,
  handleUpdateForm,
  GetActiveCompaignValues,
  cardToOpen,
}) {
  const [
    activeCampaignURLandKeyConnected,
    setActiveCampaignURLandKeyConnected,
  ] = useState(false);
  const [expandActiveCampaign, setexpandActiveCampaign] = useState(false);
  const [activeCampaignModal, setActiveCampaignModal] = useState(false);

  const setpageData = async () => {
    const user = _get_user();
    user.active_compaign_url && setActiveCampaignURLandKeyConnected(true);
  };
  useEffect(() => {
    setpageData();
  }, [activeCampaignModal]);
  return (
    <div className="col-12">
      <div className="row px-4">
        {/* <div className="col-6">
          <div
            className={
              thirdPartyTab === "1"
                ? "third-party-box-active"
                : "third-party-box"
            }
            onClick={() => setThirdPartyTab("1")}
          >
            <Icon
              icon="logos:active-campaign-icon"
              className="me-2"
              height={24}
              width={24}
            />
            <div className="d-flex flex-column">
              <span>ActiveCampain</span>
              {campaignConnected && (
                <span className="mb-0" style={{ fontSize: "8px" }}>
                  (Connected)
                </span>
              )}
            </div>
          </div>
        </div> */}
        {/* <div className="col-6">
          <div
            className={
              thirdPartyTab === "2"
                ? "third-party-box-active"
                : "third-party-box"
            }
            onClick={() => setThirdPartyTab("2")}
          >
            <Icon
              icon="logos:mailchimp-freddie"
              className="me-2"
              height={24}
              width={24}
            />
            <div className="d-flex flex-column">
              <span>MailChimp</span>

              <span className="mb-0" style={{ fontSize: "8px" }}>
                (Coming Soon)
              </span>
            </div>
          </div>
        </div> */}
      </div>

      <>
        <ActiveCampaignURLandKeyModal
          open={activeCampaignModal}
          setOpen={setActiveCampaignModal}
          setexpandActiveCampaign={setexpandActiveCampaign}
        />

        <>
          <div className="mx-3 my-2">
            <Accordion
              className="card mb-2 "
              expanded={expandActiveCampaign}
            >
              <AccordionSummary
                 onClick={() =>
                  activeCampaignURLandKeyConnected &&
                  setexpandActiveCampaign(!expandActiveCampaign)
                }
                expandIcon={
                  activeCampaignURLandKeyConnected && <SettingsIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className=" d-flex justify-content-between align-items-center w-100">
                  <div className="third-party-box">
                    <Icon
                      icon="logos:active-campaign-icon"
                      className="me-2"
                      height={24}
                      width={24}
                    />
                    <div className="d-flex flex-column">
                      <span>ActiveCampain</span>
                      {activeCampaignURLandKeyConnected && (
                        <span className="mb-0" style={{ fontSize: "8px" }}>
                          (Configured)
                        </span>
                      )}
                    </div>
                  </div>
                  {!activeCampaignURLandKeyConnected && (
                    <Button
                      onClick={() => setActiveCampaignModal(true)}
                      size="small"
                    >
                      Configure Campaign
                    </Button>
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <ActiveCampaign
                  page={Page}
                  setPage={setPage}
                  activeCompaignData={activeCompaignData}
                  handleUpdateForm={handleUpdateForm}
                  GetActiveCompaignValues={GetActiveCompaignValues}
                  cardToOpen={cardToOpen}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="card mb-2 "
              // onClick={() => setexpandActiveCampaign(!expandActiveCampaign)}
              // expanded={expandActiveCampaign}
            >
              <AccordionSummary
                expandIcon={<SettingsIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={"third-party-box"}>
                  <Icon
                    icon="logos:mailchimp-freddie"
                    className="me-2"
                    height={24}
                    width={24}
                  />
                  <div className="d-flex flex-column">
                    <span>MailChimp</span>

                    <span className="mb-0" style={{ fontSize: "8px" }}>
                      (Coming Soon)
                    </span>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails></AccordionDetails>
            </Accordion>
          </div>
        </>
      </>
    </div>
  );
}

export default ThirdPary;
