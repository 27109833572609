import React, { useEffect, useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { RowColumnStyleAccordion } from "../Row-Setting-Menu/components/Row-Column-Style/RowColumnStyleAccordion";
import GenerateGradient from "../../../../components/Generate-Gradient/GenerateGradient";
import ElementSourceCodeEditorBar from "../../../../components/Element-Source-Code-Editor-Bar/ElementSourceCodeEditorBar";
import { Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
const SelectedBoxDivSettingMenu = () => {
  const {
    selectedBoxDiv,
    setSelectedBoxDiv,
    setOpenSelectedBoxDiv,
    getParentNode,
    SaveActionToStack,
  } = useBuilder();
  const [change, setChange] = useState(false);
  const [imgData, setImgData] = useState([]);
  const [showData, setShowData] = useState(false);
  const handleChangeColumnBlockStyle = (property, value, index, name = "") => {
    selectedBoxDiv.style.setProperty(property, value);
    if (property === "background-image" && value !== "" && name !== "") {
      selectedBoxDiv.setAttribute("data-image_name", name);
    }
    if (
      value == "" &&
      property === "background-image" &&
      selectedBoxDiv.getAttribute("data-image_name")
    ) {
      selectedBoxDiv.removeAttribute("data-image_name");
    }
    setChange(!change);
  };
  const handleChangeColumnBackgroundColor = (property, value, index) => {
    selectedBoxDiv.style.setProperty(property, value);
    setChange(!change);
  };
  const getColumnproperty = (name, index) => {
    let property = selectedBoxDiv.style[name];
    return property ? property : "";
  };
  const ResetColumnproperty = (name, index) => {
    selectedBoxDiv.style.removeProperty(name);
    setChange(!change);
  };
  const ResetColumnBackgroundColor = (name, index) => {
    selectedBoxDiv.style.removeProperty(name);
    setChange(!change);
  };
  const handleChangeColumnBlockClass = (value, class_list, index) => {
    // let column_class_list = Children[index].classList;

    class_list.forEach((c) => {
      selectedBoxDiv.classList.remove(c.value);
    });
    if (value) {
      selectedBoxDiv.classList.add(value);
    }
    setChange(!change);
  };
  const handleCancel = () => {
    setOpenSelectedBoxDiv(false);
  };
  const handlegetColumnBlockClass = (class_list, index) => {
    let classname = "";

    class_list.forEach((c) => {
      if (selectedBoxDiv.classList.contains(c.value)) {
        classname = c.value;
      }
    });

    return classname;
  };
  const removeColumnproperty = (name) => {
    selectedBoxDiv.style.removeProperty(name);
    setChange(!change);
  };
  const handleRemoveBox = () => {
    SaveActionToStack();
    let element = getParentNode(selectedBoxDiv);
    element.remove();
    setOpenSelectedBoxDiv(false);
  };
  useEffect(() => {
    let data = [];
    const { offsetHeight, offsetWidth } = selectedBoxDiv;
    selectedBoxDiv.setAttribute("imgheight", offsetHeight);
    selectedBoxDiv.setAttribute("imgwidth", offsetWidth);
    data.push({
      height: selectedBoxDiv.getAttribute("imgheight"),
      width: selectedBoxDiv.getAttribute("imgwidth"),
    });
    // height=child.getAttribute('imgheight') width=child.getAttribute('imgwidth')
    setImgData([...data]);
    setShowData(true);
  }, [change]);

  return (
    <div className="p-2">
      {showData && (
        <>
          <div
            className="col-12 pt-2 mb-2 pb-2 border-bottom"
            style={{
              position: "sticky",
              zIndex: "100",
              background: "white",
              top: "0%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h6 className="mb-0">Box Settings</h6>
            <div style={{ display: "flex" }}>
            <ElementSourceCodeEditorBar />
              <Tooltip title="Remove Row" placement="bottom" arrow>
                <div
                  className="icon icon-delete me-2"
                  onClick={handleRemoveBox}
                >
                  <Icon
                    icon="material-symbols:delete-outline-sharp"
                    style={{
                      color: "#f23105",
                    }}
                  ></Icon>
                </div>
              </Tooltip>
              <Tooltip title="Close" placement="bottom" arrow>
                <div
                  className="icon me-2"
                  onClick={() => setOpenSelectedBoxDiv(false)}
                >
                  <i class="fa fa-xmark"></i>
                </div>
              </Tooltip>
            </div>
          </div>
          <RowColumnStyleAccordion
            child={selectedBoxDiv}
            // OpenColumnStyle={0}
            ChangeColumnBlockStyle={handleChangeColumnBlockStyle}
            ChangeColumnBackgroundColor={handleChangeColumnBackgroundColor}
            getColumnproperty={getColumnproperty}
            ResetColumnproperty={ResetColumnproperty}
            ResetColumnBackgroundColor={ResetColumnBackgroundColor}
            ChangeColumnBlockClass={handleChangeColumnBlockClass}
            getColumnBlockClass={handlegetColumnBlockClass}
            // img properties
            ImgData={imgData[0]}
          />

          {/* <GenerateGradient
            handleAddGradient={handleChangeColumnBlockStyle}
            getColumnproperty={getColumnproperty}
            removeColumnproperty={removeColumnproperty}
          /> */}
        </>
      )}
    </div>
  );
};

export default SelectedBoxDivSettingMenu;
