import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Tooltip from "@mui/material/Tooltip";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Icon } from "@iconify/react";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import { useSnackbar } from "notistack";
import { Button, FormControlLabel, Switch } from "@mui/material";
import { _dispatch_find_nav_dropdown_list_and_child } from "../../../../../../hooks/builder-context/utils/DOM-Basic-Manipulations/DOM-Basic-Manipulations";

function DropdownMenuList({
  listChild,
  groupPage_list,
  SectionsDataset,
  listItemindex,
  handleRemoveDropdown,
  ShowDropdownItems,
}) {
  const [list, setList] = useState([]);
  const { rootValues, ReRenderHtml, handleReplaceListElements } = useBuilder();
  const [linkTypeList, setLinkTypeList] = useState([]);
  const [currentElement, setCurrentElement] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const handleChangeLinkType = (e, i) => {
    let data = linkTypeList;
    data[i][e.target.name] = e.target.value;
    setLinkTypeList([...data]);
  };
  const handleChange = (e, i) => {
    if (e.target.value == "custom") {
    }
    let value = e.target.value;
    if (linkTypeList[i].type === "page") {
      value = value.replace("/", "");
    }
    let data = list.data;
    data[i][e.target.name] = value;
    data[i]["target"] = "";
    setList({ ...list, data });
  };

  const handleChangeTarget = (value, i) => {
    let data = list.data;
    data[i]["target"] = value;
    setList({ ...list, data });
  };
  const handleChangeActive = (value, i) => {
    let data = list.data;

    data.map((li) => {
      if (li.classes?.contains("active")) {
        li.classes.remove("active");
      }
    });
    if (value) {
      data[i].classes.add("active");
    }
    setList({ ...list, data });
  };

  const findLinks = (data, sections) => {
    data?.data?.map((li) => {
      let obj = {
        type: "custom",
      };

      sections.map((section) => {
        if (section.section_id === li.link) {
          obj = {
            type: "section",
          };
        }
      });
      rootValues.page_list.map((page) => {
        if (page.page_slug + ".html" == li.link) {
          obj = {
            type: "page",
          };
        }
      });
      setLinkTypeList((prev) => [...prev, obj]);
    });
  };
  const handleRemove = (i) => {
    let data = list.data.map((l) => l);
    data.splice(i, 1);
    setList({ ...list, data });
  };

  const handleAddNew = () => {
    // setList({
    //   ...list,
    //   data: [
    //     ...list.data,
    //     {
    //       text: "",
    //       link: "",
    //       children: [],
    //       show_dropDown_items: false,
    //     },
    //   ],
    // });
    let data = list.data;
    data.push({
      text: "Dropdown Item",
      link: "",
      children: [],
      show_dropDown_items: false,
    });
    setList({ ...list, data });
    let typelist = [...linkTypeList];
    typelist.push({ type: "custom" });
    setLinkTypeList([...typelist]);
    handleSave();
    get_list_items();
  };
  const handleDragEnd = ({ source, destination, ...rest }) => {
    if (source && destination && source.index != destination.index) {
      let newList = [...list.data];
      newList.splice(destination.index, 0, newList.splice(source.index, 1)[0]);

      setList({ ...list, data: newList });
    } else {
      console.log("ERROR", source, destination);
    }
  };
  const create_class_list = (element, class_list = []) => {
    let index = 0;
    if (element[index]?.class_name?.includes("wp-list-block")) {
      index = 1;
    }
    class_list.push({
      class_name: element[index].class_name,
      tag_name: element[index].main_element.tagName,
    });
    if (element[index].sub_elements.length > 0) {
      create_class_list(element[index].sub_elements, class_list);
    }
    return class_list;
  };

  const handleSave = () => {
    const main = create_class_list(list.list);

    console.log(list, "listDataa");

    console.log(main, "mainData");

    let created_dom_array = [];

    let ul;
    let li;
    let a;

    const elements = list.data.map((data, index) => {
      let target_index = 0;
      let ul_child_append = false;

      if (data.children.length > 0) {
        for (let index = 0; index < data.children.length; index++) {
          if (data.children[index].tagName == "UL") {
            target_index = index;
            ul_child_append = true;
          }
        }
      }
      //
      const element = main.map((el_info, i) => {
        if (i === 0 && index === 0) {
          ul = document.createElement(el_info.tag_name);
          ul.className = el_info.class_name;
        }

        if (i === 1) {
          li = document.createElement(el_info.tag_name);
          li.className = el_info.class_name;
          if (ul_child_append === true) {
            li.className = "dropdown " + li.className;
          }
          ul.appendChild(li);
        }

        if (i === 2) {
          a = document.createElement(el_info.tag_name);
          console.log(el_info.class_name, "testingtesting");
          a.className = el_info.class_name.replace("active", "");
          if (data.classes?.contains("active")) {
            a.className = a.className + " active";
          }
          rootValues.page_list.map((page) => {
            if (page.page_slug + ".html" === data.link) {
              a.href = page.page_slug + ".html";
            } else {
              a.href = data.link;
            }
          });
          a.target = data.target;
          a.innerText = data.text;

          if (ul_child_append === true) {
            a.className = "dropdown-toggle wb-disable " + a.className;
            a.setAttribute("data-bs-toggle", "dropdown");
            a.setAttribute("aria-expanded", "false");
          }
          li.appendChild(a);
          if (ul_child_append === true) {
            li.appendChild(data.children[target_index]);
          }
        }
      });

      //   handleClose();
      return element;
    });

    const current_element = document.getElementsByClassName(
      main[0].class_name
    )[0];
    handleReplaceListElements(currentElement[0], ul);
    enqueueSnackbar("Dropdown List Updated", { variant: "success" });
  };

  const get_list_items = async () => {
    let filteredList = [];
    for (let index = 0; index < listChild.length; index++) {
      if (listChild[index].tagName === "UL") {
        filteredList.push(listChild[index]);
      }
    }

    setCurrentElement([...filteredList]);

    let data = _dispatch_find_nav_dropdown_list_and_child(filteredList);
    setList(data);
    findLinks(data, SectionsDataset);
  };

  useEffect(() => {
    get_list_items();
  }, []);

  return (
    <>
      {/* <Accordion className="card" defaultExpanded={expandedTrue}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Menu</Typography>
      </AccordionSummary>
      <AccordionDetails> */}
      {/* <div className="col-12 mb-1 d-flex justify-content-between">
        <span className="custom-label">Dropdown Menu:</span>
        <button
          type="button"
          className="btn  extra-small-btn p-1"
          onClick={() => handleRemoveDropdown(listItemindex)}
        >
          <Tooltip arrow placement="top" title="Remove Dropdown">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="red"
              className="bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </Tooltip>
        </button>
      </div> */}
      <div className="dragdrop">
        <DragDropContext onDragEnd={(props) => handleDragEnd(props)}>
          <Droppable droppableId="doprable1">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {list?.data?.map((li, i) => {
                  return (
                    <>
                      <Draggable
                        key={i}
                        draggableId={`dragable-${i}`}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={`${
                              snapshot.isDragging ? "shadow" : null
                            } row border m-1 mt-2 pb-3 p-1 align-items-center`}
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              background: snapshot.isDragging ? "white" : null,
                            }}
                          >
                            <>
                              <div className="col-12 mt-1">
                                <div className="row justify-content-end">
                                  <span
                                    className="ms-auto d-inline-block"
                                    style={{ maxWidth: "2.5rem" }}
                                  >
                                    <button
                                      type="button"
                                      className="btn  extra-small-btn p-1"
                                      onClick={() => handleRemove(i)}
                                    >
                                      <Tooltip
                                        arrow
                                        placement="top"
                                        title="Remove"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="red"
                                          className="bi bi-x-circle"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                      </Tooltip>
                                    </button>
                                  </span>
                                </div>
                              </div>
                              <div
                                className={
                                  li.children.length > 0
                                    ? "col-12 mb-1"
                                    : " col-6"
                                }
                              >
                                <span>Title:</span>
                                <input
                                  value={li.text}
                                  onChange={(e) => handleChange(e, i)}
                                  name="text"
                                  className="form-control form-control-sm"
                                />
                              </div>

                              {li.children.length > 0 ? (
                                <div className="col-12">
                                  Dropdown Items:
                                  <FormControlLabel
                                    style={{ marginLeft: 10 }}
                                    control={
                                      <Switch
                                        color="primary"
                                        checked={li.classes?.contains("active")}
                                        onChange={(e) =>
                                          handleChangeActive(
                                            e.target.checked,
                                            i
                                          )
                                        }
                                        name="active_status"
                                      />
                                    }
                                  />
                                </div>
                              ) : (
                                <>
                                  <div className="col-6 ">
                                    <label htmlFor="selectLink">
                                      Choose Link:
                                    </label>
                                    <select
                                      onChange={(e) =>
                                        handleChangeLinkType(e, i)
                                      }
                                      value={
                                        linkTypeList[i]?.type &&
                                        linkTypeList[i]?.type
                                      }
                                      name="type"
                                      id="selectLink"
                                      className=" form-control custom-icon-select form-control-sm"
                                    >
                                      <option value="custom">
                                        Custom Link
                                      </option>
                                      <option value="page">Page</option>
                                      <option value="section">Section</option>
                                    </select>
                                  </div>

                                  {linkTypeList[i]?.type &&
                                    linkTypeList[i]?.type === "custom" && (
                                      <>
                                        <div className="col-6">
                                          <div>
                                            <span>
                                              <Tooltip
                                                arrow
                                                placement="top"
                                                title="Link"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  className="bi bi-link"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                                                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                                                </svg>
                                              </Tooltip>
                                            </span>
                                            <div className="custom">
                                              <input
                                                value={li.link}
                                                onChange={(e) =>
                                                  handleChange(e, i)
                                                }
                                                name="link"
                                                className="form-control form-control-sm"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6 mt-1 pt-3">
                                          <Tooltip
                                            title={"Reset"}
                                            placement="top"
                                            arrow
                                          >
                                            <span
                                              className={
                                                li.target === ""
                                                  ? "wb-icons-box-active"
                                                  : "wb-icons-box"
                                              }
                                              onClick={(e) =>
                                                handleChangeTarget("", i)
                                              }
                                            >
                                              <Icon icon="ant-design:stop-outlined" />
                                            </span>
                                          </Tooltip>
                                          <Tooltip
                                            title={"Open in new tab"}
                                            placement="top"
                                            arrow
                                          >
                                            <span
                                              onClick={(e) =>
                                                handleChangeTarget("_blank", i)
                                              }
                                              className={
                                                li.target === "_blank"
                                                  ? "wb-icons-box-active"
                                                  : "wb-icons-box"
                                              }
                                            >
                                              <Icon icon="ic:baseline-open-in-new" />
                                            </span>
                                          </Tooltip>
                                        </div>
                                      </>
                                    )}
                                  {linkTypeList[i]?.type &&
                                    linkTypeList[i]?.type === "page" && (
                                      <>
                                        <div className="col-6">
                                          <div>
                                            <span>
                                              <Tooltip
                                                arrow
                                                placement="top"
                                                title="Link"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  className="bi bi-link"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                                                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                                                </svg>
                                              </Tooltip>
                                            </span>
                                            <div className="custom">
                                              <select
                                                onChange={(e) =>
                                                  handleChange(e, i)
                                                }
                                                value={li.link.replace("/", "")}
                                                name="link"
                                                id="page_link"
                                                className=" form-control custom-icon-select form-control-sm"
                                              >
                                                <option value={"#"}>
                                                  Select page
                                                </option>
                                                {groupPage_list?.length > 0 &&
                                                  groupPage_list?.map(
                                                    (page, i) => {
                                                      return (
                                                        <option
                                                          key={i}
                                                          value={
                                                            page.page_slug +
                                                            ".html"
                                                          }
                                                        >
                                                          {page.page_name}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6 mt-1 pt-3">
                                          <Tooltip
                                            title={"Reset"}
                                            placement="top"
                                            arrow
                                          >
                                            <span
                                              className={
                                                li.target === ""
                                                  ? "wb-icons-box-active"
                                                  : "wb-icons-box"
                                              }
                                              onClick={(e) =>
                                                handleChangeTarget("", i)
                                              }
                                            >
                                              <Icon icon="ant-design:stop-outlined" />
                                            </span>
                                          </Tooltip>
                                          <Tooltip
                                            title={"Open in new tab"}
                                            placement="top"
                                            arrow
                                          >
                                            <span
                                              onClick={(e) =>
                                                handleChangeTarget("_blank", i)
                                              }
                                              className={
                                                li.target === "_blank"
                                                  ? "wb-icons-box-active"
                                                  : "wb-icons-box"
                                              }
                                            >
                                              <Icon icon="ic:baseline-open-in-new" />
                                            </span>
                                          </Tooltip>
                                        </div>
                                      </>
                                    )}
                                  {linkTypeList[i]?.type &&
                                    linkTypeList[i]?.type === "section" && (
                                      <>
                                        <div className="col-12">
                                          <div>
                                            <span>
                                              <Tooltip
                                                arrow
                                                placement="top"
                                                title="Link"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  className="bi bi-link"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                                                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                                                </svg>
                                              </Tooltip>
                                            </span>
                                            <div className="custom">
                                              <select
                                                onChange={(e) =>
                                                  handleChange(e, i)
                                                }
                                                value={li.link}
                                                name="link"
                                                id="section_link"
                                                className=" form-control custom-icon-select form-control-sm"
                                              >
                                                <option value={"#"}>
                                                  Select a section
                                                </option>
                                                {SectionsDataset &&
                                                  SectionsDataset.map(
                                                    (dataset, i) => {
                                                      return (
                                                        <option
                                                          key={i}
                                                          value={
                                                            dataset.section_id
                                                          }
                                                        >
                                                          {
                                                            dataset.section_title
                                                          }
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                  {li.classes && (
                                    <div className="col-12">
                                      Navlink show Active:
                                      <FormControlLabel
                                        style={{ marginLeft: 10 }}
                                        control={
                                          <Switch
                                            color="primary"
                                            checked={li.classes?.contains(
                                              "active"
                                            )}
                                            onChange={(e) =>
                                              handleChangeActive(
                                                e.target.checked,
                                                i
                                              )
                                            }
                                            name="active_status"
                                          />
                                        }
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          </div>
                        )}
                      </Draggable>
                    </>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="row">
          <div className="col-12 mt-2 text-center">
            <Button
              size="small"
              variant="contained"
              type="button"
              className="btn-sm me-3"
              onClick={() => {
                list?.data?.length > 0
                  ? () => {
                      handleSave();
                      ShowDropdownItems(false, listItemindex);
                    }
                  : handleRemoveDropdown(listItemindex);
              }}
            >
              Update
            </Button>

            <Button
              size="small"
              variant="outlined"
              type="button"
              className="btn-sm"
              onClick={handleAddNew}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
      {/* </AccordionDetails>
    </Accordion> */}
    </>
  );
}

export default DropdownMenuList;
