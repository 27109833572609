import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { IconButton, Switch, Tooltip } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function DropdownOptionModal({
  setSelectOptionList,
  selectOptionList,
  targetIndex,
  open,
  setOpen,
  handleUpdateOptionList,
  handleDragEndOptionList,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const handlechangeSelectedption = (e, select_list_index, option_inex) => {
    let tempArray = selectOptionList;

    if (e.target.checked) {
      tempArray[select_list_index].selectOptions.map((option, i) => {
        if (option_inex === i) {
          option.defaultSelected = "true";
        } else {
          option.defaultSelected = null;
        }
      });
    } else {
      tempArray[select_list_index].selectOptions[option_inex].defaultSelected =
        null;
    }

    setSelectOptionList([...tempArray]);
  };
  const changeLabelOfOption = (value, select_list_index, option_inex) => {
    let tempArray = selectOptionList;

    tempArray[select_list_index].selectOptions[option_inex].label = value;

    setSelectOptionList([...tempArray]);
  };
  const handleRemoveSelectOption = (select_list_index, option_inex) => {
    let tempArray = selectOptionList;

    tempArray[select_list_index].selectOptions.splice(option_inex, 1);

    setSelectOptionList([...tempArray]);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <div
          className="border-bottom"
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflow: "hidden",
          }}
        >
          <DialogTitle className="py-2 mb-0 border-bottom">
            Option List
          </DialogTitle>
          <div
            className="icon"
            style={{ margin: "16px 24px" }}
            onClick={handleClose}
          >
            <i class="fa fa-xmark"></i>
          </div>
        </div>

        <DialogContent
          sx={{
            maxHeight: "65vh",
          }}
        >
          <div className="col-12 p-2">
            <DragDropContext
              onDragEnd={(props) =>
                handleDragEndOptionList(
                  props,
                  selectOptionList[targetIndex].selectOptions
                )
              }
            >
              <Droppable droppableId="doprable1">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {selectOptionList[targetIndex].selectOptions?.length > 0 ? (
                      selectOptionList[targetIndex].selectOptions.map(
                        (option, option_index) => (
                          <Draggable
                            draggable={false}
                            key={option_index}
                            draggableId={`dragable-${option_index}`}
                            index={option_index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className={`${
                                  snapshot.isDragging ? "shadow" : null
                                } row border rounded formsetting-div-color-silver mb-1 align-items-center column-block-list`}
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  background: snapshot.isDragging
                                    ? "white"
                                    : null,
                                }}
                              >
                                <div
                                  key={option_index}
                                  className="col-12  form-label-checkbox-color "
                                >
                                  <div className=" col-12 d-flex align-items-center">
                                    <div className="col-1 d-flex align-items-center">
                                      <div>
                                        <label className="custom-label d-flex align-items-center">
                                          Option {option_index + 1}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-8">
                                      <input
                                        className="form-control form-control-sm"
                                        value={option.label}
                                        onChange={(e) => {
                                          changeLabelOfOption(
                                            e.target.value,
                                            targetIndex,
                                            option_index
                                          );
                                        }}
                                        autoFocus={
                                          option_index ===
                                            selectOptionList[targetIndex]
                                              .selectOptions.length -
                                              1 && option.label === ""
                                        }
                                      />
                                    </div>

                                    <div className="col-2">
                                      <div className="d-flex align-items-center justify-content-evenly">
                                        <label className="custom-label d-flex align-items-center me-1">
                                          Selected:{" "}
                                        </label>
                                        <Switch
                                          checked={
                                            option.defaultSelected === "true"
                                          }
                                          onChange={(e) =>
                                            handlechangeSelectedption(
                                              e,
                                              targetIndex,
                                              option_index
                                            )
                                          }
                                          size="small"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-1 d-flex justify-content-end">
                                      <Tooltip
                                        arrow
                                        placement="top"
                                        title="Remove Option"
                                      >
                                        <IconButton component="label">
                                          <Close
                                            className="pointer"
                                            htmlColor="red"
                                            onClick={() => {
                                              handleRemoveSelectOption(
                                                targetIndex,
                                                option_index
                                              );
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      )
                    ) : (
                      <div className="col-12">
                        <div className="d-flex justify-content-center">
                          <label className="custom-label">
                            No Options Available
                          </label>
                        </div>
                      </div>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </DialogContent>
        <DialogActions className="border-top d-flex justify-content-between px-4 py-2">
          <button
            style={
              selectOptionList[targetIndex].selectOptions.length > 0 &&
              selectOptionList[targetIndex].selectOptions[
                selectOptionList[targetIndex].selectOptions.length - 1
              ].label.trim() == ""
                ? {
                    pointerEvents: "none",
                    opacity: 0.3,
                  }
                : {
                    pointerEvents: "auto",
                  }
            }
            type="button"
            className="btn btn-sm btn-secondary"
            onClick={() => {
              let tempArray = selectOptionList;
              tempArray[targetIndex].selectOptions.push({
                label: "",
                defaultSelected: null,
              });
              setSelectOptionList([...tempArray]);
            }}
          >
            Add New Option
          </button>

          <div>
            <Button onClick={handleClose} variant="outlined" size="small">
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleUpdateOptionList(targetIndex);
                handleClose();
              }}
              className="ms-2"
            >
              Update List
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
