import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { TwitterPicker } from "react-color";
import Select from "react-select";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  FontWeightBar,
  ImageAlignBar,
  InputProperties,
} from "../../../Right-Menu/components";
import InlineInputField from "../../../Right-Menu/components/Inline-Input-Field/InlineInputField";
import {
  Button,
  ButtonGroup,
  FormControlLabel,
  FormGroup,
  Switch,
  Checkbox,
  Divider,
  Stack,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  font_sizes_classes,
  google_font_family_classes,
  colors,
  border_classes,
  padding_classes,
  form_inputs_transform_classes,
  font_weight_classes,
  form_spacing_classes,
  label_alignment_classes,
} from "../../../../../../ui_libs/Constants/constant";
import MuiSelectClasses from "../../../Right-Theme-Menu/components/MuiSelect/MuiSelectClasses";
import ButtonProperty from "../../../Right-Menu/components/Buton-Property/ButtonProperty";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import CustomSpacing from "../../../Custom-Spacing/CustomSpacing";
import FormMarginSpacing from "../FormMarginSpacing";
import InputTransformBar from "../InputTransformBar";
const useStyles = makeStyles({
  list: {
    width: 280,
  },
  fullList: {
    width: "auto",
  },
});

const container_layout_action_child = 1;
const background_image_action_child = 0;
const background_color_action_child = 0;
const text_color_action_child = 0;
const paddings_action_child = 0;
const margins_action_child = 0;

function FormStyling({ Form, setForm, getForm, GetInputList }) {
  const { ReRenderHtml, handleChangeRootValue, rootValues } = useBuilder();
  const classes = useStyles();
  const [FormWidth, setFormWidth] = useState(
    Form.classList.contains("col-6") ? "col-6" : "col-12"
  );
  const [Change, setChange] = useState(false);
  const [form_classes, setform_classes] = useState({
    // label
    label_alignment: "",
    label_font_family: "",
    label_font_size: "",
    label_font_weight: "",

    // input
    input_alignment: "",
    input_border_class: "",
    input_font_family: "",

    // helper_text
    helper_text_alignment: "",
    helper_taxt_font_size: "",
    helper_taxt_font_weight: "",
    helper_taxt_font_family: "",
  });

  const renderInputsPlaceholders = () => {
    let input_list = GetInputList();
    let list = [];
    for (let index = 0; index < input_list.length; index++) {
      if (input_list[index].type != "checkbox") {
        list.push(
          <div className="mt-2">
            <InlineInputField
              placeholder={input_list[index].placeholder}
              value={input_list[index].placeholder}
              handleUpdate={(val) => {
                input_list[index].placeholder = val;
                setChange(!Change);
              }}
            />
          </div>
        );
      }
    }
    return list;
  };
  const renderInputLabelText = () => {
    let input_list = GetInputList();
    let list = [];
    for (let index = 0; index < input_list.length; index++) {
      let label = input_list[index].parentNode.getElementsByTagName("label")[0];
      if (label) {
        if (label.style.visibility == "visible") {
          list.push(
            <div className="mt-2">
              <InlineInputField
                placeholder={label.innerHTML}
                value={label.innerHTML}
                handleUpdate={(val) => {
                  label.innerHTML = val;
                  setChange(!Change);
                }}
              />
            </div>
          );
        }
      }
    }
    return list;
  };

  const Parent_div_col = (node, val) => {
    let result = false;
    let parent = node.parentNode;
    let parent_classes = parent.classList;
    if (parent_classes) {
      result = parent_classes.contains(val);
    }

    return result;
  };

  const renderInputswidth = () => {
    let input_list = GetInputList();
    let list = [];
    for (let index = 0; index < input_list.length; index++) {
      if (
        input_list[index].type != "checkbox" &&
        input_list[index].type != "radio"
      ) {
        list.push(
          <div
            key={index}
            className="col-12 border mb-2 p-2 rounded form-label-checkbox-color formsetting-label-parent-div"
          >
            <div className=" col-12 d-flex align-items-center">
              <div className=" col-4 d-flex align-items-center">
                <div>
                  <label className="custom-label d-flex align-items-center">
                    {input_list[index]?.dataset?.name
                      ? input_list[index].dataset.name
                      : input_list[index].name}
                    &nbsp;&nbsp;
                  </label>
                </div>
              </div>
              <div className="col-8">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name={input_list[index].name}
                    id={`${input_list[index].name}_50%`}
                    checked={Parent_div_col(input_list[index], "col-md-6")}
                    onChange={(e) => {
                      handleChaneInputWidth(input_list[index], "col-md-6");
                    }}
                  />
                  <label
                    className="form-check-label pointer custom-label"
                    htmlFor={`${input_list[index].name}_50%`}
                  >
                    Half width
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name={input_list[index].name}
                    id={`${input_list[index].name}_100%`}
                    checked={Parent_div_col(input_list[index], "col-md-12")}
                    onChange={(e) => {
                      handleChaneInputWidth(input_list[index], "col-md-12");
                    }}
                  />
                  <label
                    className="form-check-label pointer custom-label"
                    htmlFor={`${input_list[index].name}_100%`}
                  >
                    Full width
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    let Radio_Input_List = renderRadioOptionList();
    if (Radio_Input_List.length > 0) {
      Radio_Input_List.map((Radio_Input, index) => {
        list.push(
          <div
            key={index}
            className="col-12 border mb-2 p-2 rounded form-label-checkbox-color formsetting-label-parent-div"
          >
            <div className=" col-12 d-flex align-items-center">
              <div className=" col-4 d-flex align-items-center">
                <div>
                  <label className="custom-label d-flex align-items-center">
                    {Radio_Input.list[0].dataset.name}
                    &nbsp;&nbsp;
                  </label>
                </div>
              </div>
              <div className="col-8">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name={Radio_Input.list[0].name}
                    id={`${Radio_Input.list[0].name}_50%`}
                    checked={Parent_div_col(
                      Radio_Input.list[0].parentNode.parentNode,
                      "col-md-6"
                    )}
                    onChange={(e) => {
                      handleChaneInputWidth(
                        Radio_Input.list[0].parentNode.parentNode,
                        "col-md-6"
                      );
                    }}
                  />
                  <label
                    className="form-check-label pointer custom-label"
                    htmlFor={`${Radio_Input.list[0].name}_50%`}
                  >
                    Half width
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name={Radio_Input.list[0].name}
                    id={`${Radio_Input.list[0].name}_100%`}
                    checked={Parent_div_col(
                      Radio_Input.list[0].parentNode.parentNode,
                      "col-md-12"
                    )}
                    onChange={(e) => {
                      handleChaneInputWidth(
                        Radio_Input.list[0].parentNode.parentNode,
                        "col-md-12"
                      );
                    }}
                  />
                  <label
                    className="form-check-label pointer custom-label"
                    htmlFor={`${Radio_Input.list[0].name}_100%`}
                  >
                    Full width
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    return list;
  };

  const renderRadioOptionList = () => {
    let Radio_Input_List = [...Form.querySelectorAll('input[type="radio"]')];
    let Radio_List_By_Name = [];
    Radio_Input_List.map((input) => {
      if (Radio_List_By_Name.length == 0) {
        Radio_List_By_Name.push({
          name: input.name,
          list: [input],
        });
      } else {
        let Input_Added = false;
        for (let index = 0; index < Radio_List_By_Name.length; index++) {
          if (input.name == Radio_List_By_Name[index].name) {
            Input_Added = true;
            Radio_List_By_Name[index].list.push(input);
            break;
          }
        }

        if (!Input_Added) {
          Radio_List_By_Name.push({
            name: input.name,
            list: [input],
          });
        }
      }
    });

    return Radio_List_By_Name;
  };

  const handleChaneInputWidth = (node, val) => {
    const parent = node.parentNode;
    const class_list = parent.classList;
    if (class_list) {
      class_list.remove("col-md-6");
      class_list.remove("col-md-12");
      class_list.add(val);
    }
    setChange(!Change);
  };
  const handleChangeFormWidth = (val) => {
    let form = getForm();

    form.classList.remove("col-6");
    form.classList.remove("col-12");

    form.classList.add(val);
    setChange(!Change);
  };
  const GetList = () => {
    let inputs = Form.getElementsByTagName("input");
    let textarea = Form.getElementsByTagName("textarea");
    let a = Form.getElementsByTagName("a");
    let buttons = Form.getElementsByTagName("button");
    let labels = Form.getElementsByTagName("label");
    return [...inputs, ...textarea, ...a, ...buttons, ...labels];
  };

  // set and reset property
  const handleChangeClassByProperty = (
    property,
    value,
    class_list,
    input_type
  ) => {
    const all = [...Form.getElementsByTagName(input_type)];
    class_list.map((cl) => {
      all.map((item) => {
        let classes = item.classList;
        if (classes.contains(cl.value)) {
          item.classList.remove(cl.value);
        }
        if (value && value.length > 0) {
          item.classList.add(value);
        }
      });
    });
  };
  const handleChangeClassByProperty_fontSize = (
    property,
    value,
    class_list,
    tag
  ) => {
    const all = [...Form.getElementsByTagName(tag)];
    class_list.map((cl) => {
      all.map((item) => {
        let classes = item.classList;
        if (classes.contains(cl.value)) {
          item.classList.remove(cl.value);
        }
        if (value && value.length > 0) {
          item.classList.add(value);
        }
      });
    });

    setform_classes({ ...form_classes, [property]: value });
  };

  // get class property
  const handleGetClassByProperty = (property, class_list, all) => {
    let class_name = "";
    class_list.forEach((cl) => {
      all.map((c) => {
        if (c.classList.contains(cl.value)) {
          class_name = cl.value;
        }
      });
    });

    return class_name;
  };
  const handleGetProperty = (property, list = []) => {
    let all = GetList();

    if (list.length > 0 > 0) {
      all = list;
    }

    let color = "";
    for (let index = 0; index < all.length; index++) {
      let computedStyles = window.getComputedStyle(all[index]);
      let value = computedStyles.getPropertyValue(property);
      if (value) {
        color = value;
        break;
      }
    }

    return color;
  };
  const handleReturnRootValueProperty = (property, list = []) => {
    return rootValues.property;
  };

  const handleChangeProperty = (property, value, name) => {
    let all = [];
    if (name == "input") {
      all = [
        ...Form.getElementsByTagName(name),
        ...Form.getElementsByTagName("textarea"),
        ...Form.getElementsByTagName("select"),
      ];
    } else {
      all = [...Form.getElementsByTagName(name)];
    }

    all.map((item) => {
      if (value) {
        item.style[property] = value;
      } else {
        item.style.removeProperty(property);
      }
    });
  };
  // for margin and padding

  const handleGetFormSpacingProperty = ({ property }) => {
    const list = [...Form.children[1].children];
    let value = "";
    list.map((item) => {
      let val = item.style[property];
      if (val) {
        value = val;
      }
    });
    return value;
  };
  const UpdateFormSpacingProperty = (property, value) => {
    const list = [...Form.children[1].children];
    if (value > 0) {
      list.map((item) => {
        item.style[property] = value + "rem";
      });
    } else {
      list.map((item) => {
        item.style.removeProperty(property);
      });
    }
  };
  const UpdateFormSpacingClass = (class_list, value) => {
    const list = [...Form.children[1].children];
    list.map((item) => {
      class_list.map((cl) => {
        item.classList.remove(cl.value);
      });
      if (value) {
        item.classList.add(value);
      }
    });
  };
  const handleGetFormSpacingClass = ({ class_list }) => {
    const list = [...Form.children[1].children];
    let value = "";
    list.map((item) => {
      class_list.map((cl) => {
        if (item.classList.contains(cl.value)) {
          value = cl.value;
        }
      });
    });

    return value;
  };

  // handlers
  const handleUpdateFormSpacing = (name, property, value) => {
    if (property == "top") {
      UpdateFormSpacingProperty(`${name}-bottom`, value);
    }
    if (property == "left") {
      UpdateFormSpacingProperty(`${name}-right`, value);
    }
    UpdateFormSpacingProperty(`${name}-${property}`, value);
  };
  const handleUpdateFormSpacingClass = ({ class_list, value }) => {
    if (class_list[0].value.split("-")[1] == "mt") {
      UpdateFormSpacingClass(
        form_spacing_classes("bottom"),
        value.replace("mt", "mb")
      );
    } else {
      UpdateFormSpacingClass(
        form_spacing_classes("right"),
        value.replace("pl", "pr")
      );
    }
    UpdateFormSpacingClass(class_list, value);
  };

  // for border width
  const handleGetSpacingProperty = ({ property }) => {
    const list = [...Form.getElementsByTagName("input")];
    let value = "";
    list.map((item) => {
      let val = item.style[property];
      if (val) {
        value = val;
      }
    });
    return value;
  };
  const handleUpdateSpacingProperty = (property, value) => {
    const list = [
      ...Form.getElementsByTagName("input"),
      ...Form.getElementsByTagName("textarea"),
      ...Form.getElementsByTagName("select"),
    ];
    if (value > 0) {
      list.map((item) => {
        item.style[property] = value + "rem";
      });
    } else {
      list.map((item) => {
        item.style.removeProperty(property);
      });
    }
  };
  const handleUpdateSpacingClass = ({ class_list, value }) => {
    const list = [
      ...Form.getElementsByTagName("input"),
      ...Form.getElementsByTagName("textarea"),
      ...Form.getElementsByTagName("select"),
    ];

    list.map((item) => {
      class_list.map((cl) => {
        if (item.classList.contains("wp-border-" + cl.value)) {
          item.classList.remove("wp-border-" + cl.value);
        }
      });
      if (value) {
        item.classList.add("wp-border-" + value);
      }
    });
  };
  const handleGetSpacingClass = ({ class_list }) => {
    const list = [
      ...Form.getElementsByTagName("input"),
      ...Form.getElementsByTagName("textarea"),
      ...Form.getElementsByTagName("select"),
    ];
    let value = "";
    list.map((item) => {
      class_list.map((cl) => {
        if (item.classList.contains("wp-border-" + cl.value)) {
          value = cl.value;
        }
      });
    });

    return value;
  };
  const handleChangeInputRadiusClass = (label, value, class_list) => {
    const list = [...GetInputList(), ...Form.getElementsByTagName("button")];
    list.map((item) => {
      class_list.map((cl) => {
        item.classList.remove(cl.value);
      });

      if (value) {
        item.classList.add(value);
      }
    });
    setChange(!Change);
  };
  const handleChangeLabelFontWeightClass = (label, value, class_list, tag) => {
    const list = [...Form.getElementsByTagName(tag)];
    list.map((item) => {
      class_list.map((cl) => {
        item.classList.remove(cl.value);
      });

      if (value) {
        item.classList.add(value);
      }
    });
    setChange(!Change);
  };
  const getFormInputStyle = ({ property }) => {
    const list = [...GetInputList()];
    let value = "";
    list.map((item) => {
      let val = item.style[property];
      if (val) {
        value = val;
      }
    });
    return value;
  };
  const updateFormInputBorderRadiusStyleByProperty = ({ property, value }) => {
    const list = [...GetInputList(), ...Form.getElementsByTagName("button")];
    list.map((item) => {
      item.style.removeProperty(property);
    });
  };
  const getFormValues = () => {
    // general form classes
    // label classes
    let label_alignment_ = handleGetClassByProperty(
      "label_alignment",
      label_alignment_classes,
      [...Form.getElementsByTagName("label")]
    );
    let helper_text_alignment_ = handleGetClassByProperty(
      "helper_text_alignment",
      label_alignment_classes,
      [...Form.getElementsByTagName("p")]
    );
    let label_google_font_family_ = handleGetClassByProperty(
      "google_font_family",
      google_font_family_classes,
      [...Form.getElementsByTagName("label")]
    );
    let helper_taxt_font_family_ = handleGetClassByProperty(
      "google_font_family",
      google_font_family_classes,
      [...Form.getElementsByTagName("p")]
    );

    let label_font_size_ = handleGetClassByProperty(
      "font_size",
      font_sizes_classes,
      [...Form.getElementsByTagName("label")]
    );
    let helper_taxt_font_size_ = handleGetClassByProperty(
      "font_size",
      font_sizes_classes,
      [...Form.getElementsByTagName("p")]
    );
    let label_font_weight_ = handleGetClassByProperty(
      "font_weight_classes",
      font_weight_classes,
      [...Form.getElementsByTagName("label")]
    );

    let helper_taxt_font_weight_ = handleGetClassByProperty(
      "font_weight_classes",
      font_weight_classes,
      [...Form.getElementsByTagName("p")]
    );
    // input classes
    let input = [...Form.querySelectorAll("input[type=radio]")];

    let input_alignment_ = "";
    if (input.length > 0) {
      input[input.length - 1] = input[input.length - 1].parentNode.parentNode;
      input_alignment_ = handleGetClassByProperty(
        "input_alignment",
        label_alignment_classes,
        [...input]
      );
    }

    // input alignmentclass for radio

    let input_border_class_ = handleGetClassByProperty(
      "input_border_class",
      form_inputs_transform_classes,
      [
        ...Form.getElementsByTagName("input"),
        ...Form.getElementsByTagName("textarea"),
        ...Form.getElementsByTagName("select"),
      ]
    );
    let input_google_font_family_ = handleGetClassByProperty(
      "google_font_family",
      google_font_family_classes,
      [...Form.getElementsByTagName("input")]
    );

    setform_classes({
      ...classes,
      label_alignment: label_alignment_,
      helper_text_alignment: helper_text_alignment_,
      label_font_family: label_google_font_family_,
      helper_taxt_font_family: helper_taxt_font_family_,
      label_font_size: label_font_size_,
      helper_taxt_font_size: helper_taxt_font_size_,
      label_font_weight: label_font_weight_,
      helper_taxt_font_weight: helper_taxt_font_weight_,
      input_border_class: input_border_class_,
      input_alignment: input_alignment_,
      input_font_family: input_google_font_family_,
    });
    setFormWidth(getForm().classList.contains("col-6") ? "col-6" : "col-12");
  };
  const handleChangeInputFontFamily = (property, value, class_list) => {
    handleChangeClassByProperty(property, value, class_list, "input");
    handleChangeClassByProperty(property, value, class_list, "textarea");
    handleChangeClassByProperty(property, value, class_list, "select");
  };
  useEffect(() => {
    getFormValues();
  }, [Change]);

  const handleChangeLabelAlignment = (property, value, class_list, tag) => {
    let label_list = [...Form.getElementsByTagName(tag)];

    if (label_list.length > 0) {
      label_list.map((label) => {
        if (tag == "label" && label.parentNode.children.length == 1) {
          label = label.parentNode;
        }
        class_list.map((cl) => {
          label.classList.remove(cl.value);
        });
        if (value) {
          label.classList.add(value);
        }
      });
    }
    setChange(!Change);
  };
  const handleChangeInputAlignment = (property, value, class_list, tag) => {
    let input_list = [...Form.getElementsByTagName(tag)];
    if (input_list.length > 0) {
      input_list.map((input_element) => {
        if (input_element.type == "radio") {
          let input_div = input_element.parentNode.parentNode;
          class_list.map((cl) => {
            input_div.classList.remove(cl.value);
          });
          if (value) {
            input_div.classList.add(value);
          }
        }
      });
    }
    setChange(!Change);
  };
  return (
    <div className="col-12">
      <div className="col-12 px-3">
        <FormMarginSpacing
          updateSelectedElementSpacing={handleUpdateFormSpacing}
          updateSelectedElementClass={handleUpdateFormSpacingClass}
          getSelectedElementClassByType={handleGetFormSpacingClass}
          getSelectedElementStyleByProperty={handleGetFormSpacingProperty}
        />
      </div>

      {/* form width */}
      <div className="col-12 px-3 pb-2">
        <div className="col-12 mb-2 custom-label">Form Width</div>
        <div className="col-12 border mb-2 p-2 rounded form-label-checkbox-color formsetting-label-parent-div">
          <div className=" col-12 d-flex align-items-center">
            <div className=" col-4 d-flex align-items-center">
              <div>
                <label className="custom-label d-flex align-items-center">
                  Form Width &nbsp;&nbsp;
                </label>
              </div>
            </div>
            <div className="col-8">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="width"
                  id="50%"
                  checked={FormWidth == "col-6" && true}
                  onChange={() => {
                    handleChangeFormWidth("col-6");
                  }}
                />
                <label
                  className="form-check-label pointer custom-label"
                  htmlFor="50%"
                >
                  Half Width
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="width"
                  id="100%"
                  checked={FormWidth == "col-12" && true}
                  onChange={() => {
                    handleChangeFormWidth("col-12");
                  }}
                />
                <label
                  className="form-check-label pointer custom-label"
                  htmlFor="100%"
                >
                  Full width
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* form input label styling */}
      {Form.getAttribute("form_label") == "true" && (
        <Accordion className="card mx-3 mb-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Input Label Style</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <>
              {/* font family */}
              <div className="col-12 mb-3">
                <MuiSelectClasses
                  label="family"
                  handleChange={(property, value, class_list) => {
                    handleChangeClassByProperty(
                      property,
                      value,
                      class_list,
                      "label"
                    );
                  }}
                  data={google_font_family_classes}
                  value={form_classes.label_font_family}
                />
              </div>
              {/* font size */}

              <div className="col-12 mb-3">
                <label
                  for="edit-able-text-input"
                  className="form-label custom-label"
                >
                  Label Font Size
                </label>

                <select
                  className="w-100 form-control custom-icon-select"
                  value={form_classes.label_font_size}
                  onChange={(e) =>
                    handleChangeClassByProperty_fontSize(
                      "label_font_size",
                      e.target.value,
                      font_sizes_classes,
                      "label"
                    )
                  }
                >
                  <option value="">Reset</option>
                  {font_sizes_classes.map((fs, i) => (
                    <option key={i} value={fs.value}>
                      {fs.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-12 mt-2">
                <div>
                  <label className="form-label custom-label">
                    Label Alignment
                  </label>
                </div>
                <ImageAlignBar
                  imageAlign={form_classes.label_alignment}
                  handleChangeInClass={(property, value, class_list) => {
                    handleChangeLabelAlignment(
                      property,
                      value,
                      class_list,
                      "label"
                    );
                  }}
                  classesList={label_alignment_classes}
                />
              </div>
              {/* font-weight */}
              <div className="col-12 mb-3">
                <label for="edit-able-text-input" className="custom-label">
                  Label Font weight
                </label>
                <FontWeightBar
                  fontWeight={form_classes.label_font_weight}
                  handleChangeInClass={(label, value, class_list) => {
                    handleChangeLabelFontWeightClass(
                      label,
                      value,
                      class_list,
                      "label"
                    );
                  }}
                  classesList={font_weight_classes}
                />
              </div>

              {/* label color */}
              <div className="col-12">
                <ButtonProperty
                  GetProperty={(property) =>
                    handleGetProperty(property, [
                      ...Form.getElementsByTagName("label"),
                    ])
                  }
                  label={"Label Color"}
                  ResetProperty={(property, value) => {
                    handleChangeProperty(property, value, "label");
                  }}
                  property={"color"}
                  handleChangeProperty={(property, value) => {
                    handleChangeProperty(property, value, "label");
                  }}
                  colors={colors}
                />
              </div>
            </>
          </AccordionDetails>
        </Accordion>
      )}
      {/* form helper text styling */}

      {Form.getAttribute("helper_text_require") == "true" && (
        <Accordion className="card mx-3 mb-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Helper-text Style</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <>
              {/* font family */}
              <div className="col-12 mb-3">
                <MuiSelectClasses
                  label="family"
                  handleChange={(property, value, class_list) => {
                    handleChangeClassByProperty(
                      property,
                      value,
                      class_list,
                      "p"
                    );
                  }}
                  data={google_font_family_classes}
                  value={form_classes.helper_taxt_font_family}
                />
              </div>
              {/* font size */}

              <div className="col-12 mb-3">
                <label
                  for="edit-able-text-input"
                  className="form-label custom-label"
                >
                  Helper Text Font Size
                </label>

                <select
                  className="w-100 form-control custom-icon-select"
                  value={form_classes.helper_taxt_font_size}
                  onChange={(e) =>
                    handleChangeClassByProperty_fontSize(
                      "label_font_size",
                      e.target.value,
                      font_sizes_classes,
                      "p"
                    )
                  }
                >
                  <option value="">Reset</option>
                  {font_sizes_classes.map((fs, i) => (
                    <option key={i} value={fs.value}>
                      {fs.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-12 mt-2">
                <div>
                  <label className="form-label custom-label">
                    Helper-text Alignment
                  </label>
                </div>
                <ImageAlignBar
                  imageAlign={form_classes.helper_text_alignment}
                  handleChangeInClass={(property, value, class_list) => {
                    handleChangeLabelAlignment(
                      property,
                      value,
                      class_list,
                      "p"
                    );
                  }}
                  classesList={label_alignment_classes}
                />
              </div>
              {/* font-weight */}
              <div className="col-12 mb-3">
                <label for="edit-able-text-input" className="custom-label">
                  Helper Text Font weight
                </label>
                <FontWeightBar
                  fontWeight={form_classes.helper_taxt_font_weight}
                  handleChangeInClass={(label, value, class_list) => {
                    handleChangeLabelFontWeightClass(
                      label,
                      value,
                      class_list,
                      "p"
                    );
                  }}
                  classesList={font_weight_classes}
                />
              </div>

              {/* label color */}
              <div className="col-12">
                <ButtonProperty
                  GetProperty={(property) =>
                    handleGetProperty(property, [
                      ...Form.getElementsByTagName("p"),
                    ])
                  }
                  label={"Helper Text Color"}
                  ResetProperty={(property, value) => {
                    handleChangeProperty(property, value, "p");
                  }}
                  property={"color"}
                  handleChangeProperty={(property, value) => {
                    handleChangeProperty(property, value, "p");
                  }}
                  colors={colors}
                />
              </div>
            </>
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion className="card mx-3 mb-2">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Input Field Style</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* font family */}
          <div className="col-12 mb-3">
            <MuiSelectClasses
              label="family"
              handleChange={handleChangeInputFontFamily}
              data={google_font_family_classes}
              value={form_classes.input_font_family}
            />
          </div>
          <div className="col-12 mt-2">
            <div>
              <label className="form-label custom-label">Input Alignment</label>
            </div>
            <ImageAlignBar
              imageAlign={form_classes.input_alignment}
              handleChangeInClass={(property, value, class_list) => {
                handleChangeInputAlignment(
                  property,
                  value,
                  class_list,
                  "input"
                );
              }}
              classesList={label_alignment_classes}
            />
          </div>
          {/* border radius */}
          <div className="col-12">
            <span className="col-12 custom-label">Border Radius</span>
            <InputTransformBar
              ButtonTransform={form_classes.input_border_class}
              handleChangeInClass={handleChangeInputRadiusClass}
              classesList={form_inputs_transform_classes}
              getFormInputStyle={getFormInputStyle}
              updateFormInputBorderRadiusStyleByProperty={
                updateFormInputBorderRadiusStyleByProperty
              }
              GetInputList={GetInputList}
              Form={Form}
            />
          </div>
          {/* input color */}
          <div className="col-12  ">
            <ButtonProperty
              GetProperty={handleGetProperty}
              label={"Input Text Color"}
              ResetProperty={(property, value) =>
                handleChangeProperty(property, value, "input")
              }
              property={"color"}
              handleChangeProperty={(property, value) =>
                handleChangeProperty(property, value, "input")
              }
              colors={colors}
            />
          </div>

          {/* input Placeholder color */}

          <div className="col-12  ">
            <ButtonProperty
              GetProperty={handleReturnRootValueProperty}
              label={" Input Placeholder Color"}
              ResetProperty={(property, value) =>
                handleChangeRootValue(property, "#ffffff")
              }
              property={"input_placeholder_color"}
              handleChangeProperty={(property, value) =>
                handleChangeRootValue(property, value)
              }
              colors={colors}
            />
          </div>

          {/* input BackGround color */}
          <div className="col-12  ">
            <ButtonProperty
              GetProperty={handleGetProperty}
              label={"Input Background Color"}
              ResetProperty={(property, value) =>
                handleChangeProperty(property, value, "input")
              }
              property={"background-color"}
              handleChangeProperty={(property, value) =>
                handleChangeProperty(property, value, "input")
              }
              colors={colors}
            />
          </div>

          {/* input border color */}
          <div className="col-12  ">
            <ButtonProperty
              GetProperty={handleGetProperty}
              label={"Input Border Color"}
              ResetProperty={(property, value) =>
                handleChangeProperty(property, value, "input")
              }
              property={"border-color"}
              handleChangeProperty={(property, value) =>
                handleChangeProperty(property, value, "input")
              }
              colors={colors}
            />
          </div>

          {/* border width*/}
          <CustomSpacing
            ResetValue={""}
            myclass={border_classes}
            updateSelectedElementSpacing={(name, property, value) =>
              handleUpdateSpacingProperty("border-width", value)
            }
            updateSelectedElementClass={handleUpdateSpacingClass}
            getSelectedElementClassByType={handleGetSpacingClass}
            getSelectedElementStyleByProperty={handleGetSpacingProperty}
            spacing_type="border"
            dimention="border-width"
            label="border-width"
          />

          {/* input width */}
          <div className="col-12 mb-2 custom-label">Input Field Width</div>
          {renderInputswidth()}
        </AccordionDetails>
      </Accordion>

      {/*  */}
      {/* <Divider /> */}
    </div>
  );
}

export default FormStyling;
