import PropTypes from "prop-types";
// material
import { Paper, Typography } from "@mui/material";
import { Icon } from "@iconify/react";

// ----------------------------------------------------------------------

NotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function NotFound({
  searchQuery = "",
  title,
  icon = "",
  ...other
}) {
  return (
    <div>
      {icon && (
        <div
          style={{
            fontSize: "40px",
            background: "#02337310",
            margin: "10px",
            padding: "7px",
            borderRadius: "50%",
            height: "70px",
            width: "70px",
          }}
        >
          <Icon
            icon={icon}
            style={{
              verticalAlign: "text-top",
            }}
          />
        </div>
      )}
      <span style={{ fontSize: "1rem" }}>{title}</span>
    </div>
  );
}
