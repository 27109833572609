import { Stack, TextField } from "@mui/material";
import React from "react";

const StripeBuyButtonEmbedCode = ({
  stripeEmbedCode,
  setStripeEmbedCode,
  scriptExistInEmbedCode,
  setScriptExistInEmbedCode,
  embedCodeError,
  setEmbedCodeError,
}) => {
  const handleChange = (e) => {
    if (scriptExistInEmbedCode) {
      setScriptExistInEmbedCode(false);
      setEmbedCodeError("");
    }
    setStripeEmbedCode(e.target.value);
  };
  return (
    <Stack sx={{ paddingX: 1 }}>
      <TextField
        multiline
        required
        rows={6}
        size="small"
        label="Stripe Embeded Code"
        placeholder={`<stripe-buy-button
  buy-button-id="buy_btn_***********************"
  publishable-key="pk_**************************">
</stripe-buy-button>
              `}
        value={stripeEmbedCode}
        onChange={handleChange}
        error={scriptExistInEmbedCode}
        helperText={scriptExistInEmbedCode ? embedCodeError : ""}
      />
    </Stack>
  );
};

export default StripeBuyButtonEmbedCode;
