import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import { padding_classes } from "../../../../../../ui_libs/Constants/constant";
import CustomSpacing from "../../../Custom-Spacing/CustomSpacing";
import CustomSpacingWithSlider from "../../../Custom-Spacing/CustomSpacingWithSlider";

function Paddings({
  handleGetPaddingClass,
  handleupdatePaddingClass,
  handleGetPaddingCustom,
  handleUpdatePaddingCustom,
}) {
  //done custom
  const handleGetCustomSpacing = (property) => {
    const value = handleGetPaddingCustom(property.property);
    return value ? value / 16 + "rem" : "";
  };

  const handleUpdateCustomSpacing = (...property) => {
    handleUpdatePaddingCustom(property[0], property[2] * 16);
  };

  const handleGetClassSpacing = (myproperty) => {
    const value = handleGetPaddingClass(myproperty);
    return value ? value : 0;
  };

  const handleUpdateClassSpacing = (property) => {
    handleupdatePaddingClass(property);
  };

  return (
    <>
      {/* <Accordion className="card">
       <AccordionSummary
         expandIcon={<ExpandMoreIcon />}
         aria-controls="panel1a-content"
         id="panel1a-header"
       >
         <Typography>Padding</Typography>
       </AccordionSummary>
       <AccordionDetails> */}
      <div className="col-12 mb-1">
        <span className="custom-label">Padding:</span>
      </div>
      <div className="">
        <CustomSpacingWithSlider
          myclass={padding_classes}
          updateSelectedElementSpacing={handleUpdateCustomSpacing}
          updateSelectedElementClass={handleUpdateClassSpacing}
          getSelectedElementClassByType={handleGetClassSpacing}
          getSelectedElementStyleByProperty={handleGetCustomSpacing}
          spacing_type="padding-top"
          dimention="padding-top"
          label="top"
          paddingClass={"wp-pt-"}
        />

        <CustomSpacingWithSlider
          myclass={padding_classes}
          updateSelectedElementSpacing={handleUpdateCustomSpacing}
          updateSelectedElementClass={handleUpdateClassSpacing}
          getSelectedElementClassByType={handleGetClassSpacing}
          getSelectedElementStyleByProperty={handleGetCustomSpacing}
          spacing_type="padding-bottom"
          dimention="padding-bottom"
          label="bottom"
          paddingClass={"wp-pb-"}
        />

        <CustomSpacingWithSlider
          myclass={padding_classes}
          updateSelectedElementSpacing={handleUpdateCustomSpacing}
          updateSelectedElementClass={handleUpdateClassSpacing}
          getSelectedElementClassByType={handleGetClassSpacing}
          getSelectedElementStyleByProperty={handleGetCustomSpacing}
          spacing_type="padding-left"
          dimention="padding-left"
          label="left"
          paddingClass={"wp-pl-"}
        />

        <CustomSpacingWithSlider
          myclass={padding_classes}
          updateSelectedElementSpacing={handleUpdateCustomSpacing}
          updateSelectedElementClass={handleUpdateClassSpacing}
          getSelectedElementClassByType={handleGetClassSpacing}
          getSelectedElementStyleByProperty={handleGetCustomSpacing}
          spacing_type="padding-right"
          dimention="padding-right"
          label="right"
          paddingClass={"wp-pr-"}
        />
      </div>

      {/* </AccordionDetails>
     </Accordion> */}
    </>
  );
}

export default Paddings;
