import React from "react";
import { Icon } from "@iconify/react";
import Tooltip from "@mui/material/Tooltip";

function LetterSpacing({ letterspacing, handleChangeInClass, classesList }) {
  return (
    <>
      <div class="mb-2">
        <div
          className="font-size-xsm mt-2"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <Tooltip title={"Reset"} placement="top" arrow>
            <span
              className={letterspacing ? "wb-icons-box" : "wb-icons-box-active"}
              onClick={() =>
                handleChangeInClass("letterspacing", null, classesList)
              }
              style={{ display: "flex", alignItems: "center" }}
            >
              <Icon icon="ant-design:stop-outlined" />
            </span>
          </Tooltip>

          {classesList.map((cls) => (
            <Tooltip title={cls.label} placement="top" arrow>
              <span
                className={
                  letterspacing === cls.value
                    ? "wb-icons-box-active "
                    : "wb-icons-box "
                }
                onClick={() =>
                  handleChangeInClass("letterspacing", cls.value, classesList)
                }
              >
                {cls.title}
              </span>
            </Tooltip>
          ))}
        </div>
      </div>
    </>
  );
}

export default LetterSpacing;
