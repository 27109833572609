import {
  Button,
  Divider,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconSelect,
  MarginSpacing,
  PaddingSpacing,
} from "../Right-Menu/components";
import { fontAwesomeIcons } from "../../../../ui_libs/Constants/fontAwesomeIcons";
import {
  animate_animation_list,
  colors,
  font_sizes_classes,
} from "../../../../ui_libs/Constants/constant";
import ButtonProperty from "../Right-Menu/components/Buton-Property/ButtonProperty";
import AnimationSelect from "../Right-Theme-Menu/components/Animations/AnimationSelect";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";

const IconEditor = () => {
  const previewIconRef = useRef();
  const {
    iconAnchorEl,
    setIconAnchorEl,
    getSelectedElement,
    iconEditorPosition,
    handleRemoveElement,
    updateSelectedElementClass,
    setRightMenuOpen,
    rootValues,
    getIsDevState,
    selectedElement,
    getSelectedElementClassByType,
    setSelectedElementAttributes,
    selectedElementAttributes,
    getSelectedElementAttributes,
    updateParentLink,
    updateParentLinkTarget,
    updateParentLinkToFunnel,
    handleUpdateElement,
    getParentLink,
    funnelDetails,
    updateAttributeOfSelectedElement,
  } = useBuilder();
  const [textColor, setTextColor] = useState(rootValues.primary_text_color);
  const [LinkType, setLinkType] = useState("custom");
  const [selectValue, setSelectValue] = useState("");
  const [isParentAnchor, setIsParentAnchor] = useState(false);
  const [sectionDatasets, setSectionDatasets] = useState([]);
  const [discardChanges, setDiscardChanges] = useState(false);
  const [stepMessageShow, setStepMessageShow] = useState(false);
  const [groupPage_list, setGroupPage_list] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [classes, setClasses] = useState({
    fontSize: "",
    animation: "",
  });
  const [selectedTab, setSelectedTab] = useState(0);
  const getIconClass = () => {
    let element = getSelectedElement();
    let className = element?.getAttribute("class");
    return className;
  };

  const handleClose = () => {
    setIconAnchorEl(null);
    setSelectedTab(0);
  };
  const handleRemove = () => {
    handleRemoveElement();
    handleClose();
  };
  const getproperty = (property) => {
    let element = getSelectedElement();
    if (isParentAnchorTag() && property === "background") {
      element = element.parentElement;
    }
    let computedStyles = window.getComputedStyle(element);
    let color = computedStyles.getPropertyValue(property);
    return color;
  };
  const handleChangeInClass = (key, value, class_list) => {
    setClasses({ ...classes, [key]: value });
    const payload = {
      class_list,
      value,
    };
    updateSelectedElementClass(payload);
  };
  const handleChangeInAnimation = (key, value, identifier, class_list) => {
    setClasses({ ...classes, [key]: value });
    const payload = {
      class_list,
      value: value,
      identifier: identifier,
    };
    updateSelectedElementClass(payload);
    updateAttributeOfSelectedElement("data-animation", value);
  };

  const handleChangeTextColor = (value) => {
    let element = getSelectedElement();
    // element.style.setProperty("color", value, "important");
    if (value == rootValues.secondary_color) {
      element.style.setProperty(
        "color",
        `var(--wb-website-theme-secondary-color)`
      );
    } else {
      element.style.setProperty("color", value);
    }
    setTextColor(value);
  };
  const handleChangeBackgroundColor = (value) => {
    let element = getSelectedElement();

    if (value == rootValues.secondary_color) {
      element.style.setProperty(
        "background",
        `var(--wb-website-theme-secondary-color)`
      );
    } else {
      element.style.setProperty("background", value);
    }
    if (isParentAnchorTag()) {
      element = element.parentElement;
    }
    
    if (value == rootValues.secondary_color) {
      element.style.setProperty(
        "background",
        `var(--wb-website-theme-secondary-color)`
      );
    } else {
      element.style.setProperty("background", value);
    }
  };

  const ResetTextColor = () => {
    let element = getSelectedElement();
    element.style.removeProperty("color");

    setTextColor("#000000");
  };
  const ResetBackgroundColor = () => {
    let element = getSelectedElement();
    if (isParentAnchorTag()) {
      element = element.parentElement;
    }
    element.style.removeProperty("background");
  };
  const handleChangeIcon = (ref, className) => {
    ref.current.setAttribute("class", className);
    //
  };

  const isParentAnchorTag = () => {
    let parentAnchor = false;
    let element = getSelectedElement();
    if (element) {
      if (element.parentElement) {
        if (element.parentElement.nodeName === "A") {
          parentAnchor = true;
          // setIsParentAnchor(true);
        }
      }
    }

    return parentAnchor;
  };

  const handleChangeLinkType = (e) => {
    setSelectValue(e.target.value);
    setLinkType(e.target.value);
    if (e.target.value === "redirect-to-next-step") {
      rootValues.page_list.map((page) => {
        if (page._id === rootValues.page_id) {
          if (page.order === groupPage_list.length) {
            setStepMessageShow(true);
          } else {
            setSelectedElementAttributes({
              ...selectedElementAttributes,
              ["href"]: "",
              ["target"]: "",
              ["class"]: "move-to-next",
            });
            setStepMessageShow(false);
          }
        }
      });
    } else {
      setStepMessageShow(false);
      setSelectedElementAttributes({
        ...selectedElementAttributes,
        ["href"]: "",
        ["target"]: "",
        ["class"]: "",
      });
    }
  };

  const handleChangeInSelect = (e) => {
    setSelectValue(e.target.value);

    setSelectedElementAttributes({
      ...selectedElementAttributes,
      ["href"]: e.target.value,
      ["target"]: "",
    });
  };

  const handleHref = (action) => {
    setSelectValue(action);
    if (action == "add") {
      setSelectedElementAttributes({
        ...selectedElementAttributes,
        ["target"]: "_blank",
      });

      // update parent too if available
      // if (isParentAnchorTag()) {
      //   updateParentLinkTarget("_blank");
      // }
    }
    if (action == "remove") {
      delete selectedElementAttributes["target"];
      setSelectedElementAttributes({ ...selectedElementAttributes });
      // update parent too if available
      // if (isParentAnchorTag()) {
      //   updateParentLinkTarget("");
      // }
    }
  };

  const handleInputChange = (e) => {
    setSelectValue(e.target.value);

    setSelectedElementAttributes({
      ...selectedElementAttributes,
      ["href"]: e.target.value,
    });
  };

  const handleSave = () => {
    if (isParentAnchorTag()) {
      updateParentLink(selectedElementAttributes.href);
      updateParentLinkTarget(selectedElementAttributes.target);
      updateParentLinkToFunnel(selectedElementAttributes);
    } else {
      handleUpdateElement();
    }
    enqueueSnackbar("Link Updated Successfully", {
      variant: "success",
    });
    setSelectValue("");
  };
  const get_preview_div_sections = () => {
    let datasets = [];
    const preview_div_sections = document.getElementById("preview-div");
    let sections = preview_div_sections.getElementsByTagName("section");
    for (let index = 0; index < sections.length; index++) {
      let section = sections.item(index);

      datasets.push({
        ...section.dataset,
        section_id: "#" + section.dataset.section_id,
        section_title: section.dataset.section_title
          ? section.dataset.section_title !== "undefined"
            ? section.dataset.section_title
            : index + 1
          : index + 1,
      });
    }
    console.log(datasets, "mechlorahooo");
    setSectionDatasets(datasets);
  };

  const setAttributeStates = (data) => {
    let obj = {};
    let attributes = [];
    obj["type"] = data.type;
    data.attributes.forEach((attr) => {
      attributes.push(attr);
    });
    attributes.forEach((atr) => {
      obj[atr.name] = atr.value;
    });

    // get parent value if parent is anchor tag
    if (isParentAnchorTag()) {
      const link = getParentLink();
      obj.href = link.href;
      obj.target = link.target;
    }
    setLinkType("custom");
    if (obj.href) {
      // check pages link
      rootValues.page_list.map((page) => {
        if (page.page_slug == obj.href.split(".")[0]) {
          setLinkType("pages");
        }
      });

      // check section link
      if (findLinks(obj.href)) {
        setLinkType("section");
      }
      // custom link

      // abcdef
    }
    setSelectedElementAttributes(obj);
  };
  const findLinks = (link) => {
    let is_match = false;
    sectionDatasets.forEach((section) => {
      if (section.section_id === link) {
        is_match = true;
      }
    });
    return is_match;
  };

  useEffect(() => {
    isParentAnchorTag();
  }, [iconAnchorEl]);

  useEffect(() => {
    if (iconAnchorEl) {
      get_preview_div_sections();
      let attributesData = getSelectedElementAttributes();
      setAttributeStates(attributesData);
    }
    setSelectValue("");
    console.log("mechlorahooo");
  }, [discardChanges, iconAnchorEl]);

  const SelectedTab1 = () => {
    return (
      <Stack
        spacing={3}
        sx={{ minHeight: 350, overflowY: "scroll", paddingX: 2 }}
      >
        <div className="col-12">
          <label className="form-label custom-label m-0">Icon Preview:</label>
        </div>
        <div className="col-12 my-2">
          <i ref={previewIconRef} className={getIconClass()}></i>
        </div>

        <div className="col-12 mb-3 mt-0">
          <IconSelect
            label="Icon"
            previewRef={previewIconRef}
            iconClass={getIconClass()}
            data={fontAwesomeIcons}
            handleChange={handleChangeIcon}
          />
        </div>
      </Stack>
    );
  };
  const SelectedTab2 = () => {
    return (
      <Stack sx={{ height: 350, overflowY: "scroll", paddingX: 2 }}>
        <div className="col-12 mb-3">
          <div className="">
            <label
              for="edit-able-text-input"
              className="form-label custom-label"
            >
              Icon Size
            </label>

            <select
              className="w-100 form-control custom-icon-select"
              value={classes.fontSize}
              onChange={(e) =>
                handleChangeInClass(
                  "fontSize",
                  e.target.value,
                  font_sizes_classes
                )
              }
            >
              <option value="">Reset</option>
              {font_sizes_classes.map((fs, i) => (
                <option key={i} value={fs.value}>
                  {fs.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-12">
          <ButtonProperty
            GetProperty={getproperty}
            label={"Icon Color:"}
            ResetProperty={ResetTextColor}
            property={"color"}
            handleChangeProperty={(property, value) =>
              handleChangeTextColor(value)
            }
            colors={colors}
          />
        </div>
        <div className="col-12">
          <ButtonProperty
            GetProperty={getproperty}
            label={"Background Color:"}
            ResetProperty={ResetBackgroundColor}
            property={"background"}
            handleChangeProperty={(property, value) =>
              handleChangeBackgroundColor(value)
            }
            colors={colors}
          />
        </div>
      </Stack>
    );
  };
  const SelectedTab3 = () => {
    return (
      <Stack
        spacing={1}
        sx={{ height: 350, overflowY: "scroll", overflowX: "hidden" }}
      >
        {/* image size */}
        <Stack direction={"column"} spacing={0.5} sx={{ paddingX: 2 }}>
          <AnimationSelect
            label="animation"
            handleChange={handleChangeInAnimation}
            data={animate_animation_list}
            value={classes.animation}
            showFrequent={true}
          />
        </Stack>
      </Stack>
    );
  };
  const SelectedTab4 = () => {
    return (
      <Stack spacing={3} sx={{ minHeight: 350, padding: 2 }}>
        <div className="col-12 mb-2">
          <Typography>Link to</Typography>

          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12 mb-3">
                <div>
                  <div className="custom">
                    <select
                      value={LinkType}
                      onChange={(e) => handleChangeLinkType(e)}
                      name="linkSelect"
                      id="linkSelect"
                      className="form-control custom-icon-select form-control-sm pt-1"
                    >
                      <option value="custom">Custom Link</option>
                      <option value="pages">Pages</option>
                      <option value="section">Section</option>
                      {rootValues.page_group_id !== "" &&
                        funnelDetails?.page_group_type !==
                          "generic_website" && (
                          <option value="redirect-to-next-step">
                            Redirect To Next Step
                          </option>
                        )}
                    </select>
                  </div>
                  {stepMessageShow && (
                    <div className="mt-2">
                      <span style={{ fontSize: "12px" }}>
                        There are no additional steps after this page.*
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {LinkType === "section" && (
              <div className="row align-items-center">
                <div className="col-12 ">
                  <div>
                    <span>
                      <Tooltip arrow placement="top" title="Link">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-link"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                          <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                        </svg>
                      </Tooltip>
                    </span>
                    <div className="custom">
                      <select
                        value={selectedElementAttributes?.href?.replace(
                          "/",
                          ""
                        )}
                        onChange={(e) => handleChangeInSelect(e)}
                        name="linkSelect"
                        id="linkSelect"
                        className="form-control custom-icon-select form-control-sm"
                      >
                        {!sectionDatasets.length < 1 &&
                          sectionDatasets.map((data, i) => {
                            return (
                              <option key={i} value={data.section_id}>
                                {data.section_title}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {LinkType === "pages" && (
              <div className="row align-items-center">
                <div className="col-9 ">
                  <div>
                    <span>
                      <Tooltip arrow placement="top" title="Link">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-link"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                          <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                        </svg>
                      </Tooltip>
                    </span>
                    <div className="custom">
                      <select
                        value={selectedElementAttributes?.href?.replace(
                          "/",
                          ""
                        )}
                        onChange={(e) => handleChangeInSelect(e)}
                        name="linkSelect"
                        id="linkSelect"
                        className=" form-control custom-icon-select form-control-sm"
                      >
                        <option value={"#"}>Select any page </option>
                        {groupPage_list?.length > 0 &&
                          groupPage_list.map((page, i) => {
                            return (
                              <option key={i} value={page.page_slug + ".html"}>
                                {page.page_name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-3 mt-1 pt-3 d-flex justify-content-end">
                  <span>
                    <Tooltip title={"Reset"} placement="top" arrow>
                      <span
                        className={`wb-icons-box ${
                          !selectedElementAttributes.target &&
                          "wb-icons-box-active"
                        }`}
                        onClick={(e) => handleHref("remove")}
                      >
                        <Icon icon="ant-design:stop-outlined" />
                      </span>
                    </Tooltip>
                  </span>
                  <span>
                    <Tooltip title={"Open in new tab"} placement="top" arrow>
                      <span
                        onClick={(e) => handleHref("add")}
                        className={
                          selectedElementAttributes.target === "_blank"
                            ? "wb-icons-box-active"
                            : "wb-icons-box"
                        }
                      >
                        <Icon icon="ic:baseline-open-in-new" />
                      </span>
                    </Tooltip>
                  </span>
                </div>
              </div>
            )}

            {LinkType === "custom" && (
              <>
                <div className="row align-items-center">
                  <div className="col-9">
                    <div>
                      <span>
                        <Tooltip arrow placement="top" title="Link">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-link"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                            <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                          </svg>
                        </Tooltip>
                      </span>
                      <div className="custom">
                        <input
                          value={selectedElementAttributes.href}
                          onChange={(e) => handleInputChange(e)}
                          name="link"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-3 mt-1 pt-3 d-flex justify-content-end">
                    <span>
                      <Tooltip title={"Reset"} placement="top" arrow>
                        <span
                          className={`wb-icons-box ${
                            !selectedElementAttributes.target &&
                            "wb-icons-box-active"
                          }`}
                          onClick={(e) => handleHref("remove")}
                        >
                          <Icon icon="ant-design:stop-outlined" />
                        </span>
                      </Tooltip>
                    </span>
                    <span>
                      <Tooltip title={"Open in new tab"} placement="top" arrow>
                        <span
                          onClick={(e) => handleHref("add")}
                          className={
                            selectedElementAttributes.target === "_blank"
                              ? "wb-icons-box-active"
                              : "wb-icons-box"
                          }
                        >
                          <Icon icon="ic:baseline-open-in-new" />
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                </div>
              </>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "0.8rem",
              }}
            >
              {selectValue !== "" && (
                <Tooltip title="Discard Changes" placement="top" arrow>
                  <Button
                    className="me-2"
                    variant="outlined"
                    onClick={() => setDiscardChanges(!discardChanges)}
                    style={{ padding: "0.1rem 0.75rem", fontSize: "0.8rem" }}
                  >
                    Discard
                  </Button>
                </Tooltip>
              )}
              <Tooltip title="Update Link" placement="top" arrow>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  style={{ padding: "0.1rem 0.75rem", fontSize: "0.8rem" }}
                >
                  Update
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </Stack>
    );
  };
  const SelectedTab5 = () => {
    return (
      <Stack spacing={1} sx={{ minHeight: 350, padding: 2 }}>
        <div>
          <PaddingSpacing />
        </div>
        <div>
          <MarginSpacing />
        </div>
      </Stack>
    );
  };

  const handleGetClassByType = () => {
    const classes_data = {
      animation: getSelectedElementClassByType({
        class_list: animate_animation_list,
      }),
    };

    setClasses(classes_data);
  };

  useEffect(() => {
    if (selectedElement) {
      handleGetClassByType();
    }
  }, []);

  useEffect(() => {
    setStepMessageShow(false);
  }, [Boolean(iconAnchorEl)]);

  useEffect(() => {
    if (rootValues) {
      let values = rootValues;
      let list = [];
      if (
        funnelDetails?.page_group_type !== "generic_website" &&
        values.page_group_id !== ""
      ) {
        values?.page_list?.map((page) => {
          if (page.page_group_id?._id === values.page_group_id) {
            list.push(page);
          }
          if (page.page_group_id === values.page_group_id) {
            list.push(page);
          }
        });
      } else {
        list = values.page_list;
      }
      console.log(values, list, "njenjcnececneiniceicienicei");
      setGroupPage_list(list);
    }
  }, [rootValues]);

  return (
    <>
      <Popover
        // anchorEl={iconAnchorEl}
        id="account-menu"
        open={Boolean(iconAnchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "auto",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0,
            py: 1,
            ml: iconEditorPosition === "left" ? 4 : -4,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
            },
          },
        }}
        transformOrigin={{ horizontal: iconEditorPosition, vertical: "center" }}
        anchorOrigin={{
          horizontal: iconAnchorEl?.pageX,
          vertical: iconAnchorEl?.pageY,
        }}
      >
        <Box sx={{ width: 392 }}>
          <Stack spacing={0.5} direction={"column"}>
            <Stack
              direction={"row"}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Stack direction={"row"} sx={{ paddingX: 2 }}>
                <h5 className="mb-0">Icon settings</h5>
              </Stack>

              <div className="d-flex">
                <Tooltip title="Remove Icon" arrow placement="top">
                  <IconButton
                    onClick={handleRemove}
                    size="small"
                    className="m-0 p-0"
                  >
                    <DeleteOutlineIcon sx={{ color: "red" }} className="p-1" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Close" arrow placement="top">
                  <IconButton
                    onClick={handleClose}
                    size="small"
                    className="mx-2 m-0 p-0"
                  >
                    <CloseIcon className="p-1" />
                  </IconButton>
                </Tooltip>
              </div>
            </Stack>

            <div
              className="d-flex mx-2 mb-2"
              style={{ borderBottom: "1px Solid #022859" }}
            >
              <div
                className={
                  selectedTab === 0
                    ? "image-editor-button image-editor-button-active"
                    : "image-editor-button"
                }
                onClick={() => setSelectedTab(0)}
              >
                <span>Icon</span>
              </div>
              <div
                className={
                  selectedTab === 1
                    ? "image-editor-button image-editor-button-active"
                    : "image-editor-button"
                }
                onClick={() => setSelectedTab(1)}
              >
                <span>Style</span>
              </div>
              <div
                className={
                  selectedTab === 2
                    ? "image-editor-button image-editor-button-active"
                    : "image-editor-button"
                }
                onClick={() => setSelectedTab(2)}
              >
                <span>Animation</span>
              </div>
              {isParentAnchorTag() && (
                <div
                  className={
                    selectedTab === 3
                      ? "image-editor-button image-editor-button-active"
                      : "image-editor-button"
                  }
                  onClick={() => setSelectedTab(3)}
                >
                  <span>Link</span>
                </div>
              )}
              {getIsDevState() && (
                <div
                  className={
                    selectedTab === 4
                      ? "image-editor-button image-editor-button-active"
                      : "image-editor-button"
                  }
                  onClick={() => setSelectedTab(4)}
                >
                  <span>Dev Mode</span>
                </div>
              )}
            </div>
            {/* <Divider className="mt-0 mx-3 mb-2" /> */}

            {selectedTab == 0 && SelectedTab1()}
            {selectedTab == 1 && SelectedTab2()}
            {selectedTab == 2 && SelectedTab3()}
            {selectedTab == 3 && SelectedTab4()}
            {selectedTab == 4 && SelectedTab5()}
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

export default IconEditor;
