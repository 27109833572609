import { invokeApi } from "../../bl_libs/invokeApi";

export const _send_query_to_ai = (data) => {
    let requestObj = {
      path: "/api/app_api/get_gpt_content",
      method: "POST",
      headers: {
        "x-sh-auth": sessionStorage.getItem("token"),
      },
    };
  
    requestObj["postData"] = data;
    return invokeApi(requestObj);
  };
  