import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_book_a_call_event_listing = (id) => {
  let requestObj = {
    path: "/api/event/get_event/" + id,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
