import {
  Button,
  Divider,
  IconButton,
  Popover,
  Stack,
  Tooltip,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import CloseIcon from "@mui/icons-material/Close";

import {
  checkDisableEditing,
  colors,
  google_font_family_classes,
} from "../../../../ui_libs/Constants/constant";
import ButtonProperty from "../Right-Menu/components/Buton-Property/ButtonProperty";
import MuiSelectClasses from "../Right-Theme-Menu/components/MuiSelect/MuiSelectClasses";
import { MarginSpacing, PaddingSpacing } from "../Right-Menu/components";
import { useSnackbar } from "notistack";

const TextEditor = () => {
  const {
    textEditorAnchorEl,
    setTextEditorAnchorEl,
    getSelectedElement,
    iconEditorPosition,
    handleChangeInEditableInputs,
    handleRemoveElement,
    updateSelectedElementClass,
    handleUpdateElement,
    rootValues,
    getEditAbleInputs,
    SaveActionToStack,
    selectedElement,
    setRightMenuOpen,
    getSelectedElementClassByType,
    getIsDevState,
  } = useBuilder();
  const [selectedTab, setSelectedTab] = useState(1);

  const { enqueueSnackbar } = useSnackbar();
  const [classes, setClasses] = useState({
    fontFamily: "",
  });

  const handleSave = () => {
    handleUpdateElement();
    enqueueSnackbar("Text Updated", { variant: "success" });
  };

  const handleClose = () => {
    setTextEditorAnchorEl(null);
  };

  const handleRemove = () => {
    handleRemoveElement();
    handleClose();
  };

  const handleChangeInClass = (key, value, class_list) => {
    setClasses({ ...classes, [key]: value });
    const payload = {
      class_list,
      value,
    };
    updateSelectedElementClass(payload);
  };

  const getproperty = (property) => {
    let element = getSelectedElement();
    let computedStyles = window.getComputedStyle(element);
    let color = computedStyles.getPropertyValue(property);
    return color;
  };
  const ResetProperty = (property) => {
    let element = getSelectedElement();
    element.style.removeProperty(property);
    return getproperty(property);
  };

  const handleChangeProperty = (property, value) => {
    SaveActionToStack();
    let element = getSelectedElement();
    if (value == rootValues.secondary_color) {
      element.style.setProperty(
        property,
        `var(--wb-website-theme-secondary-color)`,
        "important"
      );

      return;
    }
    element.style.setProperty(property, value, "important");
  };

  const SelectedTab1 = () => {
    return (
      <Stack spacing={3} sx={{ minHeight: 200, padding: 2 }}>
        <div className="col-12">
          <div>
            <textarea
              disabled={checkDisableEditing(getSelectedElement())}
              className="w-100 form-control"
              id="edit-able-text-input"
              label="Text"
              multiline
              rows={5}
              value={getEditAbleInputs("text")}
              onChange={(e) =>
                handleChangeInEditableInputs("text", e.target.value)
              }
              autoFocus
            ></textarea>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip title="Update Text" placement="top" arrow>
                <Button
                  variant="contained"
                  type="button"
                  className="my-2"
                  onClick={handleSave}
                  style={{ padding: "0.1rem 0.75rem", fontSize: "0.8rem" }}
                >
                  Update
                </Button>
              </Tooltip>
            </div>
          </div>
          {/* font-fmily */}
          <div className="col-12">
            <div className="mb-3">
              <MuiSelectClasses
                label="family"
                handleChange={handleChangeInClass}
                data={google_font_family_classes}
                value={classes.fontFamily}
              />
            </div>
          </div>
          <ButtonProperty
            GetProperty={getproperty}
            label={"Text Color"}
            ResetProperty={ResetProperty}
            property={"color"}
            handleChangeProperty={handleChangeProperty}
            colors={colors}
            rootValues={rootValues}
          />
        </div>
      </Stack>
    );
  };
  const SelectedTab2 = () => {
    return (
      <Stack spacing={1} sx={{ minHeight: 400, padding: 2 }}>
        <div>
          <PaddingSpacing />
        </div>
        <div>
          <MarginSpacing />
        </div>
      </Stack>
    );
  };
  const handleGetClassByType = () => {
    let classes_data = {
      fontFamily: getSelectedElementClassByType({
        class_list: google_font_family_classes,
      }),
    };

    setClasses(classes_data);
  };

  useEffect(() => {
    if (selectedElement) {
      handleGetClassByType();
    }
  }, []);

  return (
    <>
      <Popover
        // anchorEl={iconAnchorEl}
        id="account-menu"
        open={Boolean(textEditorAnchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "auto",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0,
            ml: iconEditorPosition === "left" ? 4 : -4,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
            },
            "& .MuiList-root": {
              padding: "0",
            },
          },
        }}
        transformOrigin={{ horizontal: iconEditorPosition, vertical: "center" }}
        anchorOrigin={{
          horizontal: textEditorAnchorEl?.pageX,
          vertical: textEditorAnchorEl?.pageY,
        }}
      >
        <Box sx={{ width: 392, height: 450 }}>
          <Stack direction={"column"}>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                position: "sticky",
                top: "0",
                background: "#fff",
                zIndex: 100,
                padding: "8px 0",
              }}
            >
              <Stack direction={"row"} sx={{ paddingX: 2 }}>
                <h5 className="mb-0">Text Editor</h5>
              </Stack>
              <div className="d-flex">
                <Tooltip title="Remove Button" arrow placement="top">
                  <IconButton
                    onClick={handleRemove}
                    size="small"
                    className="m-0 p-0"
                  >
                    <DeleteOutlineIcon sx={{ color: "red" }} className="p-1" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Close" arrow placement="top">
                  <IconButton
                    onClick={handleClose}
                    size="small"
                    className="mx-2 m-0 p-0"
                  >
                    <CloseIcon className="p-1" />
                  </IconButton>
                </Tooltip>
              </div>
            </Stack>
            <div
              style={{
                position: "sticky",
                top: "40px",
                background: "#fff",
                zIndex: 100,

                borderBottom: "1px Solid #022859",
              }}
              className="mb-2"
            >
              <div className="d-flex mx-2">
                <div
                  className={
                    selectedTab === 1
                      ? "image-editor-button image-editor-button-active"
                      : "image-editor-button"
                  }
                  onClick={() => setSelectedTab(1)}
                >
                  <span>Basic Setting</span>
                </div>
                {getIsDevState() && (
                  <div
                    className={
                      selectedTab === 2
                        ? "image-editor-button image-editor-button-active"
                        : "image-editor-button"
                    }
                    onClick={() => setSelectedTab(2)}
                  >
                    <span>Dev Mode</span>
                  </div>
                )}
                <div
                  className="image-editor-button"
                  onClick={() => {
                    handleClose();
                    setRightMenuOpen(true);
                  }}
                >
                  <span>Advance Settings</span>
                </div>
              </div>
              {/* <Divider className="mt-0 mx-3 mb-2" /> */}
            </div>
            {selectedTab == 1 && SelectedTab1()}
            {selectedTab == 2 && SelectedTab2()}
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

export default TextEditor;
