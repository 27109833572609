/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/lab/Autocomplete";
import { Autocomplete } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  selected: {
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: "#0d85e734",
      // backgroundColor: "#ff0b4424",
      color: "black",
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: "#0d85e7",
      // backgroundColor: "#fa2356",
      color: "white",
    },
    fontSize: ".875rem",
    "& > span": {
      marginRight: 50,
      fontSize: "0.875rem",
    },
  },
});
export default function MuiSelect({ data, handleChange, currentFamily, label }) {
  const [prevFamily, setPrevFamily] = useState("");
  const [AutoCompleteValue, setAutoCompleteValue] = React.useState(data[0]);
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = useState(false);
  const curFamily = useRef();
  const classes = useStyles();
  const handleInputChange = (event, newValue) => {
    setInputValue(newValue);
  };
  const handleOnChange = (event, newValue) => {
    if (newValue === null) return;
    curFamily.current = newValue;
    handleChange("font_family", newValue?.css_rule);
    setAutoCompleteValue(newValue);
    setPrevFamily(newValue?.css_rule);
  };
  const handleMouseEnter = (value) => {
    // handleChange("font_family", value);
  };
  const handleMouseLeave = () => {
    // handleChange("font_family", prevFamily);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleBlur = (e) => {
    handleChange("font_family", curFamily.current?.css_rule);
    setOpen(false);
  };
  const handleClose = (e) => {
    setOpen(false);
    handleChange("font_family", curFamily.current?.css_rule);
  };
  const setSelectedFontFamily = () => {
    data.map((family, i) => {
      if (family?.css_rule == currentFamily) {
        setAutoCompleteValue(data[i]);
        curFamily.current = data[i];
      }
    });
  };
  const handleHighlightChange = (e, newValue) => {
    if (!newValue || newValue == "" || newValue == undefined) return;
    handleChange("font_family", newValue?.css_rule);
  };
  useEffect(() => {
    setSelectedFontFamily();
    setPrevFamily(currentFamily);
  }, []);
  return (
    <Autocomplete
      blurOnSelect
      open={open}
      onHighlightChange={(e, newValue) => handleHighlightChange(e, newValue)}
      onOpen={handleOpen}
      onBlur={handleBlur}
      onClose={handleClose}
      inputValue={inputValue}
      value={AutoCompleteValue}
      onChange={(event, newValue) => {
        handleOnChange(event, newValue);
      }}
      onInputChange={(event, newValue) => handleInputChange(event, newValue)}
      id="family-select"
      color="secondary"
      size="small"
      options={data}
      classes={{
        option: classes.selected,
      }}
      className="familySelect"
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, state) => {
        return (
          <li
            {...props}
            value={option.value}
            onMouseEnter={(e) => handleMouseEnter(e.target.getAttribute("value"))}
            onMouseLeave={() => handleMouseLeave()}
            className={`${option.value} ${props.className} auto-complete-option`}
          >
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          autoComplete="off"
          className="familySelect"
          size="small"
          color="secondary"
          classes={{ root: classes.selected }}
          {...params}
          label={label ? label : "untitled"}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
