/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/lab/Autocomplete";
import { Autocomplete, Tooltip } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  selected: {
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: "#0d85e734",
      // backgroundColor: "#ff0b4424",
      color: "black",
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: "#0d85e7",
      // backgroundColor: "#fa2356",
      color: "white",
    },
    fontSize: ".875rem",
    "& > span": {
      marginRight: 50,
      fontSize: "0.875rem",
    },
  },
});

const common_animations = [
  {
    label: "Frequently Used",
    values: [
      {
        identifier: "",
        label: "Default",
        value: "",
      },
      {
        identifier: "animate__animated",
        label: "Bounce",
        value: "animate__bounce",
      },
      {
        identifier: "animate__animated",
        label: "Pulse",
        value: "animate__pulse",
      },
      {
        identifier: "animate__animated",
        label: "Rubber Band",
        value: "animate__rubberBand",
      },
      {
        identifier: "animate__animated",
        label: "Swing",
        value: "animate__swing",
      },
      {
        identifier: "animate__animated",
        label: "Tada",
        value: "animate__tada",
      },
      {
        identifier: "animate__animated",
        label: "Wobble",
        value: "animate__wobble",
      },
      {
        identifier: "animate__animated",
        label: "Heart Beat",
        value: "animate__heartBeat",
      },
      {
        identifier: "animate__animated",
        label: "Jello",
        value: "animate__jello",
      },
      {
        identifier: "animate__animated",
        label: "Bounce In",
        value: "animate__bounceIn",
      },
      {
        identifier: "animate__animated",
        label: "Fade In",
        value: "animate__fadeIn",
      },
      {
        identifier: "animate__animated",
        label: "Zoom In",
        value: "animate__zoomIn",
      },
      {
        identifier: "animate__animated",
        label: "Slide In Down",
        value: "animate__slideInDown",
      },
    ],
  },
];
export default function AnimationSelect({
  data,
  handleChange,
  value,
  label,
  showFrequent = false,
}) {
  const [prevFamily, setPrevFamily] = useState("");
  const [isFamilyEmpty, setIsFamilyEmpty] = useState(true);
  const [AutocompleteValue, setAutocompleteValue] = React.useState(data[0]);
  const [inputValue, setInputValue] = React.useState("");
  const [animation, setAnimation] = React.useState();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const curFamily = useRef();
  const handleInputChange = (event, newValue) => {
    setInputValue(newValue);
  };
  const handleOnChange = (event, newValue) => {
    console.log("handleOnchage");
    if (newValue === null || undefined) return;
    curFamily.current = newValue;
    handleChange("animation", newValue.value, newValue.identifier, data);
    setAutocompleteValue(newValue);
    setPrevFamily(newValue.value);
    setAnimation(newValue);
  };
  const handleHighlightChange = (e, newValue) => {
    if (!newValue || newValue == "" || newValue == undefined) return;
    if (newValue.value !== prevFamily) {
      handleChange("animation", newValue.value, newValue.identifier, data);
    }
  };
  const isInvalid = () => {
    let invalid = false;
    if (curFamily.current === undefined || !curFamily.current) {
      invalid = true;
    }
    return invalid;
  };
  const handleClose = () => {
    if (!isInvalid()) {
      handleChange(
        "animation",
        curFamily.current.value,
        curFamily.current.identifier,
        data
      );
    } else {
      handleChange("animation", "", "", data);
    }
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleBlur = (e) => {
    if (!isInvalid()) {
      handleChange(
        "animation",
        curFamily.current.value,
        curFamily.current.identifier,
        data
      );
    } else {
      handleChange("animation", "", "", data);
    }

    setOpen(false);
  };
  const handleMouseEnter = (value) => {
    // handleChange("animation", value, data);
  };
  const handleMouseLeave = () => {
    // handleChange("animation", prevFamily, data);
  };
  const setSelectedFontFamily = () => {
    data.map((family, i) => {
      if (family.value == value) {
        setAutocompleteValue(data[i]);
        curFamily.current = data[i];
      }
    });
  };
  useEffect(() => {
    if (value != "") {
      setIsFamilyEmpty(false);
    }
  }, [animation]);
  useEffect(() => {
    if (!isFamilyEmpty) {
      setSelectedFontFamily();
      setPrevFamily(value);
    }
  }, [isFamilyEmpty]);

  useEffect(() => {
    setAnimation(value);
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between">
        <label className="form-label custom-label">Animation</label>
        <Tooltip title="Remove Animation" arrow placement="top">
          <span>
            <RotateLeftIcon
              className="pointer"
              onClick={() => {
                handleChange("animation", "", "", data);
                setAutocompleteValue(data[0]);
              }}
            />
          </span>
        </Tooltip>
      </div>

      {showFrequent &&
        common_animations.map((animation_obj) => (
          <>
            <div>
              <label className="form-label custom-label">
                {animation_obj.label}:
              </label>
            </div>
            <div className="d-flex flex-wrap mb-2">
              {animation_obj.values.map((animation) => (
                <div
                  className="px-2 border m-1"
                  style={{
                    borderRadius: "4px",
                    cursor: "pointer",
                    backgroundColor:
                      animation.value === AutocompleteValue.value
                        ? "#02285912"
                        : "#fff",
                    
                  }}
                  onClick={(e) => handleOnChange(e, animation)}
                >
                  <label
                    className={`form-label  m-2  custom-label animate__infinite animate__slower ${animation.value} ${animation.identifier}`}
                    style={{ cursor: "pointer" }}
                  >
                    {animation.label}
                  </label>
                </div>
              ))}
            </div>
          </>
        ))}
      <Autocomplete
        autoComplete="off"
        blurOnSelect
        open={open}
        onOpen={handleOpen}
        onBlur={handleBlur}
        onClose={handleClose}
        inputValue={inputValue}
        value={AutocompleteValue}
        onChange={(event, newValue) => {
          handleOnChange(event, newValue);
        }}
        onHighlightChange={(e, newValue) => handleHighlightChange(e, newValue)}
        onInputChange={(event, newValue) => handleInputChange(event, newValue)}
        id="animation"
        color="secondary"
        size="small"
        options={data}
        classes={{
          option: classes.selected,
        }}
        className="familySelect"
        autoHighlight
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, state) => {
          return (
            <li
              {...props}
              value={option.value}
              onMouseEnter={(e) =>
                handleMouseEnter(e.target.getAttribute("value"))
              }
              onMouseLeave={() => handleMouseLeave()}
              className={`${option.value} ${props.className} auto-complete-option`}
            >
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            autoComplete="off"
            //   onHighlightChange={(e) => console.log(e)}
            className="familySelect"
            size="small"
            color="secondary"
            classes={{ root: classes.selected }}
            {...params}
            label={label ? label : "untitled"}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </>
  );
}
