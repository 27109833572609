import React, { useState, useEffect } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Icon } from "@iconify/react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Close, Done } from "@mui/icons-material";
import DrawerMenuHeader from "../../../../components/Drawer-Menu-Header/DrawerMenuHeader";
import { MenuList } from "../Right-Section-Settings-Menu/components";
import { _dispatch_find_nav_list_and_child } from "../../../../hooks/builder-context/utils";
import {
  flex_classes,
  margin_classes,
  padding_classes,
} from "../../../../ui_libs/Constants/constant";
import ListStyle from "./component/ListStyle";

const ListSettingMenu = () => {
  const {
    selectedElement,
    ReRenderHtml,
    setOpenListBlockDrawer,
    SaveActionToStack,
    handleChangeDuplicateId,
    getParentNode,
    selectedNavBarElement,
    setSelectedNavBarElement,
    handleReplaceListElements,
    getSelectedElementClassByType,
    openListBlockDrawer,
    updateSelectedElementClass,
  } = useBuilder();
  const [editColumnIndex, setEditColumnIndex] = useState(-1);
  const [listElementChild, setListElementChild] = useState([]);
  const [editColumnName, setEditColumnName] = useState("");
  const [selectedTab, setSelectedTab] = useState(1);
  const [ClassChange, setClassChange] = useState(false);
  const [Reset, setReset] = useState(false);

  const [classes, setClasses] = useState({
    listdirection: "",
  });

  //   react dnd functions
  const handleDragEnd = ({ source, destination, ...rest }) => {
    if (source && destination && source.index != destination.index) {
      let newList = [...listElementChild];
      newList.splice(destination.index, 0, newList.splice(source.index, 1)[0]);
      setListElementChild([...newList]);
      let newdata = "";
      for (let i = 0; i < newList.length; i++) {
        newdata += newList[i].outerHTML;
      }
      selectedElement.innerHTML = newdata;
      ReRenderHtml();
    } else {
      console.log("ERROR", source, destination);
    }
  };

  const handleDuplicateChild = (index) => {
    SaveActionToStack();
    let cloneNode = listElementChild[index].cloneNode(true);
    handleChangeDuplicateId(cloneNode);
    listElementChild.splice(index, 0, cloneNode);
    setListElementChild([...listElementChild]);
    selectedElement.insertBefore(
      cloneNode,
      selectedElement.children[index].nextSibling
    );
    ReRenderHtml();
  };
  const handleMoveUp = (source) => {
    let destination = source - 1;
    if (source > 0) {
      let newList = [...listElementChild];
      newList.splice(destination, 0, newList.splice(source, 1)[0]);
      setListElementChild([...newList]);
      let newdata = "";
      for (let i = 0; i < newList.length; i++) {
        newdata += newList[i].outerHTML;
      }

      selectedElement.innerHTML = newdata;
      ReRenderHtml();
    }
  };
  const handleMoveDown = (source) => {
    let destination = source + 1;
    if (source < listElementChild.length) {
      let newList = [...listElementChild];
      newList.splice(destination, 0, newList.splice(source, 1)[0]);
      setListElementChild([...newList]);
      let newdata = "";
      for (let i = 0; i < newList.length; i++) {
        newdata += newList[i].outerHTML;
      }
      selectedElement.innerHTML = newdata;

      ReRenderHtml();
    }
  };

  //   other functions
  const handleRemoveChild = (index) => {
    SaveActionToStack();
    let element = listElementChild[index];
    listElementChild.splice(index, 1);
    setListElementChild([...listElementChild]);
    element.remove();
  };
  const handleEditBlockName = (value, index) => {
    setEditColumnName(value);
    setEditColumnIndex(index);
  };
  const handleDoneEditColumnName = (index) => {
    SaveActionToStack();
    listElementChild[index].setAttribute("block-name", editColumnName);
    setEditColumnIndex(null);
  };
  const handleRemoveList = () => {
    let element = getParentNode(selectedElement);
    element.remove();
    setOpenListBlockDrawer(false);
  };
  const getSelectedNavLists = () => {
    let list = [];
    let data = [];

    let show_menu = true;

    let ul_list = selectedNavBarElement.getElementsByTagName("UL");
    if (selectedNavBarElement.tagName != "NAV") {
      show_menu = false;
      return { show_menu };
    }

    let filteredList = [];
    for (let index = 0; index < ul_list.length; index++) {
      if (ul_list[index].parentNode.tagName !== "LI") {
        filteredList.push(ul_list[index]);
      }
    }
    ul_list = filteredList;

    return _dispatch_find_nav_list_and_child(ul_list);
  };
  const handleClose = () => {
    setOpenListBlockDrawer(false);
  };

  useEffect(() => {
    if (selectedElement) {
      const temp = [...selectedElement.getElementsByTagName("LI")];
      temp.map((li, index) => {
        let name = temp[index].getAttribute("block-name");
        if (name) {
        } else {
          temp[index].setAttribute("block-name", `Item-${index + 1}`);
        }
      });
      setListElementChild([...temp]);
    }
    return () => {
      setSelectedNavBarElement(null);
    };
  }, []);

  const handleResetSpacing = () => {
    updateSelectedElementClass({
      class_list: padding_classes("top"),
      value: "",
    });
    updateSelectedElementClass({
      class_list: padding_classes("bottom"),
      value: "",
    });
    updateSelectedElementClass({
      class_list: padding_classes("left"),
      value: "",
    });
    updateSelectedElementClass({
      class_list: padding_classes("right"),
      value: "",
    });

    // RESEST CUSTOM PADDING
    handleUpdateCustomSpace("padding", ["top"], "");
    handleUpdateCustomSpace("padding", ["bottom"], "");
    handleUpdateCustomSpace("padding", ["left"], "");
    handleUpdateCustomSpace("padding", ["right"], "");
    setReset(!Reset);
  };

  const handleUpdateCustomSpace = (property, dimention, value) => {
    listElementChild.forEach((column) => {
      if (value > 0) {
        column.style.setProperty(
          `${property + "-" + dimention}`,
          `${value}rem`,
          "important"
        );
      } else {
        column.style.removeProperty(`${property + "-" + dimention}`);
      }
    });
    setClassChange(!ClassChange);
  };

  const handleUpdateSpaceClass = (property) => {
    let class_list = property.class_list;

    listElementChild.forEach((column) => {
      if (property.value == "") {
        let answer = Check_Class_Available(column.classList, class_list);
        if (answer) {
          column.classList.remove(answer);
        }
      } else {
        let answer = Check_Class_Available(column.classList, class_list);
        if (answer != "") {
          column.classList.remove(answer);
        }
        column.classList.add(property.value);
      }
    });
    setClassChange(!ClassChange);
  };

  const handleGetSpaceClass = (property) => {
    let class_list = property.class_list;
    let answer = "";
    if (
      listElementChild &&
      listElementChild[0] &&
      listElementChild[0].classList
    ) {
      answer = Check_Class_Available(listElementChild[0].classList, class_list);
    }
    return answer ? answer : "";
  };

  const handleGetCustomSpace = (property) => {
    let space = "";
    if (listElementChild && listElementChild[0] && listElementChild[0].style) {
      space = listElementChild[0].style[property.property];
    }

    return space ? space : "";
  };

  const Check_Class_Available = (row_class_list, searching_list) => {
    let classname = "";
    searching_list.forEach((cl) => {
      row_class_list.forEach((c) => {
        if (c === cl.value || c === cl?.extra_class) {
          classname = c;
        }
      });
    });
    return classname;
  };

  const handleChangeInClass = (key, value, class_list) => {
    setClasses({ ...classes, [key]: value });
    const payload = {
      class_list,
      value,
    };
    updateSelectedElementClass(payload);
  };

  const handleGetClassByType = () => {
    let classes_data = {
      listdirection: getSelectedElementClassByType({
        class_list: flex_classes,
      }),
    };
    setClasses(classes_data);
  };

  useEffect(() => {
    if (openListBlockDrawer) {
      handleGetClassByType();
    }
  }, [openListBlockDrawer]);

  return (
    <>
      {/* header */}
      <DrawerMenuHeader
        // label={`${
        //   selectedElement.tagName == "UL" ? "Un-Ordered" : "Ordered"
        // } List Settings`}
        label="List Settings"
        setState={setOpenListBlockDrawer}
        handleRemove={handleRemoveList}
      />

      {/* body */}
      {selectedNavBarElement ? (
        <div className="col-12 px-3 mb-5">
          <MenuList
            handleReplaceListElements={handleReplaceListElements}
            getSelectedSectionLists={getSelectedNavLists}
            handleClose={handleClose}
            expandedTrue={true}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              position: "sticky",
              top: "40px",
              background: "#fff",
              zIndex: 100,
              borderBottom: "1px Solid #022859",
            }}
            className="mb-2 mx-3"
          >
            <div className="d-flex">
              <div
                className={
                  selectedTab === 1
                    ? "image-editor-button image-editor-button-active"
                    : "image-editor-button"
                }
                onClick={() => setSelectedTab(1)}
              >
                <span>List</span>
              </div>
              <div
                className={
                  selectedTab === 2
                    ? "image-editor-button image-editor-button-active"
                    : "image-editor-button"
                }
                onClick={() => setSelectedTab(2)}
              >
                <span>Layout</span>
              </div>
            </div>
          </div>

          {selectedTab === 1 && (
            <div className="col-12 px-3 mb-5">
              <DragDropContext onDragEnd={(props) => handleDragEnd(props)}>
                <Droppable droppableId="doprable1">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {listElementChild.map((child, index) => (
                        <Draggable
                          draggable={false}
                          key={index}
                          draggableId={`dragable-${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={`${
                                snapshot.isDragging ? "shadow" : null
                              } row border m-0 mt-2  p-1 align-items-center column-block-list`}
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                background: snapshot.isDragging
                                  ? "white"
                                  : null,
                              }}
                            >
                              <Stack
                                sx={{
                                  display: "flex",
                                  alignitems: "center",
                                  justifyContent: "space-between",
                                }}
                                direction="row"
                                spacing={1}
                              >
                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  sx={{ maxWidth: 200, paddingTop: 0.5 }}
                                >
                                  {child.getAttribute("block-name")}
                                </Typography>

                                {/* edit bolock name icon */}
                                <div className="d-flex row-reverse justify-content-end">
                                  <span>
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title="Move element up"
                                    >
                                      <ArrowUpwardIcon
                                        sx={{
                                          height: "18px",
                                          width: "18px",
                                        }}
                                        className="edit-able-section-actions-delete"
                                        onClick={() => handleMoveUp(index)}
                                      />
                                    </Tooltip>

                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title="Move element down"
                                    >
                                      <ArrowDownwardIcon
                                        sx={{
                                          height: "18px",
                                          width: "18px",
                                        }}
                                        className="edit-able-section-actions-delete"
                                        onClick={() => handleMoveDown(index)}
                                      />
                                    </Tooltip>

                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title="Duplicate element"
                                    >
                                      <Icon
                                        icon="heroicons-outline:document-duplicate"
                                        width="16"
                                        height="16"
                                        className="edit-able-section-actions-delete"
                                        onClick={() =>
                                          handleDuplicateChild(index)
                                        }
                                      />
                                    </Tooltip>

                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title="Remove element"
                                    >
                                      <HighlightOffIcon
                                        sx={{
                                          height: "18px",
                                          width: "18px",
                                        }}
                                        className="edit-able-section-actions-delete"
                                        onClick={() => {
                                          handleRemoveChild(index);
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                </div>
                              </Stack>
                            </div>
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
          {selectedTab === 2 && (
            <ListStyle
              listdirection={classes.listdirection}
              handleChangeInClass={handleChangeInClass}
              classes={flex_classes}
              handleResetSpacing={handleResetSpacing}
              updateElementSpacing={handleUpdateCustomSpace}
              padding_classes={padding_classes}
              Reset={Reset}
              handleUpdateSpaceClass={handleUpdateSpaceClass}
              handleGetSpaceClass={handleGetSpaceClass}
              handleGetCustomSpace={handleGetCustomSpace}
              margin_classes={margin_classes}
            />
          )}
        </>
      )}
    </>
  );
};

export default ListSettingMenu;
