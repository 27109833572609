import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { s3baseUrl, s3builderSource } from "../../../../config/config";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import SearchIcon from "@mui/icons-material/Search";
import MediaUploadDialog from "./MediaUploadDialog";
import {
  get_readable_storage_size,
  get_short_string,
} from "../../../../utils/utils";
import NotFound from "../../../../components/NotFound";

function GalleryDialog({
  galleryDialog,
  setGalleryDialog,
  uploadDialog,
  setUploadDialog,
  DeleteImageConfirmation,
  UpdateImageSrc = false,
  changeBackgroundImage = false,
  handleImageSelectFromGallery = () => {},
}) {
  const {
    userImagesList,
    setUserImagesList,
    handleupdateIMG,
    setRightMenuOpen,
    selectedElement,
    SaveActionToStack,
    setSearchImageList,
    searchImageList,
    searchTemplateImageList,
    templateImagesList,
    stockImagesList,
    searchStockImageList,
  } = useBuilder();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedImagePath, setSelectedImagePath] = useState("Gallery Images");
  const [imagesToShow, setImagesToShow] = useState([...userImagesList]);
  const [imagesToSearch, setImagesToSearch] = useState([...searchImageList]);
  const [searchInput, setSearchInput] = useState("");

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImages((p) => p.concat(imagesArray));
  };

  const handleClose = () => {
    setGalleryDialog(false);
    handleChangeSearch();
  };
  const handleDialogOpen = () => {
    setUploadDialog(true);
  };
  const handleClick = async (e, name) => {
    if (UpdateImageSrc) {
      console.log(e, "image from gallery");
      await SaveActionToStack();
      selectedElement.src = e.target.src;
      // selectedElement.alt = name;
      selectedElement.setAttribute("data-image_name", name);
      setRightMenuOpen(false);
      handleClose();
    }
    if (changeBackgroundImage) {
      await SaveActionToStack();
      handleImageSelectFromGallery(e.target.src, name);
      handleClose();
    }
  };

  const handleChangeSearch = (e) => {
    if (e) {
      setSearchInput(e.target.value);
      let value = e.target.value;
      let list = imagesToShow;
      if (value.trim().length > 0) {
        list = [];
        imagesToShow.map((image) => {
          if (image.file_name.toLowerCase().includes(value.toLowerCase())) {
            list.push(image);
          }
        });
      }
      setImagesToSearch([...list]);
    } else {
      let list = imagesToShow;
      setImagesToSearch([...list]);
    }
  };

  useEffect(() => {
    if (selectedImagePath === "Gallery Images") {
      setImagesToShow([...userImagesList]);
      setImagesToSearch([...searchImageList]);
    } else if (selectedImagePath === "Stock Images") {
      setImagesToShow([...stockImagesList]);
      setImagesToSearch([...searchStockImageList]);
    } else if (selectedImagePath === "Theme Images") {
      setImagesToShow([...templateImagesList]);
      setImagesToSearch([...searchTemplateImageList]);
    }
    setSearchInput("");
  }, [selectedImagePath, userImagesList, searchImageList]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={galleryDialog}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderTop: "5px solid #023373",
            },
          },
        }}
      >
        <div
          className="border-bottom"
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflow: "hidden",
          }}
        >
          <DialogTitle>
            <Stack>Media Files</Stack>
          </DialogTitle>
          {UpdateImageSrc && (
            <DialogTitle>
              <Stack>Click image to select</Stack>
            </DialogTitle>
          )}
          <div
            className="icon"
            style={{ margin: "16px 24px" }}
            onClick={handleClose}
          >
            <i class="fa fa-xmark"></i>
          </div>
        </div>
        <div
          style={{
            height: "85vh",
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
          }}
        >
          <div
            className=""
            style={{
              backgroundColor: "#f0f4f7",
              width: "20%",
              overflow: "hidden",
            }}
          >
            {/* <div style={{ padding: "30px" }}>
              <Button
                fullWidth
                variant="contained"
                component="label"
                sx={{
                  backgroundColor: "#3899ec",
                  borderRadius: "30px",
                  padding: "3px 23px",
                  "&:hover": {
                    backgroundColor: "#4eb7f5",
                  },
                }}
                onClick={handleDialogOpen}
              >
                <i class="fa-solid fa-plus" style={{ fontSize: "8px" }}></i>
                <span
                  style={{
                    marginLeft: "0.5rem",
                    fontSize: "16px",
                    fontWeight: "lighter",
                  }}
                >
                  Upload Media
                </span>
              </Button>
            </div> */}

            <div
              onClick={() => setSelectedImagePath("Gallery Images")}
              className={
                selectedImagePath === "Gallery Images"
                  ? "pointer border-bottom d-flex wb-add-section-modal-selected"
                  : "pointer border-bottom d-flex wb-add-section-modal"
              }
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0px 0px 0px 20px",
                height: "35px",
                alignItems: "center",
              }}
            >
              <span className="ms-2 sidebar-title text-capitalize">
                Gallery Images
              </span>
            </div>

            <div
              onClick={() => setSelectedImagePath("Theme Images")}
              className={
                selectedImagePath === "Theme Images"
                  ? "pointer border-bottom d-flex wb-add-section-modal-selected"
                  : "pointer border-bottom d-flex wb-add-section-modal"
              }
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0px 0px 0px 20px",
                height: "35px",
                alignItems: "center",
              }}
            >
              <span className="ms-2 sidebar-title text-capitalize">
                Theme Images
              </span>
            </div>

            <div
              onClick={() => setSelectedImagePath("Stock Images")}
              className={
                selectedImagePath === "Stock Images"
                  ? "pointer border-bottom d-flex wb-add-section-modal-selected"
                  : "pointer border-bottom d-flex wb-add-section-modal"
              }
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0px 0px 0px 20px",
                height: "35px",
                alignItems: "center",
              }}
            >
              <span className="ms-2 sidebar-title text-capitalize">
                Stock Images
              </span>
            </div>
          </div>
          <div
            className="d-flex flex-column"
            style={{ padding: "10px 24px 20px", width: "80%" }}
          >
            <div className="d-flex justify-content-between align-items-center pb-2">
              <h5 className="m-0">{selectedImagePath}</h5>
              <div className="d-flex justify-content-center align-items-center">
                {selectedImagePath === "Gallery Images" && (
                  <div style={{ paddingInline: "30px" }}>
                    <Button
                      fullWidth
                      variant="contained"
                      component="label"
                      sx={{
                        backgroundColor: "#3899ec",
                        borderRadius: "30px",
                        padding: "3px 23px",
                        "&:hover": {
                          backgroundColor: "#4eb7f5",
                        },
                      }}
                      onClick={handleDialogOpen}
                    >
                      <i
                        class="fa-solid fa-plus"
                        style={{ fontSize: "8px" }}
                      ></i>
                      <span
                        style={{
                          marginLeft: "0.5rem",
                          fontSize: "16px",
                          fontWeight: "lighter",
                        }}
                      >
                        Upload Media
                      </span>
                    </Button>
                  </div>
                )}
                <TextField
                  InputProps={{
                    style: {
                      borderRadius: "30px",
                      backgroundColor: "white",
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ color: "#bebebe" }}
                      >
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label={null}
                  variant="standard"
                  id="outlined-required"
                  placeholder="Search..."
                  value={searchInput}
                  onChange={handleChangeSearch}
                />
              </div>
            </div>
            <div className="wb-image-block">
              {imagesToSearch.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                  className="mt-5"
                  align="center"
                  colSpan={12}
                >
                  <NotFound
                    searchQuery={imagesToSearch}
                    title={"Couldn't find any images"}
                    icon="clarity:image-gallery-solid"
                  />
                </div>
              )}

              {imagesToShow &&
                imagesToSearch.map((image, index) => {
                  return (
                    <div
                      key={index}
                      className="wb-image col-4 border p-1"
                      style={{
                        width: "190px",
                        height: "192px",
                        margin: "10px",
                        borderRadius: "4px",
                      }}
                    >
                      <Tooltip
                        title="Click on image to select"
                        arrow
                        placement="top"
                      >
                        <img
                          onClick={(e) => handleClick(e, image.file_name)}
                          src={
                            selectedImagePath === "Gallery Images"
                              ? s3baseUrl + "/" + image.file_path
                              : s3builderSource + "/" + image.file_path
                          }
                          style={{
                            width: "190px",
                            height: "161px",
                            objectFit: "cover",
                            cursor: "pointer",
                            background: "#f0f7ff",
                          }}
                          alt="upload"
                        />
                      </Tooltip>
                      {!UpdateImageSrc &&
                        selectedImagePath === "Gallery Images" && (
                          <Tooltip title="Remove Image" placement="top" arrow>
                            <div
                              className="image-icon icon-delete"
                              style={{ top: "2%" }}
                              onClick={() => DeleteImageConfirmation(image)}
                            >
                              <i class="fa-solid fa-trash-can"></i>
                            </div>
                          </Tooltip>
                        )}

                      <div className="image-name-size mx-0 my-1 d-flex align-items-center justify-content-center">
                        <span>{get_short_string(image.file_name, 20)}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <MediaUploadDialog
          uploadDialog={uploadDialog}
          setUploadDialog={setUploadDialog}
          userImagesList={userImagesList}
          setUserImagesList={setUserImagesList}
        />
      </Dialog>
    </>
  );
}

export default GalleryDialog;
