import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Avatar, Menu, MenuItem, Box, Button, Tooltip } from "@mui/material";
import Assignment from "@mui/icons-material/Assignment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PeopleIcon from "@mui/icons-material/People";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation, useNavigate, withRouter } from "react-router-dom";
import {
  app_logo_icon,
  themeImage,
  chat_bot,
  vissiion_logo_icon,
} from "../../assets";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";
import TabletIcon from "@mui/icons-material/Tablet";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import Badge from "@mui/material/Badge";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ExitBuilderConfirmation from "../../components/ExitBuilderConfirmation";
import CircularProgress from "@mui/material/CircularProgress";
import {
  _is_dev,
  _remove_token,
  _remove_user,
  _set_user,
} from "../../DAL/localStorage/localStorage";
import {
  _save_page_version,
  _get_version_list,
  _use_page_version,
  _get_version_count,
} from "../../DAL/projects-pages/projectspages";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";
import { client_side_url, s3baseUrl } from "../../config/config";
import { Outlet, Navigate } from "react-router-dom";
import AccountPopover from "../dashboard/AccountPopover";
import BuilderAccountPopover from "../dashboard/BuilderAccountPopover";
import CustomConfirmation from "../../components/CustomConfirmation";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { useSnackbar } from "notistack";
import { _get_user_profile } from "../../DAL/login/login";
import { Icon } from "@iconify/react";
import AddNewPage from "./components/AddNewPage/AddNewPage";
import { set } from "lodash";
import AddNewVersion from "./components/AddNewVersion/AddNewVersion";
import { getPageHTML_CSS_JS } from "../../utils/convertStyleToClass";
import DownloadIcon from "@mui/icons-material/Download";

import MenuPopup from "../../components/MenuPopup/MenuPopup";
import { debounce } from "../../utils/utils";
import ExitMenuPopup from "../../components/ExitMenuPopup";
import EditButtonPopup from "../../components/EditButtonPopup/EditButtonPopup";
import PageMetaTitles from "../../components/Page";
import NavlistButtonPopup from "../../components/NavlistEditButtonPopup/NavlistEditButtonPopup";
import ListSettingButton from "../../components/ListSettingButton/ListSettingButton";
import IframeButtonPopup from "../../components/IframEditButton/IframeButtonPopup";
import Joyride from "react-joyride";
import { edit_customer_over_view } from "../../DAL/customer/Customer";
import BowSettingButton from "../../components/BoxSettingButton/BoxSettingButton";
import LayoutSettingButton from "../../components/PopupButtons/LayoutSettingButton";
const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({}));

function Appbar(props) {
  const { children } = props;
  const {
    setIsDev,
    getIsDevState,
    getSelectedSections,
    handleRemoveSelection,
    customerSubscriptionDetail,
    setCustomerSubscriptionDetail,
    handleUpSelection,
    handleDownSelection,
    handleSaveTemplateToServer,
    CheckChangeExsistInHTML,
    isSaveLoading,
    handleDownload,
    setMobilePreview,
    setTabletPreview,
    setChangeInHTML,
    handleThemeClick,
    rootValues,
    setHeaderContentModal,
    setIsHeaderDialogOpen,
    handlePublishProject,
    isPublishLoading,
    getEditModeState,
    SelectedPage,
    UndoAction,
    RedoAction,
    HistoyStack,
    RedoStack,
    setAIModalOpen,
    aIModalMinimize,
    setAIModalMinimize,
    GotoLoginPage,
    isPreviewLoading,
  } = useBuilder();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  //for sidebar selected navigation
  const [selectedIndex, setSelectedIndex] = React.useState();
  //for profile menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  //for open change template
  //for profile
  const profile = JSON.parse(sessionStorage.getItem("user"));
  const [OpenNewPageDailogue, setOpenNewPageDailogue] = useState(false);
  const [ExitBuilder, setExitBuilder] = useState(false);
  const [LogoutBuilder, setLogoutBuilder] = useState(false);
  const [OpenPageDuplicateDailogue, setOpenPageDuplicateDailogue] =
    useState(false);
  const [currentVersion, setcurrentVersion] = useState(null);
  const [OpenSaveVersionDailogue, setOpenSaveVersionDailogue] = useState(false);
  const [FinalHTML, setFinalHTML] = useState("");
  const [FinalCSS, setFinalCSS] = useState("");
  const [FinalJS, setFinalJS] = useState("");
  const [PageValue, setPageValue] = useState("");
  const [ChangePageAlert, setChangePageAlert] = useState(false);
  const [TargetSlug, setTargetSlug] = useState("");
  const [versionList, setVersionList] = useState(null);
  const [versionSave, setVersionSave] = useState(false);
  const [versionSaving, setVersionSaving] = useState(false);
  const [showSaveVersionAlert, setShowSaveVersionAlert] = useState(false);
  const [selectedVersionId, setSelectedVersionId] = useState("");
  const [savedVersionCount, setsavedVersionCount] = useState(false);
  const [downloadCode, setDownloadCode] = useState(false);

  const [clickCount, setClickCount] = useState(1);
  const [exitMenuPopup, setExitMenuPopup] = useState(null);
  const [exitBuilderLink, setExitBuilderLink] = useState("");
  const [vissionUser, setVissionUser] = useState(false);
  const [joyRideRun, setJoyRideRun] = useState(false);

  // JoyRide Steps

  const joyride_steps = [
    {
      title: `Welcome to ${
        sessionStorage.getItem("userType") === "3" ? "Vissioon" : "Inovate.Tech"
      } Builder!`,
      target: ".builder-logo",
      content: `Please spare a minute to learn about our Builder Editor`,
      disableBeacon: true,
    },
    {
      target: ".element-div",
      content: "You can add elements to your page.",
    },
    {
      target: ".add-section-element",
      content: "Add sections to your page from here.",
    },
    {
      target: ".page-setting-element",
      content: "You can switch between pages and adjust your sections.",
    },
    {
      target: ".theme-setting-element",
      content: "Change theme colors and typography from here.",
    },
    {
      target: ".ai-element",
      content: "Get content for your page from AI Writer",
    },
    {
      target: ".tablet-mobile-preview-element",
      content: "Preview your page in tablet and mobile mode.",
    },
    {
      target: ".undo-redo-element",
      content: "You can Undo and Redo changes from here.",
    },
    {
      target: ".save-preview-buttons-element",
      content: "You can save changes and preview your page from here.",
    },
    {
      target: ".builder-exit-button",
      content: "Leave builder from here.",
    },
  ];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //for sidebar
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleLogout = () => {
    _remove_token();
    _remove_user();
    GotoLoginPage();
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  const changePassword = () => {
    handleClose();
    navigate("/change-password");
  };

  const Logout = () => {
    _remove_token();
    navigate("/");
  };
  const openWebSettings = () => {
    navigate(`/website-settings/${rootValues._id}`);
  };
  const openPageSettings = () => {
    navigate(`/page-settings/${rootValues._id}/${rootValues.page_id}`);
  };

  const openSubscribers = () => {
    navigate(`/subscribers/${rootValues._id}`);
  };
  const handlExitBuilder = () => {
    // navigate(`/projects`, {
    //   state: {
    //     NavigateToPageList: true,
    //     project_id: rootValues._id,
    //     project_name: rootValues.project_name,
    //   },
    // });
    GotoClientPortal();
  };
  const handleLogoutBuilder = () => {
    sessionStorage.clear();
    GotoLoginPage();
  };
  const handleSaveandLogoutBuilder = () => {
    handleSaveTemplateToServer({ preview: false, exitbuilder: "login" });
  };
  const handleSavePageAndExit = async () => {
    const resp = await handleSaveTemplateToServer({
      preview: false,
      // exitbuilder: "projects",
    });
    if (resp) {
      GotoClientPortal();
    }
  };
  const GotoClientPortal = () => {
    const baseURL = sessionStorage.getItem("client_base_url");
    const paramsPath = sessionStorage.getItem("source_link");
    let user = sessionStorage.getItem("userType");

    if (paramsPath !== "" && paramsPath !== null) {
      window.location.href = paramsPath;
    } else if (baseURL && user === "3") {
      window.location.href = baseURL + "website-pages";
    } else if (baseURL) {
      if (exitBuilderLink === "projects") {
        window.location.href = baseURL + "/projects";
      } else {
        window.location.href =
          baseURL + "/" + "manage-project/" + rootValues._id;
      }
    } else {
      window.location.href = client_side_url;
    }
  };
  const openHeaderContentModal = () => {
    setHeaderContentModal(true);
  };
  const openHeaderDialog = () => {
    setIsHeaderDialogOpen(true);
  };
  const handleDuplicatePage = () => {
    let html = collectTargetHTML();
    let css = collectTargetCSS();
    let js = collectTargetJS();
    setFinalHTML(html);
    setFinalCSS(css);
    setFinalJS(js);
    rootValues.page_list.map((page) => {
      if (page.page_slug == SelectedPage) {
        setPageValue({ ...page });
      }
    });
    setOpenPageDuplicateDailogue(true);
  };

  const handleSavePageandChangePage = async () => {
    let result = await handleSaveTemplateToServer({ preview: false });
    if (result) {
      handleChangePage();
    }
  };
  const handleChangePage = (value = null) => {
    value = value ? value : TargetSlug;
    rootValues.page_list.map((page) => {
      if (page.page_slug == value) {
        navigate("/builder-redirect-controller", {
          state: {
            project_id: rootValues._id,
            page_id: page._id,
          },
        });
      }
    });
  };
  const handlechangepage = (value) => {
    if (value == "") {
      rootValues.page_list.map((page) => {
        if (page.page_slug == SelectedPage) {
          setPageValue({ ...page });
        }
      });
      setOpenNewPageDailogue(true);
    } else if (value == "button") {
      return;
    } else {
      rootValues.page_list.map((page) => {
        if (page.page_slug == value) {
          setTargetSlug(value);
          if (CheckChangeExsistInHTML()) {
            setChangePageAlert(true);
          } else {
            handleChangePage(value);
          }

          // navigate('/builder-redirect-controller', {
          //   state: {
          //     project_id: rootValues._id,
          //     page_id: page._id,
          //   },
          // })
        }
      });
    }
  };
  const project_id = rootValues._id;

  if (!sessionStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }

  const collectTargetHTML = () => {
    let collect_html = "";
    getSelectedSections().forEach((section) => {
      collect_html += section.section_html + "\n\n";
    });

    return collect_html ? collect_html : `/* No HTML */`;
  };
  const collectTargetCSS = () => {
    let collect_css = "";
    getSelectedSections().forEach((section) => {
      collect_css += section.section_css + "\n\n";
    });

    return collect_css ? collect_css : `/* No CSS */`;
  };

  const collectTargetJS = () => {
    let collect_js = "";
    getSelectedSections().forEach((section) => {
      collect_js += section.section_js;
    });
    ///asdasd
    return collect_js ? collect_js : `/* No JS */`;
  };
  useEffect(() => {}, []);
  const HandleUndoRedo = (value) => {
    if (value == "undo") {
      // UndoAction()
      debounce(UndoAction, 100);
    } else {
      // RedoAction()
      debounce(RedoAction, 100);
    }
  };

  const onClickSaveVersion = () => {
    setShowSaveVersionAlert(false);
    setOpenSaveVersionDailogue(true);
    return;
  };

  const saveVersion = async (name, useversion) => {
    const formData = new FormData();
    formData.append("page_id", rootValues.page_id);
    formData.append("version_name", name);
    if (useversion) {
      formData.append("version_id", rootValues.version);
    } else {
      let versioncount = await fetchSavedVersionCount();

      if (!versioncount) {
        enqueueSnackbar("You already have 3 pages versions", {
          variant: "error",
        });
        return;
      }
    }

    setVersionSaving(true);

    const save_res = await _save_page_version(formData);

    if (save_res.code === 200) {
      enqueueSnackbar("Page version saved.", { variant: "success" });
    } else {
      enqueueSnackbar(save_res.message, { variant: "error" });
    }
    setVersionSaving(false);
  };

  // const usePageVersion = async () => {
  //   const formData = new FormData();
  //   formData.append("page_id", rootValues.page_id);
  //   formData.append("version_id", selectedVersionId);
  //   const user_version_res = await _use_page_version(formData)

  //   if (user_version_res.code == 200) {
  //     navigate('/builder-redirect-controller', {
  //       state: {
  //         project_id: rootValues._id,
  //         page_id: rootValues.page_id,
  //       },
  //     })
  //   }
  // }

  const fetchSavedVersionCount = async () => {
    let resp = true;
    const fetch_res = await _get_version_count(rootValues.page_id);
    if (fetch_res.code == 200) {
      setsavedVersionCount(fetch_res.Page.Length_Status);
      resp = fetch_res.Page.Length_Status;
    }

    return resp;
  };

  const handleSaveVersion = () => {
    setShowSaveVersionAlert(true);
  };
  const Exit_Menu_List = [
    {
      label: "Manage Project",
      icon: "ant-design:setting-outlined",
      handleClick: () => {
        setExitBuilderLink("Manage Project");
        setExitBuilder(true);
      },
    },
    {
      label: "All Projects",
      icon: "tabler:arrow-back-up-double",
      handleClick: () => {
        setExitBuilderLink("projects");
        setExitBuilder(true);
      },
    },
  ];
  const Exit_Menu_List_vission = [
    {
      label: "Website Pages",
      icon: "tabler:arrow-back-up-double",
      handleClick: () => {
        setExitBuilderLink("website-pages");
        setExitBuilder(true);
      },
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (status === "finished") {
      editCustomerOverView();
    } else if (status === "skipped") {
      editCustomerOverView();
    } else if (status === "closed") {
      editCustomerOverView();
    }
  };

  const editCustomerOverView = async () => {
    const postData = {
      over_view: {
        builder_editor: true,
      },
    };
    const resp = await edit_customer_over_view(postData);
    if (resp.code === 200) {
      setCustomerSubscriptionDetail({ ...resp.customer });
    }
    return resp;
  };

  useEffect(() => {
    let user = sessionStorage.getItem("userType");
    if (user === "3") {
      setVissionUser(true);
    }
    setJoyRideRun(true);
  }, []);

  return (
    <div className={classes.root}>
      {/* add page dailogue */}
      <AddNewPage
        open={OpenNewPageDailogue}
        setOpen={setOpenNewPageDailogue}
        Page={PageValue}
        CheckChanges={true}
      />
      {sessionStorage.getItem("userType") === "3" ? (
        <PageMetaTitles
          title="Vissioon | Builder"
          favIcon={
            sessionStorage.getItem("brand_favicon") !== "null"
              ? sessionStorage.getItem("brand_favicon")
              : "../favicon/vissiion-favicon.png"
          }
        />
      ) : (
        <PageMetaTitles
          title="Wezily | Builder"
          favIcon={"../favicon/wezily-favIcon.png"}
        />
      )}
      {!isPreviewLoading &&
        customerSubscriptionDetail?.over_view.builder_editor === false && (
          <>
            <Joyride
              steps={joyride_steps}
              continuous={true}
              run={joyRideRun}
              callback={handleJoyrideCallback}
              styles={{
                options: {
                  arrowColor: "#022859",
                  backgroundColor: "#022859",
                  // overlayColor: "#02285983",
                  primaryColor: "#022859",
                  textColor: "#fff",
                  zIndex: "1200",
                },
                buttonBack: {
                  color: "#fff",
                },
              }}
              showProgress={true}
              // Add this
              showSkipButton={true}
            />
          </>
        )}

      {/* duplicate Page */}
      <AddNewPage
        duplicate={true}
        open={OpenPageDuplicateDailogue}
        Page={PageValue}
        setOpen={setOpenPageDuplicateDailogue}
        FinalHTML={FinalHTML}
        FinalCSS={FinalCSS}
        FinalJS={FinalJS}
        CheckChanges={true}
      />

      {/* {showSaveVersionAlert && ( */}
      <AddNewVersion
        open={showSaveVersionAlert}
        setOpen={setShowSaveVersionAlert}
        Page_id={rootValues.page_id}
        SaveHTML={true}
        version={rootValues.version ? rootValues.version : ""}
      />
      {/* )} */}

      {/* Exit MenuPopup */}
      <ExitMenuPopup
        menuAnchorEl={exitMenuPopup}
        setMenuAnchorEl={setExitMenuPopup}
        menuList={vissionUser ? Exit_Menu_List_vission : Exit_Menu_List}
      />

      {/* save page version confirmation */}
      {/* <ExitBuilderConfirmation
        open={showSaveVersionAlert}
        setOpen={setShowSaveVersionAlert}
        handleAgree={onClickSaveVersion}
        handleSavePageAndExit={handleSavePageAndExit}
        title="You have unsaved changing. Please save your page setting"
        option1={'Save Version'}
        option2={'Save Page & Version'}
      /> */}

      {/* exit buider confirmation message */}
      <ExitBuilderConfirmation
        open={ExitBuilder}
        setOpen={setExitBuilder}
        handleAgree={handlExitBuilder}
        handleSavePageAndExit={handleSavePageAndExit}
        title="Are you sure you want to exit from builder?"
      />

      {/* page cahnge */}
      <ExitBuilderConfirmation
        open={ChangePageAlert}
        setOpen={setChangePageAlert}
        handleAgree={() => handleChangePage(TargetSlug)}
        handleSavePageAndExit={handleSavePageandChangePage}
        // title="You might have unsaved changes please save otherwise you might lose your page data."
        title="Leaving page without saving data"
        option1={"Continue"}
        option2={"Save and Continue"}
      />
      {/* logout confirmation */}
      <ExitBuilderConfirmation
        open={LogoutBuilder}
        setOpen={setLogoutBuilder}
        handleAgree={handleLogoutBuilder}
        handleSavePageAndExit={handleSaveandLogoutBuilder}
        title="Are you sure you want to Logout "
        option1={"Logout"}
        option2={"Save and Logout"}
      />
      <CssBaseline />
      {/* <div
        style={{
          zIndex: "99",
          position: "fixed",
          bottom: "2vh",
          right: "1vw",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          cursor: "pointer",
        }}
        onClick={() => {
          aIModalMinimize ? setAIModalMinimize(false) : setAIModalOpen(true);
        }}
      >
        <Tooltip title="AI Writer" arrow placement="top">
          <img src={chat_bot} alt={chat_bot} style={{ borderRadius: "50%" }} />
        </Tooltip>
      </div> */}

      {/* Popup Buttons */}
      <EditButtonPopup />
      <NavlistButtonPopup />
      <ListSettingButton />
      <IframeButtonPopup />
      <BowSettingButton />
      <LayoutSettingButton />

      {/* PopupButtons end */}
      <AppBar
        style={{
          height: 40,
          backgroundColor: "white",
          justifyContent: "center",
        }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className="px-0">
          <div
            className="border-start border-end builder-exit-button"
            style={{
              height: "40px",
              minWidth: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={(e) => setExitMenuPopup(e.currentTarget)}
          >
            <Icon
              icon="ic:round-arrow-back-ios-new"
              color="black"
              width={20}
              height={24}
            />
          </div>
          <div
            className="border-start border-end builder-logo"
            style={{
              height: "40px",
              width: "165px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              onClick={() => {
                setTimeout(() => {
                  setClickCount(1);
                }, 1500);
                if (getIsDevState()) {
                  return;
                }
                setClickCount(clickCount + 1);
                if (clickCount == 5) {
                  setIsDev(true);
                  sessionStorage.setItem("is_dev", "true");
                  enqueueSnackbar("Developer mode enabled", {
                    variant: "success",
                  });
                }
              }}
              // onClick={() => navigate("/dashboard")}
              alt="app-logo"
              style={{
                marginTop: 0,
                cursor: "pointer",
                padding: "0.15rem 0.7rem",
              }}
              height="32"
              src={
                vissionUser
                  ? sessionStorage.getItem("brand_logo") !== "null"
                    ? sessionStorage.getItem("brand_logo")
                    : vissiion_logo_icon
                  : app_logo_icon
              }
            ></img>
          </div>
          {/* <span
            onClick={() => setExitBuilder(true)}
            style={
              isSaveLoading
                ? { pointerEvents: "none", opacity: 0.4, width: 250 }
                : { width: 250 }
            }
            className="exit-builder-style border-start"
          >
            <Icon fontSize="24" className="me-1" icon="majesticons:door-exit" />
            Dashboard
          </span> */}
          <span className="text-center text-capitalize text-dark  business-name-preview">
            <Tooltip
              title={rootValues.project_name ? rootValues.project_name : ""}
              arrow
              placement="bottom"
            >
              <Typography width={150} variant="body2" noWrap>
                {rootValues.project_name}
              </Typography>
            </Tooltip>
          </span>

          {/* <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              width: 150,
              fontSize: 14,
              color: "black",
              "&:hover": {
                "&& fieldset": {
                  color: "#fa2356",
                  backgroundColor: "initial",
                  backgroundColor: "#fa23560d",
                  border: "1px",
                },
              },
              "&:not(:hover)": {
                "&& fieldset": {
                  border: "1px",
                },
              },
            }}
            className="border-end"
            value={rootValues.page_slug ? rootValues.page_slug : "index"}
            onChange={(e) => {
              handlechangepage(e.target.value);
            }}
            label="page"
          >
            {!rootValues?.page_list && (
              <MenuItem
              sx={{
                fontSize: 14,
                  width: 130,
                }}
                className="text-dark"
                value="index"
                >
                Index
              </MenuItem>
            )}
            {rootValues?.page_list &&
              rootValues.page_list.map((page) => (
                <MenuItem
                  noWrap
                  sx={{ fontSize: 14 }}
                  className="text-dark"
                  value={page.page_slug}
                >
                <Typography width={120} variant="body2" noWrap>
                    {page.page_name}
                  </Typography>
                </MenuItem>
              ))}
            <MenuItem
              sx={{
                width: 130,
                "&:hover": {
                  backgroundColor: "transparent",
                },
                Overflow: "hidden",
              }}
              disableRipple
              className="text-dark"
              value={"button"}
              >
              <Button
                sx={{ fontSize: 12, color: "black" }}
                variant="outlined"
                value=""
                size="small"
                onClick={(e) => {
                  handlechangepage(e.target.value);
                }}
                >
                Add new Page
              </Button>
            </MenuItem>
          </Select> */}
          <div className="tablet-mobile-preview-element d-flex">
            <Tooltip title="Tablet Preview" arrow placement="top">
              <span
                className="wb-header-preview border-start text-dark pe-3"
                onClick={() => {
                  if (CheckChangeExsistInHTML()) {
                    setChangeInHTML(true);
                  }
                  setTabletPreview(true);
                  setMobilePreview(false);
                }}
                style={
                  isSaveLoading ? { pointerEvents: "none", opacity: 0.4 } : {}
                }
              >
                <TabletIcon sx={{ fontSize: 15 }} />
              </span>
            </Tooltip>
            <Tooltip title="Mobile Preview" arrow placement="top">
              <span
                className="wb-header-preview  border-end  text-dark pe-3"
                onClick={() => {
                  if (CheckChangeExsistInHTML()) {
                    setChangeInHTML(true);
                  }
                  setMobilePreview(true);
                  setTabletPreview(false);
                }}
                style={
                  isSaveLoading ? { pointerEvents: "none", opacity: 0.4 } : {}
                }
              >
                <SmartphoneIcon sx={{ fontSize: 15 }} />
              </span>
            </Tooltip>
          </div>
          {_is_dev() && (
            <Tooltip title="Download Source Code" arrow placement="top">
              <span
                className="wb-header-preview  border-end  p-3  text-dark"
                onClick={async () => {
                  setDownloadCode(true);
                  await getPageHTML_CSS_JS(s3baseUrl, rootValues.project);
                  setDownloadCode(false);
                }}
                style={
                  downloadCode
                    ? { pointerEvents: "none" }
                    : { cursor: "pointer" }
                }
              >
                {downloadCode ? (
                  <CircularProgress size={15} />
                ) : (
                  <DownloadIcon sx={{ fontSize: 15 }} />
                )}
              </span>
            </Tooltip>
          )}

          {/* <span
            onClick={handleSaveVersion}
            className="wb-header-preview border-end wb-save"
            >
            {versionSaving ? (
              <div className="spinner-border" role="status"></div>
            ) : (
              <>
                <SaveIcon className="me-1" /> Save Version
              </>
              )}
          </span> */}

          {/* <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              width: 150,
              fontSize: 14,
              color: 'black',
              '&:hover': {
                '&& fieldset': {
                  color: '#fa2356',
                  backgroundColor: 'initial',
                  backgroundColor: '#fa23560d',
                  border: '1px',
                },
              },
              '&:not(:hover)': {
                '&& fieldset': {
                  border: '1px',
                },
              },
            }}
            className="border-end"
            value={selectedVersionId}
            // value={versionList ? versionList[0]._id : ''}
            onChange={(e) => {
              // handlechangepage(e.target.value)
              
              setSelectedVersionId(e.target.value)
              usePageVersion()
            }}
            label="page"
          >
            {!versionList || versionList.length == 0 && (
              <MenuItem
                sx={{
                  fontSize: 14,
                  width: 130,
                }}
                className="text-dark"
                value="index"
                >
                No Version Saved
                </MenuItem>
            )}
            {versionList &&
              versionList.map((page, index) => (
                <MenuItem
                sx={{ fontSize: 14, width: 130 }}
                  className="text-dark"
                  value={page._id}
                // value={page.pages[0].page_slug}
                >
                  Version {index + 1}
                  </MenuItem>
              ))}
              <MenuItem
              sx={{
                width: 130,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              disableRipple
              className="text-dark"
              value={'button'}
            >
              <Button
                sx={{ fontSize: 12, color: 'black' }}
                variant="outlined"
                value=""
                size="small"
                onClick={(e) => {
                  // handlechangepage(e.target.value)
                  saveVersion()
                }}
              >
              Save Version
              </Button>
            </MenuItem>
          </Select> */}

          {/* undo redo actions */}
          {/* <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              width: 150,
              fontSize: 14,
              color: 'black',
              '&:hover': {
                '&& fieldset': {
                  color: '#fa2356',
                  backgroundColor: 'initial',
                  backgroundColor: '#fa23560d',
                  border: '1px',
                },
              },
              '&:not(:hover)': {
                '&& fieldset': {
                  border: '1px',
                },
              },
            }}
            className="border-end"
            value={'val'}
            onChange={HandleUndoRedo}
            label="action"
          >
            <MenuItem
              disabled
              hidden
              sx={{
                visibility: 'none',
                fontSize: 14,
              }}
              className="text-dark"
              value="val"
            >
              Action
            </MenuItem>
            <MenuItem
            sx={{
                fontSize: 14,
              }}
              className="text-dark"
              value="undo"
            >
              <UndoIcon sx={{ marginRight: 1 }} />
              Undo
              </MenuItem>
              
              <MenuItem
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              disableRipple
              className="text-dark"
              value="redo"
            >
              <RedoIcon sx={{ marginRight: 1 }} />
              Redo
              </MenuItem>
          </Select> */}
          <span className="d-flex justify-content-end text-end w-100">
            {getSelectedSections().length >= 0 && (
              <>
                <div className="undo-redo-element d-flex">
                  <Tooltip
                    style={
                      isSaveLoading
                        ? { pointerEvents: "none", opacity: 0.4 }
                        : HistoyStack.length > 0
                        ? {
                            pointerEvents: "auto",
                          }
                        : { pointerEvents: "none", opacity: 0.5 }
                    }
                    title="Undo Change"
                    arrow
                    placement="top"
                  >
                    <span
                      className="wb-header-preview border-start text-dark pe-3"
                      onClick={() => HandleUndoRedo("undo")}
                      style={{ height: "40px" }}
                    >
                      {/* <Badge badgeContent={HistoyStack.length}> */}
                      <UndoIcon sx={{ fontSize: 15 }} />
                      {/* </Badge> */}
                    </span>
                  </Tooltip>

                  <Tooltip
                    style={
                      isSaveLoading
                        ? { pointerEvents: "none", opacity: 0.4 }
                        : RedoStack.length > 0
                        ? {
                            pointerEvents: "auto",
                          }
                        : { pointerEvents: "none", opacity: 0.5 }
                    }
                    title="Redo Change"
                    arrow
                    placement="top"
                  >
                    <span
                      onClick={() => HandleUndoRedo("redo")}
                      className="wb-header-preview border-start text-dark pe-3"
                    >
                      {/* <Badge badgeContent={RedoStack.length}> */}
                      <RedoIcon sx={{ fontSize: 15 }} />
                      {/* </Badge> */}
                    </span>
                  </Tooltip>
                </div>

                {/* {getIsDevState() && (
                  <span
                    onClick={handleDownload}
                    className="wb-header-preview border-start text-dark pe-3"
                  >
                    Download Website
                  </span>
                )} */}

                {/* <span
                  onClick={handleDuplicatePage}
                  className="wb-header-preview border-start text-dark pe-3"
                >
                  Duplicate Page
                </span> */}
                {/* <span
                  onClick={openHeaderDialog}
                  className="wb-header-preview border-start"
                  >
                  <Assignment className="me-1" /> Header
                </span> */}

                {/* {getEditModeState() && (
                  <span
                    onClick={openSubscribers}
                    className="wb-header-preview border-start"
                  >
                    <Icon
                    fontSize="24"
                      className="me-1"
                      icon="clarity:users-solid"
                    />
                    Subscribers
                  </span>
                )} */}
                {/* {getEditModeState() && (
                  
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{
                      fontSize: 14,
                      color: 'black',
                      '&:hover': {
                        '&& fieldset': {
                          color: '#fa2356',
                          backgroundColor: 'initial',
                          backgroundColor: '#fa23560d',
                          border: '1px',
                        },
                      },
                      '&:not(:hover)': {
                        '&& fieldset': {
                          border: '1px',
                        },
                      },
                    }}
                    className="wb-header-preview border-start"
                    label="Settings"
                    value="settings"
                  >
                    <MenuItem
                      sx={{
                        fontSize: 14,
                      }}
                      disabled
                      className="wb-header-preview text-dark"
                      value="settings"
                      hidden
                    >
                      <SettingsApplicationsIcon className="me-1" />
                      Settings
                    </MenuItem>
                    <MenuItem
                      sx={{
                        fontSize: 14,
                        backgroundColor: '#e6e6e6',
                      }}
                      onClick={openWebSettings}
                      className="wb-header-preview text-dark"
                      value="website"
                    >
                      Website Settings
                    </MenuItem>
                    <MenuItem
                    sx={{
                        fontSize: 14,
                      }}
                      onClick={openPageSettings}
                      className="wb-header-preview text-dark"
                      value="page"
                    >
                      Page Setings
                    </MenuItem>
                  </Select>
                )} */}
                {/* <span
                  onClick={handleThemeClick}
                  className="wb-header-preview d-flex border-start"
                >
                  <img
                    src={themeImage}
                    height="30"
                    className="wb-header-theme me-1 pointer"
                    // onClick={handleThemeClick}
                  />
                  Theme
                </span> */}
                <div
                  className="border-end save-preview-buttons-element"
                  style={{
                    display: "flex",
                    width: "16.2rem",
                    pointerEvents: isSaveLoading && "none",
                  }}
                >
                  <span
                    id="wb-save-button"
                    onClick={() =>
                      handleSaveTemplateToServer({ preview: false })
                    }
                    className="wb-header-preview border-start wb-save"
                  >
                    {isSaveLoading ? (
                      <div style={{ width: "16.2rem", display: "flex" }}>
                        <div className="spinner-border" role="status"></div>
                        <div className="mx-5 text-center">Please wait...</div>
                      </div>
                    ) : (
                      <>
                        <SaveIcon className="me-1" /> Save
                      </>
                    )}
                  </span>
                  <span
                    id="wb-save-and-preview-button"
                    onClick={() =>
                      handleSaveTemplateToServer({ preview: true })
                    }
                    className="wb-header-preview border-start wb-save"
                    style={{
                      display: isSaveLoading ? "none" : "flex",
                      alignItems: "center",
                      width: "65%",
                    }}
                  >
                    <VisibilityIcon className="me-1" />
                    Save & Preview{" "}
                  </span>
                </div>

                {/* {getEditModeState() && (
                  <>
                    <span
                    onClick={() => {
                        handlePublishProject()
                      }}
                      className={
                        rootValues.domain
                        ? 'wb-header-preview border-start wb-header-download'
                        : 'wb-header-preview border-start wb-header-download force-disabled'
                      }
                    >
                      {isPublishLoading ? (
                        <div
                          className="spinner-border me-1"
                          style={{ fontSize: 8, height: 24, width: 24 }}
                          role="status"
                        ></div>
                      ) : (
                        <>
                          <PublishedWithChangesIcon className="me-1" />
                          </>
                      )}
                      Publish
                    </span>
                  </>
                )} */}

                <div className="px-3 d-flex align-items-center">
                  <BuilderAccountPopover
                    handleLogout={setLogoutBuilder}
                    project_id={rootValues._id}
                    handleSaveVersion={handleSaveVersion}
                  />
                  {/* <div
                    className="dropdown-toggle d-flex align-items-center"
                    type="button"
                    id="defaultDropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="true"
                    aria-expanded="false"
                  >
                    <Avatar
                      className="float-start ms-2 text-uppercase"
                      style={{ height: 40 }}
                      >
                      {profile?.profile_image ? (
                        <img
                          style={{ width: 40 }}
                          src={s3baseUrl + profile.profile_image}
                          alt=""
                        />
                      ) : (
                        profile.first_name[0]
                      )}
                      </Avatar>
                    <ArrowDropDownIcon style={{ color: "black" }} />
                  </div> */}

                  <ul
                    className="dropdown-menu mt-1"
                    aria-labelledby="defaultDropdown"
                  >
                    <li>
                      <a
                        className="dropdown-item wb-dropdown-link pointer"
                        onClick={handleLogout}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </span>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          {/* Avatar */}
          <div>
            <img
              className={classes.avatar}
              alt="app-logo"
              style={{ marginTop: 5 }}
              height="50"
              src={vissionUser ? vissiion_logo_icon : app_logo_icon}
            ></img>
          </div>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />

        {/* Side menu */}
        {/* <SideMenu
          selectedIndex={selectedIndex}
          handleListItemClick={handleListItemClick}
        /> */}
        {/* Side menu */}
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {/* children */}
        <MenuPopup />
        {<Outlet />}
      </main>
    </div>
  );
}

export default Appbar;
