import React, { useEffect, useState } from "react";

import {
  Box,
  Menu,
  Typography,
  Link,
  ListItemButton,
  List,
  ListItemText,
  Button,
  Divider,
  Tooltip,
  Stack,
} from "@mui/material";
import { FlashOnTwoTone } from "@mui/icons-material";
import { Icon } from "@iconify/react";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";
import {
  _find_element_has_class,
  _find_element_has_tag,
  _find_is_editor_element,
  _find_parent_row_of_element,
  _find_section_of_element,
} from "../../hooks/builder-context/utils";

let list_Elements = [
  {
    value: "I",
    label: "Icon",
    icon: "simple-icons:iconify",
  },
  {
    value: "A",
    label: "Button",
    icon: "material-symbols:radio-button-checked-outline",
  },
  {
    value: "IMG",
    label: "Image",
    icon: "fluent:image-edit-16-regular",
  },
  {
    value: "BUTTON",
    label: "Button",
    icon: "material-symbols:radio-button-checked-outline",
  },
  {
    value: "INPUT",
    label: "Input",
    icon: "material-symbols:insert-text-outline",
  },
  {
    value: "TEXTAREA",
    label: "Textarea",
    icon: "material-symbols:text-fields-rounded",
  },
  {
    value: "SELECT",
    label: "Option",
    icon: "uil:list-ui-alt",
  },
  // {
  //   value: "FORM",
  //   label: "Form",
  //   icon: "ant-design:form-outlined",
  // },
];

let spanElement = [
  {
    value: "SPAN",
    label: "Text",
    icon: "material-symbols:text-fields-rounded",
  },
  {
    value: "LABEL",
    label: "Text",
    icon: "material-symbols:text-fields-rounded",
  },
  {
    value: "P",
    label: "Text",
    icon: "material-symbols:text-fields-rounded",
  },
  {
    value: "H1",
    label: "Text",
    icon: "material-symbols:text-fields-rounded",
  },
  {
    value: "H2",
    label: "Text",
    icon: "material-symbols:text-fields-rounded",
  },
  {
    value: "H3",
    label: "Text",
    icon: "material-symbols:text-fields-rounded",
  },
  {
    value: "H4",
    label: "Text",
    icon: "material-symbols:text-fields-rounded",
  },
  {
    value: "H5",
    label: "Text",
    icon: "material-symbols:text-fields-rounded",
  },
  {
    value: "H6",
    label: "Text",
    icon: "material-symbols:text-fields-rounded",
  },
];

const MenuPopup = () => {
  const [rightMenuList, setRightMenuList] = useState(null);

  const {
    rightMenuAnchorEl,
    setRightMenuAnchorEl,
    selectedElement,
    setSelectedElement,
    setSelectedRowClasses,
    checkIsEditorElement,
    setEditorMenuOpen,
    setSelectedRow,
    setOpenRowSetting,
    handleOpenSectionSettings,
    setOpenReplaceElementMenu,
    SaveActionToStack,
    setEditAbleInputs,
    handleElementClick,
    editAbleInputs,
    setIsReplaceableElement,
    setOpenListBlockDrawer,
    editorMenuOpen,
    validateElement,
    setPlanSettingsMenuOpen,
    setSelectedSectionSettingId,
    getSelectedSectionPlanByID,
    setFormSettingsMenuOpen,
    setOpenPlanCardSetting,
    handleRemoveElement,
    isParentAnchorTag,
    isElementOfEditor,
  } = useBuilder();
  const handleClose = () => {
    setRightMenuAnchorEl(null);
  };

  const removeElement = (element, checkIframe = true) => {
    handleRemoveElement(element);
    let target = element;
    if (checkIframe) {
      while (target.parentNode.children.length == 1) {
        checkIframeExistagainstElement(target);
        target = target.parentNode;
      }
      checkIframeExistagainstElement(target);
    }
  };

  const checkIframeExistagainstElement = (element) => {
    let element_name = element.getAttribute("name");
    if (element_name) {
      let iframe = document.getElementById(element_name);
      if (iframe) {
        iframe.remove();
      }
    }
  };
  const addElementSettingOption = (
    event,
    List,
    Project_Menu_list,
    validations = []
  ) => {
    let element = event.target;
    List.map((list) => {
      if (list.value === element.tagName) {
        if (
          element.classList.contains("wp-add-element-button") ||
          element.classList.contains("wp-remove-block-button")
        ) {
          return;
        }
        let validationIsValid = false;
        validations.map((validation) => {
          if (validation) {
            validationIsValid = true;
          }
        });
        if (!validationIsValid) {
          Project_Menu_list.push({
            label: `${list.label} Setting`,
            icon: list.icon,
            divider: false,
            handleClick: () => handleElementClick(event, element),
          });
        }
      }
    });
  };
  const removeElementOption = (
    event,
    List,
    Project_Menu_list,
    validations = []
  ) => {
    let element = event.target;
    List.map((list) => {
      if (list.value === element.tagName) {
        if (
          element.classList.contains("wp-add-element-button") ||
          element.classList.contains("wp-remove-block-button")
        ) {
          return;
        }
        let validationIsValid = false;
        validations.map((validation) => {
          if (validation) {
            validationIsValid = true;
          }
        });
        if (!validationIsValid) {
          Project_Menu_list.push({
            label: `Remove ${list.label}`,
            icon: "material-symbols:delete-outline-sharp",
            color: "red",
            divider: true,
            handleClick: () => {
              removeElement(element);
            },
          });
        }
      }
    });
  };

  const getPlanCard = (id) => {
    let { payment_plan_card, status } = getSelectedSectionPlanByID(id);

    return { payment_plan_card, status };
  };

  const handleElementRightClick = (e, element) => {
    e.preventDefault();

    let disabled = false;
    disabled = element.classList?.contains("wb-disable");

    setSelectedElement(element);

    let is_editor = _find_is_editor_element(element);
    let is_span_editor = checkIsEditorElement(element);
    let is_parent_anchor = isParentAnchorTag(e.target);
    setRightMenuAnchorEl(e);

    const Project_Menu_list = [];

    if (!disabled || element.tagName === "I") {
      addElementSettingOption(e, list_Elements, Project_Menu_list);
      addElementSettingOption(e, spanElement, Project_Menu_list, [
        is_editor,
        is_span_editor,
        is_parent_anchor,
      ]);
    }

    if (is_parent_anchor && (!disabled || element.tagName === "I")) {
      Project_Menu_list.push({
        label: `Button Setting`,
        icon: "material-symbols:radio-button-checked-outline",
        divider: false,
        handleClick: () => handleElementClick(e, e.target),
      });
    }

    if (is_editor) {
      Project_Menu_list.push({
        label: `Editor Setting`,
        icon: "material-symbols:text-fields-rounded",
        divider: false,
        handleClick: () => {
          setSelectedElement(is_editor);
          setTimeout(() => {
            if (!editorMenuOpen) {
              setEditorMenuOpen(true);
            }
          }, 100);
        },
      });
    }
    let sectionElement = _find_section_of_element(element);
    let rowElement = _find_parent_row_of_element(element);
    let formElement = _find_element_has_tag(element, "FORM");
    if (formElement) {
      Project_Menu_list.push({
        label: "Form Settings",
        icon: "ant-design:form-outlined",
        divider: false,
        handleClick: () => {
          setSelectedSectionSettingId(sectionElement?.getAttribute("id"));
          setSelectedElement(formElement);
          setFormSettingsMenuOpen(true);
        },
      });
    }
    if (
      (_find_element_has_tag(element, "UL") ||
        _find_element_has_tag(element, "OL")) &&
      !isElementOfEditor(element)
    ) {
      Project_Menu_list.push({
        label: "List Settings",
        icon: "clarity:view-list-line",
        divider: false,
        handleClick: () => {
          setSelectedElement(
            _find_element_has_tag(element, "OL") ||
              _find_element_has_tag(element, "UL")
          );
          setOpenListBlockDrawer(true);
        },
      });
    }

    let paymentCard = _find_element_has_class(element, "wb-plan-card");
    if (paymentCard) {
      Project_Menu_list.push({
        label: "Plan Card Settings",
        icon: "radix-icons:id-card",
        divider: false,
        handleClick: () => {
          setSelectedElement(paymentCard);
          setOpenPlanCardSetting(true);
        },
      });
    }

    let paymentPlans =
      sectionElement !== false &&
      getPlanCard(sectionElement?.getAttribute("id"));

    if (paymentPlans?.status) {
      Project_Menu_list.push({
        label: "Plans Setting",
        icon: "fluent:payment-32-filled",
        divider: false,
        handleClick: () => {
          setSelectedSectionSettingId(sectionElement?.getAttribute("id"));
          setPlanSettingsMenuOpen(true);
        },
      });
    }

    if (rowElement) {
      Project_Menu_list.push({
        label: "Layout Setting",
        icon: "charm:layout-stack-h",
        divider: false,
        handleClick: () => {
          setSelectedRow(rowElement);
          setSelectedRowClasses(rowElement.classList);
          setTimeout(() => {
            setOpenRowSetting(true);
          }, 100);
        },
      });
    }
    if (sectionElement) {
      Project_Menu_list.push({
        label: "Section Setting",
        icon: "carbon:row",
        divider: false,
        handleClick: () => {
          handleOpenSectionSettings(sectionElement?.getAttribute("id"));
        },
      });
      if (
        !(
          is_editor ||
          is_span_editor ||
          (element.classList &&
            element.classList.contains("wb-replaceable-element"))
        )
      ) {
        if (validateElement(element)) {
          Project_Menu_list.push({
            label: "Add New Element",
            icon: "material-symbols:add",
            divider: false,
            handleClick: () => {
              setIsReplaceableElement(false);
              setOpenReplaceElementMenu(true);
            },
          });
        }
      }
    }

    if (!disabled || element.tagName === "I") {
      removeElementOption(e, spanElement, Project_Menu_list, [
        is_editor,
        is_span_editor,
        is_parent_anchor,
      ]);
      removeElementOption(e, list_Elements, Project_Menu_list);
    }

    if (is_parent_anchor && (!disabled || element.tagName === "I")) {
      Project_Menu_list.push({
        label: `Remove Button`,
        icon: "material-symbols:delete-outline-sharp",
        color: "red",
        divider: true,
        handleClick: () => {
          removeElement(e.target);
        },
      });
    }

    if (formElement) {
      Project_Menu_list.push({
        label: `Remove Form`,
        icon: "material-symbols:delete-outline-sharp",
        color: "red",
        divider: false,
        handleClick: () => {
          removeElement(formElement);
        },
      });
    }

    if (is_editor) {
      Project_Menu_list.push({
        label: `Remove Editor Block`,
        icon: "material-symbols:delete-outline-sharp",
        color: "red",
        divider: true,
        handleClick: () => {
          removeElement(is_editor, false);
        },
      });
    }

    setRightMenuList(Project_Menu_list);

    if (is_editor) {
      setEditAbleInputs({
        ...editAbleInputs,
        text: is_editor.innerHTML,
      });
      return;
    }
    setEditAbleInputs({
      ...editAbleInputs,
      text: element.innerText,
    });
  };

  useEffect(() => {
    if (rightMenuAnchorEl) {
      handleElementRightClick(rightMenuAnchorEl, selectedElement);
      let is_editor = _find_is_editor_element(rightMenuAnchorEl?.target);
      if (is_editor) {
        setSelectedElement(is_editor);
      }
    }
  }, [rightMenuAnchorEl]);

  return (
    <>
      <div
        className="right-menu-wrapper"
        style={{
          visibility: rightMenuAnchorEl ? "visible" : "hidden",
        }}
      >
        <Box>
          <List
            component="nav"
            aria-label="secondary mailbox folder"
            onClick={handleClose}
            sx={{ padding: "0 0" }}
          >
            {rightMenuList?.map((list, index) => (
              <>
                <div key={index}>
                  {list?.divider == true && <Divider className="my-2" />}

                  <ListItemButton
                    // disabled={list.disable}
                    onClick={list.handleClick}
                    sx={{
                      borderRadius: "8px",
                      cursor: "pointer",
                      marginInline: "8px",
                      padding: "2px 30px 2px 8px",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor:
                          list.backgroundColor && list.backgroundColor,
                      },
                    }}
                  >
                    <Icon
                      icon={list.icon}
                      className="me-3"
                      style={{
                        color: list.color ? list.color : "#023373",
                      }}
                    />
                    <ListItemText
                      sx={{
                        ".MuiListItemText-primary": {
                          fontSize: "14px",
                          color: list.color ? list.color : "#023373",
                        },
                      }}
                      primary={list.label}
                    />
                  </ListItemButton>
                </div>
              </>
            ))}
          </List>
        </Box>
      </div>
    </>
  );
};

export default MenuPopup;
