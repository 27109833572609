import React, { useEffect, useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { Button, Tooltip } from "@mui/material";
import { _get_book_a_call_event_listing } from "../../../../DAL/BookACallEvents/BookACall";
import { _dispatch_find_element_by_id_and_child } from "../../../../hooks/builder-context/utils";

export default function EventSettings() {
  const [selectedEvent, setSelectedEvent] = useState();
  const {
    setEventSettingsMenuOpen,
    rootValues,
    setBookACallEventListing,
    bookACallEventListing,
    updateAttributeOfSelectedSection,
    selectedSectionSettingId,
  } = useBuilder();

  const handleGetEventListing = async () => {
    const resp = await _get_book_a_call_event_listing(rootValues.page_group_id);
    if (resp.code === 200) {
      setBookACallEventListing(resp.data);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const handleSetEvent = (e) => {
    setSelectedEvent(e.target.value);
    const section = _dispatch_find_element_by_id_and_child(
      selectedSectionSettingId,
      0
    );
    let eventObj;
    if (e.target.value === "") {
      eventObj = {
        _id: "",
        event_title: "Event Title",
        event_daily_time_duration: "Duration",
        event_type: "Event Type",
      };
    } else {
      eventObj = bookACallEventListing.find(
        (event) => event._id === e.target.value
      );
    }
    console.log(eventObj);
    updateAttributeOfSelectedSection("book-a-call-event-id", eventObj._id);
    updateElementValue(section, "wb-event-title", eventObj.event_title);
    updateElementValue(
      section,
      "wb-event-duration",
      `${
        eventObj.event_daily_time_duration === "Duration"
          ? "Duration"
          : `${eventObj.event_daily_time_duration} min`
      }`
    );
    updateElementValue(
      section,
      "wb-organizer-name",
      `${
        eventObj._id === ""
          ? "Organizer Name"
          : `${eventObj.user.first_name} ${eventObj.user.last_name}`
      }`
    );
    updateElementValue(section, "wb-event-type", eventObj.event_type);

    if (eventObj.event_type === "Video Call") {
      updateElementValue(
        section,
        "wb-event-type-icon",
        `<i class="fas fa-video " aria-hidden="true"></i>`
      );
    } else if (
      eventObj.event_type === "Phone Call" ||
      eventObj.event_type === "Event Type"
    ) {
      updateElementValue(
        section,
        "wb-event-type-icon",
        `<i class="fas fa-phone " aria-hidden="true"></i>`
      );
    } else {
      updateElementValue(
        section,
        "wb-event-type-icon",
        `<i class="fas fa-map-marker-alt " aria-hidden="true"></i>`
      );
    }

    let formElement = section.getElementsByTagName("FORM");
    if (eventObj._id === "") {
      if (formElement[0].hasAttribute("data-book_a_call_event_id")) {
        formElement[0].removeAttribute("data-book_a_call_event_id");
      }
    } else {
      formElement[0].setAttribute("data-book_a_call_event_id", eventObj._id);
      // const formInputFields = createInputFields(eventObj);
      // formElement[0].getElementsByClassName("wb-form-fields")[0].innerHTML =
      //   formInputFields;
    }
  };

  function createInputFields(data) {
    let str = "";
    data.event_form_setting.map((input) => {
      str += `<div class="col-md-12 mb-2"><div class="form-group"><label class="d-flex flex-start" style="visibility: visible;">${
        input.label_text
      }</label>
      <${input.tag} class="form-control mt-1" 
            type="${input.type}" 
            name="${input.name}"
            required="${input.input_require}" 
            class="form-control"
            data-name="${input.label_text}" 
            ${input.tag === "textarea" ? `rows="4"` : ""}
            />${input.tag === "textarea" ? "</textarea>" : ""}
      </div>
      </div>
            `;
    });
    return str;
  }

  const updateElementValue = (section, className, value) => {
    let element = section.getElementsByClassName(className);
    [...element].forEach((elm) => (elm.innerHTML = value));
  };

  const getElementAttributeValue = (attr) => {
    const section = _dispatch_find_element_by_id_and_child(
      selectedSectionSettingId,
      0
    );
    let value = section.getAttribute(attr);
    return value ? value : "";
  };

  const GotoClientPortal = () => {
    const baseURL = sessionStorage.getItem("client_base_url");
    let user = sessionStorage.getItem("userType");

    if (baseURL && user === "3") {
      window.open(
        `${baseURL}website-pages/${rootValues.page_group_id}/book-a-call-events`
      );
    } else if (baseURL) {
      window.open(
        `${baseURL}/manage-funnel/${rootValues._id}/${rootValues.page_group_id}/book-a-call-event`
      );
    } else {
      window.open(baseURL);
    }
  };

  useEffect(() => {
    handleGetEventListing();
    setSelectedEvent(getElementAttributeValue("book-a-call-event-id"));
  }, []);

  return (
    <>
      <div className="p-3 pt-0">
        <div
          className="col-12  py-2 mb-2 border-bottom"
          style={{
            position: "sticky",
            zIndex: "100",
            background: "white",
            top: "0%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h6 className="mb-0">Event Settings</h6>
          <div style={{ display: "flex" }}>
            <Tooltip title="Close" placement="bottom" arrow>
              <div
                className="icon me-2"
                onClick={() => setEventSettingsMenuOpen(false)}
              >
                <i class="fa fa-xmark"></i>
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="">
          <div className="pb-3 mb-2">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <span> {`Select Event:`}</span>
              <Button
                size="small"
                variant="outlined"
                onClick={GotoClientPortal}
              >
                Manage Events
              </Button>
            </div>

            <div className="col-12 mt-3">
              <select
                value={selectedEvent}
                onChange={(e) => handleSetEvent(e)}
                className="form-select form-select-sm"
              >
                <option value="">Select Event</option>
                {bookACallEventListing.map((event, index) => (
                  <option key={`${index}`} value={event._id}>
                    {event.event_title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
